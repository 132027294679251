export enum TableFilterActions {
    TOGGLE_FILTER_LICENSING = 'TOGGLE_FILTER_LICENSING',
    TOGGLE_FILTER_CONTRIBUTOR_TYPE = 'TOGGLE_FILTER_CONTRIBUTOR_TYPE',
    TOGGLE_FILTER_CONTENT_TYPE = 'TOGGLE_FILTER_CONTENT_TYPE',
    TOGGLE_FILTER_UPLOAD_DATE = 'TOGGLE_FILTER_UPLOAD_DATE',
    TOGGLE_FILTER_PUBLISH_DATE = 'TOGGLE_FILTER_PUBLISH_DATE',
    TOGGLE_FILTER_CHANNEL = 'TOGGLE_FILTER_CHANNEL',
    TOGGLE_FILTER_SUBCHANNEL = 'TOGGLE_FILTER_SUBCHANNEL',
    TOGGLE_FILTER_THREAD = 'TOGGLE_FILTER_THREAD',
    RESET_FILTERS = 'RESET_FILTERS',
    SET_FLOW_TITLE = "SET_FLOW_TITLE",
    SET_USERNAME = "SET_USERNAME",
    SET_CONTRIBUTOR_NAME = "SET_CONTRIBUTOR_NAME",
    SET_CONTRIBUTOR_TYPE = "SET_CONTRIBUTOR_TYPE",
    SET_NUMBER_OF_CONTENTS = "SET_NUMBER_OF_CONTENTS",
    SET_NUMBER_OF_CONTENT_SETS = "SET_NUMBER_OF_CONTENT_SETS",
    SET_NUMBER_OF_ARTICLES = "SET_NUMBER_OF_ARTICLES",
    SET_SEARCH_STRING = "SET_SEARCH_STRING",
}

export interface TableFilterState {
    licensingType: string;
    contributorType: string;
    contentType: string;
    uploadDate: Date[];
    publishDate: Date[];
    channelFilter: ChannelFilter;
    subchannelFilter: SubchannelFilter;
    threadFilter: ThreadFilter;
    flowTitle: string;
    username: string;
    contributorName: string;
    numberOfContents: number;
    numberOfContentSets: number;
    numberOfArticles: number;
    searchString: string | undefined;
}

export interface ChannelFilter {
    id: number;
    title: string;
    code: string;
}

export interface SubchannelFilter {
    id: number;
    title: string;
}

export interface ThreadFilter {
    id: number;
    title: string;
}