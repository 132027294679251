import { UserActionTypes, UserState, User } from './admin.types';
import {TUserReducerActions} from './admin.actions';

const InitialState : UserState = {
    awaitingAuthentication: false,
    authenticated: false,
    registeredUser: false,
    currentUser: {} as User,
    selectedTabFromNavigation: false,
    contentListRowsSelected: 10,
    waitingListRowsSelected: 10
};

export const adminReducer = ( state = InitialState, action: TUserReducerActions) : UserState => {
            switch(action.type) {
                case UserActionTypes.Login:
                    return {
                        ...state,
                        awaitingAuthentication: true,
                        registeredUser: false
                    };
                case UserActionTypes.LoginSuccess:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: true,
                        currentUser: action.data
                    };
                case UserActionTypes.LoginError:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: false
                    };
                case UserActionTypes.LogoutUser:
                    return {
                        ...state,
                        awaitingAuthentication: true
                    };
                case UserActionTypes.LogoutUserSuccess:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: false,
                        registeredUser: false,
                        currentUser: {} as User,
                        contentListRowsSelected: 10,
                        waitingListRowsSelected: 10
                    };
                case UserActionTypes.LogoutUserError:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: true
                    };
                case UserActionTypes.SelectTabFromNavBar:
                    return {
                        ...state,
                        selectedTabFromNavigation: action.data
                    }
                case UserActionTypes.SelectRowsInContentList:
                    return {
                        ...state,
                        contentListRowsSelected: action.data
                    }
                case UserActionTypes.SelectRowsInWaitingList:
                    return {
                        ...state,
                        waitingListRowsSelected: action.data
                    }
                default:
                    return state;
            }
        }

export default adminReducer;