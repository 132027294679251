import gql from "graphql-tag";

export const GET_ALL_REPORTS = gql`
    query getAllReports{
      getReports{
        user {
          id
          email
          role
          person {
            firstName
            lastName
          }
          company {
            name
          }
        }
        report {
          id
          totalPrice
          contentCount
          bucketLink
          date
        }
      }
    }
`

export const GET_LAST_EXPORT_DATE = gql`
    query getLastExportDate($userId: Int!) {
      getLastExportDate(userId: $userId){
        date
      }
    }
`

export const GENERATE_REPORT = gql`
    mutation generateReport($startDate: DateTime!, $endDate: DateTime!, $userId: Int!, $incomePercentage: Float) {
      generateReport(startDate: $startDate, endDate: $endDate, userId: $userId, incomePercentage: $incomePercentage)
    }
`

export const SEND_GENERATED_REPORT = gql`
  mutation sendGeneratedReportMutation($startDate: DateTime!, $endDate: DateTime!, $userId: Int!, $incomePercentage: Float) {
    sendGeneratedReport(startDate: $startDate, endDate: $endDate, userId: $userId, incomePercentage: $incomePercentage) {
      contents {
        orderId
        salesPerson {
          id
          username
          email
          person {
            id
            firstName
            lastName
          }
          company {
            id
            name
          }
        }
        customerName{
          id
          username
          email
          person {
            id
            firstName
            lastName
          }
          company {
            id
            name
          }
        }
        thumbnail
        referenceId
        headline
        code
        usageInformation
        grossFee
        prefferedCurrency
        royaltyRate
        netEarnings
      }
      totalContentPriceGross
      totalContentPriceNet
    }
  }
`;