export enum EditContentActionTypes {
    SET_SELECTED_IMAGE = "SET_SELECTED_IMAGE",
    SET_SELECTED_IMAGES = "SET_SELECTED_IMAGES",
    SET_MULTISELECT_IMAGES = "SET_MULTISELECT_IMAGES",
    SET_DOWNLOADING_CONTENT_IDS = "SET_DOWNLOADING_CONTENT_IDS",
    SET_DOWNLOADED_CONTENT_IDS = "SET_DOWNLOADED_CONTENT_IDS",
    TOGGLE_ANGLE_CHANGE = "TOGGLE_ANGLE_CHANGE",
    RESET_IMAGE_ANGLES = "RESET_IMAGE_ANGLES",
}

export interface EditContentState {
    selectedImage: number | undefined;
    selectedImages: number[];
    multiselectImages: boolean;
    downloadingContentIds: number[];
    downloadedContentIds: number[];
    imageAngles: ImageAngle[];
}

export interface DownloadedContent{
    id: number;
    clean: boolean;
}

export interface ImageAngle{
    contentId: number;
    rotateDegree: number;
}