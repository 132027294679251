import React from "react";
import { UserUploadedContent } from "../content-list/contentList.types";
import { Article, AudioFile, ContentSet, IndividualImageCategory, UploadedFileResponse } from "../../components/batch-upload/ContentUpload.types";
import {
    CopiedContentToSets, CopiedMultipleContents,
    MovedContentToSets,
    MovedMultipleContents
} from "../batch-upload/EditContentMetadata.types";
import { IBroadcastMessage } from "../../redux/generic/snackbar/snackbar.types";
import { ChannelFilter, SubchannelFilter, ThreadFilter } from "redux/table-filter/table-filter.types";
import { ImageAngle } from "../../redux/EditContent/editContent.types";
import { User } from "../../redux/admin/admin.types";

export const TIME_BEFORE_REDIRECT = 2000;

export interface WaitingListProps {
    contentType: string;
    waitingListData: UserUploadedContent[];
    toolbarError: IBroadcastMessage;
    licensingTypeFilter: string;
    contributorTypeFilter: string;
    contentTypeFilter: string;
    uploadDateFilter: Date[];
    channelFilter: ChannelFilter;
    subchannelFilter: SubchannelFilter;
    threadFilter: ThreadFilter;
    rowsSelected: number;
    waitingListRefreshState: boolean;
    waitingListCurrentPageNumber: number;
    previousSearchString: string | undefined;
    selectRowsInWaitingListAction: (data: number) => void;
    loadedDataAction: (data: UserUploadedContent[]) => void;
    redirectToEditPage: (data: EditContentType) => void;
    toggleRadioButtonAction: (data: string) => void;
    addIndividualImageNavigationAction: (data: IndividualImageCategory) => void;
    broadcastToolbarAction: (data: IBroadcastMessage) => void;
    resetSnackbarStateAction: () => void;
    toggleLicenseTypeFilterAction: (data: string) => void;
    toggleContentTypeFilterAction: (data: string) => void;
    togleContributorTypeFilterAction: (data: string) => void;
    toggleUploadDateFilterAction: (data: Date[]) => void;
    toggleChannelFilterAction: (data: ChannelFilter) => void;
    toggleSubchannelFilterAction: (data: SubchannelFilter) => void;
    toggleThreadFilterAction: (data: ThreadFilter) => void;
    resetTableFilterAction: () => void;
    toggleWaitingListRefresh: (data: boolean) => void;
    toggleContentUserIdAction: (data: number) => void;
    setWaitingListDataTableCurrentPage: (data: number) => void;
    setSearchString: (data: string) => void;
    setSelectedItem: (data: IndividualImageCategory) => void;
}

export interface WaitingListTabsProps {
    routeParams: any;
    selectWaitingListTab: (newTab: number) => void;
    resetSnackbarAction: () => void;
    resetTablesFilterAction: () => void;
    setWaitingListDataTableCurrentPage: (data: number) => void;
    setIncompleteTableCurrentPageNumber: (data: number) => void;
}

export type EditContentType = {
    tabIndex?: number;
    id: number;
    type: string;
}

export interface Content {
    id: number;
    type: string;
    code: string;
    headline: string;
    bucketKey: string;
    publishDate: Date;
    pathToFileCompressed: string;
    pathToFileThumbnail: string;
    externalLink: string;
    externalLinkMessage: string;
    licensingType: string;
    uploadDate: Date;
    status: string;
}

export interface SFTPContent {
    id: number;
    code: string;
    fileName: string;
    mimetype: string;
    url: string;
}

export interface TabProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    contentType: string;
    selectWaitingListTab: (tab: number) => void;
}

export enum WaitingListTabTypes {
    FMC = "FMC",
    STOCK = "Stock",
    THREAD = 'Thread',
    INCOMPLETE = 'Incomplete',
    INCOMPLETE_SFTP = 'Incomplete SFTP'
}


export interface EditWaitingListProps {
    routeParams: any;
    contentSets: ContentSet[];
    selectedRadioButton: string;
    articles: Article[];
    audioFiles: AudioFile[];
    userId: number;
    audioFilesResponses: any;
    individualImageCategories: IndividualImageCategory[];
    movedContents: MovedContentToSets[];
    copiedContents: CopiedContentToSets[];
    snackbar: IBroadcastMessage;
    movedMupltipleContents: MovedMultipleContents[];
    copiedMultipleContents: CopiedMultipleContents[];
    contentUserId: number;
    currentUser: User;
    userSelectedImage: number | undefined;
    userSelectedImages: number[];
    imageAngles: ImageAngle[];
    uploadFilesAction: (data: UploadedFileResponse[]) => void;
    uploadSetAction: (data: ContentSet) => void;
    updateSetAction: (data: ContentSet) => void;
    resetReduxState: () => void;
    resetSnackbar: () => void;
    returnToWaitingList: (data: number) => void;
    approveSingleFileAction: (data: number) => void;
    pendingSingleFileAction: (data: number) => void;
    addAudioFileResponse: (data: any) => void;
    rejectSingleFileAction: (data: number) => void;
    uploadArticleAction: (data: Article) => void;
    toggleRadioButtonAction: (data: string) => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
    resetMultiselectionAction: () => void;
    resetTableFilterAction: () => void;
    resetMovedContentsAction: () => void;
    resetCopiedContentsAction: () => void;
    setMultiselectImages: (data: boolean) => void;
    setUserSelectedImage: (data: number | undefined) => void;
    setUserSelectedImages: (data: number[]) => void;
    resetImageAngles: () => void;
}

export enum ContentTypes {
    CONTENT_SET = 'Content set',
    ARTICLE = 'Article',
    IMAGE = 'image'
}

export enum RouteParamsContentTypes {
    IMAGE = 'image',
    CONTENT_SET= 'content set',
    ARTICLE = 'article'
}
