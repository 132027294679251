import React, { useEffect, useState } from 'react';
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { dialogStyles } from '../users-table/usersAdminTable.types';
import { Commission, EditMWUserCommissionTypes, useStyles } from './commissions.types';
import { LicensePlanTypes } from '../license-table/LicenseAdminTable.types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EDIT_LOCATION_OVERRIDE, UPDATE_MW_USER_COMMISSION } from './queries';
import { useMutation } from '@apollo/react-hooks';
import { SeveritySnackbarEnum } from '../../redux/generic/snackbar/snackbar.types';

const EditCommissionsModal: React.FC<EditMWUserCommissionTypes> = ({ ...props }) => {
    const { broadcastSnackbarAction, refetch, loadedData, tableMeta } = props;

    const commissionId = parseInt(tableMeta[0]);
    const percentage = parseInt(tableMeta[2].split("%")[0]);
    const threshold = parseInt(tableMeta[3]);

    const [openModal, setOpenModal] = useState(false);
    const [response, setResponseState] = useState("");

    const classes = useStyles();

    const [updateMWUserCommissionMutation] = useMutation(UPDATE_MW_USER_COMMISSION);

    const validationSchema = Yup.object({
        percentage: Yup.number().required("You must enter percentage.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            )
    });

   
    const { handleSubmit, handleChange, values, errors, resetForm} = useFormik({
        initialValues: {
            threshold,
            percentage
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            updateCommission(values);
        }
    })

    const openUpdateModal = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        resetForm({});
    };

    const updateCommission = (commission: any)=>{
        const {percentage,threshold} = commission;
        updateMWUserCommissionMutation({
            variables: {
                id: commissionId,
                percentage: percentage,
                amount: threshold
          }
        }).then((result: any) => {
            refetch();
            handleClose();
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Commission updated successfully!"
            })
        }).catch((error: any) => {
            setResponseState(error.graphQLErrors[0].message);
        })
    }
    return (
        <React.Fragment>
            <Tooltip title={"Edit"}>
                <IconButton onClick={openUpdateModal}>
                    <CreateIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={openModal} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={openModal}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>
                            <div className='add-user-container'>
                                <h1 className='title'>Update commissions</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                variant="standard"
                                                label="Percentage"
                                                onChange={handleChange}
                                                defaultValue={percentage}
                                            />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="threshold"
                                                onChange={handleChange}
                                                label="Threshold"
                                                variant="standard"
                                                value={values.threshold}
                                                error={errors.threshold === ''}
                                                helperText={errors.threshold ? errors.threshold : null}
                                                FormHelperTextProps={{ className: classes.error }}
                                            />
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Update</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    )
}

export default EditCommissionsModal;