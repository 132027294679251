import { TTableFiltersReducerActions } from "./table-filter.actions";
import { TableFilterActions, TableFilterState } from "./table-filter.types";

const InitialState: TableFilterState = {
    licensingType: '',
    contributorType: '',
    contentType: '',
    uploadDate: [],
    publishDate: [],
    channelFilter: {id: 0, title: "", code: ""},
    subchannelFilter: {id: 0, title: ""},
    threadFilter: {id: 0, title: ""},
    flowTitle: "",
    username: "",
    contributorName: "",
    numberOfContents: -1,
    numberOfContentSets: -1,
    numberOfArticles: -1,
    searchString: undefined,
}

export const tableFilterReducer = (state = InitialState, action: TTableFiltersReducerActions) : TableFilterState => {
    switch(action.type) {
        case TableFilterActions.TOGGLE_FILTER_LICENSING: {
            return { 
                ...state,
                licensingType: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_CONTRIBUTOR_TYPE: {
            return {
                ...state,
                contributorType: action.data
            }
        }
        case TableFilterActions.SET_CONTRIBUTOR_NAME: {
            return {
                ...state,
                contributorName: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_CONTENT_TYPE: {
            return {
                ...state,
                contentType: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_UPLOAD_DATE: {
            return {
                ...state,
                uploadDate: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_PUBLISH_DATE: {
            return {
                ...state,
                publishDate: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_CHANNEL: {
            return {
                ...state,
                channelFilter: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_SUBCHANNEL: {
            return {
                ...state,
                subchannelFilter: action.data
            }
        }
        case TableFilterActions.TOGGLE_FILTER_THREAD: {
            return {
                ...state,
                threadFilter: action.data
            }
        }
        case TableFilterActions.SET_FLOW_TITLE:
            return {
                ...state,
                flowTitle: action.data
            };
        case TableFilterActions.SET_USERNAME:
            return {
                ...state,
                username: action.data
            };
        case TableFilterActions.SET_SEARCH_STRING:
            return {
                ...state,
                searchString: action.data
            };
        case TableFilterActions.SET_NUMBER_OF_CONTENTS:
            return {
                ...state,
                numberOfContents: action.data
            };
        case TableFilterActions.SET_NUMBER_OF_CONTENT_SETS:
            return {
                ...state,
                numberOfContentSets: action.data
            };
        case TableFilterActions.SET_NUMBER_OF_ARTICLES:
                return {
                    ...state,
                    numberOfArticles: action.data
                };
        case TableFilterActions.RESET_FILTERS: {
            return {
                ...state,
                licensingType: '',
                contributorType: '',
                contentType: '',
                uploadDate: [],
                publishDate: [],
                channelFilter: {id: 0, title: "", code: ""},
                subchannelFilter: {id: 0, title: ""},
                threadFilter: {id: 0, title: ""},
                flowTitle: "",
                username: "",
                contributorName: "",
                numberOfContents: -1,
                numberOfContentSets: -1,
                numberOfArticles: -1,
                searchString: undefined,
            }
        }
        default: {
            return state;
        }
    }
}
