import gql from "graphql-tag";

export const APPROVE_THREAD = gql`
    mutation approveThreadMutation($threadId: Int!) {
        approveThread(threadId: $threadId)
    }
`

export const REJECT_THREAD = gql`
    mutation rejectThreadMutation($threadId: Int!) {
        rejectThread(threadId: $threadId)
    }
`

export const GET_SUGGESTED_THREADS = gql`
    query getSuggestThreadInfo {
        getSuggestThreadInfo {
            threadId
            contributor {
                username
                role
                person {
                    id
                    firstName
                    lastName
                }
                company {
                    id
                    name
                }
            }
            threadNavigation {
                channel {
                    id
                    title
                }
                subChannel {
                    id
                    title
                }
                thread {
                    id
                    title
                }
            }
        }
}
`


export const IS_THREAD_SINGLE_NAVIGATION = gql`
    query isThreadSingleNavigation($threadId: Int!){
        isThreadSingleNavigation(threadId: $threadId)
    }
`