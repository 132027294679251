import gql from 'graphql-tag';

export const ALL_MEMBERS = gql`
    query allMembers {
        getAllMembers {
            id
            username
            email
            authStrategy
            role
            incomePercentage
            person {
                id 
                firstName
                lastName
            }
        }
    }
`;

export const ALL_CONTRIBUTORS = gql`
    query allContributors {
        getAllContributors {
            id
            username
            email
            authStrategy
            role
            incomePercentage
            preferredCurrency
            hasPublicSFTPAccess
            hasSFTPAccess
            person {
                id
                firstName
                lastName
            }
            company {
                id
                name
            }
        }
    }
`;

export const ALL_PROS = gql`
    query allPros {
        getAllPros {
            id
            username
            email
            authStrategy
            role
            incomePercentage
            person {
                id
                firstName
                lastName
            }
            company {
                id
                name
            }
        }
    }
`;

export const ALL_ADMINS = gql`
    query allAdmins {
        getAllAdmins {
            id
            username
            email
            authStrategy
            role
            incomePercentage
            person {
                id
                firstName
                lastName
            }
        }
    }
`;

export const CONTRIBUTORS_WITH_SFTP_REQUESTS = gql`
    query getUsersWithSFTPAccessRequests {
        getUsersWithSFTPAccessRequests {
            id
            username
            email
            authStrategy
            role
            incomePercentage
            company {
                id
                name
            }
        }
    }
`;

export const CREATE_USER = gql`
    mutation createUserMutation($username: String!, $email: String!, $password: String!, $role: String!, $authStrategy: String!, $firstName: String!, $lastName: String!,$organizationName: String!) {
        createUser(input: {username: $username, email: $email, password: $password, role: $role, authStrategy: $authStrategy, firstName: $firstName, lastName: $lastName,organizationName: $organizationName}) {
            id
            email
        }
    }
`;

export const UPDATE_USER = gql`
mutation updateUserMutation(
    $id: Int!
    $email: String!
    $password: String!
    $role: String!
    $firstName: String!
    $lastName: String!
    $organizationName: String!
    $currentUserEmail: String!
    $hasPublicSFTPAccess: Boolean
  ) {
    updateUser(
      input: {
        id: $id
        email: $email
        password: $password
        role: $role
        firstName: $firstName
        lastName: $lastName
        organizationName: $organizationName
      }
      currentUserEmail: $currentUserEmail
      hasPublicSFTPAccess: $hasPublicSFTPAccess
    ) {
      id
      email
      authStrategy
      role
      hasPublicSFTPAccess
    }
  }
`;

export const DELETE_USER = gql`
    mutation deleteUserMutation($id: Int!, $currentUserId: Int!){
        deleteUser(id: $id, currentUserId: $currentUserId)
    }
`;

export const DELETE_USERS = gql`
    mutation deleteUsersMutation($ids: [Int!]!, $currentUserId: Int!){
        deleteUsers(ids: $ids, currentUserId: $currentUserId)
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation changePasswordMutation($email: String!, $password: String!, $oldPassword: String!) {
        changePasswordAdmin(email: $email, password: $password, oldPassword: $oldPassword) {
            id
        }
    }
`;

export const GENERATE_SFTP_USER = gql`mutation generateSFTPUser($id: Int!, $hasPublicSFTPAccess: Boolean!) {
    generateSFTPUser(id: $id hasPublicSFTPAccess: $hasPublicSFTPAccess)
  }
`;