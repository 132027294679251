import gql from 'graphql-tag';

export const GET_ALL = gql`
    query getAllQuery {
        getAll
        getAllChannels {
            id
            title
        }
    }
`;

export const GET_CHANNELS = gql`
    query getAllChannelsQuery {
        getAllChannels {
            id
            title
            code
        }
    }
`;

export const GET_SUBCHANNELS = gql`
    query getAllSubchannelsByChannelIdQuery ($id: Int!) {
        getAllSubChannelsByChannelId(id: $id) {
            id
            title
            channelId
        }
    }
`;

export const GET_THREADS = gql`
    query getAllThreadsBySubchannelIdQuery ($id: Int!) {
        getAllThreadsBySubChannelId(id: $id) {
            id
            title
            subChannelId
            hasContent
        }
    }
`;


export const GET_THREAD_PATH = gql`
    query getThreadPathQuery($subChannelId: Int!) {
        getThreadPath(subChannelId: $subChannelId) {
            channelId
            id
        }
    }
`;

export const CREATE_CHANNEL = gql`
    mutation createChannelMutation ($title: String!) {
        createChannel(title: $title) {
            id
            title
        }
    }
`;

export const CREATE_SUBCHANNEL = gql`
    mutation createSubChannelMutation ($title: String!, $channelId: Int!)  {
        createSubChannel(title: $title, channelId: $channelId) {
            id
            title
            channelId
        }
    }
`;

export const CREATE_THREAD = gql`
    mutation createThreadMutation ($title: String!, $subChannelId: Int!) {
        createThread(title: $title, subChannelId: $subChannelId) {
            id
            title
            subChannelId
        }
    }
`;

export const UPDATE_CHANNEL = gql`
    mutation updateChannelMutation ($title: String!, $id: Int!) {
        updateChannel(title: $title, id: $id) {
            id
            title
        }
    }
`;

export const UPDATE_SUBCHANNEL = gql`
    mutation updateSubChannelMutation ($title: String!, $id: Int!, $channelId: Int!, $subChannelId: Int!, $category: String!) {
        updateSubChannel(title: $title, id: $id, channelId: $channelId, subChannelId: $subChannelId, category: $category) {
            id
            title
        }
    }
`;

export const UPDATE_THREAD = gql`
    mutation updateThreadMutation ($title: String!, $id: Int!, $channelId: Int!, $subChannelId: Int!, $category: String!) {
        updateThread(title: $title, id: $id, channelId: $channelId, subChannelId: $subChannelId, category: $category) {
            id
            title
        }
    }
`;

export const DELETE_CHANNEL = gql`
    mutation deleteChannelMutation ($id: Int!) {
        deleteChannel(id: $id)
    }
`;

export const DELETE_SUBCHANNEL = gql`
    mutation deleteSubChannelMutation ($id: Int!) {
        deleteSubChannel(id: $id)
    }
`;

export const DELETE_THREAD = gql`
    mutation deleteThreadMutation ($id: Int!) {
        deleteThread(id: $id)
    }
`;