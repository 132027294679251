import React, { useEffect, useState } from 'react'
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { dialogStyles } from '../users-table/usersAdminTable.types';
import { LicensePlanTypes } from '../license-table/LicenseAdminTable.types';
import { AddMWUserCommissionTypes, useStyles } from './commissions.types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { ADD_LOCATION_OVERRIDE, ADD_NEW_MW_USER_COMMISSION } from './queries';
import { SeveritySnackbarEnum } from '../../redux/generic/snackbar/snackbar.types';
import { RapidApiCountryDto } from '../../globals/locations/location.types';
import axios from 'axios';
import {config} from "../../config";

const AddMWUserCommission: React.FC<AddMWUserCommissionTypes> = ({...props}) => {
    const { refetch, tableData, broadcastSnackbarAction } = props;
    const [openModal, setOpenModal] = useState(false);
    const [response, setResponseState] = useState("");
    const classes = useStyles();
    
    const validationSchema = Yup.object({
        threshold: Yup.string().required("You must select threshold."),
        percentage: Yup.number().required("You must enter percentage.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            )
    });

    const [addNewMWUserCommissionMutation] = useMutation(ADD_NEW_MW_USER_COMMISSION);

    const addMWUserCommission = (commission: any) => {
        console.log("commission",commission)
        addNewMWUserCommissionMutation({
            variables: {
                percentage: commission.percentage,
                amount: commission.threshold
            }
        }).then((result: any) => {
            refetch();
            handleClose();
            resetForm({});
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "New commission added successfully!"
            })
        }).catch((error: any) => {
            setResponseState(error.graphQLErrors[0].message);
        })
    };

    const { handleSubmit, handleChange, values, errors, resetForm} = useFormik({
        initialValues: {
            amount: 0,
            percentage: 0
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            addMWUserCommission(values);
        }
    })

    const addLocationOverride = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        resetForm({});
    };

    return (
        <React.Fragment>
            <Tooltip title={"Add new override"}>
                <IconButton onClick={addLocationOverride}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={openModal} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={openModal}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>
                            <div className='add-user-container'>
                                <h1 className='title'>Add new commission for MW Users</h1>
                                {response ? <div className='error-box'>Something went wrong</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <TextField
                                                autoComplete="off" 
                                                type="number"
                                                name="percentage"
                                                onChange={handleChange} 
                                                label="Percentage"
                                                variant="standard"
                                                defaultValue={0}
                                                error={errors.percentage === ''}
                                                helperText={errors.percentage ? errors.percentage : null}
                                                FormHelperTextProps={{ className: classes.error }} 
                                            />
                                             <TextField
                                                autoComplete="off" 
                                                type="number"
                                                name="threshold"
                                                onChange={handleChange} 
                                                label="Threshold"
                                                variant="standard"
                                                defaultValue={0}
                                                error={errors.percentage === ''}
                                                helperText={errors.percentage ? errors.percentage : null}
                                                FormHelperTextProps={{ className: classes.error }} 
                                            />
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Add Commission</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    )
};

export default AddMWUserCommission;