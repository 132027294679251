import gql from 'graphql-tag';

export const GET_ALL_LICENSING_PLANS = gql`
    query getLicensingPlansQuery{
        getLicensingPlans{
            id
            amount
            type
            totalPrice
            period
            licensingType
        }
    }
`;


export const ADD_LICENSING_PLAN = gql`
    mutation addLicensingPlanMutatuion($amount: Int!, $totalPrice: Int!, $type: String!, $licensingType: String!,  $period: Int){
        addLicensingPlan(amount:$amount, totalPrice:$totalPrice, type:$type, licensingType:$licensingType, period:$period) {
            id
        }
    }
`;


export const DELETE_LICENSING_PLAN = gql`
    mutation deleteLicensePlanMutation($id:Int!){
        deleteLicensePlan(id:$id)
    }
`;

export const UPDATE_LICENSING_PLAN =gql`
    mutation updateLicensingPlanMutatuins($id: Int!, $amount: Int!, $totalPrice: Int!, $type:String!, $licensingType: String!, $period: Int){
        updateLicensingPlan(id:$id, amount:$amount, totalPrice:$totalPrice, type:$type, licensingType:$licensingType, period:$period)
        {
            id
        }
    }
`;
export const GET_FLAT_RATES =gql`
    query getContentRatesQuery
    {
        getContentRates{
            id,
            licensingType,
            contentType,
            role,
            flatRate
        }
    }
`;

export const ADD_FLAT_RATE =gql`
    mutation addContentRateMutation($licenseType: String!, $flatRate: Int!, $contentType: String!, $role: String!){
        addContentRate(licenseType: $licenseType, flatRate: $flatRate, contentType: $contentType, role: $role)
        {
            id
        }
    }
`;

export const DELETE_FLAT_RATE=gql`
    mutation deleteContentRateMutation($id: Int!){
        deleteContentRate(id:$id)
    }
`;

export const UPDATE_FLAT_RATE=gql`
    mutation updateContentRateMutation($id: Int!, $licenseType: String!, $flatRate: Int!, $contentType: String!, $role: String!)
    {
        updateContentRate(id:$id, licenseType: $licenseType, flatRate: $flatRate, contentType: $contentType, role: $role)
        {
            id
        }
    }
`;


export const GET_EDITORIAL_RATES =gql`
    query getEditorialRatesQuery{
        getEditorialRates{
            id,
            contentType,
            rate,
            size,
        }
    }
`;

export const ADD_EDITORIAL_RATE =gql`
    mutation addEditorialRateMutation($licenseType: String!, $rate: Int!, $contentType: String!, $size: String!){
        addEditorialRate(licenseType: $licenseType, rate: $rate, contentType: $contentType, size:$size)
        {
            id
        }
    }
`;

export const DELETE_EDITORIAL_RATE=gql`
    mutation deleteEditorialRateMutation($id: Int!){
        deleteEditorialRate(id:$id)
    }
`;

export const UPDATE_EDITORIAL_RATE=gql`
    mutation updateEditorialRateMutation($id: Int!, $rate: Int!, $contentType: String!, $size: String!){
        updateEditorialRate(id:$id, rate: $rate, contentType: $contentType , size:$size)
        {
            id
        }
    }
`;