import {
    CopiedContentToSets, CopiedMultipleContents,
    MovedContentToSets,
    MovedMultipleContents
} from '../../components/batch-upload/EditContentMetadata.types';
import { ContentSet, UploadedFileResponse, Article, DroppedFile, AudioFile, IndividualImageCategory } from '../../components/batch-upload/ContentUpload.types';

export enum ContentUploadActionTypes {
    TOGGLE_RADIO_BUTTON = "TOGGLE_RADIO_BUTTON",
    UPLOAD_SET = "UPLOAD_SET",
    DELETE_FILES = "DELETE_FILES",
    UPDATE_SET = "UPDATE_SET",
    DELETE_SET = "DELETE_SET",
    UPLOAD_FILES = "UPLOAD_FILES",
    UPLOAD_ARTICLE = "UPLOAD_ARTICLE",
    UPDATE_ARTICLE = "UPDATE_ARTICLE",
    DELETE_ARTICLE = "DELETE_ARTICLE",
    REMOVE_FILE = "REMOVE_FILE",
    ADD_FILE = "ADD_FILE",
    DELETE_FILES_MODAL = 'DELETE_FILES_MODAL',
    REMOVE_ALL_DROPPED_FILES = 'REMOVE_ALL_DROPPED_FILES',
    ADD_AUDIO_FILE = "ADD_AUDIO_FILE",
    DELETE_AUDIO_FILE = "DELETE_AUDIO_FILE",
    LOAD_METADATA = "LOAD_METADATA",
    ADD_CHANNEL_ID = "ADD_CHANNEL_ID",
    ADD_CHANNEL_TO_INDIVIDUAL= "ADD_CHANNEL_TO_INDIVIDUAL",
    RESET_CONTENT = "RESET_CONTENT",
    APPROVE_SINGLE_FILE = "APPROVE_SINGLE_FILE",
    PENDING_SINGLE_FILE = "PENDING_SINGLE_FILE",
    REJECT_SINGLE_FILE = "REJECT_SINGLE_FILE",
    DELETE_SINGLE_FILE = "DELETE_SINGLE_FILE",
    ADD_AUDIO_FILE_RESPONSE = "ADD_AUDIO_FILE_RESPONSE",
    REMOVE_AUDIO_FILE_RESPONSE = "REMOVE_AUDIO_FILE_RESPONSE",
    BROADCAST_MESSAGE = "BROADCAST_MESSAGE",
    MOVE_CONTENT_TO_NEW_SET = "MOVE_CONTENT_TO_NEW_SET",
    COPY_CONTENT_TO_NEW_SET = "COPY_CONTENT_TO_NEW_SET",
    REMOVE_CONTENT_TO_NEW_SET = "REMOVE_CONTENT_TO_NEW_SET",
    MOVE_MULTIPLE_CONTENT_TO_NEW_SET = "MOVE_MULTIPLE_CONTENT_TO_NEW_SET",
    COPY_MULTIPLE_CONTENT_TO_NEW_SET = "COPY_MULTIPLE_CONTENT_TO_NEW_SET",
    RESET_MULTISELECTION = "RESET_MULTISELECTION",
    TOGGLE_SAVED_CHANGES = "TOGGLE_SAVED_CHANGES",
    RESET_MOVED_CONTENTS = "RESET_MOVED_CONTENTS",
    RESET_COPIED_CONTENTS = "RESET_COPIED_CONTENTS"
} 

export interface ContentUploadState {
    uploadedFiles: UploadedFileResponse[];
    contentSets: ContentSet[];
    articles: Article[];
    audioFiles: AudioFile[];
    audioFilesResponses: any[];
    droppedFiles: DroppedFile[];
    selectedRadioButton: string;
    individualImagesCategory: IndividualImageCategory[];
    movedContents: MovedContentToSets[];
    copiedContents: CopiedContentToSets[];
    movedMultipleContents: MovedMultipleContents[];
    copiedMultipleContents: CopiedMultipleContents[];
    changesToBeSaved: boolean;
    selectedIncompleteSFTPSet: any;
}
