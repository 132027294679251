import { TSnackbarActions } from "./snackbar.actions";
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes, SnackbarState } from "./snackbar.types";

const initialState: SnackbarState = {
    snackbar: {} as IBroadcastMessage
}

export const snackbarReducer = (state = initialState, action: TSnackbarActions) => {
    switch(action.type) {
        case SnackbarActionTypes.BROADCAST_MESSAGE: 
            return {
                ...state,
                snackbar: action.data
            }
        case SnackbarActionTypes.RESET_MESSAGE: 
            return {
                snackbar: {
                    severity: SeveritySnackbarEnum.none,
                    message: ""
                }
            }
        default:
            return state;
    }
}