import {ContentUploadActionTypes, ContentUploadState } from './content-upload.types';
import { TContentUploadActions } from './content-upload.actions';
import {
    addOrUpdateArticle,
    addImageCategory,
    rejectSingleImage,
    approveSingleImage,
    updateSet,
    updateMovedContents,
    updateMultipleMovedData,
    deleteSingleImage,
    updateCopiedContents, updateMultipleCopiedData, pendingSingleImage
} from './content-upload.functions';
import { ContentSet, DroppedFile, UploadedFileResponse, RadioButtonGroup } from '../../components/batch-upload/ContentUpload.types';
import {CopiedContentToSets, MovedContentToSets} from '../../components/batch-upload/EditContentMetadata.types';

const InitialState: ContentUploadState = {
    uploadedFiles: [],
    contentSets: [],
    articles: [],
    audioFiles: [],
    audioFilesResponses: [],
    droppedFiles: [],
    selectedRadioButton: 'individual',
    individualImagesCategory: [],
    movedContents: [],
    copiedContents: [],
    changesToBeSaved: false,
    movedMultipleContents: [],
    copiedMultipleContents: [],
    selectedIncompleteSFTPSet: undefined
};

export const contentUploadReducer = (state = InitialState, action: TContentUploadActions) => {
    switch(action.type) {
        case ContentUploadActionTypes.TOGGLE_RADIO_BUTTON: 
            return {
                ...state,
                selectedRadioButton: action.data
            }
        case ContentUploadActionTypes.UPLOAD_FILES:
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.concat(action.data)
            }
        case ContentUploadActionTypes.ADD_FILE:
                return {
                    ...state,
                    droppedFiles: state.droppedFiles.concat(action.data)
            }
        case ContentUploadActionTypes.DELETE_FILES: 
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.filter((file: UploadedFileResponse) => !action.data.includes(file.id))
            }
        case ContentUploadActionTypes.DELETE_FILES_MODAL: 
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.filter((file: UploadedFileResponse) => !action.data.includes(file.id))
            }
        case ContentUploadActionTypes.UPLOAD_ARTICLE:
            return {
                ...state,
                articles: state.articles.length === 0 ? state.articles.concat(action.data) 
                    : addOrUpdateArticle(state.articles, action.data)
            }
        case ContentUploadActionTypes.UPDATE_ARTICLE:
            return {
                ...state,
                articles: addOrUpdateArticle(state.articles, action.data)
            }
        case ContentUploadActionTypes.DELETE_ARTICLE:
            // eslint-disable-next-line no-case-declarations
            const article = state.articles.find(article => article.contentSetId === action.data);
            return {
                ...state,
                audioFiles: state.audioFiles.filter(audioFile => audioFile.articleId !== article?.contentSetId),
                articles: state.articles.filter(article => article.contentSetId !== action.data),
                audioFilesResponses: article ? state.audioFilesResponses.filter(audio => audio.id !== article?.audioId) : state.audioFilesResponses
            }
        case ContentUploadActionTypes.ADD_AUDIO_FILE:
            // eslint-disable-next-line no-case-declarations
            const articleObj = state.articles.find(article => article.contentSetId === action.data.articleId);
            return {
                ...state,
                audioFiles: state.audioFiles.concat(action.data),
                audioFilesResponses: articleObj ? state.audioFilesResponses.filter(audio => audio.id !== articleObj?.audioId) : state.audioFilesResponses
            }
        case ContentUploadActionTypes.DELETE_AUDIO_FILE:
            return {
                ...state,
                audioFiles: state.audioFiles.filter(audioFile => audioFile.articleId !== action.data)
            }
        case ContentUploadActionTypes.ADD_AUDIO_FILE_RESPONSE:
            return {
                ...state,
                audioFilesResponses: state.audioFilesResponses.concat(action.data)
            }
        case ContentUploadActionTypes.REMOVE_AUDIO_FILE_RESPONSE:
            return {
                ...state,
                audioFilesResponses: state.audioFilesResponses.filter(audio => audio.id !== action.data)
            }
        case ContentUploadActionTypes.UPLOAD_SET:
            return {
                ...state,
                selectedIncompleteSFTPSet: action.data,
                contentSets: state.contentSets.concat(action.data),
                uploadedFiles: state.uploadedFiles.filter((file: UploadedFileResponse) => !action.data.files.includes(file))
            }
        case ContentUploadActionTypes.UPDATE_SET:
            return {
                ...state,
                contentSets: updateSet(action.data, state.contentSets)
            }
        case ContentUploadActionTypes.REMOVE_FILE:
                return {
                    ...state,
                    droppedFiles: state.droppedFiles.filter((file: DroppedFile) => parseInt(file.fileWithMeta.meta.id, 10) !== action.data)
            }
        case ContentUploadActionTypes.REMOVE_ALL_DROPPED_FILES:
                return {
                    ...state,
                    droppedFiles: []
            }
        case ContentUploadActionTypes.DELETE_SET:
            return {
                ...state,
                contentSets: state.contentSets.filter((set: ContentSet) => set.id !== action.data)
            }
        case ContentUploadActionTypes.ADD_CHANNEL_ID:
            return {
                ...state,
                contentSets: state.contentSets.map(contentSet => contentSet.id === action.data.selectedContentSetId ? 
                    {...contentSet, selectedThreadIds: action.data.selectedThreads} : contentSet)
            }
        case ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL:
            return {
                ...state,
                individualImagesCategory: addImageCategory(state.individualImagesCategory, action.data)
            }
        case ContentUploadActionTypes.REJECT_SINGLE_FILE:
            return {
                ...state,
                contentSets: rejectSingleImage(state.contentSets, action.data)
            }
        case ContentUploadActionTypes.PENDING_SINGLE_FILE:
            return {
                ...state,
                contentSets: pendingSingleImage(state.contentSets, action.data)
            }
        case ContentUploadActionTypes.APPROVE_SINGLE_FILE:
            return {
                ...state,
                contentSets: approveSingleImage(state.contentSets, action.data)
            }
        case ContentUploadActionTypes.DELETE_SINGLE_FILE:
            return {
                ...state,
                contentSets: deleteSingleImage(state.contentSets, action.data)
            }
        case ContentUploadActionTypes.TOGGLE_SAVED_CHANGES:
            return {
                ...state,
                changesToBeSaved: action.data
            }
        case ContentUploadActionTypes.MOVE_CONTENT_TO_NEW_SET:
            return {
                ...state,
                movedContents: updateMovedContents(state.movedContents, action.data)
            }
        case ContentUploadActionTypes.COPY_CONTENT_TO_NEW_SET:
            // eslint-disable-next-line no-case-declarations
            const obje = {
                ...state,
                copiedContents: updateCopiedContents(state.copiedContents, action.data)
            }
            return obje;

        case ContentUploadActionTypes.COPY_MULTIPLE_CONTENT_TO_NEW_SET:
            // eslint-disable-next-line no-case-declarations
            const obj = {
                ...state,
                copiedContents: action.data
            }
            return obj;
        case ContentUploadActionTypes.REMOVE_CONTENT_TO_NEW_SET:
            return {
                ...state,
                movedContents: state.movedContents.filter((content: MovedContentToSets) => content.contentId !== action.data.contentId),
                copiedContents: state.copiedContents.filter((content: CopiedContentToSets) => content.contentId !== action.data.contentId)
            }
        case ContentUploadActionTypes.RESET_MOVED_CONTENTS:
            return {
                ...state,
                movedContents: [],
                movedMultipleContents: []
            }

        case ContentUploadActionTypes.RESET_COPIED_CONTENTS:
            return {
                ...state,
                copiedContents: [],
                copiedMultipleContents: []
            }
        case ContentUploadActionTypes.MOVE_MULTIPLE_CONTENT_TO_NEW_SET:
            return {
                ...state,
                movedMultipleContents: updateMultipleMovedData(state.movedMultipleContents, action.data)
            }
        case ContentUploadActionTypes.RESET_CONTENT: 
            return {
                uploadedFiles: [],
                contentSets: [],
                articles: [],
                audioFiles: [],
                audioFilesResponses: [],
                droppedFiles: [],
                selectedRadioButton: 'individual',
                individualImagesCategory: [],
                movedContents: [],
                copiedContents: [],
                changesToBeSaved: false,
                movedMultipleContents: [],
                copiedMultipleContents: [],
                selectedIncompleteSFTPSet: undefined
            }
        case ContentUploadActionTypes.RESET_MULTISELECTION:
            return {
                ...state,
                movedContents: [],
                movedMultipleContents: [],
                copiedContents: [],
                copiedMultipleContents: []
            }
        default: 
            return state;
    }
}
