import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, FormControl, FormGroup, FormLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useQuery } from 'react-apollo-hooks';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentSetContent, IncompleteSFTPUserUploadFlow, IncompleteSFTPUploadProps, SFTPFilesResponse } from './incompleteSFTPUpload.types';
import SyncLoader from 'react-spinners/SyncLoader';
import { CLEAN_SFTP_TEMP_FOLDER, DELETE_INCOMPLETE_FLOW, GET_ALL_INCOMPLETE_SFTP_FLOWS, GET_SFTP_FOLDERS, UPLOAD_SFTP_SET_FOLDER } from './queries';
import Moment from "react-moment";
import { AdminStoreState } from '../../redux/root-reducer';
import { Dispatch } from 'redux';
import { RefreshDateTime, SnackbarComponent, SearchBarComponent } from 'shared';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { Article, ContentSet, IndividualImageCategory, RadioButtonGroup, SFTPContentSet, UploadedFileResponse } from '../batch-upload/ContentUpload.types';
import { IAddAudioFileResponse, IAddChannelToIndividualImage, IResetContentUpload, ISFTPUploadSet, IToggleRadioButton, IUpdateSet, IUploadArticle, IUploadFiles, IUploadSet, TContentUploadActions } from '../../redux/content-upload/content-upload.actions';
import { connect } from 'react-redux';
import { Content, SFTPContent } from '../waiting-list/waitingList.types';
import { getIncompleteTableCurrentPageNumber, selectIncompleteFlowData } from '../../redux/incomplete-upload/incompleteUpload.selectors';
import { ILoadIncompleteSFTPData, IncompleteTableCurrentPageNumber, ISelectIncompleteSFTPFlow, TIncompleteSFTPFlowReducerActions } from '../../redux/incomplete-sftp-upload/incompleteSFTPUpload.actions';
import { IncompleteFlowActionTypes } from '../../redux/incomplete-upload/incompleteUpload.types';
import '../waiting-list/editWaitingList.styles.scss';
import { Channel } from '../channels-table/channelsTable.types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { User } from '../../redux/admin/admin.types';
import  ConfirmationModal  from './deleteModalConfirmation.component';
import { selectIncompleteTableRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleIncompleteTableRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { TableFilterActions } from '../../redux/table-filter/table-filter.types';
import { IContributorName, IFlowTitle, INumberOfArticles, INumberOfContents, INumberOfContentSets, ISearchString, IToggleFilterContributorType, IToggleFilterUploadDate, IUsername, TTableFiltersReducerActions } from '../../redux/table-filter/table-filter.actions';
import { getSearchString, selectContributorName, selectContributorType, selectFlowTitle, selectNumberOfArticles, selectNumberOfContents, selectNumberOfContentSets, selectUploadDate, selectUsername } from '../../redux/table-filter/table-filter.selectors';
import { selectIncompleteSFTPFlowData } from '../../redux/incomplete-sftp-upload/incompleteSFTPUpload.selectors';
import { IncompleteSFTPFlowActionTypes } from '../../redux/incomplete-sftp-upload/incompleteSFTPUpload.types';
import { v4 as uuidv4 } from 'uuid';
import { selectContentSets, selectUploadedFiles } from '../../redux/content-upload/content-upload.selectors';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';

const IncompleteSFTPUploadList: React.FC<IncompleteSFTPUploadProps> = ({...props}) => {
    const {incompleteSFTPFlowData, incompleteTableRefreshState, incompleteTableCurrentPageNumber,previousSearchString, flowTitle, username, contributorName, numberOfContents, numberOfContentSets, numberOfArticles, uploadDate, contributorType, loadIncompleteSFTPDataAction,
        uploadedFiles, contentSets, snackbar, addAudioFileResponse, uploadArticleAction, uploadFilesAction, redirectToContentUploadPage, 
        uploadSetAction, resetReduxState, addIndividualImageNavigationAction, toggleRadioButtonAction, broadcastSnackbarAction, 
        selectIncompleteFlowAction, toggleIncompleteTableRefresh,setSearchString} = props;
    const [fetchIncompleteSFTPData,{data, loading, error, refetch}]= useLazyQuery(GET_ALL_INCOMPLETE_SFTP_FLOWS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });
    
    const today = new Date();
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [snackbarIndicator, setSnackbarIndicator] = useState(false);
    const [selectedFlowId, setSelectedFlowId] = useState<number>();
    const [open, setOpen] = useState(false);
    const [deletingTemporaryFolder, setDeletingTemporaryFolder] = useState(false);
    const [userId, setUserId] = useState<number | null>();
    const [selectedFolderName, setSelectedFolderName] = useState<string>("");
    const [fetchingSFTPFolders, setFetchingSFTPFolders] = useState<boolean>(false);
    const [fetchingIncompleteSFTPSets, setFetchingIncompleteSFTPSets] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowsPage, setRowsPerPage] = useState<number>(10);


    // const [fetchFolderData, { data: folderData, loading: loadingFolderData, error: errorFolderData }] = useLazyQuery(GET_SFTP_FOLDERS,
    //     {
    //         fetchPolicy: "no-cache",
    //     });
    const [uploadSFTPSetFolder] = useMutation(UPLOAD_SFTP_SET_FOLDER);
    const uploadSFTPSetFolderMutation = (sftpSetFolderName: string, userId: number) => {
        return uploadSFTPSetFolder({ 
            variables: { sftpSetFolder: sftpSetFolderName, userId: userId } })
            .then((response: any) => {
                return Promise.resolve(response.data.uploadSFTPSetFolder);
            })
            .catch((error: any) => {
                return Promise.reject(error);
            })
    }
    const [deleteIncompleteFlowMutation] = useMutation(DELETE_INCOMPLETE_FLOW, {onCompleted(data) {
        if(data.deleteIncompleteFlow) {
            setSnackbarIndicator(false);
            refetch();
        }
    }, onError(error) {
        setLoadingIndicator(false);
        refetch();
    }});


    const [cleanTempFolderMutation] = useMutation(CLEAN_SFTP_TEMP_FOLDER);
    const cleanTempFolder = (userId: number, folderName: string): Promise<boolean> => {
        return cleanTempFolderMutation({ variables: { id: userId, folderName } })
            .then((response: any) => {
                if(response.data.cleanSingleTempFolder){
                    fetchIncompleteSFTPData({
                        variables: {
                            pageNumber: 0,
                            contentPerPage: 10
                          }
                      });
                }
                return Promise.resolve(response.data.cleanSingleTempFolder);
            }).catch((error: any) => {
                return Promise.reject(error);
            })
    }

    const deleteIncompleteFlow = async (userId: number, folderName: string) => {
        setSelectedFlowId(userId);
        setDeletingTemporaryFolder(true);
        cleanTempFolder(userId,folderName);
        setLoadingIndicator(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
 
    const fetchSFTPFolders = async (userId: number) => {
        return await uploadSFTPSetFolderMutation(selectedFolderName, userId)
            .then((currentSet: SFTPFilesResponse) => {
                const setToAdd: ContentSet = {
                    id: uuidv4(),
                    title: currentSet.key,
                    files: currentSet.value
                }
                if (contentSets.filter((set: any) => set.title === setToAdd.title).length === 0) {
                    uploadSetAction(setToAdd);
                }
                setFetchingSFTPFolders(false);
                return Promise.resolve(currentSet);
            })
            .catch((error: any) => {
                setFetchingSFTPFolders(false);
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Failed to load set"
                });
            })

    }

    const constructUploadedFilesResponseObjects = (contents: Content[]) => {
        const files: UploadedFileResponse[] = contents.map((content: any) => {
            return {
                id: content.id,
                code: content.code,
                url: content.url,
                filename: content.fileName,
                mimetype: content.mimeType,
                encoding: ''
            }
        })

        return files;
    }

    const loadContentSetsInRedux = (contentSets: ContentSetContent[]) => {
        contentSets.map((set: any) => {
            //compose set files
            const setFiles = constructUploadedFilesResponseObjects(set.files);
            //compose set cover photo if exists
            //set object
            const setObj: SFTPContentSet = {
                id: set.id.toString(),
                title: set.title,
                files: setFiles,
            };
            if(!contentSets.find((set: any)=> set.title === setObj.title)){
                uploadSetAction(setObj);
            }
        })
    }


    const openIncompleteFlowInContentUpload = (incompleteFlowId: number) => {
        setLoadingIndicator(true);
        const incompleteSFTPFlow = incompleteSFTPFlowData.find((flow: IncompleteSFTPUserUploadFlow) => 
            flow.userInfo.id === incompleteFlowId);
    
        if(incompleteSFTPFlow && selectedFlowId) {
            loadContentSetsInRedux(contentSets); //content sets
            selectIncompleteFlowAction(selectedFlowId);
            toggleRadioButtonAction(RadioButtonGroup.SET);
            setLoadingIndicator(false);
            redirectToContentUploadPage(selectedFlowId);
        } else {
            //flow not found, display error
        }
    }

    useEffect(() => {
        if (snackbar.severity && snackbar.message) {
            setSnackbarIndicator(true);
        }
    }, [snackbar])

    useEffect(()=>{
        fetchIncompleteSFTPData({
            variables: {
                pageNumber: 0,
                contentPerPage: 10
              }
          });
    },[]);
    
    useEffect(() => {
        if(data && data.getAllIncompleteSFTPFolderList) {
            loadIncompleteSFTPDataAction(data.getAllIncompleteSFTPFolderList.incompleteSets);
            resetReduxState();
        }
    }, [data])

    // useEffect(()=>{
    //     if(folderData && folderData?.getSetFolderNames){
    //         setFolderNames([...folderData?.getSetFolderNames])
    //     }
    // },[folderData])

    useEffect(()=>{
        if(userId){
            fetchSFTPFolders(userId);
            setFetchingSFTPFolders(true);
            setUserId(null);
        }
    },[userId]);
    useEffect(()=>{
        if(contentSets.length > 0 && selectedFlowId){
            openIncompleteFlowInContentUpload(selectedFlowId);
        }
    },[contentSets,selectedFlowId])

    useEffect(()=>{
        if(fetchingSFTPFolders){
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Processing set, Please wait"
            });
        }
    },[fetchingSFTPFolders])

    const columns: any = [
        {
            name: "Flow title",
        },
        {
            name: "Username",
        },
        {
            name: "Contributor name",
        },
        {
            name: "Contributor type",
        },
        {
            name: "Last edited",
        },
        {
            name: "No. of contents",
            },
        {
            name: "No. of content sets",
            },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    const disableSelectedFinishButton: boolean = fetchingSFTPFolders && selectedFolderName === tableMeta.rowData[0];
                    const disableSelectedDeleteButton: boolean = deletingTemporaryFolder && selectedFolderName === tableMeta.rowData[0];
                    return (
                        <div>
                            <Button className={fetchingSFTPFolders ? 'disabled-button' : ''} disabled={fetchingSFTPFolders } style={{minWidth: '90px'}} size='small' variant='contained' onClick={event => {
                                    event.stopPropagation();
                                    const incompleteSet: any = data.getAllIncompleteSFTPFolderList.incompleteSets;
                                    for(let i=0;i<incompleteSet.length;++i){
                                        if(incompleteSet[i].userInfo.username === tableMeta.rowData[7] && incompleteSet[i].contentSet === tableMeta.rowData[0]){
                                            setUserId(incompleteSet[i].userInfo.id);
                                            setSelectedFolderName(incompleteSet[i].contentSet);
                                            setSelectedFlowId(incompleteSet[i].userInfo.id);
                                            break;
                                        }
                                    }
                                }}
                                endIcon={<EditIcon/>}>{disableSelectedFinishButton ? <CircularProgress size={20} /> : "Finish"}
                            </Button>
                            <Button disabled={disableSelectedDeleteButton} className={disableSelectedDeleteButton ? 'disabled-button' : ''} size='small' variant='contained' onClick={event => {
                                    event.stopPropagation();
                                    const incompleteSet: any = data.getAllIncompleteSFTPFolderList.incompleteSets;
                                    for(let i=0;i<incompleteSet.length;++i){
                                        if(incompleteSet[i].userInfo.username === tableMeta.rowData[7] && incompleteSet[i].contentSet === tableMeta.rowData[0]){
                                            deleteIncompleteFlow(incompleteSet[i].userInfo.id,tableMeta.rowData[0]) ;
                                            break;
                                        }
                                    }
                                    
                                }}
                                endIcon={<DeleteIcon/>}>
                                {disableSelectedDeleteButton ? <CircularProgress size={20} /> : "Delete"}
                            </Button>
                        </div>
                    )
                }
            }
        }
    ];

    const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
        return <SearchBarComponent lastSearchValue={previousSearchString} handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
      };
      
    const options: MUIDataTableOptions = {
        ///
        count: data?.getAllIncompleteSFTPFolderList?.total || 0,
        page: pageNumber,
        jumpToPage: true,
        serverSide: true,
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: false,
        elevation: 3,
        fixedHeader: false,
        responsive: 'vertical',
        filterType: 'dropdown',
        tableBodyMaxHeight: 'calc(100%)',
        tableBodyHeight: 'calc(100%-50px)',
        download: true,
        print: false,
        searchPlaceholder: "Search ",
        searchOpen: previousSearchString ? true : false,
        searchText: previousSearchString,
        sortOrder:{
            name:"Last edited",
            direction:"asc"
        },
        customSearchRender: customSearchRender,
        onChangePage:(page: number)=>{
            setPageNumber(page);
            fetchIncompleteSFTPData({
                variables: {
                        pageNumber: page,
                        contentPerPage: 10
                    }
            });
        },
        onChangeRowsPerPage: (numberOfRows: number) => {
            setRowsPerPage(numberOfRows);
            fetchIncompleteSFTPData({
                variables: {
                        pageNumber: 0,
                        contentPerPage: numberOfRows
                    }
            });
        },
        textLabels: {
            body: {
                noMatch: loading ?
                <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`} 
                size={20} color={"#36D2B3"} loading={loading}/>
                :
                'All content flows seem to be completed for now',
            },
        },
        customSort: (data, dataIndex, rowIndex) => {
            if (dataIndex === 8 || dataIndex===9) {
              return data.sort((a, b) => {
                const dateA = new Date(a.data[dataIndex].props.children).getTime();
                const dateB = new Date(b.data[dataIndex].props.children).getTime();
                return (dateA < dateB ? -1 : 1) * (rowIndex === "desc" ? 1 : -1);
              });
            } else {
              return data.sort((a, b) => {
                return (
                  (a.data[dataIndex].length < b.data[dataIndex].length ? -1 : 1) *
                  (rowIndex === "desc" ? 1 : -1)
                );
              });
            }
        }
    };

    const onSubmitSearch = (newSearchString: string) => {
        refetch();
        setSearchString(newSearchString);
      };
    
      const handleResetSearch = () => {
        refetch();
        setSearchString("");
      }

    const convertRole= (type: any) => {
        if(type==='contributor_agency')
            return "Agency";
        else 
            return "Individual";
    }

    const getContributor = (user: any) => {
        if(user?.role === 'contributor_agency') {
          return user.companyName;
        } else{
          return `${user.firstName} ${user.lastName}`;
        }
      }

    const createTableRow = (item: IncompleteSFTPUserUploadFlow) => {
        return [
            item.contentSet, 
            item.userInfo.username,
            item.userInfo.companyName,
            convertRole(item.userInfo.role),
            item.lastModifiedDate.split("T")[0],
            item.contents,
            1, 
            item.userInfo.username,
        ]; 
    }
    return (
        <React.Fragment>
            <div className='refetch-container'>
                {/* <ConfirmationModal userId={selectedFlowId} open={open} handleClose={handleClose} deleteFlow={onDelete}/> */}
                <RefreshDateTime loading={loading} autoRefreshOn={incompleteTableRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleIncompleteTableRefresh}/>
            </div>
            <MUIDataTable title={""} columns={columns} options={options}
                data={incompleteSFTPFlowData.map((item: IncompleteSFTPUserUploadFlow) => createTableRow(item))}/>
            <SnackbarComponent showSnackbar={snackbarIndicator} handleClose={handleClose}
                    severity={snackbar.severity}
                    message={snackbar.message}/>
        </React.Fragment>
    )
}

const mapStateToProps = (state: AdminStoreState): { incompleteSFTPFlowData: IncompleteSFTPUserUploadFlow[];
        incompleteTableRefreshState: boolean; incompleteTableCurrentPageNumber: number; flowTitle: string;
        username: string;
        contributorName: string;
        numberOfContents: number;
        numberOfContentSets: number;
        numberOfArticles: number;
        uploadDate: Date[];
        contributorType: string;
        previousSearchString: string | undefined;
        uploadedFiles: any;
        contentSets: any;
        snackbar: IBroadcastMessage;
     } => {
    return {
        incompleteSFTPFlowData: selectIncompleteSFTPFlowData(state),
        incompleteTableRefreshState: selectIncompleteTableRefresh(state),
        incompleteTableCurrentPageNumber: getIncompleteTableCurrentPageNumber(state),
        flowTitle: selectFlowTitle(state),
        username: selectUsername(state),
        contributorName: selectContributorName(state),
        contributorType: selectContributorType(state),
        uploadDate: selectUploadDate(state),
        numberOfContents: selectNumberOfContents(state),
        numberOfContentSets: selectNumberOfContentSets(state),
        numberOfArticles: selectNumberOfArticles(state),
        previousSearchString: getSearchString(state),
        uploadedFiles: selectUploadedFiles(state),
        contentSets: selectContentSets(state),
        snackbar: selectSnackbarState(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TIncompleteSFTPFlowReducerActions | TContentUploadActions | TRefreshTablesActions | CallHistoryMethodAction | TTableFiltersReducerActions | TSnackbarActions>) => {
    return {
        loadIncompleteSFTPDataAction: (data: IncompleteSFTPUserUploadFlow[]) => dispatch<ILoadIncompleteSFTPData>(
            {type: IncompleteSFTPFlowActionTypes.LOAD_INCOMPLETE_SFTP_DATA, data: data}),
        redirectToContentUploadPage: (data: number) => dispatch(push(`/incomplete-sftp-upload-flow/${data}`)),
        toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({
            type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON,
            data: data
        }),
        uploadSetAction: (data: SFTPContentSet) => dispatch<ISFTPUploadSet>({type: ContentUploadActionTypes.UPLOAD_SET, data: data}),
        addIndividualImageNavigationAction: (data: IndividualImageCategory) => dispatch<IAddChannelToIndividualImage>(
            {type: ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL, data: data}),
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>(
            {type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data}),
        addAudioFileResponse: (data: any) => dispatch<IAddAudioFileResponse>(
            {type: ContentUploadActionTypes.ADD_AUDIO_FILE_RESPONSE, data: data}),
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>(
            {type: ContentUploadActionTypes.UPLOAD_FILES, data: data}),
        selectIncompleteFlowAction: (data: any) => dispatch<ISelectIncompleteSFTPFlow>(
            {type: IncompleteSFTPFlowActionTypes.SELECT_INCOMPLETE_SFTP_FLOW, data: data}),
       resetReduxState: () => dispatch<IResetContentUpload>({type: ContentUploadActionTypes.RESET_CONTENT}),
        toggleIncompleteTableRefresh: (data: boolean) => dispatch<IToggleIncompleteTableRefresh>({
            type: TablesRefreshActionTypes.INCOMPLETE_TABLE_REFRESH,
            data: data
        }),
        setFlowTitle: (data: string) => dispatch<IFlowTitle>({
            type: TableFilterActions.SET_FLOW_TITLE,
            data: data
        }),
        setUsername: (data: string) => dispatch<IUsername>({
            type: TableFilterActions.SET_USERNAME,
            data: data
        }),
        setContributorName: (data: string) => dispatch<IContributorName>({
            type: TableFilterActions.SET_CONTRIBUTOR_NAME,
            data: data
        }),
        setContributorType: (data: string) => dispatch<IToggleFilterContributorType>({
            type: TableFilterActions.TOGGLE_FILTER_CONTRIBUTOR_TYPE,
            data: data
        }),
        setUploadDate: (data: Date[]) => dispatch<IToggleFilterUploadDate>({ 
            type: TableFilterActions.TOGGLE_FILTER_UPLOAD_DATE, 
            data: data 
        }),
        setNumberOfContents: (data: number) => dispatch<INumberOfContents>({
            type: TableFilterActions.SET_NUMBER_OF_CONTENTS,
            data: data
        }),
        setNumberOfContentSets: (data: number) => dispatch<INumberOfContentSets>({
            type: TableFilterActions.SET_NUMBER_OF_CONTENT_SETS,
            data: data
        }),
        setNumberOfArticles: (data: number) => dispatch<INumberOfArticles>({
            type: TableFilterActions.SET_NUMBER_OF_ARTICLES,
            data: data
        }),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        }),
        setSearchString: (data: string): any => dispatch<ISearchString>({ type: TableFilterActions.SET_SEARCH_STRING, data: data }),

    }
}
  export default connect(mapStateToProps, mapDispatchToProps)(IncompleteSFTPUploadList);