import React, { useEffect, useState } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ADD_LICENSING_PLAN } from './queries';
import { LicensePlanTypes, NewLicenseProps, styles } from './LicenseAdminTable.types';
import { dialogStyles, defaultToolbarStyles } from '../users-table/usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const AddNewLicenseModal: React.FC<NewLicenseProps> = ({ ...props }) => {
    const { licenseType, refetch, classes } = props;
    const openRegisterInitial = false;
    const [open, setOpen] = useState(openRegisterInitial);
    const [response, setResponseState] = useState("");
    const [planType, setPlanType] = useState(LicensePlanTypes.Subscription);
    const [period, setPeriod] = useState<any>();
    const getLicenseType = (licenseType: string) => {
        return licenseType.toLocaleLowerCase();
    }

    const [addLicensingPlanMutation] = useMutation(ADD_LICENSING_PLAN);
    const validationSchema = Yup.object({
        amount: Yup.number().required("You must enter price.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            ),
        totalPrice: Yup.number().required("You must enter amount.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            ),
    });

    const getNumberFromVarialbes = (variable: any) => {
        if (variable !== null) {
            return parseInt(variable.toString())
        }
        else {
            return null
        }

    }
    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            amount: 0,
            type: "",
            totalPrice: 0,
            period: period,
            licenseTypeId: getLicenseType(licenseType)
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { amount, type, totalPrice, licenseTypeId } = values;
            addLicensingPlanMutation({
                variables: {
                    amount: getNumberFromVarialbes(values.amount),
                    totalPrice: getNumberFromVarialbes(values.totalPrice),
                    type: planType,
                    licensingType: values.licenseTypeId,
                    period: period,
                }
            }).then((result: any) => {
                if (result.data.addLicensingPlan) {
                    refetch()
                    handleClose();
                    setPeriod(null);
                }
            }).catch((error: any) => {
                //setResponseState(error.graphQLErrors[0].message);
            });
        }
    })

    const addNewUser = () => {
        setOpen(true);
    }
    const handleClickType = (e: any) => {
        setPlanType(e.target.value);
    }
    const handleSelectPeriod = (e: any) => {
        setPeriod(e.target.value)
    }
    useEffect(() => {
        if (planType === LicensePlanTypes.Subscription) {
            setPlanType(LicensePlanTypes.Subscription)
        }
        else {
            setPlanType(LicensePlanTypes.Credit)
        }
    }, [planType])

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Tooltip title={"Add new " + licenseType}>
                <IconButton onClick={addNewUser}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Add new plan</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <RadioGroup name="type" defaultValue={LicensePlanTypes.Subscription} onClick={handleClickType}>
                                                <FormControlLabel value={LicensePlanTypes.Subscription} control={<Radio color="default" />} label={LicensePlanTypes.Subscription} />
                                                <FormControlLabel value={LicensePlanTypes.Credit} control={<Radio color="default" />} label={LicensePlanTypes.Credit} />
                                            </RadioGroup>
                                            <TextField
                                                autoComplete="off" type="number"
                                                className='select-label' name="amount"
                                                onChange={handleChange} label="Amount"
                                                variant="standard" defaultValue={0}
                                                error={errors.amount === ''}
                                                helperText={errors.amount ? errors.amount : null}
                                                FormHelperTextProps={{ className: classes.error }} />
                                            {
                                                planType === LicensePlanTypes.Subscription ?
                                                    <FormControl>
                                                        <InputLabel >Period</InputLabel>
                                                        <Select onChange={handleSelectPeriod} defaultValue="">
                                                            <MenuItem value={30}>Monthly</MenuItem>
                                                            <MenuItem value={365}>Yearly</MenuItem>
                                                        </Select>
                                                    </FormControl> : null
                                            }
                                            <TextField
                                                autoComplete="off" type="number"
                                                className='select-label' name="totalPrice"
                                                onChange={handleChange} label="Price" variant="standard"
                                                defaultValue={0}
                                                error={errors.totalPrice === ''}
                                                helperText={errors.totalPrice ? errors.totalPrice : null}
                                                FormHelperTextProps={{ className: classes.error }} />
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Add plan</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}


export default (withStyles(styles)(AddNewLicenseModal));