import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {TabPanelProps, RoleTypes} from './usersAdminTable.types';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UsersDataTableComponent from './UsersAdminDataTable';
import './usersAdminTable.styles.scss';

export const TabPanel: React.FC<TabPanelProps> = ({...props}) => {
    const {children, value, index, userType, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{padding: '0px'}} p={3}>
                    <Typography>{children}</Typography>
                    <UsersDataTableComponent userType={userType} index={index}/>
                </Box>
            )}
        </div>
    );
}

const UsersAdminTabPanel: React.FC = ({...props}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const a11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root} >
            <AppBar position="static" color="default" >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Contributors" {...a11yProps(0)} />
                    {/*TODO: uncomment when clients want member functionalities
                    <Tab label="Members" {...a11yProps(1)} />*/}
                    <Tab label="Pro Members" {...a11yProps(1)} />
                    <Tab label="Admins" {...a11yProps(2)} />
                    <Tab label="FTP requests" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} userType={RoleTypes.Contributor_individual || RoleTypes.Contributor_agency}>
            </TabPanel>
            {/*TODO: uncomment when clients want member functionalities
            <TabPanel value={value} index={1} userType={RoleTypes.Member}>
            </TabPanel>*/}
            <TabPanel value={value} index={1} userType={RoleTypes.Pro_organization || RoleTypes.Pro_freelancer}>
            </TabPanel>
            <TabPanel value={value} index={2} userType={RoleTypes.Admin}>
            </TabPanel>
            <TabPanel value={value} index={3} userType={RoleTypes.Contributor_agency}>
            </TabPanel>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default UsersAdminTabPanel;
