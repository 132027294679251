import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import WaitingList from "./waitingList";
import { TabProps, WaitingListTabsProps, WaitingListTabTypes } from "./waitingList.types";
import IncompleteUploadList from '../incomplete-upload-list/incompleteUploadList'
import SuggestThreadList from "../../components/suggest-thread/suggestThread";
import { SnackbarActionTypes } from "../../redux/generic/snackbar/snackbar.types";
import { IResetSnackbar, TSnackbarActions } from "../../redux/generic/snackbar/snackbar.actions";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { TableFilterActions } from "../../redux/table-filter/table-filter.types";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { IWaitingListCurrentPageNumber, TWaitingListReducerActions } from "../../redux/waiting-list/waitingList.actions";
import { WaitingListActionTypes } from "../../redux/waiting-list/waitingList.types";
import { IncompleteTableCurrentPageNumber, TIncompleteFlowReducerActions } from "../../redux/incomplete-upload/incompleteUpload.actions";
import { IncompleteFlowActionTypes } from "../../redux/incomplete-upload/incompleteUpload.types";
import IncompleteSFTPUploadList from "../../components/incomplete-sftp-upload-list/incompleteSFTPUploadList";

export const WaitingListTab: React.FC<TabProps> = ({ ...props }) => {
    const { children, value, index, contentType, selectWaitingListTab, ...other } = props;

    let tab: any;

    if(index === 0){
        tab = <WaitingList contentType={contentType} />;
    }
    else if(index === 1){
        tab = <WaitingList contentType={contentType} />;
    }
    else if(index === 2){
        tab = <SuggestThreadList />;
    }
    else if(index === 3){
        tab = <IncompleteUploadList />;
    }
    else{
        tab = <IncompleteSFTPUploadList />;
    }
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`} {...other}>
            {
                value === index && (
                    <Box sx={{ padding: '0px' }} p={3}>
                        <Typography>{children}</Typography>
                        {tab}
                    </Box>
                )}
        </div>
    );
}


const WaitingListTabs: React.FC<WaitingListTabsProps> = ({ ...props }) => {
    const { routeParams, selectWaitingListTab, resetSnackbarAction, resetTablesFilterAction, setWaitingListDataTableCurrentPage, setIncompleteTableCurrentPageNumber } = props;
    const classes = useStyles();
    const [value, setValue] = useState<number>(parseInt(routeParams.waitingListTabIndex));

    useEffect(() => {
        if(value) {
            selectWaitingListTab(value);
        }
        else {
            selectWaitingListTab(0);
        }
    }, [value]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        resetSnackbarAction();
        resetTablesFilterAction();
    };

    const a11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const resetCurrentPage = ()=>{
        setWaitingListDataTableCurrentPage(0);
        setIncompleteTableCurrentPageNumber(0);
    }

    return (
        <div className={classes.root} >
            <AppBar position="static" color="default" >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab onClick={resetCurrentPage} label="Fast Moving Content" {...a11yProps(0)} />
                    <Tab onClick={resetCurrentPage} label="Stock Content" {...a11yProps(1)} />
                    <Tab onClick={resetCurrentPage} label="Suggested threads" {...a11yProps(2)} />
                    <Tab onClick={resetCurrentPage} label="Incomplete" {...a11yProps(3)} />
                    <Tab onClick={resetCurrentPage} label="Incomplete SFTP" {...a11yProps(4)} />
                </Tabs>
            </AppBar>

            <WaitingListTab value={value} index={0} contentType={WaitingListTabTypes.FMC} selectWaitingListTab={() => selectWaitingListTab(0)}>
            </WaitingListTab>
            <WaitingListTab value={value} index={1} contentType={WaitingListTabTypes.STOCK} selectWaitingListTab={() => selectWaitingListTab(1)}>
            </WaitingListTab>
            <WaitingListTab value={value} index={2} contentType={WaitingListTabTypes.THREAD} selectWaitingListTab={() => selectWaitingListTab(2)}>
            </WaitingListTab>
            <WaitingListTab value={value} index={3} contentType={WaitingListTabTypes.INCOMPLETE} selectWaitingListTab={() => selectWaitingListTab(3)}>
            </WaitingListTab>
            <WaitingListTab value={value} index={4} contentType={WaitingListTabTypes.INCOMPLETE_SFTP} selectWaitingListTab={() => selectWaitingListTab(4)}>
            </WaitingListTab>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

const mapDispatchToProps = (dispatch: Dispatch<TSnackbarActions | TTableFiltersReducerActions | CallHistoryMethodAction | TWaitingListReducerActions | TIncompleteFlowReducerActions>) => {
    return {
        selectWaitingListTab: (selectedTabIndex: number) => dispatch(push(`/waiting-list/${selectedTabIndex}`)),
        resetSnackbarAction: () => dispatch<IResetSnackbar>({ type: SnackbarActionTypes.RESET_MESSAGE }),
        resetTablesFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_FILTERS }),
        setWaitingListDataTableCurrentPage: (data: number) => dispatch<IWaitingListCurrentPageNumber>({ type: WaitingListActionTypes.SET_WAITING_TABLE_CURRENT_PAGE, data: data}),
        setIncompleteTableCurrentPageNumber: (data: number) => dispatch<IncompleteTableCurrentPageNumber>({ type: IncompleteFlowActionTypes.SET_INCOMPLETE_TABLE_CURRENT_PAGE, data: data})

    }
}
export default connect(null, mapDispatchToProps)(WaitingListTabs);