interface Config {
    REACT_APP_ADMIN_SERVER_HOST?: string;
    REACT_APP_IMAGE_DOWNLOAD_PATH?: string;
    REACT_APP_CLOUDFRONT_PATH?: string;
    REACT_APP_MW_GRAPHQL_PATH?: string;
    REACT_APP_MW_IMAGE_PREFIX?: string;
    REACT_APP_PUBLIC_BASE?: string;
    SKIP_PREFLIGHT_CHECK?: boolean;
}

export const config: Config = {
    REACT_APP_ADMIN_SERVER_HOST:"",
    REACT_APP_IMAGE_DOWNLOAD_PATH:"",
    REACT_APP_CLOUDFRONT_PATH: "",
    REACT_APP_MW_GRAPHQL_PATH: "",
    REACT_APP_MW_IMAGE_PREFIX: "",
    REACT_APP_PUBLIC_BASE: "",
    SKIP_PREFLIGHT_CHECK: false,
};

if(process.env.REACT_APP_ENV_MODE == "development"){
    config.REACT_APP_ADMIN_SERVER_HOST="http://localhost:3001/admin";
    config.REACT_APP_IMAGE_DOWNLOAD_PATH="http://localhost:3000/public";
    config.REACT_APP_CLOUDFRONT_PATH="https://d39z20ibtp0e4s.cloudfront.net";
    config.REACT_APP_MW_GRAPHQL_PATH="http://54.86.214.163:8080/graphql";
    config.REACT_APP_MW_IMAGE_PREFIX="https://d38hhp6342myl9.cloudfront.net";
    config.REACT_APP_PUBLIC_BASE="https://public.worldillustrated.com";
    config.SKIP_PREFLIGHT_CHECK=true;
}
if(process.env.REACT_APP_ENV_MODE == "stagging"){
    config.REACT_APP_ADMIN_SERVER_HOST="https://api.worldillustrated.com/admin";
    config.REACT_APP_IMAGE_DOWNLOAD_PATH="https://api.worldillustrated.com/public";
    config.REACT_APP_CLOUDFRONT_PATH="https://d38hhp6342myl9.cloudfront.net";
    config.REACT_APP_MW_GRAPHQL_PATH="https://mwapi.worldillustrated.com/graphql";
    config.REACT_APP_MW_IMAGE_PREFIX="https://d38hhp6342myl9.cloudfront.net";
    config.REACT_APP_PUBLIC_BASE="https://public.worldillustrated.com";
    config.SKIP_PREFLIGHT_CHECK=true;
}