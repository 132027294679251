import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_LICENSING_PLAN } from './queries';
import { LicensePlanTypes, styles, UpdateLicensePlanProps } from './LicenseAdminTable.types';
import { dialogStyles, defaultToolbarStyles } from '../users-table/usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const UpdateLicenseModal: React.FC<UpdateLicensePlanProps> = ({ ...props }) => {
    const { licenseType, id, refetch, data, classes } = props;

    const currentAmount = data[2];
    const currentType = data[1];
    const currentTotalPrice = data[4];
    const currentPeriod = data[3];

    const [response, setResponseState] = useState("");
    const [open, setOpen] = useState(false);
    const [planType, setPlanType] = useState<any>(currentType);

    const getPeriod = (period: string) => {
        return parseInt(period.split(" ")[0])
    }

    const [period, setPeriod] = useState<any>(getPeriod(currentPeriod));

    const getLicenseType = (licenseType: string) => {
        return licenseType.toLocaleLowerCase();
    }
    const getNumberFromVariables = (variable: any) => {
        if (variable !== null) {
            return parseInt(variable.toString())
        }
        else {
            return
        }
    }

    const validationSchema = Yup.object({
        amount: Yup.number().required("Add an number.")
        .test(
            'Is positive?',
            'The price must be greater than 0!',
            (value: any) => value > 0
        ),
        totalPrice: Yup.number().required("Add an number.")
        .test(
            'Is positive?',
            'The price must be greater than 0!',
            (value: any) => value > 0
        ),
    });

    const [updateLicensingPlanMutation] = useMutation(UPDATE_LICENSING_PLAN);
    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            amount: currentAmount,
            type: currentType,
            totalPrice: currentTotalPrice.slice(1),
            periodValue: getPeriod(currentPeriod),
            licenseTypeId: getLicenseType(licenseType)
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { amount, type, totalPrice, periodValue, licenseTypeId } = values;
            updateLicensingPlanMutation({
                variables: {
                    id: id,
                    amount: getNumberFromVariables(amount),
                    totalPrice: getNumberFromVariables(totalPrice),
                    type: planType,
                    licensingType: values.licenseTypeId,
                    period: getNumberFromVariables(period),
                }
            }).then((result: any) => {
                if (result.data.updateLicensingPlan) {
                    refetch();
                    handleClose();
                    setPeriod("");
                }
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        }
    })
    const handleClose = () => {

        setOpen(false);
    }

    const openLicenseModal = () => {
        setOpen(true);
        values.amount = currentAmount;
        values.totalPrice = currentTotalPrice.slice(1);
        values.type = currentType;
        setPeriod(getPeriod(currentPeriod));
        setPlanType(currentType)
    }

    const handleClickType = (e: any) => {
        setPlanType(e.target.value);

    }
    const handleSelectPeriod = (e: any) => {
        setPeriod(e.target.value);
    }


    useEffect(() => {
        if (planType === LicensePlanTypes.Credit) {
            setPeriod(null)
        }
    }, [planType, period])
    return (
        <React.Fragment>
            <Tooltip title={"Update"}>
                <IconButton onClick={openLicenseModal}>
                    <CreateIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Update plan</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <RadioGroup name="type" value={planType} onClick={handleClickType}>
                                                <FormControlLabel value="Subscription" control={<Radio color="default" />} label="Subscription" />
                                                <FormControlLabel value="Credit" control={<Radio color="default" />} label="Credit" />
                                            </RadioGroup>
                                            <TextField 
                                                autoComplete="off" 
                                                type="number" 
                                                className='select-label' 
                                                name="amount" 
                                                onChange={handleChange} 
                                                label="Amount" 
                                                variant="standard" 
                                                value={values.amount} 
                                                error={errors.amount === ""}
                                                helperText={errors.amount ? errors.amount : null}
                                                FormHelperTextProps={{ className: classes.error }}/>
                                            {
                                                planType === "Subscription" ?
                                                    <FormControl>
                                                        <InputLabel >Period</InputLabel>
                                                        <Select onChange={handleSelectPeriod} value={period} defaultValue={period}>
                                                            <MenuItem value={30}>Monthly</MenuItem>
                                                            <MenuItem value={365}>Yearly</MenuItem>
                                                        </Select>
                                                    </FormControl> : null
                                            }
                                            <TextField  
                                                autoComplete="off" 
                                                type="number" 
                                                className='select-label' 
                                                name="totalPrice" 
                                                onChange={handleChange} 
                                                label="Price" 
                                                variant="standard" 
                                                value={values.totalPrice} 
                                                error={errors.totalPrice === ""}
                                                helperText={errors.totalPrice ? errors.totalPrice : null}
                                                FormHelperTextProps={{ className: classes.error }}/>
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Update plan</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}



export default (withStyles(styles)(UpdateLicenseModal));