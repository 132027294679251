import React, { useState, useEffect } from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { DataTableProps, RoleTypes } from "./usersAdminTable.types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { RefreshDateTime } from "shared";
import {
  ALL_MEMBERS,
  ALL_ADMINS,
  ALL_PROS,
  ALL_CONTRIBUTORS,
  DELETE_USER,
  DELETE_USERS,
  GENERATE_SFTP_USER,
  CONTRIBUTORS_WITH_SFTP_REQUESTS,
} from "./queries";
import AddNewUserModal from "./AddNewUserModal";
import UpdateUserModal from "./UpdateUserModal";
import { Dispatch } from "redux";
import {
  TUserTableReducerActions,
  ILoadedData,
  IDeleteUser,
} from "../../redux/users-table/usersTable.actions";
import {
  UsersTableActionTypes,
  UserData,
} from "../../redux/users-table/usersTable.types";
import { connect } from "react-redux";
import { AdminStoreState } from "../../redux/root-reducer";
import "./usersAdminTable.styles.scss";
import { selectCurrentUser } from "../../redux/admin/admin.selectors";
import { User, UserActionTypes } from "../../redux/admin/admin.types";
import {
  ILoginSuccess,
  TUserReducerActions,
} from "../../redux/admin/admin.actions";
import { Button, CircularProgress } from "@material-ui/core";
import { SnackbarComponent } from "shared";
import {
  ISnackbarMessage,
  TSnackbarActions,
} from "../../redux/generic/snackbar/snackbar.actions";
import {
  IBroadcastMessage,
  SeveritySnackbarEnum,
  SnackbarActionTypes,
} from "../../redux/generic/snackbar/snackbar.types";
import { selectSnackbarState } from "../../redux/generic/snackbar/snackbar.selectors";
import { selectUsersTableData } from "../../redux/users-table/usersTable.selectors";
import { selectUsersTableRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import {
  IToggleUsersTableRefresh,
  TRefreshTablesActions,
} from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

const UsersDataTableComponent: React.FC<DataTableProps> = ({ ...props }) => {
  const {
    userType,
    index,
    usersTable,
    snackbar,
    usersTableRefreshState,
    toggleUsersTableRefresh,
    loadedDataAction,
    loginSuccessAction,
    deleteUserAction,
    broadcastSnackbarAction,
    currentUser,
  } = props;

  // TODO: remove unused variables
  const [generatingSFTPUser, setGeneratingSFTPUser] = useState<number[]>([]);
  const [queryData, setQueryData] = useState<UserData[]>([]);

  const [queryName, setQueryName] = useState(ALL_CONTRIBUTORS);
  const [dataQuery, { data, loading, error, refetch }] = useLazyQuery(
    queryName,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );
  const [showDataTableSnackbar, setShowDataTableSnackbar] = useState(false);
  const [hasPublicSFTPAccess, setHasPublicSFTPAccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  const [deleteUserMutation] = useMutation(DELETE_USER);
  const deleteUser = (id: number, currentUserId: number): void => {
    deleteUserMutation({
      variables: {
        id: id,
        currentUserId: currentUserId,
      },
    })
      .then((result: any) => {
        deleteUserAction([id]);
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "User deleted successfully",
        });
      })
      .catch((error: any) => {
        if (error.graphQLErrors.length > 0) {
          broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.error,
            message: `${error.graphQLErrors[0].message}`,
          });
        } else {
          broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.error,
            message: "Something went wrong when deleting user",
          });
        }
      });
    setShowDataTableSnackbar(true);
  };

  const [deleteUsersMutation] = useMutation(DELETE_USERS);
  const deleteUsers = (ids: number[], currentUserId: number): void => {
    deleteUsersMutation({
      variables: {
        ids: ids,
        currentUserId: currentUserId,
      },
    })
      .then((result: any) => {
        deleteUserAction(ids);
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Users deleted successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Something went wrong when deleting users",
        });
      });
    setShowDataTableSnackbar(true);
  };

  const [generateSFTPUserMutation] = useMutation(GENERATE_SFTP_USER);
  const handleGenerateSFTPUser = (id: number) => {
    setGeneratingSFTPUser((prev) => [...prev, id]);
    setHasPublicSFTPAccess(false);
    generateSFTPUserMutation({
      variables: {
        id: id,
        hasPublicSFTPAccess,
      },
    })
      .then(() => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Successfully generated FTP user",
        });
        setGeneratingSFTPUser((prev) => prev.filter((prevId) => prevId != id));
        refetch();
        setShowDataTableSnackbar(true);
      })
      .catch((error) => {
        setGeneratingSFTPUser((prev) => prev.filter((prevId) => prevId != id));
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: `Failed to generate FTP User`,
        });

        setShowDataTableSnackbar(true);
      });
  };

  const handleDialogueClose = () => {
    setOpenDialog(false);
    setHasPublicSFTPAccess(false);
  };
  
  useEffect(() => {
    const isContributor =
      userType === RoleTypes.Contributor_agency ||
      userType === RoleTypes.Contributor_individual;
    const isProMember =
      userType === RoleTypes.Pro_freelancer ||
      userType === RoleTypes.Pro_organization;
    const isAdmin =
      userType === RoleTypes.Admin || userType === RoleTypes.SuperAdmin;

    if (userType === RoleTypes.Member) {
      setQueryName(ALL_MEMBERS);
    } else if (isContributor && index === 0) {
      setQueryName(ALL_CONTRIBUTORS);
    } else if (userType === RoleTypes.Contributor_agency && index === 3) {
      setQueryName(CONTRIBUTORS_WITH_SFTP_REQUESTS);
    } else if (isProMember) {
      setQueryName(ALL_PROS);
    } else if (isAdmin) {
      setQueryName(ALL_ADMINS);
    }
    dataQuery();
  }, []);

  useEffect(() => {
    if (data && data.getAllMembers) {
      setQueryData(data.getAllMembers);
      loadedDataAction(data.getAllMembers);
    } else if (data && data.getAllContributors) {
      setQueryData(data.getAllContributors);
      loadedDataAction(data.getAllContributors);
    } else if (data && data.getUsersWithSFTPAccessRequests) {
      setQueryData(data.getUsersWithSFTPAccessRequests);
      loadedDataAction(data.getUsersWithSFTPAccessRequests);
    } else if (data && data.getAllPros) {
      setQueryData(data.getAllPros);
      loadedDataAction(data.getAllPros);
    } else if (data && data.getAllAdmins) {
      setQueryData(data.getAllAdmins);
      loadedDataAction(data.getAllAdmins);
    }
  }, [data]);

  const columns = [
    {
      name: "ID",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    "Name",
    "Username",
    "Email",
    {
      name:"SFTP Access",
      options: {
        display: index === 0
      }
    },
    {
      name:"SFTP Public Access",
      options: {
        display: index === 0
      }
    },
    "Auth strategy",
    "Role",
    {
      name: "Update",
      options: {
        filter: false,
        sort: false,
        empty: true,
        display: index !== 3 ? true : ("false" as "false"),
        viewColumns: index !== 3 ? true : false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return toggleEditUser(tableMeta);
        },
      },
    },
    {
      name: "FTP username",
      options: {
        filter: false,
        sort: false,
        empty: true,
        display: index === 3,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const generateUserAccess = tableMeta.rowData[7] === "Agency";

          if (generateUserAccess) {
            return (
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenDialog(true);
                    setSelectedUserId(tableMeta.rowData[0]);
                  }}
                >
                  {" "}
                  {generatingSFTPUser.includes(tableMeta.rowData[0]) ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Generate FTP user"
                  )}
                </Button>
              </div>
            );
          }
        },
      },
    },
  ];

  const toggleEditUser = (tableMeta: any) => {
    let ownAccount = tableMeta.rowData[3] === currentUser.email;
    let adminsTab =
      tableMeta.rowData[7] === RoleTypes.SuperAdmin ||
      tableMeta.rowData[7] === RoleTypes.Admin;
    let superAdminAccess =
      tableMeta.rowData.length > 4 &&
      adminsTab &&
      currentUser.role === RoleTypes.SuperAdmin;
    let adminAccess =
      tableMeta.rowData.length > 4 &&
      tableMeta.rowData[7] === RoleTypes.Admin &&
      currentUser.role === RoleTypes.Admin;
    let localStrategyAccess =
      tableMeta.rowData.length > 4 &&
      tableMeta.rowData[6] === "local" &&
      !adminsTab;

    if (
      !ownAccount &&
      (localStrategyAccess || superAdminAccess || adminAccess)
    ) {
      return (
        <UpdateUserModal rowIndex={tableMeta.rowIndex} refetch={refetch} />
      );
    }
  };

  const onRowsDeleted = (rowsDeleted: any) => {
    if (rowsDeleted.data.length === 1) {
      let row = rowsDeleted.data[0];
      deleteUser(usersTable[row.dataIndex].id, currentUser.id);
    } else {
      let idArray = rowsDeleted.data.map(
        (row: any) => usersTable[row.dataIndex].id
      );
      deleteUsers(idArray, currentUser.id);
    }
  };

  const options: MUIDataTableOptions = {
    selectableRowsHeader: true,
    selectableRows: "multiple",
    selectableRowsOnClick: false,
    filter: true,
    fixedHeader: false,
    filterType: "dropdown",
    responsive: "standard",
    download: true,
    print: false,
    customToolbar: () => {
      if (index !== 3) {
        return <AddNewUserModal userType={userType} refetch={refetch} />;
      } else {
        return null;
      }
    },
    onRowsDelete: onRowsDeleted,
    searchPlaceholder: "Search " + userType,
  };

  const displayRole = (role: string) => {
    if (role) {
      let splitRole = role.split("_");
      return (
        splitRole[splitRole.length - 1].charAt(0).toUpperCase() +
        splitRole[splitRole.length - 1].slice(1)
      );
    } else {
      return "N/A";
    }
  };

  const getName = (item: UserData) => {
    const isCompanyRole =
      item.role == RoleTypes.Contributor_agency ||
      item.role == RoleTypes.Pro_organization;
    const isIndividualRole =
      item.role == RoleTypes.Pro_freelancer ||
      item.role == RoleTypes.Contributor_individual;
    const isAdminRole =
      item.role == RoleTypes.Admin || item.role == RoleTypes.SuperAdmin;

    if (isCompanyRole) {
      return item.company ? item.company.name : "N/A";
    } else if (
      isIndividualRole ||
      isAdminRole ||
      item.role == RoleTypes.Member
    ) {
      return item.person
        ? item.person.firstName + " " + item.person.lastName
        : "N/A";
    } else return "N/A";
  };

  const handleDialogueOk = () => {
    setOpenDialog(false);
    handleGenerateSFTPUser(selectedUserId);
  };

  const handleClose = () => {
    setShowDataTableSnackbar(false);
  };

  const handlePublicSFTPAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if((event.target as HTMLInputElement).value === "true"){
        setHasPublicSFTPAccess(true);
    }
    else{
        setHasPublicSFTPAccess(false);
    }
    
  };

  return (
    <React.Fragment>
      <div className="refetch-container">
        <RefreshDateTime
          loading={loading}
          autoRefreshOn={usersTableRefreshState}
          refreshTime={30000}
          refreshFunc={refetch}
          toggleReduxFunction={toggleUsersTableRefresh}
        />
      </div>
      <MUIDataTable
        title={""}
        data={usersTable.map((item) => {
         
          return [
            item.id,
            getName(item),
            item.username,
            item.email,
            item.hasSFTPAccess === null ? "No" : "Yes",
            item.hasPublicSFTPAccess === null || item.hasPublicSFTPAccess === false ? "No" : "Yes",
            item.authStrategy,
            displayRole(item.role),
          ];
        })}
        columns={columns}
        options={options}
      />
      <SnackbarComponent
        showSnackbar={showDataTableSnackbar}
        handleClose={handleClose}
        severity={snackbar.severity}
        message={snackbar.message}
      />
      <Dialog open={openDialog}>
        <DialogTitle>Is user required to use the public site for SFTP upload completion?</DialogTitle>
        <DialogContent dividers>
          {" "}
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={hasPublicSFTPAccess}
                    onChange={handlePublicSFTPAccessChange}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialogueClose}>
            Cancel
          </Button>
          <Button onClick={handleDialogueOk}>Generate</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (
  state: AdminStoreState
): {
  usersTable: UserData[];
  currentUser: User;
  snackbar: IBroadcastMessage;
  usersTableRefreshState: boolean;
} => {
  return {
    usersTable: selectUsersTableData(state),
    currentUser: selectCurrentUser(state),
    snackbar: selectSnackbarState(state),
    usersTableRefreshState: selectUsersTableRefresh(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<
    | TUserTableReducerActions
    | TRefreshTablesActions
    | TUserReducerActions
    | TSnackbarActions
  >
) => {
  return {
    loginSuccessAction: (data: User) => {
      dispatch<ILoginSuccess>({
        type: UserActionTypes.LoginSuccess,
        data: data,
      });
    },
    loadedDataAction: (data: UserData[]) => {
      dispatch<ILoadedData>({
        type: UsersTableActionTypes.LOADED_DATA,
        data: data,
      });
    },
    deleteUserAction: (data: number[]) => {
      dispatch<IDeleteUser>({
        type: UsersTableActionTypes.DELETE_USER,
        data: data,
      });
    },
    broadcastSnackbarAction: (data: IBroadcastMessage) =>
      dispatch<ISnackbarMessage>({
        type: SnackbarActionTypes.BROADCAST_MESSAGE,
        data: data,
      }),
    toggleUsersTableRefresh: (data: boolean) =>
      dispatch<IToggleUsersTableRefresh>({
        type: TablesRefreshActionTypes.USERS_TABLE_REFRESH,
        data: data,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersDataTableComponent);
