import { TUserTableReducerActions, IUpdateUser } from './usersTable.actions';
import {UsersTableActionTypes, UpdateUserData, DataTableState, UserData} from './usersTable.types';

const InitialState: DataTableState = {
    tableData: []
}

export const usersTableReducer = (state = InitialState, action: TUserTableReducerActions) : DataTableState => {
    switch(action.type) {
        case UsersTableActionTypes.LOADED_DATA:
            return {
                tableData: action.data
            };
        case UsersTableActionTypes.ADD_USER:
            return {
                tableData: state.tableData.concat(action.data)
                    .sort(function(userData1, userData2) {return userData2.id - userData1.id})
            };
        case UsersTableActionTypes.UPDATE_USER:
            const newData = state.tableData.filter( item => item.id !== action.data.id);
            return {
                tableData: newData.concat(action.data)
                    .sort(function(userData1, userData2) {return userData2.id - userData1.id})
            };
        case UsersTableActionTypes.DELETE_USER:
            let newDataDeleted = state.tableData;
            for(let index of action.data) {
                newDataDeleted = newDataDeleted.filter(item => item.id !== index);
            }
            return {
                tableData: newDataDeleted
            };
        default:
            return state;
    }
}

export default usersTableReducer;