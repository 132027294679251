import { TWaitingListReducerActions } from './waitingList.actions';
import { WaitingListActionTypes, WaitingListState } from "./waitingList.types";

const InitialState: WaitingListState = {
    waitingListData: [],
    isModalOpen: false,
    contentUserId: 0,
    waitingListCurrentPageNumber: 0
}

export const waitingListReducer = (state = InitialState, action: TWaitingListReducerActions): WaitingListState => {
    switch (action.type) {
        case WaitingListActionTypes.LOADED_WAITING_LIST_DATA:
            return {
                ...state,
                waitingListData: action.data
            };
        case WaitingListActionTypes.TOGGLE_ADDCONTENT_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen
            };
        case WaitingListActionTypes.TOGGLE_CONTENT_USER_ID:
            return {
                ...state,
                contentUserId: action.data
            }
        case WaitingListActionTypes.SET_WAITING_TABLE_CURRENT_PAGE:
            return {
                ...state,
                waitingListCurrentPageNumber: action.data
            };
        case WaitingListActionTypes.APPROVE_CONTENT:
        case WaitingListActionTypes.REJECT_CONTENT:
        case WaitingListActionTypes.UPDATE_METADATA:
        
        default:
            return state;
    }
}

export default waitingListReducer;