import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectIncompleteSFTP = (state: AdminStoreState) => state.incompleteSFTPFlow;

export const selectIncompleteSFTPFlowData = createSelector(
    [selectIncompleteSFTP],
    (incomplete) => incomplete.incompleteSFTPFlowData
);

export const selectCurrentIncompleteSFTPFlow = createSelector(
    [selectIncompleteSFTP],
    (incomplete) => incomplete.incompleteSFTPFlowData.find(
        (flow) => flow.userInfo.id === incomplete.selectedUserId)
);

export const getIncompleteTableCurrentPageNumber = createSelector(
    [selectIncompleteSFTP],
    (incomplete) => incomplete.incompleteTableCurrentPageNumber
);

export const selectIncompleteSFTPSetContributorId = createSelector(
    [selectIncompleteSFTP],
    (incomplete) => incomplete.selectedUserId
);

