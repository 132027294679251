import React, { useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LicensingCountries, CommissionsType, Commission } from './commissions.types';
import { DELETE_LOCATION_OVERRIDE, GET_ALL_MW_USER_COMMISSIONS } from './queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Dispatch } from 'redux';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { connect } from 'react-redux';
import { SnackbarComponent } from "shared";
import EditLocationOverrideModal from './editCommissionsModal';
import { RefreshDateTime } from 'shared';
import { selectLocationOverrideRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleLocationOverrideRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import Commissions from './Commissions.component';
import EditCommissionsModal from './editCommissionsModal';
import AddMWUserCommission from './addMSUserCommissionModal';

const MWCommissionsTable: React.FC<CommissionsType> = ({ ...props }) => {
    const { licenseType, snackbarAction, locationOverrideRefreshState, 
        broadcastSnackbarAction, toggleLocationOverrideRefresh } = props;
    const [commissions, setMWCommissions] = useState<Commission[]>([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(0);
console.log("showSnackbar",showSnackbar)
    const [fetchMWUserCommissions,{ data, loading, error, refetch }] = useLazyQuery(GET_ALL_MW_USER_COMMISSIONS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        fetchMWUserCommissions({
            variables: {
                pageNumber: 1,
                contentPerPage: rowsPerPage
              }
        })
    }, []);
    
    useEffect(() => {
        if (data && data.getAllCommission) {
            setMWCommissions([...data.getAllCommission.commissions]);
        }
    }, [data]);

    useEffect(() => {
        if (snackbarAction.severity && snackbarAction.message.length > 0) {
            console.log("snackbarAction.message",snackbarAction.message)
            setShowSnackbar(true);
        }
    }, [snackbarAction]);

    const createTableRow = (item: Commission) => {
        return [
            item.id,
            item.hurdleLevel,
            item.percentage.toFixed(2) + '%',
            item.amount,
            item.createdAt.split("T")[0]
        ];
    };

    const handleClose = () => {
        setShowSnackbar(false);
    }

    const columns = [
        {
            name: "ID",
            options: {
                display: 'false' as 'false',
                viewColumns: false,
            }
        },
        "Level",
        "Percentage",
        "Threshold",
        "Created At",
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <EditCommissionsModal tableMeta={tableMeta.rowData} loadedData={commissions} broadcastSnackbarAction={broadcastSnackbarAction} refetch={refetch} />
                    )
                }
            }
        },
    ];

    const options: MUIDataTableOptions = {
        count: data?.getAllCommission?.total || 0,
        selectableRowsOnClick: false,
        filter: false,
        page: selectedPage,
        fixedHeader: false,
        filterType: 'dropdown',
        responsive: 'standard',
        download: true,
        print: false,
        rowsPerPage: rowsPerPage,
        jumpToPage: true,
        serverSide: true,
        search: false,
        searchPlaceholder: "Search " + licenseType,
        onChangeRowsPerPage: (numberOfRows: number) => {
            setRowsPerPage(numberOfRows);
            fetchMWUserCommissions({
                variables: {
                    pageNumber: 1,
                    contentPerPage: numberOfRows
                  }
            })
        },
        onChangePage: (currentPage: number) => {
            fetchMWUserCommissions({
                variables: {
                    pageNumber: currentPage + 1,
                    contentPerPage: rowsPerPage
                  }
            })
            setSelectedPage(currentPage);
        },
        customToolbar: (tableMeta: any) => {
            return (
                <AddMWUserCommission refetch={refetch} tableData={commissions} broadcastSnackbarAction={broadcastSnackbarAction} />
            )
        }
    }
    return (
        <React.Fragment>
            <div className='refetch-container'>
                <RefreshDateTime loading={loading} autoRefreshOn={locationOverrideRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleLocationOverrideRefresh}/>
            </div>
            <MUIDataTable title={""} data={commissions.map((item: Commission) => createTableRow(item))}
                columns={columns} options={options}
            />
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                severity={snackbarAction.severity} message={snackbarAction.message} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: AdminStoreState): { snackbarAction: IBroadcastMessage, locationOverrideRefreshState: boolean } => {
    return {
        snackbarAction: selectSnackbarState(state),
        locationOverrideRefreshState: selectLocationOverrideRefresh(state)
    }
};

const mapDispatchToProps = (dispatch: Dispatch<TSnackbarActions | TRefreshTablesActions>) => {
    return {
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data }),
        toggleLocationOverrideRefresh: (data: boolean) => dispatch<IToggleLocationOverrideRefresh>({
            type: TablesRefreshActionTypes.LOCATION_OVERRIDE_REFRESH,
            data: data
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MWCommissionsTable);