import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, FormLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useQuery } from 'react-apollo-hooks';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentSetContent, IncompleteUploadProps, IncompleteUserUploadFlow } from './incompleteUpload.types';
import SyncLoader from 'react-spinners/SyncLoader';
import { DELETE_INCOMPLETE_FLOW, GET_ALL_INCOMPLETE_FLOWS } from './queries';
import Moment from "react-moment";
import { AdminStoreState } from '../../redux/root-reducer';
import { Dispatch } from 'redux';
import { RefreshDateTime, SnackbarComponent, SearchBarComponent } from 'shared';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { Article, ContentSet, IndividualImageCategory, RadioButtonGroup, UploadedFileResponse } from '../batch-upload/ContentUpload.types';
import { IAddAudioFileResponse, IAddChannelToIndividualImage, IResetContentUpload, IToggleRadioButton, IUpdateSet, IUploadArticle, IUploadFiles, IUploadSet, TContentUploadActions } from '../../redux/content-upload/content-upload.actions';
import { connect } from 'react-redux';
import { Content } from '../waiting-list/waitingList.types';
import { getIncompleteTableCurrentPageNumber, selectIncompleteFlowData } from '../../redux/incomplete-upload/incompleteUpload.selectors';
import { ILoadIncompleteData, IncompleteTableCurrentPageNumber, ISelectIncompleteFlow, TIncompleteFlowReducerActions } from '../../redux/incomplete-upload/incompleteUpload.actions';
import { IncompleteFlowActionTypes } from '../../redux/incomplete-upload/incompleteUpload.types';
import '../waiting-list/editWaitingList.styles.scss';
import { Channel } from '../channels-table/channelsTable.types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { User } from '../../redux/admin/admin.types';
import  ConfirmationModal  from './deleteModalConfirmation.component';
import { selectIncompleteTableRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleIncompleteTableRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { TableFilterActions } from '../../redux/table-filter/table-filter.types';
import { IContributorName, IFlowTitle, INumberOfArticles, INumberOfContents, INumberOfContentSets, ISearchString, IToggleFilterContributorType, IToggleFilterUploadDate, IUsername, TTableFiltersReducerActions } from '../../redux/table-filter/table-filter.actions';
import { getSearchString, selectContributorName, selectContributorType, selectFlowTitle, selectNumberOfArticles, selectNumberOfContents, selectNumberOfContentSets, selectUploadDate, selectUsername } from '../../redux/table-filter/table-filter.selectors';

const IncompleteUploadList: React.FC<IncompleteUploadProps> = ({...props}) => {
    const {incompleteFlowData, incompleteTableRefreshState, incompleteTableCurrentPageNumber,previousSearchString, flowTitle, username, contributorName, numberOfContents, numberOfContentSets, numberOfArticles, uploadDate, contributorType, loadIncompleteDataAction, 
        addAudioFileResponse, uploadArticleAction, uploadFilesAction, redirectToContentUploadPage, 
        uploadSetAction, resetReduxState, addIndividualImageNavigationAction, toggleRadioButtonAction, 
        selectIncompleteFlowAction, toggleIncompleteTableRefresh, setIncompleteTableCurrentPageNumber,setFlowTitle,setUsername, setContributorName, setContributorType, setUploadDate, setNumberOfContents, setNumberOfContentSets, setNumberOfArticles,setSearchString} = props;
    const {data, loading, error, refetch}= useQuery(GET_ALL_INCOMPLETE_FLOWS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    const today = new Date();
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [snackbarIndicator, setSnackbarIndicator] = useState(false);
    const [selectedFlowId, setSelectedFlowId] = useState("");
    const [open, setOpen] = useState(false);
    const [startUploadDate, setStartUploadDate] = useState<string | null>(null);
    const [endUploadDate, setEndUploadDate] = useState<string | null>(null);

    const [deleteIncompleteFlowMutation] = useMutation(DELETE_INCOMPLETE_FLOW, {onCompleted(data) {
        if(data.deleteIncompleteFlow) {
            setSnackbarIndicator(false);
            refetch();
        }
    }, onError(error) {
        setLoadingIndicator(false);
        refetch();
    }});


    const onDelete = async (flowId:string) => {
        setLoadingIndicator(true);
        await deleteIncompleteFlowMutation({variables: {incompleteFlowId: flowId}}).then(
            result => {
                setSnackbarIndicator(true)
                setLoadingIndicator(false)
            }
        ).catch( error => {
            setSnackbarIndicator(false)
            setLoadingIndicator(false)
        });
    }

    const deleteIncompleteFlow = (incompleteFlowId: string) => {
        setSelectedFlowId(incompleteFlowId);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
 
    const getUploadDateFilter: any = ()=>{
        if (uploadDate[0] && uploadDate[1]) {
                  return [`${new Date(uploadDate[0]).toISOString().slice(0, 10)}`,`${new Date(uploadDate[1]).toISOString().slice(0, 10)}`];
                } else if (uploadDate[0]) {
                  return [`${new Date(uploadDate[0]).toISOString().slice(0, 10)}`];
                } else if (uploadDate[1]) {
                  return [`${new Date(uploadDate[1]).toISOString().slice(0, 10)}`];
                }
                return "";
      }

    useEffect(() => {
        if(data && data.getAllIncompleteFlows) {
            loadIncompleteDataAction(data.getAllIncompleteFlows);
            resetReduxState();
        }
    }, [data])

    useEffect(()=>{
        if(uploadDate.length > 0 && uploadDate[0]){
          setStartUploadDate(new Date(uploadDate[0]).toISOString().slice(0,10));
        }
        if(uploadDate.length > 0 && uploadDate[1]){
          setEndUploadDate(new Date(uploadDate[1]).toISOString().slice(0,10));
        }
      },[uploadDate])

      useEffect(() => {
        if (startUploadDate && !endUploadDate) {
          setUploadDate([new Date(startUploadDate), today]);
        } else if (startUploadDate && endUploadDate) {
          let endDate = new Date(endUploadDate);
          endDate.setHours(today.getHours());
          setUploadDate([new Date(startUploadDate), new Date(endDate)]);
        } else if (!startUploadDate && !endUploadDate) {
            setUploadDate([]);
        }
      }, [startUploadDate, endUploadDate]);

    const columns: any = [
        {
            name: "Thumbnail",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <div>
                            <img src={tableMeta.rowData[0]} alt={value}
                                 style={{
                                     display: "block",
                                     width: '60px',
                                     height: '60px'
                                 }}
                            />
                        </div>
                    )
                }
            }
        },
        {
            name:"ID",
            options:{
                viewColumns:false,
                filter:false,
                sort:false,
                display: 'false' as 'false'
            }
        },
        {
        name: "Flow title",
        options: {
            filterList: flowTitle ? [flowTitle] : null,
        }
          },
        {
        name: "Username",
        options: {
            filterType: 'dropdown' as 'dropdown',
            filterList: username ? [username] : null,
        }
        },
        {
            name: "Contributor name",
            options: {
                filterType: 'dropdown' as 'dropdown',
                filterList: contributorName ? [contributorName] : null,
            }
            },
        {
            name: "Contributor type",
            options: {
                filterType: 'dropdown' as 'dropdown',
                filterList: contributorType ? [contributorType] : null,
            }
            },
        {
            name:"Last edited",
            options: {
                filterType: 'custom' as 'custom',
                filterList: getUploadDateFilter() || null,
                customFilterListOptions: {
                    render: (v:any) => {
                        if (v[0] && v[1]) {
                            return `Start date: ${v[0]}, End date: ${v[1]}`;
                        } else if (v[0]) {
                            return `Start date: ${v[0]}`;
                        } else if (v[1]) {
                            return `End date: ${v[1]}`;
                        }
                        return ' ';
                    },
                    update: (filterList: any, filterPos: any, index: any) => {
                        setUploadDate([]);
                        setStartUploadDate(null);
                        setEndUploadDate(null);
                        if (filterPos === 0) {
                          filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                          filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                          filterList[index] = [];
                        }
            
                        return filterList;
                      },
                },
                filterOptions: {
                  names: [],
                  logic:(date:any, filters:any)=> {
                    let check = new Date(date.props.children);
                    let from:any = new Date(filters[0]);
                    let to:any = new Date(filters[1]);
                    from.setDate(from.getDate() + 1);
                    to.setDate(to.getDate() + 1);
                    from = new Date(from).setHours(0,0,0,0);
                    to = new Date(to).setHours(23,59,59,59);
      
                    if(filters[0] && filters[1] && check >= to && check <= from) {
                      return true;
                    } else if (filters[0] && check >= to) {
                      return true;
                    } else if (filters[1] && check <= from) {
                      return true;
                    }
                    return false;
                },
                  display: (filterList:any, onChange:any, index:any, column:any) => (
                    <div>
                      <FormLabel>Upload date</FormLabel>
                      <FormGroup row>
                        <TextField
                          id="startDate"
                          label="From"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={filterList[index][0] || ''}
                          onChange={event => {
                            filterList[index][0] = event.target.value;
                            setStartUploadDate(filterList[index][0])
                            onChange(filterList[index], index, column);
                          }}
                          style={{ width: '45%', marginRight: '5%' }}
                        />
                        <TextField
                          id="endDate"
                          label="To"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={filterList[index][1] || ''}
                          onChange={event => {
                            filterList[index][1] = event.target.value;
                            setEndUploadDate(filterList[index][1])
                            onChange(filterList[index], index, column);
                          }}
                          style={{ width: '45%', marginRight: '5%' }}
                        />
                      </FormGroup>
                    </div>
                  )
                }
            }
        },
        {
            name: "No. of contents",
            options: {
                filterType: 'dropdown' as 'dropdown',
                filterList: numberOfContents >= 0 ? [numberOfContents] : null,
                customFilterListOptions: {
                render: (v: any) => {
                    return `No. of contents: ${v}`
                },
                },
            }
            },
        {
            name: "No. of content sets",
            options: {
                filterType: 'dropdown' as 'dropdown',
                filterList: numberOfContentSets >= 0 ? [numberOfContentSets] : null,
                customFilterListOptions: {
                render: (v: any) => {
                    return `No. of content sets: ${v}`;
                },
                },
            }
            },
        {
            name: "No. of articles",
            options: {
                filterType: 'dropdown' as 'dropdown',
                filterList: numberOfArticles >= 0 ? [numberOfArticles] : null,
                customFilterListOptions: {
                render: (v: any) => {
                    return `No. of articles: ${v}`;
                },},
            }
            },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <div>
                            <Button size='small' variant='contained' onClick={event => {
                                    event.stopPropagation();
                                    openIncompleteFlowInContentUpload(tableMeta.rowData[1]);
                                }}
                                endIcon={<EditIcon/>}>
                                Finish
                            </Button>
                            <Button size='small' variant='contained' onClick={event => {
                                    event.stopPropagation();
                                    deleteIncompleteFlow(tableMeta.rowData[1]);
                                }}
                                endIcon={<DeleteIcon/>}>
                                Delete
                            </Button>
                        </div>
                    )
                }
            }
        }
    ];

    const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
        return <SearchBarComponent lastSearchValue={previousSearchString} handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
      };
      
    const options: MUIDataTableOptions = {
        ///
        count: incompleteFlowData.length,
        page: incompleteTableCurrentPageNumber,
        jumpToPage: true,
        serverSide: false,
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: true,
        elevation: 3,
        fixedHeader: false,
        responsive: 'vertical',
        filterType: 'dropdown',
        tableBodyMaxHeight: 'calc(100%)',
        tableBodyHeight: 'calc(100%-50px)',
        download: true,
        print: false,
        searchPlaceholder: "Search ",
        searchOpen: previousSearchString ? true : false,
        searchText: previousSearchString,
        sortOrder:{
            name:"Last edited",
            direction:"asc"
        },
        customSearchRender: customSearchRender,
        onChangePage:(page: number)=>{
            setIncompleteTableCurrentPageNumber(page);
            refetch();
        },
        onFilterChange: (changedColumn: any, filterList: any, type: any) => {
            setFlowTitle(filterList[2][0]);
            setUsername(filterList[3][0]);
            setContributorName(filterList[4][0]);
            setContributorType(filterList[5][0]);
            setNumberOfContents(filterList[7][0]);
            setNumberOfContentSets(filterList[8][0]);
            setNumberOfArticles(filterList[9][0]);
            
        },
        textLabels: {
            body: {
                noMatch: loading ?
                <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`} 
                size={20} color={"#36D2B3"} loading={loading}/>
                :
                'All content flows seem to be completed for now',
            },
        },
        customSort: (data, dataIndex, rowIndex) => {
            if (dataIndex === 8 || dataIndex===9) {
              return data.sort((a, b) => {
                const dateA = new Date(a.data[dataIndex].props.children).getTime();
                const dateB = new Date(b.data[dataIndex].props.children).getTime();
                return (dateA < dateB ? -1 : 1) * (rowIndex === "desc" ? 1 : -1);
              });
            } else {
              return data.sort((a, b) => {
                return (
                  (a.data[dataIndex].length < b.data[dataIndex].length ? -1 : 1) *
                  (rowIndex === "desc" ? 1 : -1)
                );
              });
            }
        }
    };

    const openIncompleteFlowInContentUpload = (incompleteFlowId: string) => {
        setLoadingIndicator(true);
        const incompleteFlow = incompleteFlowData.find((flow: IncompleteUserUploadFlow) => 
            flow.incompleteFlowId === incompleteFlowId);
        if(incompleteFlow) {
            const files = constructUploadedFilesResponseObjects(incompleteFlow.contents); //individual contents
            loadContentSetsInRedux(incompleteFlow.contentSets); //content sets
            loadArticlesAndAudioFilesInRedux(incompleteFlow.articles, incompleteFlow.audioFiles); //articles and audio files

            uploadFilesAction(files);
            selectIncompleteFlowAction(incompleteFlow.incompleteFlowId);
            toggleRadioButtonAction(RadioButtonGroup.SET);
            setLoadingIndicator(false);
            redirectToContentUploadPage(incompleteFlow.incompleteFlowId);
        } else {
            //flow not found, display error
        }
    }

    const onSubmitSearch = (newSearchString: string) => {
        refetch();
        setSearchString(newSearchString);
      };
    
      const handleResetSearch = () => {
        refetch();
        setSearchString("");
      }

    const loadArticlesAndAudioFilesInRedux = (articles: Article[], audioFiles: Content[]) => {
        articles.map((article: Article) => {
            let articleObject: Article = {
                articleId: article.articleId,
                contentSetId: article.contentSetId.toString(),
                title: article.title,
                description: article.description,
                audioId: article.audioId ? article.audioId : undefined
            }
            uploadArticleAction(articleObject);
                 
            if(article.audioId) {
                let audioFile = audioFiles.find((audio: Content) => audio.id === article.audioId)
                if(audioFile) {
                    addAudioFileResponse(audioFile);
                }
            }
        })
    }

    const loadContentSetsInRedux = (contentSets: ContentSetContent[]) => {
        contentSets.map((set: ContentSetContent) => {
            //compose set files
            const setFiles = constructUploadedFilesResponseObjects(set.content);
            //compose set cover photo if exists
            const coverPhotoObj= setFiles.find((content: UploadedFileResponse) => content.id === set.contentSet.coverId); 
            //set object
            const setObj: ContentSet = {
                id: set.contentSet.contentSetId.toString(),
                title: set.contentSet.title,
                files: setFiles,
                coverPhoto: coverPhotoObj ? coverPhotoObj : undefined,
                selectedThreadIds: set.contentNavigation.map((nav: Channel) => {return nav.id})
            };

            uploadSetAction(setObj);
        })
    }

    const constructUploadedFilesResponseObjects = (contents: Content[]) => {
        const files: UploadedFileResponse[] = contents.map((content: Content) => {
            return {
                id: content.id,
                key: content.bucketKey,
                code: content.code,
                url: content.pathToFileThumbnail,
                filename: '', mimetype: '', encoding: '', status: content.status
            }
        })

        return files;
    }

    const convertRole= (contributor: User) => {
        if(contributor.role==='contributor_agency')
            return "Agency";
        else 
            return "Individual";
    }

    const getContributor = (user: User | undefined) => {
        if(user && user.company) {
          return user.company.name;
        } else if(user && user.person) {
          return `${user.person.firstName} ${user.person.lastName}`;
        } else {
          return "User unavailable";
        }
      }

    const createTableRow = (item: IncompleteUserUploadFlow) => {
        //thumbnail, ID, headline, username, name/agency, role, lastEdited, No. of contents, No. of content sets, No. of articles
        let totalContents = 0;
        item?.contentSets.forEach(contentSet => {
            totalContents += contentSet?.content?.length;
        })
        return [ item?.contents?.length > 0 ? item?.contents[0]?.pathToFileThumbnail 
                : 
                item?.contentSets?.length > 0 ? item?.contentSets[0]?.content[0]?.pathToFileThumbnail : "No photo available",
            item?.incompleteFlowId, 
            item?.flowTitle, 
            item?.contributor.username, 
            getContributor(item?.contributor),
            convertRole(item?.contributor),
            <Moment format="DD/MM/YYYY">{item?.lastEditedDate}</Moment>,
            totalContents,
            item?.contentSets?.length,
            item?.articles?.length]; 
    }
    return (
        <React.Fragment>
            <div className='refetch-container'>
                <ConfirmationModal incompleteFlowId={selectedFlowId} open={open} handleClose={handleClose} deleteFlow={onDelete}/>
                <RefreshDateTime loading={loading} autoRefreshOn={incompleteTableRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleIncompleteTableRefresh}/>
            </div>
            <MUIDataTable title={""} columns={columns} options={options}
                data={incompleteFlowData.map((item: IncompleteUserUploadFlow) => createTableRow(item))}/>
            {/* <SnackbarComponent showSnackbar={snackbarIndicator} handleClose={handleClose}
                    severity={snackbar.severity}
                    message={snackbar.message}/> */}
        </React.Fragment>
    )
}

const mapStateToProps = (state: AdminStoreState): { incompleteFlowData: IncompleteUserUploadFlow[];
        incompleteTableRefreshState: boolean; incompleteTableCurrentPageNumber: number; flowTitle: string;
        username: string;
        contributorName: string;
        numberOfContents: number;
        numberOfContentSets: number;
        numberOfArticles: number;
        uploadDate: Date[];
        contributorType: string;
        previousSearchString: string | undefined;
     } => {
    return {
        incompleteFlowData: selectIncompleteFlowData(state),
        incompleteTableRefreshState: selectIncompleteTableRefresh(state),
        incompleteTableCurrentPageNumber: getIncompleteTableCurrentPageNumber(state),
        flowTitle: selectFlowTitle(state),
        username: selectUsername(state),
        contributorName: selectContributorName(state),
        contributorType: selectContributorType(state),
        uploadDate: selectUploadDate(state),
        numberOfContents: selectNumberOfContents(state),
        numberOfContentSets: selectNumberOfContentSets(state),
        numberOfArticles: selectNumberOfArticles(state),
        previousSearchString: getSearchString(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TIncompleteFlowReducerActions | TContentUploadActions | TRefreshTablesActions | CallHistoryMethodAction | TTableFiltersReducerActions>) => {
    return {
        loadIncompleteDataAction: (data: IncompleteUserUploadFlow[]) => dispatch<ILoadIncompleteData>(
            {type: IncompleteFlowActionTypes.LOAD_INCOMPLETE_DATA, data: data}),
        redirectToContentUploadPage: (data: string) => dispatch(push(`/incomplete-upload-flow/${data}`)),
        toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({
            type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON,
            data: data
        }),
        updateSetAction: (data: ContentSet) => dispatch<IUpdateSet>({type: ContentUploadActionTypes.UPDATE_SET, data: data}),
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({type: ContentUploadActionTypes.UPLOAD_SET, data: data}),
        addIndividualImageNavigationAction: (data: IndividualImageCategory) => dispatch<IAddChannelToIndividualImage>(
            {type: ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL, data: data}),
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>(
            {type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data}),
        addAudioFileResponse: (data: any) => dispatch<IAddAudioFileResponse>(
            {type: ContentUploadActionTypes.ADD_AUDIO_FILE_RESPONSE, data: data}),
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>(
            {type: ContentUploadActionTypes.UPLOAD_FILES, data: data}),
        selectIncompleteFlowAction: (data: string) => dispatch<ISelectIncompleteFlow>(
            {type: IncompleteFlowActionTypes.SELECT_INCOMPLETE_FLOW, data: data}),
        resetReduxState: () => dispatch<IResetContentUpload>({type: ContentUploadActionTypes.RESET_CONTENT}),
        toggleIncompleteTableRefresh: (data: boolean) => dispatch<IToggleIncompleteTableRefresh>({
            type: TablesRefreshActionTypes.INCOMPLETE_TABLE_REFRESH,
            data: data
        }),
        setFlowTitle: (data: string) => dispatch<IFlowTitle>({
            type: TableFilterActions.SET_FLOW_TITLE,
            data: data
        }),
        setUsername: (data: string) => dispatch<IUsername>({
            type: TableFilterActions.SET_USERNAME,
            data: data
        }),
        setContributorName: (data: string) => dispatch<IContributorName>({
            type: TableFilterActions.SET_CONTRIBUTOR_NAME,
            data: data
        }),
        setContributorType: (data: string) => dispatch<IToggleFilterContributorType>({
            type: TableFilterActions.TOGGLE_FILTER_CONTRIBUTOR_TYPE,
            data: data
        }),
        setUploadDate: (data: Date[]) => dispatch<IToggleFilterUploadDate>({ 
            type: TableFilterActions.TOGGLE_FILTER_UPLOAD_DATE, 
            data: data 
        }),
        setNumberOfContents: (data: number) => dispatch<INumberOfContents>({
            type: TableFilterActions.SET_NUMBER_OF_CONTENTS,
            data: data
        }),
        setNumberOfContentSets: (data: number) => dispatch<INumberOfContentSets>({
            type: TableFilterActions.SET_NUMBER_OF_CONTENT_SETS,
            data: data
        }),
        setNumberOfArticles: (data: number) => dispatch<INumberOfArticles>({
            type: TableFilterActions.SET_NUMBER_OF_ARTICLES,
            data: data
        }),
        setIncompleteTableCurrentPageNumber: (data: number) => dispatch<IncompleteTableCurrentPageNumber>({ type: IncompleteFlowActionTypes.SET_INCOMPLETE_TABLE_CURRENT_PAGE, data: data}),
        setSearchString: (data: string): any => dispatch<ISearchString>({ type: TableFilterActions.SET_SEARCH_STRING, data: data }),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncompleteUploadList);