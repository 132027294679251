import React, { useEffect, useState } from 'react';
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faSync } from "@fortawesome/free-solid-svg-icons";
import { APPROVE_THREAD, GET_SUGGESTED_THREADS, IS_THREAD_SINGLE_NAVIGATION, REJECT_THREAD } from './queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { SuggestedThread, SuggestThreadListProps } from './suggestThread.types';
import { RoleTypes } from '../../components/users-table/usersAdminTable.types';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { RefreshDateTime, ConfirmationDialog, SnackbarComponent } from 'shared';
import { SeveritySnackbarEnum } from '../../redux/generic/snackbar/snackbar.types';
import SyncLoader from 'react-spinners/SyncLoader';
import { selectSuggestThreadRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleSuggestThreadRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { AdminStoreState } from '../../redux/root-reducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const SuggestThreadList: React.FC<SuggestThreadListProps> = ({...props}) => {
    const {suggestThreadListRefreshState, toggleSuggestThreadRefresh} = props;

    const [fetchSingleNavigation, { data: isSingleNavData, loading: isSingleNavDataLoading, error: isSingleNavDataError }] = useLazyQuery(IS_THREAD_SINGLE_NAVIGATION);
    const { data, loading, error, refetch } = useQuery(GET_SUGGESTED_THREADS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });
    const [allSuggestThread, setAllSuggestThreads] = useState<SuggestedThread[]>([]);
    const [isThreadSingleNavigation, setIsThreadSingleNavigation] = useState(false);
    const [rejectThreadConfirmationDialog, setRejectThreadConfirmationDialog] = useState(false);
    const [removeThreadId, setRemoveThreadId] = useState(0);

    const [showApprovedContentSnackbar, setShowApprovedContentSnackbar] = useState(false);
    const [showRejectedContentSnackbar, setShowRejectedContentSnackbar] = useState(false);

    useEffect(() => {
        if (showApprovedContentSnackbar || showRejectedContentSnackbar) {
            refetch();
        }
    }, [showApprovedContentSnackbar, showRejectedContentSnackbar]);

    useEffect(() => {
        if (data) {
            setAllSuggestThreads(data.getSuggestThreadInfo);
        }
    }, [data]);

    useEffect(() => {
        if (isSingleNavData && isSingleNavData.isThreadSingleNavigation) {
            setIsThreadSingleNavigation(isSingleNavData.isThreadSingleNavigation);
        }
    }, [isSingleNavData]);

    const [approveThreadMutation] = useMutation(APPROVE_THREAD);
    const [rejectThreadMutation] = useMutation(REJECT_THREAD);

    const approveThread = (threadId: number) => {
        approveThreadMutation({
            variables: { threadId: threadId }
        })
            .then((result: any) => {
                setShowApprovedContentSnackbar(true);
            })
    }

    const rejectThread = (threadId: number) => {
        rejectThreadMutation({
            variables: { threadId: threadId }
        })
            .then((result: any) => {
                setShowRejectedContentSnackbar(true);
            })
    }

    const handleRejectThreadButton = (id: number) => {
        setRejectThreadConfirmationDialog(true);
        setRemoveThreadId(id);
        fetchSingleNavigation({ variables: { threadId: id } });
        setIsThreadSingleNavigation(false);
    }

    const handleRejectThreadDialogAgree = () => {
        setRejectThreadConfirmationDialog(false);
        if (removeThreadId !== 0) {
            rejectThread(removeThreadId);
        }
    }

    const handleRejectThreadDialogClose = () => {
        setRejectThreadConfirmationDialog(false);
        setRemoveThreadId(0);
        setIsThreadSingleNavigation(false);
    }


    const suggestThreadColumns = [
        {
            name: "Thread ID",
            options: {
                viewColumns: false,
                sort: false,
                display: 'false' as 'false',
                filter: false
            }
        },
        "Contributor username",
        "Contributor name",
        "Contributor type",
        "Navigation",
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <div>
                            <Button size='small' startIcon={<FontAwesomeIcon className='icon-button' icon={faCheck} />}
                                onClick={event => {
                                    event.stopPropagation();
                                    approveThread(tableMeta.rowData[0]);
                                }}
                            >
                                Approve
                            </Button>

                            <Button size='small' startIcon={<FontAwesomeIcon className='icon-button' icon={faTimes} />}
                                onClick={event => {
                                    event.stopPropagation();
                                    handleRejectThreadButton(tableMeta.rowData[0]);
                                }}
                            >
                                Reject
                            </Button>

                        </div>
                    )
                }
            }
        },
    ];

    const options: MUIDataTableOptions = {
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: false,
        elevation: 3,
        jumpToPage: true,
        fixedHeader: false,
        filterType: 'dropdown',
        responsive: 'vertical',
        tableBodyMaxHeight: 'calc(100%)',
        tableBodyHeight: 'calc(100%-50px)',
        download: true,
        print: false,
        sort: true,
        searchPlaceholder: "Search ",
        textLabels: {
            body: {
                noMatch: loading ?
                    <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
                        size={20} color={"#36D2B3"} loading={loading} />
                    :
                    'Sorry, no matching records found for now',
            },
        },
        customSort: (data, dataIndex, rowIndex) => {
            if(dataIndex === 4 ) {
                return data.sort((a, b) => {
                  return (
                    (a.data[dataIndex][0].localeCompare(b.data[dataIndex][0])) *
                    (rowIndex === "desc" ? 1 : -1)
                  );
                });
            } 
            else {
                return data.sort((a, b) => {
                    if(a.data[dataIndex] && b.data[dataIndex])
                    {
                        return (
                            (a.data[dataIndex].localeCompare(b.data[dataIndex])) *
                            (rowIndex === "desc" ? 1 : -1)
                        );
                    }
                    else {
                        return 0;
                    }
                });
            }
        },

    };

    const convertRole = (item: any) => {
        if (item.contributor.role === RoleTypes.Contributor_agency) {
            return 'Agency';
        }
        else {
            return 'Individual'
        }
    }

    const getName = (item:  SuggestedThread) => {
        if (item.contributor) {
            if (item.contributor.role === RoleTypes.Pro_organization || item.contributor.role === RoleTypes.Contributor_agency) {
                return item.contributor.company ? item.contributor.company.name : "N/A";
            }
            else {
                return item.contributor.person ? item.contributor.person.firstName + " " + item.contributor.person.lastName : "N/A";
            }
        }
    }


    const createTableThreadRow = (suggestedThread: SuggestedThread) => {
        return [
            suggestedThread.threadId! ? suggestedThread.threadId! : '',
            suggestedThread.contributor ? suggestedThread.contributor.username : "N/A",
            getName(suggestedThread),
            suggestedThread.contributor ? convertRole(suggestedThread) : 'N/A',
            suggestedThread.threadNavigation ? suggestedThread.threadNavigation.map(nav => {
                return nav.channel.title + '=>' + nav.subChannel.title + ' => ' + nav.thread.title + "\n"
            }) : ''
        ]
    };

    const handleClose = () => {
        setShowApprovedContentSnackbar(false);
        setShowRejectedContentSnackbar(false);
    }

    return (
        <React.Fragment>
            <div className='refetch-container'>
                <RefreshDateTime loading={loading} autoRefreshOn={suggestThreadListRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleSuggestThreadRefresh}/>
            </div>
            <MUIDataTable
                title={""}
                columns={suggestThreadColumns} options={options}
                data={allSuggestThread!.map((suggestThread: SuggestedThread) => createTableThreadRow(suggestThread))}
            />
            <SnackbarComponent showSnackbar={showApprovedContentSnackbar} handleClose={handleClose}
                severity={SeveritySnackbarEnum.success}
                message={"Content was successfully approved"} />
            <SnackbarComponent showSnackbar={showRejectedContentSnackbar} handleClose={handleClose} severity={SeveritySnackbarEnum.success}
                message={"Content was successfully rejected"} />

            {
                isThreadSingleNavigation ?
                    <ConfirmationDialog
                        open={rejectThreadConfirmationDialog}
                        title="Rejection of thread"
                        contentText="You can't reject thread because it is the only navigation of its content"
                        acceptButtonText="Okay"
                        handleClose={handleRejectThreadDialogClose}
                        handleConfirmationDialogAgree={handleRejectThreadDialogClose}
                    /> :
                    <ConfirmationDialog
                        open={rejectThreadConfirmationDialog}
                        title="Rejection of thread"
                        contentText="Are you sure you want to reject this thread?"
                        rejectButtonText="No"
                        acceptButtonText="Yes"
                        handleClose={handleRejectThreadDialogClose}
                        handleConfirmationDialogAgree={handleRejectThreadDialogAgree}
                    />
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: AdminStoreState): {suggestThreadListRefreshState: boolean} => {
    return {
        suggestThreadListRefreshState: selectSuggestThreadRefresh(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TRefreshTablesActions>) => {
    return {
        toggleSuggestThreadRefresh: (data: boolean) => dispatch<IToggleSuggestThreadRefresh>({
            type: TablesRefreshActionTypes.SUGGEST_THREAD_REFRESH,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestThreadList);