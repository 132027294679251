import { LOGOUT_USER, CHECK_USER } from "../../components/navigation/queries";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useMutation } from "react-apollo-hooks";
import "./navbar.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import {
    ILogoutFailure,
    ILogoutSuccess,
    ISelectTabFromNavBar,
    IStartLogout,
    TUserReducerActions
} from "../../redux/admin/admin.actions";
import { selectCurrentTabFromNavigation, selectCurrentUser } from "../../redux/admin/admin.selectors";
import { UserActionTypes, User } from "../../redux/admin/admin.types";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { createTheme, Fade, Menu, MenuItem, MuiThemeProvider, Tab, Tabs } from "@material-ui/core";
import ChangePassword from "../users-table/change-password";
import { AdminStoreState } from "../../redux/root-reducer";
import { IResetContentUpload, TContentUploadActions } from "../../redux/content-upload/content-upload.actions";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { useLazyQuery } from "@apollo/react-hooks";
import { IResetSnackbar, TSnackbarActions } from "../../redux/generic/snackbar/snackbar.actions";
import { SnackbarActionTypes } from "../../redux/generic/snackbar/snackbar.types";
import { IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { TableFilterActions } from "../../redux/table-filter/table-filter.types";
import { IContentTableCurrentPageNumber, TContentTableReducerActions } from "redux/content-table/content.actions";
import { ContentTableActionTypes } from "../../redux/content-table/contentTable.types";
import { WaitingListActionTypes } from "../../redux/waiting-list/waitingList.types";
import { IWaitingListCurrentPageNumber, TWaitingListReducerActions } from "redux/waiting-list/waitingList.actions";
import { IncompleteFlowActionTypes } from "../../redux/incomplete-upload/incompleteUpload.types";
import {
    IncompleteTableCurrentPageNumber,
    TIncompleteFlowReducerActions
} from "../../redux/incomplete-upload/incompleteUpload.actions";

export const navbarTheme = createTheme({
    overrides: {
        MuiTabs: {
            scroller: {
                display: "flex !important",
                alignItems: "center"
            },
            indicator: {
                backgroundColor: "#0caf95"
            }
        }
    }
});

const Navbar = ({ ...props }) => {
    const {
        currentUser,
        selectedTabValue,
        logoutUserAction,
        logoutSuccessAction,
        logoutFailureAction,
        redirectToHome,
        selectNewTabValue,
        resetReduxState,
        resetSnackbarAction,
        resetTablesFilterAction,
        setDataTableCurrentPage,
        setWaitingListDataTableCurrentPage,
        setIncompleteTableCurrentPage
    } = props;
    const [logoutUserMutation] = useMutation(LOGOUT_USER);
    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
    const [checkIfUserStillExists, { data: userData, loading, error, refetch }] = useLazyQuery(CHECK_USER);
    const open = Boolean(anchorElement);

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === "POP") {
                resetReduxState();
                resetSnackbarAction();
                resetTablesFilterAction();
            }
        });
    }, []);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        selectNewTabValue(newValue);
        resetReduxState();
        resetSnackbarAction();
        resetTablesFilterAction();
    };

    const logoutUser = (event: any): void => {
        event.preventDefault();

        logoutUserAction();

        logoutUserMutation()
            .then((result: any) => {
                logoutSuccessAction();
                redirectToHome();
            })
            .catch((response: any) => {
                logoutFailureAction(response.message);
            });

        setAnchorElement(null);
    };

    useEffect(() => {
        if (currentUser && currentUser.id) {
            checkIfUserStillExists({ variables: { userId: currentUser.id } });
        }
    }, [currentUser]);

    useEffect(() => {
        if (userData) {
            if (!userData.checkIfUserExists) {
                logoutUserAction();
                logoutUserMutation()
                    .then((result: any) => {
                        logoutSuccessAction();
                        redirectToHome();
                    })
                    .catch((response: any) => {
                        logoutFailureAction(response.message);
                    });
            }
        }
    }, [userData]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const goToHomepage = () => {
        resetReduxState();
        selectNewTabValue(false);
        redirectToHome();
    };

    const resetContentTableAndIncompleteTableCurrentPage = () => {
        setDataTableCurrentPage(0);
        setIncompleteTableCurrentPage(0);
    };

    const resetIncompleteTableAndWaitingListCurrentPage = () => {
        setIncompleteTableCurrentPage(0);
        setWaitingListDataTableCurrentPage(0);
    };

    const resetTablePageNumbers = () => {
        setIncompleteTableCurrentPage(0);
        setDataTableCurrentPage(0);
        setWaitingListDataTableCurrentPage(0);
    };
    return (
        <MuiThemeProvider theme={navbarTheme}>
            <div className="navigation-header">
                <div className="navigation-heading" onClick={() => goToHomepage()}>
                    world illustrated Admin
                </div>
                <Tabs
                    value={selectedTabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="navigation-admin-tabs"
                >
                    <Tab
                        onClick={resetContentTableAndIncompleteTableCurrentPage}
                        label="Waiting list"
                        value={0}
                        component={Link}
                        to={`/waiting-list/0`}
                    />
                    <Tab
                        onClick={resetTablePageNumbers}
                        label="Channels"
                        value={1}
                        component={Link}
                        to="/channels-table"
                    />
                    <Tab
                        onClick={resetIncompleteTableAndWaitingListCurrentPage}
                        label="Content"
                        value={2}
                        component={Link}
                        to="/content"
                    />
                    <Tab onClick={resetTablePageNumbers} label="Users" value={3} component={Link} to="/users" />
                    <Tab onClick={resetTablePageNumbers} label="Sales" value={4} component={Link} to="/sales" />
                    <Tab onClick={resetTablePageNumbers} label="License" value={5} component={Link} to="/license" />
                    <Tab label="Reports" value={6} component={Link} to="/reports" />
                    <Tab label="Post Reports" value={7} component={Link} to="/post-reports" />
                </Tabs>
                <a className="navigation-profile" onClick={handleClick}>
                    <FontAwesomeIcon size="2x" icon={faUserCircle} />
                </a>
                <Menu
                    id="fade-menu"
                    anchorEl={anchorElement}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem id="userInfo">
                        <span className="userSpan">{currentUser.email}</span>
                        <span className="roleSpan">{currentUser.role}</span>
                    </MenuItem>
                    <MenuItem>
                        <ChangePassword email={currentUser.email} />
                    </MenuItem>
                    <MenuItem onClick={logoutUser}>Logout</MenuItem>
                </Menu>
            </div>
        </MuiThemeProvider>
    );
};

const mapStateToProps = (state: AdminStoreState): { currentUser: User; selectedTabValue: number } => {
    return {
        currentUser: selectCurrentUser(state),
        selectedTabValue: selectCurrentTabFromNavigation(state)
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<
        | TContentUploadActions
        | TUserReducerActions
        | CallHistoryMethodAction
        | TSnackbarActions
        | TTableFiltersReducerActions
        | TContentTableReducerActions
        | TWaitingListReducerActions
        | TIncompleteFlowReducerActions
    >
) => {
    return {
        logoutUserAction: () => dispatch<IStartLogout>({ type: UserActionTypes.LogoutUser }),
        logoutSuccessAction: () => dispatch<ILogoutSuccess>({ type: UserActionTypes.LogoutUserSuccess }),
        logoutFailureAction: (message: string) =>
            dispatch<ILogoutFailure>({ type: UserActionTypes.LogoutUserError, data: message }),
        redirectToHome: () => dispatch(push("/")),
        selectNewTabValue: (data: any) =>
            dispatch<ISelectTabFromNavBar>({ type: UserActionTypes.SelectTabFromNavBar, data: data }),
        resetReduxState: () => dispatch<IResetContentUpload>({ type: ContentUploadActionTypes.RESET_CONTENT }),
        resetSnackbarAction: () => dispatch<IResetSnackbar>({ type: SnackbarActionTypes.RESET_MESSAGE }),
        resetTablesFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_FILTERS }),
        setDataTableCurrentPage: (data: number) =>
            dispatch<IContentTableCurrentPageNumber>({ type: ContentTableActionTypes.SET_CURRENT_PAGE, data: data }),
        setWaitingListDataTableCurrentPage: (data: number) =>
            dispatch<IWaitingListCurrentPageNumber>({
                type: WaitingListActionTypes.SET_WAITING_TABLE_CURRENT_PAGE,
                data: data
            }),
        setIncompleteTableCurrentPage: (data: number) =>
            dispatch<IncompleteTableCurrentPageNumber>({
                type: IncompleteFlowActionTypes.SET_INCOMPLETE_TABLE_CURRENT_PAGE,
                data: data
            })
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
