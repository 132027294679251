import axios from 'axios';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { UploadedFileResponse } from './ContentUpload.types';

export const uploadToBucket = async (uploadedFile: IFileWithMeta, userId: number, url: string, config: any) : Promise<UploadedFileResponse> => {
    const formData = new FormData();
    
    const operation = {
        "query": `mutation singleUploadMutation($file: Upload!, $userId: String!) {
            singleUpload(file: $file, userId: $userId) {
                id
                filename
                mimetype
                encoding
                key
                url
                code
            }
          }`, "variables": { "file": null, "userId": userId.toString() }
    } 

    const map = {"0":["variables.file"]};

    formData.append('operations', JSON.stringify(operation));
    formData.append('map', JSON.stringify(map));
    formData.append('0', uploadedFile.file);

    return axios({
        method: 'POST',
        url: url,
        data: formData,
        headers: config
    })
    .then((response: any) => { 
        if(response.data.errors) {
            return Promise.reject(response.data.errors[0].message);
        }

        let responseProps = response.data.data.singleUpload;
        const fileResponse: UploadedFileResponse = {
            id: responseProps.id,
            filename: responseProps.filename,
            mimetype: responseProps.mimetype,
            encoding: responseProps.encoding,
            key: responseProps.key,
            url: responseProps.url,
            code: responseProps.code,
            blobObject: uploadedFile.file,
            status: responseProps.status
        }

        return Promise.resolve(fileResponse);
    })
    .catch((error: any) => {
        return Promise.reject(error.response);
    });
}