import { TIncompleteFlowReducerActions } from "./incompleteUpload.actions";
import { IncompleteFlowActionTypes, IncompleteFlowState } from "./incompleteUpload.types";

const InitialState: IncompleteFlowState = {
    incompleteFlowData: [],
    selectedIncompleteFlowData: null,
    incompleteTableCurrentPageNumber:0,
}

export const incompleteUploadReducer = (state = InitialState, action: TIncompleteFlowReducerActions) : IncompleteFlowState => {
    switch(action.type) {
        case IncompleteFlowActionTypes.LOAD_INCOMPLETE_DATA: {
            return {
                ...state,
                incompleteFlowData: action.data
            }
        }
        case IncompleteFlowActionTypes.SELECT_INCOMPLETE_FLOW: {
            return {
                ...state,
                selectedIncompleteFlowData: action.data
            }
        }
        case IncompleteFlowActionTypes.RESET_INCOMPLETE_STATE: {
            return {
                ...state,
                incompleteFlowData: [],
                selectedIncompleteFlowData: null
            }
        }
        case IncompleteFlowActionTypes.SET_INCOMPLETE_TABLE_CURRENT_PAGE:
            return {
                ...state,
                incompleteTableCurrentPageNumber: action.data
            };
        default:
            return state;
    }
}

export default incompleteUploadReducer;