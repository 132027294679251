import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, MuiThemeProvider, TextField, Tooltip } from '@material-ui/core';
import { ArticleComponentProps, AddArticleTheme, ContentSet, Article, textfieldStyles } from './ContentUpload.types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import SunEditor, { buttonList } from 'suneditor-react';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectArticles, selectChangesToBeSaved } from '../../redux/content-upload/content-upload.selectors';
import { Dispatch } from 'redux';
import { TContentUploadActions, IUploadArticle, IUpdateArticle, IDeleteArticle, IToggleChangesToBeSaved } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import { SnackbarComponent } from 'shared';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './AddArticle.styles.scss';
import AddAudioInput from './AddAudioInput';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';

export const AddArticleComponent: React.FC<ArticleComponentProps> = ({ ...props }) => {
    const classes = textfieldStyles();
    const { changesToBeSaved, contentSetId, articles, snackbar, uploadArticleAction, updateArticleAction,
        deleteArticleAction, toggleChangesToBeSavedAction, broadcastSnackbarAction } = props;

    const [isArticleFound, setIsArticleFound] = useState(false);
    const [articleHeading, setArticleHeading] = useState('');
    const [articleText, setArticleText] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);

    useEffect(() => {
        if (contentSetId) {
            const selectedArticle = articles.find(article => article.contentSetId === contentSetId);
            if (selectedArticle) {
                setIsArticleFound(true);
                setArticleText(selectedArticle.description);
                setArticleHeading(selectedArticle.title);
            } else {
                setIsArticleFound(false);
                setArticleText("");
                setArticleHeading("");
            }
        }
    }, [articles]);

    useEffect(() => {
        if (snackbar.severity && snackbar.message) {
            setContentUploadSnackbar(true);
        }
    }, [snackbar])

    const handleClose = () => {
        setContentUploadSnackbar(false);
    };

    const handleFiles = async (files: FileList | null) => {
        if (files![0] && contentSetId && files![0].type === "text/plain") {
            await readFileContent(files![0]).then((content: any) => {
                let updatedArticle: Article = {
                    contentSetId: contentSetId,
                    title: files![0].name,
                    description: content
                }
                uploadArticleAction(updatedArticle);
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Article uploaded successfully"
                });
            }).catch((error: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Article upload failed"
                });
            })
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Uploaded wrong file type! Only .txt files allowed"
            });
        }
    }

    const readFileContent = (file: File) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onload = (event: any) => resolve(event.target.result);
            reader.onerror = (error: any) => reject(error);
            reader.readAsText(file);
        })
    }

    const handleDrop = (event: DragEvent) => {
        console.log(event);
        return true;
    }

    const handleChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;
        if (!changesToBeSaved) {
            toggleChangesToBeSavedAction(true);
        }

        setArticleHeading(value);
    };

    const handleContentOnChange = (text: string) => {
        if (!changesToBeSaved && !firstLoad) {
            toggleChangesToBeSavedAction(true);
        }

        setFirstLoad(false);
        setArticleText(text);
    }

    const handleOnInputTrigger = (event: any) => {
        event.preventDefault();
        if (!changesToBeSaved) {
            toggleChangesToBeSavedAction(true);
        }
    }

    const saveChanges = (contentSetId: string | undefined) => {
        if (articleHeading.length <= 1
            || articleHeading.length > 200
            || articleText.length <= 1) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.warning,
                message: "Article heading and description must contain more than 1 symbol and heading must not exceed 200 symbols"
            });
            return;
        }

        if (contentSetId) {
            let hasArticle = articles.find(article => article.contentSetId === contentSetId);
            if (hasArticle) {
                updateArticleAction({ contentSetId, title: articleHeading, description: articleText });
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Article updated successfully"
                });
            } else {
                let newArticle: Article = {
                    contentSetId: contentSetId,
                    title: articleHeading,
                    description: articleText
                }
                uploadArticleAction(newArticle);
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Article uploaded successfully"
                });
            }
            toggleChangesToBeSavedAction(false);
        }
    }

    const removeArticle = () => {
        const deletedArticle = articles.find(article => article.contentSetId === contentSetId);

        if (deletedArticle) {
            deleteArticleAction(deletedArticle.contentSetId);
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Article deleted successfully"
            });
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to delete article with id: " + contentSetId
            });
        }
    }

    const isArticleChanged = () => {
        return changesToBeSaved
            && (articleHeading.length > 0 || articleText.length > 0);
    }

    return (
        <div className='add-article-container'>
            <MuiThemeProvider theme={AddArticleTheme}>
                <div className='article-header'>
                <Tooltip title={articleHeading} disableFocusListener disableTouchListener>
                        <FormControlLabel aria-label='content-set-title'
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <TextField
                                    autoComplete="off"
                                    name="textfield"
                                    value={articleHeading}
                                    margin="normal"
                                    placeholder="Set article name..."
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 200 }}
                                    InputProps={{
                                        classes: {
                                            root: classes.inputBaseRoot,
                                            input: classes.inputBase,
                                            underline: classes.inputUnderline,
                                            formControl: classes.formControl
                                        }
                                    }}
                                />
                            } label='' />
                    </Tooltip>
                    {isArticleChanged() ?
                        <Button size='small' className='save-button'
                            onClick={() => saveChanges(contentSetId)} variant='contained'
                            component='span' endIcon={<SaveIcon />}>
                            Save Changes
                        </Button>
                        : null}
                </div>

                <div className="text-field-container">
                    <SunEditor lang="en"
                        name="sunEditor" setContents={articleText} placeholder="Please type here..."
                        setOptions={{
                            customPlugins: [], buttonList: [['bold', 'underline', 'italic',
                                'strike', 'list']]
                        }}
                        onChange={handleContentOnChange} onInput={handleOnInputTrigger} onDrop={handleDrop} />
                </div>

                <div className='button-group-article'>
                    {articles.find(article => article.contentSetId === contentSetId) ?
                        <Button size='small' className='white-button' onClick={removeArticle} variant='contained' component='span'
                            endIcon={<DeleteIcon />}>
                            Remove article
                        </Button>
                        :
                        <React.Fragment>
                            <input accept=".txt" className='upload-input' id="contained-button-file" multiple={false} type="file"
                                onChange={(e) => handleFiles(e.target.files)} />
                            <label htmlFor="contained-button-file">
                                <Button size='small' className='white-button' variant='contained' component='span'
                                    endIcon={<CloudUploadIcon />}>
                                    Upload TXT
                                </Button>
                            </label>
                        </React.Fragment>
                    }
                    {isArticleFound ?
                        <AddAudioInput articleId={contentSetId!}
                            audioId={articles.find(article => article.contentSetId === contentSetId)?.audioId} />
                        : null}
                </div>
                <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                    severity={snackbar.severity}
                    message={snackbar.message} />
            </MuiThemeProvider>
        </div>
    )
}

const mapStateToProps = (state: AdminStoreState): {
    articles: Article[], snackbar: IBroadcastMessage,
    changesToBeSaved: boolean
} => {
    return {
        articles: selectArticles(state),
        snackbar: selectSnackbarState(state),
        changesToBeSaved: selectChangesToBeSaved(state)
    }

}
const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions>) => {
    return {
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>({ type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data }),
        updateArticleAction: (data: Article) => dispatch<IUpdateArticle>({ type: ContentUploadActionTypes.UPDATE_ARTICLE, data: data }),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        }),
        deleteArticleAction: (data: string) => dispatch<IDeleteArticle>({ type: ContentUploadActionTypes.DELETE_ARTICLE, data: data }),
        toggleChangesToBeSavedAction: (data: boolean) => dispatch<IToggleChangesToBeSaved>({ type: ContentUploadActionTypes.TOGGLE_SAVED_CHANGES, data: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleComponent);
