import gql from 'graphql-tag';

export const LOGIN_USER = gql`
    mutation loginUserMutation($email: String!, $password: String!) {
        loginAdmin(email: $email, password: $password) {
            id
            email
            role
            logInMaxAge
            logInTime
            token
        }
    }
`;

export const CURRENT_USER = gql`
    query getCurrentUser {
        currentUser {
            id
            email
            role
        }
    }
`;