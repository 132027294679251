import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { adminReducer } from './admin/admin.reducer';
import { UserState } from './admin/admin.types';
import { DataTableState } from './users-table/usersTable.types';
import { ContentTableState } from './content-table/contentTable.types';
import { usersTableReducer } from './users-table/usersTable.reducer';
import { contentTableReducer } from './content-table/contentTable.reducer';
import { ChannelsTableState } from './channels-table/channels.types';
import channelsTableReducer from './channels-table/channels.reducer';
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import {contentUploadReducer} from "./content-upload/content-upload.reducer";
import {ContentUploadState} from "./content-upload/content-upload.types";
import {WaitingListState} from "./waiting-list/waitingList.types";
import waitingListReducer from "./waiting-list/waitingList.reducer";
import { LicenseTableState } from './licence/licenseTable.types';
import licenseTableReducer from './licence/license.reducer';
import { IncompleteFlowState } from './incomplete-upload/incompleteUpload.types';
import incompleteUploadReducer from './incomplete-upload/incompleteUpload.reducer';
import { IncompleteSFTPFlowState } from './incomplete-sftp-upload/incompleteSFTPUpload.types';
import incompleteSFTPUploadReducer from './incomplete-sftp-upload/incompleteSFTPUpload.reducer';
import { snackbarReducer } from './generic/snackbar/snackbar.reducer';
import { SnackbarState } from './generic/snackbar/snackbar.types';
import orderHistoryReducer from './order-history/orderHistory.reducer';
import { OrderHistoryState } from './order-history/orderHistory.types';
import { TableFilterState } from './table-filter/table-filter.types';
import { tableFilterReducer } from './table-filter/table-filter.reducer';
import { tablesRefreshReducer } from './tables-refresh/tables-refresh.reducer';
import { TablesRefreshState } from './tables-refresh/tables-refresh.types';
import { EditContentState } from './EditContent/editContent.types';
import editContentReducer from './EditContent/editContent.reducer';
import { singleItemReducer } from './SingleItem/singleItem.reducer';
import { SingleItemState } from './SingleItem/singleItem.types';

export interface AdminStoreState {
    admin: UserState & PersistPartial;
    router: RouterState;
    usersTable: DataTableState;
    contentTable: ContentTableState;
    channelsTable: ChannelsTableState;
    contentUpload: ContentUploadState;
    orderHistory: OrderHistoryState;
    incompleteFlow: IncompleteFlowState;
    incompleteSFTPFlow: IncompleteSFTPFlowState;
    waitingList: WaitingListState;
    licenseTable: LicenseTableState;
    snackbar: SnackbarState;
    tablesRefresh: TablesRefreshState & PersistPartial;
    tableFilters: TableFilterState;
    editContent: EditContentState;
    singleItem: SingleItemState & PersistPartial;
}

const adminConfig = {
    key: 'admin',
    storage: storage
}

const tablesRefreshConfig = {
    key: 'tablesRefresh',
    storage: storage
}

const singleItemConfig = {
    key: 'singleItem',
    storage: storage,
    blacklist: ['singleItem']
}

export const rootAdminReducer = (history: any) => combineReducers<AdminStoreState>({
    admin: persistReducer(adminConfig, adminReducer),
    router: connectRouter(history),
    usersTable: usersTableReducer,
    contentTable: contentTableReducer,
    contentUpload: contentUploadReducer,
    orderHistory: orderHistoryReducer,
    incompleteFlow: incompleteUploadReducer,
    incompleteSFTPFlow: incompleteSFTPUploadReducer,
    channelsTable: channelsTableReducer,
    waitingList: waitingListReducer,
    licenseTable: licenseTableReducer,
    snackbar: snackbarReducer,
    tablesRefresh: persistReducer(tablesRefreshConfig, tablesRefreshReducer),
    tableFilters: tableFilterReducer,
    editContent: editContentReducer,
    singleItem: persistReducer(singleItemConfig, singleItemReducer),
});

export type RootState = ReturnType<typeof rootAdminReducer>;