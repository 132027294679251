import React, { useState, useEffect } from 'react';
import ChannelsTable from './channelsTable.component';
import { ChannelsPageProps, Channel, SubChannel, Thread, TableNames } from './channelsTable.types';
import { GET_CHANNELS, GET_ALL } from './queries';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Box } from '@material-ui/core';
import { Dispatch } from 'redux';
import { ChannelsTableState, ChannelsTableActionTypes } from '../../redux/channels-table/channels.types';
import { AdminStoreState } from '../../redux/root-reducer';
import {selectCurrentChannels, selectCurrentSubChannels, selectCurrentThreads} from '../../redux/channels-table/channels.selectors';
import { TChannelsTableActions, ILoadChannels, ILoadSubchannels, ILoadThreads, IResetThreads } from '../../redux/channels-table/channels.actions';
import { connect } from 'react-redux';
import './channelsTable.styles.scss';

const ChannelsPage: React.FC<ChannelsPageProps> = ({...props}) => {
    const {channels, subchannels, threads, loadChannelsAction, resetThreadsAction} = props;
    const [allChannelsCount, setAllChannelsCount] = useState({
        channelsCount: 0,
        subChannelsCount: 0,
        threadsCount: 0
    });
    const { data, loading, error } = useQuery(GET_ALL, {fetchPolicy: 'cache-and-network'});

    useEffect(() => {
        resetThreadsAction();
        if(data && data.getAll) {
            const {getAll, getAllChannels} = data;
            setAllChannelsCount({channelsCount: getAll[0], subChannelsCount: getAll[1], threadsCount: getAll[2]});
            loadChannelsAction(getAllChannels);
        }
    }, [data]);

    return (
        <div>
            <div className='table-header'>
                <span>{allChannelsCount.channelsCount} Channels | {allChannelsCount.subChannelsCount} Subchannels | {allChannelsCount.threadsCount} Threads</span>
            </div>
            <Box className='table-container'>
                <ChannelsTable tableName={TableNames.Channels} channelData={channels}/>
                <ChannelsTable tableName={TableNames.SubChannels} channelData={subchannels}/>
                <ChannelsTable tableName={TableNames.Threads} channelData={threads}/>
            </Box>
        </div>
    );
}

const mapStateToProps = (state: AdminStoreState) : { channels: Channel[], subchannels: SubChannel[], threads: Thread[]} => {
    return {
        channels: selectCurrentChannels(state),
        subchannels: selectCurrentSubChannels(state),
        threads: selectCurrentThreads(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TChannelsTableActions>) => {
    return {
        loadChannelsAction: (data: Channel[]) => { dispatch<ILoadChannels>({type: ChannelsTableActionTypes.LoadChannels, data: data})},
        resetThreadsAction: () => {dispatch<IResetThreads>({type: ChannelsTableActionTypes.ResetThreads})}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsPage);