import React, { useEffect, useState } from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { SelectChannelsProps } from './EditContentMetadata.types';
import { GET_SUBCHANNELS, GET_THREADS } from '../channels-table/queries';
import './EditContentMetadata.styles.scss';
import { useLazyQuery } from '@apollo/react-hooks';

const SelectChannelsComponent: React.FC<SelectChannelsProps> = ({...props}) => {
    const { disabled, channels, selectedDropdownData, handleSelect } = props;
    const [channelQuery, setChannelsQuery] = useState(GET_SUBCHANNELS);
    const [dataQuery, {data, loading, error}] = useLazyQuery(channelQuery);
    const [channelData, setChannelData] = useState({
        channels: channels,
        subchannels: [],
        threads: []
    })

    useEffect(() => {
        if(data && data.getAllSubChannelsByChannelId) {
            setChannelData({...channelData, subchannels: data.getAllSubChannelsByChannelId});
        }
        else if(data && data.getAllThreadsBySubChannelId) {
            setChannelData({...channelData, threads: data.getAllThreadsBySubChannelId});
        }

    }, [data])

    const handleSelectChange = (event: any) => {
        event.preventDefault();
        const selectedId = parseInt(event.target.value, 10);
        const selectedField = event.target.name;

        if(selectedField === 'channel') {
            setChannelsQuery(GET_SUBCHANNELS);
        } else if(selectedField === 'subchannel') {
            setChannelsQuery(GET_THREADS);
        }

        if(selectedField !== 'thread') {
            dataQuery({variables: {id: selectedId}});
        }
    }

    return (
        <div className='accordion-details'>
            <div className='form-field-row'>
                <InputLabel htmlFor="channel-input" shrink={true}>Channel: </InputLabel>
                <Select variant='standard' name="channel" onChange={handleSelectChange} disabled={disabled} 
                    value={selectedDropdownData.channel.selectedId !== 0 ? selectedDropdownData.channel.selectedId : ''}>
                    {channels.map((channel: any) => (
                        <MenuItem key={channel.id} value={channel.id} onClick={() => handleSelect(channel.id, channel.title, "channel")}>{channel.title}</MenuItem>
                    ))}
                </Select>
            </div>
            <div className='form-field-row'>
                <InputLabel htmlFor="subchannel-input" shrink={true}>Subchannel: </InputLabel>
                <Select variant='standard' name="subchannel" onChange={handleSelectChange} disabled={disabled} 
                    value={selectedDropdownData.subchannel.selectedId !== 0 ? selectedDropdownData.subchannel.selectedId : ''}>
                    {channelData.subchannels.map((subchannel: any) => (
                        <MenuItem key={subchannel.id} value={subchannel.id} onClick={() => handleSelect(subchannel.id, subchannel.title, "subchannel")}>{subchannel.title}</MenuItem>
                    ))}
                </Select>
            </div>
            <div className='form-field-row'>
                <InputLabel htmlFor="thread-input" shrink={true}>Thread: </InputLabel>
                <Select variant='standard' name="thread" onChange={handleSelectChange} disabled={disabled} 
                    value={selectedDropdownData.thread.selectedId !== 0 ? selectedDropdownData.thread.selectedId : ''}>
                    {channelData.threads.map((thread: any) => (
                        <MenuItem key={thread.id} value={thread.id} onClick={() => handleSelect(thread.id, thread.title, "thread")}>{thread.title}</MenuItem>
                    ))}
                </Select>
            </div>
            <hr/>
    </div>
    )
}

export default SelectChannelsComponent;