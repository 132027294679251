import { IBroadcastMessage } from "../../redux/generic/snackbar/snackbar.types";
import { LicensePlanTypes } from "../license-table/LicenseAdminTable.types";
import { makeStyles } from "@material-ui/core";
import { RapidApiCountryDto } from "../../globals/locations/location.types";

export interface CommissionsType {
    licenseType: string;
    snackbarAction: IBroadcastMessage;
    locationOverrideRefreshState: boolean;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
    toggleLocationOverrideRefresh: (data: boolean) => void;
}

export interface WICommissionsType {
    snackbarAction: IBroadcastMessage;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export interface LicensingCountries {
    id: number;
    country: RapidApiCountryDto;
    licensingPlanType: LicensePlanTypes;
    percentageFactor: number;
}

export interface AddMWUserCommissionTypes {
    tableData: Commission[];
    refetch: () => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export interface EditMWUserCommissionTypes {
    tableMeta: any;
    loadedData: Commission[];
    refetch: () => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export const useStyles = makeStyles((theme) => ({
    error: {
        color: 'red',
        fontSize: '10px',
        width: '240px'
    }
}));


export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

export interface Commission{
    id: number;
    hurdleLevel: number;
    percentage: number;
    amount: number;
    createdAt: string;
}

export interface Contributor {
    id: number;
    username: string;
    email: string;
    authStrategy: string;
    role: string;
    incomePercentage: number;
    preferredCurrency: string;
    hasPublicSFTPAccess: boolean | null;
    hasSFTPAccess: boolean | null;
    person: {
        id: number;
        firstName: string;
        lastName: string;
    };
    company: string;
}

export interface CurrentUser{
    id: number;
    email: string ;
    role: string ;
    defaultCommission: number ;
    incomePercentage: number ;
}
