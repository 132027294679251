import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectUser = (state: AdminStoreState) => state.admin;

export const selectCurrentUser = createSelector(
    [selectUser],
    (admin) => admin.currentUser
);

export const selectCurrentUserId = createSelector(
    [selectUser],
    (admin) => admin.currentUser.id
);

export const selectCurrentTabFromNavigation = createSelector(
    [selectUser],
    (admin) => admin.selectedTabFromNavigation
);

export const selectRowsInWaitingList = createSelector(
    [selectUser],
    (admin) => admin.waitingListRowsSelected
);

export const selectRowsInContentList = createSelector(
    [selectUser],
    (admin) => admin.contentListRowsSelected
)