import React, { useEffect, useState } from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  LicensingCountries,
  CommissionsType,
  Contributor,
  CurrentUser,
  WICommissionsType,
} from "./commissions.types";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import "./commissions.styles.scss";
import { Dispatch } from "redux";
import {
  IBroadcastMessage,
  SeveritySnackbarEnum,
  SnackbarActionTypes,
} from "../../redux/generic/snackbar/snackbar.types";
import {
  ISnackbarMessage,
  TSnackbarActions,
} from "../../redux/generic/snackbar/snackbar.actions";
import { AdminStoreState } from "../../redux/root-reducer";
import { selectSnackbarState } from "../../redux/generic/snackbar/snackbar.selectors";
import { connect } from "react-redux";
import { SnackbarComponent } from "shared";
import EditLocationOverrideModal from "./editCommissionsModal";
import { RefreshDateTime } from "shared";
import { selectLocationOverrideRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import {
  IToggleLocationOverrideRefresh,
  TRefreshTablesActions,
} from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { CURRENT_USER, UPDATE_WI_USER_COMMISSION } from "./queries";
import { ALL_CONTRIBUTORS } from "../users-table/queries";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import Select from "@mui/material/Select";

const WICommissions: React.FC<WICommissionsType> = ({ ...props }) => {
  const { snackbarAction, broadcastSnackbarAction } = props;
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [
    defaultCommissionPercentageError,
    setDefaultCommissionPercentageError,
  ] = useState<boolean>(false);
  const [
    overrideCommissionPercentageError,
    setOverrideCommissionPercentageError,
  ] = useState<boolean>(false);
  const [commissionUpdateSnackbar, setCommissionUpdateSnackbar] =
    useState<boolean>(false);
  const [updatingWIUserCommission, setUpdatingWIUserCommission] =
    useState<boolean>(false);
  const [overridingWIUserCommission, setOverridingWIUserCommission] =
    useState<boolean>(false);
  const [selectedContributor, setSelectedContributor] = useState<any>("");
  const [
    selectedContributorIncomePercentage,
    setSelectedContributorIncomePercentage,
  ] = useState<string>("0");
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [applyToAllContributors, setApplyToAllContributors] =
    useState<boolean>(false);

  const [
    selectedDefaultCommissionPercentage,
    setSelectedDefaultCommissionPercentage,
  ] = useState<string>("0");
  const [
    fetchAllContributors,
    {
      data: contributorsData,
      loading: contributorsLoading,
      error: contributorsError,
      refetch: refetchContributors,
    },
  ] = useLazyQuery(ALL_CONTRIBUTORS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [
    fetchCurrentUserData,
    {
      data: currentUserData,
      loading: currentUserLoading,
      error: currentUserError,
      refetch: refetchCurrentUser,
    },
  ] = useLazyQuery(CURRENT_USER, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [updateWIUserCommission] = useMutation(UPDATE_WI_USER_COMMISSION);
  const handleCommissionPercentageUpdate = (overriding: boolean): void => {
    if (currentUser) {
      let variables = {};
      if (overriding) {
        setOverridingWIUserCommission(true);
        if (applyToAllContributors) {
          variables = {
            percentage: parseFloat(selectedContributorIncomePercentage) / 100,
            universal: true,
          };
        } else {
          variables = {
            userId: selectedContributor,
            percentage: parseFloat(selectedContributorIncomePercentage) / 100,
            universal: false,
          };
        }
      } else {
        setUpdatingWIUserCommission(true);
        variables = {
          percentage: parseFloat(selectedDefaultCommissionPercentage) / 100,
          universal: true,
        };
      }
      updateWIUserCommission({ variables })
        .then((result: any) => {
          setCommissionUpdateSnackbar(true);
          if (overriding) {
            setOverridingWIUserCommission(false);
            if (applyToAllContributors) {
              fetchCurrentUserData();
              fetchAllContributors();
              setSelectedDefaultCommissionPercentage("0");
              setSelectedContributorIncomePercentage("0");
              setSelectedContributor("");
              broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Default commission percentage updated successfully",
              });
            } else {
              fetchAllContributors();
              setSelectedContributorIncomePercentage("0");
              setSelectedContributor("");
              broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Commission percentage overridden successfully",
              });
            }
          } else {
            setUpdatingWIUserCommission(false);
            fetchAllContributors();
            fetchCurrentUserData();
            broadcastSnackbarAction({
              severity: SeveritySnackbarEnum.success,
              message: "Default commission percentage updated successfully",
            });
          }
        })
        .catch((error: any) => {
          if (overriding) {
            setOverridingWIUserCommission(false);
            broadcastSnackbarAction({
              severity: SeveritySnackbarEnum.success,
              message: "Override commission percentage failed",
            });
          } else {
            setUpdatingWIUserCommission(false);
            broadcastSnackbarAction({
              severity: SeveritySnackbarEnum.success,
              message: "Default commission percentage update failed",
            });
          }
        });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedContributor(parseInt(event.target.value as string));
    for (const element of contributors) {
      if (element.id === parseInt(event.target.value as string)) {
        setSelectedContributorIncomePercentage(
          `${(element.incomePercentage * 100).toFixed(2)}%`
        );
        break;
      }
    }
  };

  const isValidNumber = (str: string): boolean => {
    return /^\d+(\.\d+)?$/.test(str);
  };

  const handleDefaultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedString = e.target.value.includes("%")
      ? e.target.value.split("%")[0]
      : e.target.value;
    setSelectedDefaultCommissionPercentage(formattedString);
    if (
      parseInt(formattedString) > 0 &&
      parseInt(formattedString) <= 100 &&
      isValidNumber(formattedString)
    ) {
      setDefaultCommissionPercentageError(false);
    } else {
      setDefaultCommissionPercentageError(true);
    }
  };

  const handleApplyAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyToAllContributors(event.target.checked);
  };

  const handleOverride = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedString = e.target.value.includes("%")
      ? e.target.value.split("%")[0]
      : e.target.value;
    setSelectedContributorIncomePercentage(formattedString);
    if (
      parseInt(formattedString) > 0 &&
      parseInt(formattedString) <= 100 &&
      isValidNumber(formattedString)
    ) {
      setOverrideCommissionPercentageError(false);
    } else {
      setOverrideCommissionPercentageError(true);
    }
  };

  const handleClose = () => {
    setCommissionUpdateSnackbar(false);
  };

  useEffect(() => {
    fetchCurrentUserData();
    fetchAllContributors();
    return ()=>{
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "",
      });
    }
  }, []);

  useEffect(() => {
    if (currentUserData) {
      setCurrentUser(currentUserData.currentUser);
      setSelectedDefaultCommissionPercentage(
        currentUserData.currentUser?.defaultCommission
          ? `${(currentUserData.currentUser?.defaultCommission * 100).toFixed(
              2
            )}%`
          : "0"
      );
    }
  }, [currentUserData]);

  useEffect(() => {
    if (contributorsData && contributorsData.getAllContributors)
      setContributors([...contributorsData.getAllContributors]);
  }, [contributorsData]);
  return (
    <div className="wi-commission-box">
      <p className="section-title">Default commission of WI Contributors:</p>
      <div className="default-commissions-box">
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={selectedDefaultCommissionPercentage}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={handleDefaultChange}
          error={defaultCommissionPercentageError}
          helperText={
            defaultCommissionPercentageError
              ? "Invalid commission percentage."
              : ""
          }
        />
        <button
          className={
            defaultCommissionPercentageError || updatingWIUserCommission
              ? "disabled-pill-button"
              : `pill-button`
          }
          disabled={
            defaultCommissionPercentageError || updatingWIUserCommission
          }
          onClick={() => handleCommissionPercentageUpdate(false)}
          style={{ marginLeft: "1rem" }}
        >
          {updatingWIUserCommission ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            "Update"
          )}
        </button>
      </div>
      <p className="section-title">Override commission for a WI Contributor:</p>
      <InputLabel
        id="demo-simple-select-label"
        style={{ width: "100%", textAlign: "left" }}
      >
        Select Contributor
      </InputLabel>
      <div className="override-commissions-box">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedContributor}
          style={{ width: "40%", marginBottom: "1rem" }}
          onChange={handleChange}
        >
          {contributors.map((contributor: Contributor) => (
            <MenuItem value={contributor.id}>
              <b>Username: </b> {contributor.username}, <b>Commission: </b>{" "}
              {(contributor.incomePercentage * 100).toFixed(2)}%
            </MenuItem>
          ))}
        </Select>
        <p>Income percentage:</p>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={selectedContributorIncomePercentage}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={handleOverride}
          error={overrideCommissionPercentageError}
          helperText={
            overrideCommissionPercentageError
              ? "Invalid commission percentage."
              : ""
          }
        />
        <FormControlLabel
          style={{ width: "35%", textAlign: "left" }}
          control={
            <Checkbox
              onChange={handleApplyAllChange}
              checked={applyToAllContributors}
            />
          }
          label="Apply for all contributors"
        />
        <button
          className={
            overrideCommissionPercentageError || overridingWIUserCommission
              ? "disabled-pill-button"
              : `pill-button`
          }
          disabled={
            overrideCommissionPercentageError || overridingWIUserCommission
          }
          onClick={() => handleCommissionPercentageUpdate(true)}
        >
          {overridingWIUserCommission ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            "Override"
          )}
        </button>
      </div>
      <SnackbarComponent
        showSnackbar={commissionUpdateSnackbar}
        handleClose={handleClose}
        severity={snackbarAction.severity}
        message={snackbarAction.message}
      />
    </div>
  );
};

const mapStateToProps = (
  state: AdminStoreState
): { snackbarAction: IBroadcastMessage } => {
  return {
    snackbarAction: selectSnackbarState(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<TSnackbarActions | TRefreshTablesActions>
) => {
  return {
    broadcastSnackbarAction: (data: IBroadcastMessage) =>
      dispatch<ISnackbarMessage>({
        type: SnackbarActionTypes.BROADCAST_MESSAGE,
        data: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WICommissions);
