import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, FormLabel, Select, withStyles } from "@material-ui/core";
import { NewChannelProps, TableNames, channelsTableTheme, Channel, SubChannel, Thread, CHANNEL_TITLE_REGEX, styles } from "./channelsTable.types";
import AddIcon from '@material-ui/icons/Add';
import { CREATE_CHANNEL, CREATE_SUBCHANNEL, CREATE_THREAD, GET_THREAD_PATH, GET_SUBCHANNELS, GET_THREADS } from './queries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Alert, AlertTitle } from '@material-ui/lab';
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Dispatch } from "redux";
import { TChannelsTableActions, IAddChannel, ILoadSubchannels, ILoadThreads } from "../../redux/channels-table/channels.actions";
import { ChannelsTableActionTypes } from "../../redux/channels-table/channels.types";
import { connect } from "react-redux";
import { AdminStoreState } from "../../redux/root-reducer";
import { selectedChannelId, selectedSubChannelId, selectedThreadId, selectCurrentChannels } from '../../redux/channels-table/channels.selectors';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const AddNewChannelModal: React.FC<NewChannelProps> = ({ ...props }) => {
    const { tableName, selectedChannelId, selectedItem, selectedSubChannelId, selectFormChannels, selectedThreadId, classes, addChannelAction } = props;
    const openNewChannelModal = false;
    const [open, setOpen] = useState(openNewChannelModal);
    const [state, setState] = useState({
        underTheChannel: 1,
        underTheSubChannel: 0
    });
    const [selectFormSubChannels, setSelectFormSubChannels] = useState([]);
    const [response, setResponseState] = useState("");
    const [selectedRadioButton, setSelectedRadioButton] = useState(tableName.toLowerCase().slice(0, -1));
    const [dataQuery, setDataQuery] = useState(GET_THREAD_PATH);
    const [fetchDataQuery, { data, loading, error }] = useLazyQuery(dataQuery);

    let subChannelTemp = 0

    useEffect(() => {
        if (data && Object.values(data)[0]) {
            const arr: any = Object.values(data)[0];
            setSelectFormSubChannels(arr);
            if (arr.length > 0 && arr[0].id) {
                subChannelTemp = arr[0].id;
            }
            if (selectedSubChannelId) { subChannelTemp = selectedSubChannelId }
            setState({ ...state, underTheSubChannel: subChannelTemp });
        }
    }, [data]);

    const addNewChannel = (tableName: string) => {
        setResponseState('');
        let channelIdTemp = 1;
        if (selectedChannelId) { channelIdTemp = selectedChannelId }
        if (selectedSubChannelId) { subChannelTemp = selectedSubChannelId }
        setState({ underTheChannel: channelIdTemp, underTheSubChannel: subChannelTemp });
        setFieldValue('title', '')
        setOpen(true);
        setDataQuery(GET_SUBCHANNELS);
        fetchDataQuery({ variables: { id: channelIdTemp } });
    }

    const handleClose = () => {
        setOpen(false);
    }

    const [createChannelMutation] = useMutation(CREATE_CHANNEL);
    const [createSubChannelMutation] = useMutation(CREATE_SUBCHANNEL);
    const [createThreadMutation] = useMutation(CREATE_THREAD);

    const createChannel = (title: string): void => {
        if (tableName === TableNames.Channels) {
            createChannelMutation({
                variables: {
                    title: title
                }
            }).then((result: any) => {
                if (result.data.createChannel) {
                    addChannelAction(result.data.createChannel);
                    handleClose();
                }
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        } else if (tableName === TableNames.SubChannels) {
            createSubChannelMutation({
                variables: {
                    title: title,
                    channelId: state.underTheChannel
                }
            }).then((result: any) => {
                if (result.data.createSubChannel) {
                    addChannelAction(result.data.createSubChannel);
                    handleClose();
                }
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        } else if (tableName === TableNames.Threads) {
            createThreadMutation({
                variables: {
                    title: title,
                    subChannelId: state.underTheSubChannel
                }
            }).then((result: any) => {
                if (result.data.createThread) {
                    addChannelAction(result.data.createThread);
                    handleClose();
                }
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        }
    };

    const handleSelectChange = (event: any) => {
        event.preventDefault();
        const id = parseInt(event.target.value, 10);
        if (event.target.name === 'channels') {
            setState({ ...state, underTheChannel: id });
            setDataQuery(GET_SUBCHANNELS);
            fetchDataQuery({ variables: { id: id } });
        }
        else if (event.target.name === 'subchannels') {
            setState({ ...state, underTheSubChannel: id });
        }
    };

    const validationSchema = Yup.object({
        title: Yup.string().required().matches(
            CHANNEL_TITLE_REGEX,
            "Field must not contain special characters as ^ or ~"),
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormik({
        initialValues: {
            title: "",
            underTheChannel: 1,
            underTheSubChannel: 0
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            try {
                const { title, underTheChannel, underTheSubChannel } = values;
                createChannel(title);
            } catch (error) {
                setResponseState(error.graphQLErrors[0].message);
            }
        },
    });

    return (
        <React.Fragment>
            <Button size='small' onClick={() => addNewChannel(tableName)}>
                <AddIcon />
                ADD
            </Button>
            <MuiThemeProvider theme={channelsTableTheme}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Create new {tableName.toLowerCase().slice(0, -1)}</h1>
                                {response ?
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {response}
                                    </Alert> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <FormLabel className='select-label' htmlFor="channel">Name:</FormLabel>
                                    <TextField autoComplete="off" 
                                            type='text' 
                                            onChange={handleChange} 
                                            hiddenLabel={true} 
                                            name='title'
                                            variant='standard' 
                                            value={values.title} 
                                            required 
                                            autoFocus={true}
                                            error={errors.title === ""}
                                            helperText={errors.title ? errors.title : null}
                                            InputLabelProps={{ shrink: false }}
                                            FormHelperTextProps={{ className: classes.error }}/>
                                    {selectedRadioButton !== 'channel' ? <div>
                                        <FormLabel htmlFor="channel">Under the Channel:</FormLabel>
                                        <Select native value={state.underTheChannel} onChange={handleSelectChange}
                                            inputProps={{ name: 'channels', id: 'channel' }}>
                                            {selectFormChannels ? selectFormChannels.map((channel: any) => {
                                                return <option key={channel.id} aria-label={channel.title} value={channel.id}>{channel.title}</option>
                                            }) : null}
                                        </Select>
                                    </div> : null}
                                    {selectedRadioButton === 'thread' ? <div>
                                        <FormLabel htmlFor="subchannel">Under the Subchannel:</FormLabel>
                                        <Select native value={state.underTheSubChannel} onChange={handleSelectChange}
                                            inputProps={{ name: 'subchannels', id: 'subchannel' }}>
                                            {selectFormSubChannels ? selectFormSubChannels.map((channel: any) => {
                                                return <option key={channel.id} aria-label={channel.title} value={channel.id}>{channel.title}</option>
                                            }) : null}
                                        </Select>
                                    </div> : null}
                                    <div className='button-group'>
                                        <Button className='cancel-button' onClick={handleClose} variant='contained'>Cancel</Button>
                                        <Button type='submit' className='submit-button' variant='contained'>Save</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}

const mapStateToProps = (state: AdminStoreState): { selectedChannelId: number, selectedSubChannelId: number, selectedThreadId: number, selectFormChannels: Channel[] } => {
    return {
        selectedChannelId: selectedChannelId(state),
        selectedSubChannelId: selectedSubChannelId(state),
        selectedThreadId: selectedThreadId(state),
        selectFormChannels: selectCurrentChannels(state)
    }
};

const mapDispatchToProps = (dispatch: Dispatch<TChannelsTableActions>) => {
    return {
        addChannelAction: (data: any) => { dispatch<IAddChannel>({ type: ChannelsTableActionTypes.AddChannel, data: data }) },
        loadSubchannelsAction: (data: SubChannel[] | number) => { dispatch<ILoadSubchannels>({ type: ChannelsTableActionTypes.LoadSubChannels, data: data }) },
        loadThreadsAction: (data: Thread[] | number) => { dispatch<ILoadThreads>({ type: ChannelsTableActionTypes.LoadThreads, data: data }) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNewChannelModal)); 