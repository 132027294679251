import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { LicenseTableTabTypes, TabPanelProps } from './LicenseAdminTable.types';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LicenseDataTableComponent from './LicenseAdminDataTable';
import './licenseAdminTable.styles.scss';
import LocationOverrideTable from '../../components/location-override/locationOverride.component';
import Commissions from '../../components/commissions/Commissions.component';

export const TabPanel: React.FC<TabPanelProps> = ({...props}) => {
    const {children, value, index, licenseType, ...other} = props;
    let Table: any = null;
    if(index === 4){
        Table = <LocationOverrideTable licenseType={licenseType}/>;
    }
    else if(index === 5){
        Table = <Commissions licenseType={licenseType}/>;
    }
    else{
        Table = <LicenseDataTableComponent licenseType={licenseType}/>;
    }
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{padding: '0px'}} p={3}>
                    <Typography>{children}</Typography> 
                    {Table}
                    
                </Box>
            )}
        </div>
    );
}

const LicenseAdminTabPanel: React.FC = ({...props}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(1);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const a11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root} >
            <AppBar position="static" color="default" >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Editorial" {...a11yProps(0)} />
                    <Tab label="Standard" {...a11yProps(1)} />
                    <Tab label="Premium" {...a11yProps(2)} />
                    <Tab label="Single item price" {...a11yProps(3)} />
                    <Tab label='Overrides' {...a11yProps(4)}/>
                    <Tab label='Commissions' {...a11yProps(4)}/>
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} licenseType={LicenseTableTabTypes.EDITORIAL}/>
            <TabPanel value={value} index={1} licenseType={LicenseTableTabTypes.STANDARD}/>
            <TabPanel value={value} index={2} licenseType={LicenseTableTabTypes.PREMIUM}/>
            <TabPanel value={value} index={3} licenseType={LicenseTableTabTypes.FLAT_RATES}/>
            <TabPanel value={value} index={4} licenseType={LicenseTableTabTypes.OVERRIDES}/>
            <TabPanel value={value} index={5} licenseType={LicenseTableTabTypes.COMMISSIONS}/>
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default LicenseAdminTabPanel;
