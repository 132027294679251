import React, { useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { RefreshDateTime } from 'shared';
import { Button } from "@material-ui/core";
import SyncLoader from 'react-spinners/SyncLoader';
import { GET_ALL_REPORTS } from './queries';
import { useQuery } from '@apollo/react-hooks';
import { ReportsData, ReportTableProps } from './reportsTable.types';
import { RoleTypes } from '../../components/users-table/usersAdminTable.types';
import ExportReportModal from './ExportReportModal';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Moment from "react-moment";
import { selectReportsTableRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleReportsTableRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { AdminStoreState } from '../../redux/root-reducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const ReportsTable: React.FC<ReportTableProps> = ({ ...props }) => {
    const {reportsTableRefreshState, toggleReportsTableRefresh} = props;

    const { data, loading, error, refetch } = useQuery(GET_ALL_REPORTS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    const [allReportsData, setAllReportsData] = useState<ReportsData[]>([]);

    useEffect(() => {
        if (data && data.getReports) {
            setAllReportsData(data.getReports);
        }
    }, [data]);

    const columns = [
        {
            name: "User ID",
            options: {
                viewColumns: false,
                filter: false,
                sort: false,
                display: 'false' as 'false'
            }
        },
        {
            name: "Report ID",
            options: {
                viewColumns: false,
                filter: false,
                sort: false,
                display: 'false' as 'false'
            }
        },
        "Content count",
        "Total price",
        "Contributor name",
        "Contributor email",
        "Contributor type",
        "Creation date",
        {
            name: "Bucket link",
            options: {
                viewColumns: false,
                filter: false,
                sort: false,
                display: 'false' as 'false'
            }
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <Button href={tableMeta.rowData[8]} download>
                            Download
                            <PictureAsPdfIcon />
                        </Button>
                    )
                }
            }
        }

    ];

    const options: MUIDataTableOptions = {
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: true,
        fixedHeader: false,
        elevation: 3,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'vertical',
        tableBodyMaxHeight: 'calc(100%)',
        tableBodyHeight: 'calc(100%-50px)',
        download: true,
        print: false,
        searchPlaceholder: "Search ",
        sortOrder: {
            name: "Creation date",
            direction: "asc"
        },
        textLabels: {
            body: {
                noMatch: loading ?
                    <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
                        size={20} color={"#36D2B3"} loading={loading} />
                    :
                    'Sorry, no matching records found for now',
            },
        },
        customToolbar: () => {
            return <ExportReportModal refetch={refetch} />
        },
        customSort: (data, dataIndex, rowIndex) => {
            if (dataIndex === 7) {
                return data.sort((a, b) => {
                    const dateA = new Date(a.data[dataIndex].props.children).getTime();
                    const dateB = new Date(b.data[dataIndex].props.children).getTime();
                    return (dateA < dateB ? -1 : 1) * (rowIndex === "desc" ? 1 : -1);
                });
            } else {
                return data.sort((a, b) => {
                    if (a.data[dataIndex] && b.data[dataIndex]) {
                        return (
                            a.data[dataIndex].localeCompare(b.data[dataIndex]) *
                            (rowIndex === "desc" ? 1 : -1)
                        );
                    } else {
                        return 0;
                    }
                });
            }
        }

    };

    const convertRole = (report: ReportsData) => {
        if (report.user.role === RoleTypes.Contributor_agency) {
            return "Agency";
        }
        else {
            return "Individual";
        }

    }
    const getName = (report: ReportsData) => {
        if (report.user) {
            if (report.user.role === RoleTypes.Pro_organization || report.user.role === RoleTypes.Contributor_agency) {
                return report.user.company ? report.user.company.name : "N/A";
            }
            else {
                return report.user.person ? report.user.person.firstName + " " + report.user.person.lastName : "N/A";
            }
        }
    }

    const createTableRow = (report: ReportsData) => {
        return [
            report.user.id,
            report.report.id,
            report.report.contentCount,
            report.report.totalPrice,
            getName(report),
            report.user.email,
            report.user ? convertRole(report) : 'N/A',
            <Moment format="DD/MM/YYYY">{report.report.date}</Moment>,
            report.report.bucketLink

        ]
    }

    return (
        <div>
            <div className='refetch-container'>
                <RefreshDateTime loading={loading} autoRefreshOn={reportsTableRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleReportsTableRefresh}/>
            </div>
            <MUIDataTable title={""}
                columns={columns} options={options}
                data={allReportsData.map((report: ReportsData) => (
                    createTableRow(report)
                ))}
            />

            {/* <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleCloseSnackbar}
                severity={toolbarError.severity} message={toolbarError.message} /> */}
        </div>
    )
}

const mapStateToProps = (state: AdminStoreState): {reportsTableRefreshState: boolean} => {
    return {
        reportsTableRefreshState: selectReportsTableRefresh(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TRefreshTablesActions>) => {
    return {
        toggleReportsTableRefresh: (data: boolean) => dispatch<IToggleReportsTableRefresh>({
            type: TablesRefreshActionTypes.REPORTS_TABLE_REFRESH,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);