import {
    CopiedContentToSets, CopiedMultipleContents,
    MovedContentToSets,
    MovedMultipleContents
} from '../../components/batch-upload/EditContentMetadata.types';
import { Article, ContentSet, IndividualImageCategory, UploadedFileResponse } from '../../components/batch-upload/ContentUpload.types';

export const updateSet = (updatedSet: ContentSet, allSets: ContentSet[]) => {
    return allSets.map((set: ContentSet) => set.id === updatedSet.id ? updatedSet : set);
}

export const addOrUpdateArticle = (articles: Article[], newArticle: Article) => {
    if(articles.find(art => art.contentSetId === newArticle.contentSetId)) {
        return articles.map((article: Article) => article.contentSetId === newArticle.contentSetId ? newArticle : article);
    } else {
        return articles.concat(newArticle)
    }
}

export const addChannelToContent = (contentSets: ContentSet[], threadIds: number[], contentSetId: string) => {
    const selectedContentSet = contentSets.find(contentSet => contentSet.id === contentSetId);
    if(selectedContentSet) {
        selectedContentSet.selectedThreadIds = threadIds;
        const otherSets = contentSets.filter(contentSet => contentSet.id !== contentSetId);
        return otherSets.concat(selectedContentSet);
    }
    return contentSets;
}

export const addImageCategory = (imageCategories: IndividualImageCategory[], newCategory: IndividualImageCategory) => {
    let imageCategory = imageCategories.find(category => category.contentId === newCategory.contentId);
    if(imageCategory) {
        let temp = imageCategories.filter(category => category.contentId !== imageCategory!.contentId);
        temp.push(newCategory);
        return temp;
    }

    return imageCategories.concat(newCategory);
}

export const approveSingleImage = (contentSets: ContentSet[], contentId: number) => {
    let updatedSets = contentSets;
    for(let i=0; i< updatedSets.length; i++) {
        let approvedFile = updatedSets[i].files.findIndex((file: UploadedFileResponse) => file.id === contentId);
        if(approvedFile !== -1) {
            updatedSets[i].files[approvedFile].isApproved = true;
        }
    }

    return updatedSets;
}


export const pendingSingleImage = (contentSets: ContentSet[], contentId: number) => {
    let updatedSets = contentSets;
    for(let i=0; i< updatedSets.length; i++) {
        let pendingFile = updatedSets[i].files.findIndex((file: UploadedFileResponse) => file.id === contentId);
        if(pendingFile !== -1) {
            updatedSets[i].files[pendingFile].isApproved = undefined;
        }
    }

    return updatedSets;
}

export const rejectSingleImage = (contentSets: ContentSet[], contentId: number) => {
    let updatedSets = contentSets;
    for(let i=0; i< updatedSets.length; i++) {
        let rejectedFile = updatedSets[i].files.findIndex((file: UploadedFileResponse) => file.id === contentId);
        if(rejectedFile !== -1) {
            updatedSets[i].files[rejectedFile].isApproved = false;
        }
    }

    return updatedSets;
}

export const deleteSingleImage = (contentSets: ContentSet[], contentId: number) => {
    let updatedSets = contentSets;
    for(let i=0; i< updatedSets.length; i++) {
        let deletedFile = updatedSets[i].files.findIndex((file: UploadedFileResponse) => file.id === contentId);
        if(deletedFile !== -1) {
            updatedSets[i].files[deletedFile].isDeleted = true;
        }
    }

    return updatedSets;
}

export const updateMovedContents = (movedContents: MovedContentToSets[], newData: MovedContentToSets) => {
    if(movedContents.find((moved: MovedContentToSets) => moved.contentId === newData.contentId)) {
        return movedContents.map((moved: MovedContentToSets) => moved.contentId === newData.contentId ? newData : moved);
    } else {
        return movedContents.concat(newData);
    }
    
}

export const updateMultipleMovedData = (movedMultipleContents: MovedMultipleContents[], newData: MovedMultipleContents) => {
    const findContentToBeMoved = movedMultipleContents.find((moved: MovedMultipleContents) => moved.contentIds === newData.contentIds);
    const movedContentsToNewSet = movedMultipleContents.map((moved: MovedMultipleContents) => moved.contentIds === newData.contentIds ? newData : moved);
    if(findContentToBeMoved) {
        return movedContentsToNewSet;
    } else {
        return movedMultipleContents.concat(newData);
    }
}

export const updateCopiedContents = (copiedContents: CopiedContentToSets[], newData: CopiedContentToSets) => {
    if(copiedContents.find((copied: CopiedContentToSets) => copied.contentId === newData.contentId)) {
        return copiedContents.map((copied: CopiedContentToSets) => copied.contentId === newData.contentId ? newData : copied);
    } else {
        return copiedContents.concat(newData);
    }
}

export const updateMultipleCopiedData = (copiedMultipleContents: CopiedMultipleContents[], newData: CopiedMultipleContents) => {

    const findContentToBeCopied = copiedMultipleContents.find((copied: CopiedMultipleContents) => copied.contentIds === newData.contentIds);
    const copiedContentsToNewSet = copiedMultipleContents.map((copied: CopiedMultipleContents) => copied.contentIds === newData.contentIds ? newData : copied);
    if(findContentToBeCopied) {
        return copiedContentsToNewSet;
    } else {
        return copiedContentsToNewSet.concat(newData);
    }
}