/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { RefreshDateTime } from "shared";
import SyncLoader from "react-spinners/SyncLoader";
import {
  GET_ALL_POST_REPORTS,
  GET_CONTENT_INFO,
  GET_CONTENT_SET_BY_ARTICLE_ID,
  GET_CONTENT_SET_BY_SET_ID,
  UPDATE_POST_REPORT_STATUS,
} from "./queries";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { PostReportTableProps, ReportsData } from "./post-reportsTable.types";
import { RoleTypes } from "../users-table/usersAdminTable.types";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Moment from "react-moment";
import {
  selectPostReportsTableRefresh,
  selectReportsTableRefresh,
} from "../../redux/tables-refresh/tables-refresh.selectors";
import {
  ITogglePostReportsTableRefresh,
  IToggleReportsTableRefresh,
  TRefreshTablesActions,
} from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { AdminStoreState } from "../../redux/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { config as conf, config } from "../../config";
import "./post-reports.styles.scss";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import { Cancel, Undo } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import ImageSlider from "../../components/ImageSlider/imageSlider";

const PostReportsTable: React.FC<PostReportTableProps> = ({ ...props }) => {
  const { postReportsTableRefreshState, togglePostReportsTableRefresh } = props;
  const [allReportsData, setAllReportsData] = useState<any>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [reportType, setReportType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [user, setUser] = useState<any>({});
  const [post, setPost] = useState<any>({});
  const [deleteId, setDeleteId] = useState<any>();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleDeleteConfirmationOpen = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteConfirmationClose = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleOpenPostModal = () => setOpenPostModal(true);
  const handleClosePostModal = () => setOpenPostModal(false);

  const handleOpenUserModal = () => setOpenUserModal(true);
  const handleCloseUserModal = () => setOpenUserModal(false);

  const getAllPostReports = (variables: any) => {
    setLoading(true);
    axios({
      url: conf.REACT_APP_MW_GRAPHQL_PATH,
      method: "post",
      data: {
        query: GET_ALL_POST_REPORTS,
        variables,
      },
    })
      .then((result) => {
        if (result?.data?.data?.reports) {
          setAllReportsData([...result.data.data.reports.edges]);
          if(totalData === 0)setTotalData(result.data.data.reports.totalCount)
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleUpdateStatus = (status: string, id: number): void => {
    setUpdatingStatus(true);
    axios({
      url: conf.REACT_APP_MW_GRAPHQL_PATH,
      method: "post",
      data: {
        query: UPDATE_POST_REPORT_STATUS,
        variables: {
          updateReportStatusId: id,
          status,
        },
      },
    })
      .then((result) => {
        setUpdatingStatus(false);
        getAllPostReports({
          pages: pageNumber,
          limit: rowsPerPage,
        });
      })
      .catch((error) => {
        setUpdatingStatus(false);
      });
  };

  const handleSeeDetails = (
    type: string,
    contentSetId: number,
    articleId: number,
    contentId: number,
    threadId: number
  ) => {
    const articlePath = `/content/article/${articleId}/set/${contentSetId}/${contentId}/thread/${threadId}`;
    const contentSetPath = `/content/set/${contentSetId}/${contentId}/thread/${threadId}`;
    const imagePath = `/content/${contentId}/thread/${threadId}`;
    let path = "";
    if (type === "article") {
      path = articlePath;
    } else if (type === "contentSet") {
      path = contentSetPath;
    } else {
      path = imagePath;
    }
    window.open(`${config.REACT_APP_PUBLIC_BASE}${path}`, "_blank");
  };

  const showWIContent = (
    type: string,
    contentSetId: number,
    articleId: number,
    contentId: number,
    threadId: number
  ) => {
    handleSeeDetails(type, contentSetId, articleId, contentId, threadId);
  };

  const showMWContent = (mwPost: any) => {
    setPost(mwPost);
    handleOpenPostModal();
  };

  const showUser = (reportedUser: any) => {
    setUser(reportedUser);
    handleOpenUserModal();
  };

  const handleDelete = ()=>{
    handleUpdateStatus("delete", deleteId);
    setOpenDeleteConfirmation(false)
  }

  const columns = [
    {
      name: "ID",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    "Type",
    {
      name: "Post Type",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    "Posted by",
    "Reported by",
    "Message",
    "Created At",
    "Updated At",
    "Status",
    {
      name: "Post",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "ContentType",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "ContentSetId",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "ArticleId",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "ContentId",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "ThreadId",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "TargetUser",
      options: {
        display: "false" as "false",
        viewColumns: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return (
            <div>
              {tableMeta.rowData[8].props.children !== "DELETED" && <Button
                onClick={() => {
                  if (tableMeta.rowData[1] === "POST") {
                    if (tableMeta.rowData[2] === "wiContent") {
                      showWIContent(
                        tableMeta.rowData[10],
                        tableMeta.rowData[11],
                        tableMeta.rowData[12],
                        tableMeta.rowData[13],
                        parseInt(tableMeta.rowData[14])
                      );
                    } else {
                      showMWContent(tableMeta.rowData[9]);
                    }
                  } else {
                    showUser(tableMeta.rowData[15]);
                  }
                }}
                className="action-button"
                variant="contained"
                size="small"
                color="info"
              >
                View
              </Button>}
              {tableMeta.rowData[8].props.children === "INITIATED" && <Button
                onClick={() => {
                  handleUpdateStatus("undo", parseInt(tableMeta.rowData[0]));
                }}
                className="action-button"
                variant="contained"
                size="small"
                color="success"
              >
                Undo
              </Button>}
              {tableMeta.rowData[8].props.children === "INITIATED" &&<Button
                onClick={() => {
                  handleUpdateStatus("hide", parseInt(tableMeta.rowData[0]));
                }}
                className="action-button"
                variant="contained"
                size="small"
                color="primary"
              >
                Hide
              </Button>}
              {tableMeta.rowData[8].props.children !== "DELETED" && <Button
                onClick={() => {
                  handleDeleteConfirmationOpen();
                  setReportType(tableMeta.rowData[1]);
                  setDeleteId(parseInt(tableMeta.rowData[0]))
                }}
                className="action-button"
                variant="outlined"
                size="small"
                color="error"
              >
                Delete
              </Button>}
            </div>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    count: totalData,
    page: pageNumber,
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    filter: false,
    fixedHeader: false,
    serverSide: true,
    elevation: 3,
    sort: false,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyMaxHeight: "calc(100%)",
    tableBodyHeight: "calc(100%-50px)",
    download: false,
    print: false,
    searchPlaceholder: "Search ",
    textLabels: {
      body: {
        noMatch:
          loading || updatingStatus ? (
            <SyncLoader
              css={`
                display: block;
                margin: auto 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              `}
              size={20}
              color={"#36D2B3"}
              loading={loading || updatingStatus}
            />
          ) : (
            "Sorry, no matching records found for now"
          ),
      },
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setRowsPerPage(numberOfRows);
      getAllPostReports({
        pages: pageNumber,
        limit: numberOfRows,
      });
    },
    onChangePage: (currentPage: number) => {
      setPageNumber(currentPage)
      getAllPostReports({
        pages: currentPage,
        limit: rowsPerPage,
      });
    },
  };

  const createTableRow = (report: any) => {
    let reportStatus = "";
    let reportStatusClass = "";
    if (report?.status === "initiated") {
      reportStatus = "initiated";
      reportStatusClass = "initiate-status";
    } else if (report?.status === "hide") {
      reportStatus = "hidden";
      reportStatusClass = "hide-status";
    } else if (report?.status === "undo") {
      reportStatus = "Ignored";
      reportStatusClass = "undo-status";
    } else if (report?.status === "delete") {
      reportStatus = "deleted";
      reportStatusClass = "delete-status";
    }
    return [
      report?.id,
      report?.type.toUpperCase(),
      report?.contentType,
      report?.targetUser?.username,
      report?.originUser?.username,
      report?.text,
      <Moment unix format="YYYY-MM-DD HH:mm:ss">
        {report?.createdAt / 1000}
      </Moment>,
      <Moment unix format="YYYY-MM-DD HH:mm:ss">
        {report?.updatedAt / 1000}
      </Moment>,
      <p className={`report-status ${reportStatusClass}`}>
        {reportStatus.toUpperCase()}
      </p>,
      report?.post,
      report?.articleId
        ? "article"
        : report?.contentSetId
        ? "contentSet"
        : report?.contentId
        ? "image"
        : "",
      report?.contentSetId,
      report?.articleId,
      report?.contentId,
      report?.threadId,
      report?.targetUser,
    ];
  };

  useEffect(() => {
    getAllPostReports({
      pages: pageNumber,
      limit: rowsPerPage,
    });
  }, []);

  return (
    <div>
      <Dialog
        closeAfterTransition={true}
        onClose={handleClosePostModal}
        open={openPostModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{ timeout: 800 }}
      >
        <div className="modal">
          <div className="modal-header">
            <p className="modal-title">Reported My World Post</p>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClosePostModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="card">
            {post && Object.keys(post).length > 0 && (
              <ImageSlider
                images={post.images.map(
                  (image: any) =>
                    {
                      return `${config.REACT_APP_MW_IMAGE_PREFIX}/${image.file}`
                    }
                )}
              />
            )}
            <p>
              <b>Title:</b> {post?.title || "N/A"}
            </p>
            <p>
              <b>Body:</b> {post?.body || "N/A"}
            </p>
            <p>
              <b>Created At:</b>{" "}
              <Moment unix format="YYYY-MM-DD HH:mm:ss">
                {post?.timestamp / 1000}
              </Moment>
            </p>
          </div>
        </div>
      </Dialog>
      <Dialog
        closeAfterTransition={true}
        onClose={handleCloseUserModal}
        open={openUserModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{ timeout: 800 }}
      >
        <div className="modal">
          <div className="modal-header">
            <p className="modal-title">Reported User</p>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseUserModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="card">
            <img
              className="avatar"
              src={`${config.REACT_APP_MW_IMAGE_PREFIX}/${user?.picture}`}
            />
            <p>
              <b>Username:</b> {user?.username}
            </p>
            <p>
              <b>Email:</b> {user?.email}
            </p>
            <p>
              <b>Bio:</b> {user?.bio || "N/A"}
            </p>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleDeleteConfirmationClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This {reportType} will be deleted permanently!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div className="refetch-container">
        <RefreshDateTime
          loading={loading || updatingStatus}
          autoRefreshOn={postReportsTableRefreshState}
          refreshTime={30000}
          refreshFunc={() => {
            getAllPostReports({
              pages: pageNumber,
              limit: rowsPerPage,
            });
          }}
          toggleReduxFunction={togglePostReportsTableRefresh}
        />
      </div>
      <MUIDataTable
        title={""}
        columns={columns}
        options={options}
        data={allReportsData.map((report: any) => createTableRow(report))}
      />

      {/* <SnackbarComponent showSnackbar={showSnackbar} handleCloseModal={handleCloseSnackbar}
                severity={toolbarError.severity} message={toolbarError.message} /> */}
    </div>
  );
};

const mapStateToProps = (
  state: AdminStoreState
): { postReportsTableRefreshState: boolean } => {
  return {
    postReportsTableRefreshState: selectPostReportsTableRefresh(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TRefreshTablesActions>) => {
  return {
    togglePostReportsTableRefresh: (data: boolean) =>
      dispatch<ITogglePostReportsTableRefresh>({
        type: TablesRefreshActionTypes.POST_REPORTS_TABLE_REFRESH,
        data: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostReportsTable);
