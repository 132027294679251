import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Select, Button, Backdrop, Fade, TextField, MuiThemeProvider, InputLabel, Tooltip, withStyles, FormLabel } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_USER } from './queries';
import { AdminStoreState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { UpdateUserProps, dialogStyles, RoleTypes, styles, RoleTypesMessages, PASSWORD_REGEX } from './usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import { TUserTableReducerActions, IUpdateUser } from '../../redux/users-table/usersTable.actions';
import { UsersTableActionTypes, UserData, UpdateUserData } from '../../redux/users-table/usersTable.types'
import { selectCurrentUser } from "../../redux/admin/admin.selectors";
import { selectUsersTableData } from "../../redux/users-table/usersTable.selectors";
import { User } from "../../redux/admin/admin.types";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { getEnumValues } from "./helperFunctions";
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from "../../redux/generic/snackbar/snackbar.types";
import { ISnackbarMessage, TSnackbarActions } from "../../redux/generic/snackbar/snackbar.actions";
import { SnackbarComponent } from "shared";
import { selectSnackbarState } from "../../redux/generic/snackbar/snackbar.selectors";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const UpdateUserModal: React.FC<UpdateUserProps> = ({ ...props }) => {
    const { currentUser, rowIndex, usersTable, classes, snackbarMessage, updateUserAction, refetch, broadcastSnackbarAction } = props;
    
    const [response, setResponseState] = useState("");
    const [open, setOpen] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [selectedUser, setSelectedUser] = useState(usersTable[rowIndex]);
    const [isOrganization, setIsOrganization] = useState(false);
    const [isAgency, setIsAgency] = useState(true);

    const [updateUserMutation] = useMutation(UPDATE_USER);

    useEffect(() => {
        if(selectedUser.role === RoleTypes.Pro_organization)
            setIsOrganization(true);
        else if(selectedUser.role === RoleTypes.Contributor_agency)
            setIsAgency(true);
        else {
            setIsOrganization(false);
            setIsAgency(false);
        }
    }, [selectedUser.role])
    
    useEffect(() => {
        setSelectedUser({...usersTable[rowIndex]})
    }, [usersTable])

    const validationSchema = Yup.object({
        firstName: Yup.string().notRequired().min(3),
        lastName: Yup.string().notRequired().min(3),
        organizationName: Yup.string().notRequired().min(3),
        password: Yup.string().matches(
            PASSWORD_REGEX,
            "Field requires at least 8 Characters, one Uppercase letter, one Number and one special case Character"
        ),
        email: Yup.string().email("Enter a valid email"),
       
    })

    const handlePublicSFTPAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if((event.target as HTMLInputElement).value === "true"){
            const updatedSelectedUser = {
                ...selectedUser,
                hasPublicSFTPAccess: true
            }
            setSelectedUser(updatedSelectedUser)
        }
        else{
            const updatedSelectedUser = {
                ...selectedUser,
                hasPublicSFTPAccess: false
            }
            setSelectedUser(updatedSelectedUser)
        }
        
      };

    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            id: selectedUser.id,
            email: selectedUser.email,
            password: "",
            role: selectedUser.role,
            firstName: selectedUser.person ? selectedUser.person.firstName : "",
            lastName: selectedUser.person ? selectedUser.person.lastName : "",
            organizationName: selectedUser.company ? selectedUser.company.name : "",
            hasSFTPAccess: selectedUser.hasSFTPAccess,
            hasPublicSFTPAccess: selectedUser.hasPublicSFTPAccess,
            // incomePercentage: selectedUser.incomePercentage,
        },
        validateOnBlur: true,
        enableReinitialize: true,
        validationSchema,
        onSubmit() {
            try {
                const { id, email, password, firstName, lastName, organizationName, role, hasSFTPAccess, hasPublicSFTPAccess} = values;
                const userToUpdate: UpdateUserData = {
                        id: id,
                        email: email,
                        password: password,
                        role: role,
                        currentUserEmail: currentUser.email,
                        firstName: !(isOrganization || isAgency) ? firstName : "",
                        lastName: !(isOrganization || isAgency) ? lastName : "",
                        organizationName: isOrganization || isAgency ? organizationName : "",
                        hasSFTPAccess,
                        hasPublicSFTPAccess
                        // incomePercentage: incomePercentage !== undefined ? parseFloat(incomePercentage + "") : 0.25
                }
                console.log("userToUpdate",userToUpdate)
                updateUserMutation({
                    variables: {
                        ...userToUpdate
                    }
                }).then((result: any) => {

                    updateUserAction({ ...userToUpdate});
                    setSelectedUser({ ...userToUpdate});

                    handleClose();
                    refetch();
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.success,
                        message: "User updated successfully"
                    });
                }).catch((error: any) => {
                    setResponseState(error.graphQLErrors[0].message);
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: "Something went wrong when updating user"
                    });
                });
            }
            catch (error) {
                handleClose();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when updating user"
                });
            }
        }
    })

    // const handleChangeIncomePercentage = (event: any) => {
    //     setSelectedUser({ ...selectedUser, incomePercentage: event.target.value});
    // }

    const updateUserModal = () => {
        setOpen(true);
        setSelectedUser({ ...usersTable[rowIndex], role: usersTable[rowIndex].role});
    }

    const handleClose = () => {
        setOpen(false);
        setResponseState("");
        setSelectedUser({ ...selectedUser, role: usersTable[rowIndex].role});
    }
    return (
        <React.Fragment>
            <Tooltip title={"Update " + selectedUser.role}>
                <IconButton onClick={updateUserModal}>
                    <CreateIcon/>
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Update user</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <FormLabel htmlFor="email" className='select-label'>Email</FormLabel>
                                    <TextField type='text' autoComplete='off' onChange={handleChange} hiddenLabel={true}
                                        name='email' variant='standard'
                                        defaultValue={selectedUser ? selectedUser.email : ""}
                                        value={values.email}
                                        error={errors.email === ""}
                                        helperText={errors.email ? errors.email : null}
                                        FormHelperTextProps={{ className: classes.error }}
                                    />

                                    <FormLabel htmlFor="password" className='select-label'>Password</FormLabel>
                                    <TextField type='password' autoComplete='off' onChange={handleChange} hiddenLabel={true}
                                        name='password' variant='standard'
                                        error={errors.password === ""}
                                        helperText={errors.password ? errors.password : null}
                                        FormHelperTextProps={{ className: classes.error }}
                                    />

                                    {/* <FormLabel htmlFor="incomePercentage" className='select-label'>Income Percentage</FormLabel>
                                    <TextField type='text' autoComplete='off' onChange={handleChangeIncomePercentage} hiddenLabel={true}
                                        name='incomePercentage' variant='standard'
                                        defaultValue={selectedUser ? selectedUser.incomePercentage : ""}
                                        error={errors.incomePercentage === ""}
                                        helperText={errors.incomePercentage ? errors.incomePercentage : null}
                                        FormHelperTextProps={{ className: classes.error }}
                                    /> */}

                                    <InputLabel className='select-label' htmlFor="age-native-simple">Role</InputLabel>
                                    <Select native 
                                        defaultValue={selectedUser ? selectedUser.role: ""} 
                                        value={values.role}
                                        onChange={handleChange}
                                        inputProps={{ name: 'role', id: 'age-native-simple' }} 
                                        disabled={currentUser.email === selectedUser.email}>
                                        {getEnumValues(RoleTypesMessages)}
                                    </Select>

                                    {
                                        isAgency || isOrganization ?
                                            <React.Fragment>
                                                <FormLabel htmlFor="organizationName" className='select-label'>
                                                    {isAgency ? "Agency name " : "Organization name"}
                                                </FormLabel>
                                                <TextField type="text" autoComplete="off" hiddenLabel={true}
                                                    name='organizationName' variant='standard'
                                                    defaultValue={selectedUser && selectedUser.company ? selectedUser.company.name : ""}
                                                    value={values.organizationName}
                                                    onChange={handleChange}
                                                    error={errors.organizationName === ""}
                                                    helperText={errors.organizationName ? errors.organizationName : null}
                                                    FormHelperTextProps={{ className: classes.error }}
                                                />
                                                <br />
                                                {selectedUser.hasSFTPAccess && <FormLabel>Is user required to use the public site for SFTP upload completion?</FormLabel>}
                                                {selectedUser.hasSFTPAccess && <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                    value={selectedUser.hasPublicSFTPAccess}
                                                    onChange={handlePublicSFTPAccessChange}
                                                >
                                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                                </RadioGroup>}
                                            </React.Fragment> 
                                            :
                                            <React.Fragment>
                                                <FormLabel htmlFor="firstName" className='select-label'>First name</FormLabel>
                                                <TextField type="text" autoComplete="off" hiddenLabel={true}
                                                    name='firstName' variant='standard'
                                                    defaultValue={selectedUser && selectedUser.person ? selectedUser.person.firstName : ""}
                                                    value={values.firstName}
                                                    onChange={handleChange} 
                                                    error={errors.firstName === ""}
                                                    helperText={errors.firstName ? errors.firstName : null}
                                                    FormHelperTextProps={{ className: classes.error }}
                                                />
                                                <FormLabel htmlFor="lastName" className='select-label'>Last name</FormLabel>
                                                <TextField type="text" autoComplete="off" hiddenLabel={true}
                                                    name='lastName' variant='standard'
                                                    defaultValue={selectedUser && selectedUser.person ? selectedUser.person.lastName : ""}
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    error={errors.lastName === ""}
                                                    helperText={errors.lastName ? errors.lastName : null}
                                                    FormHelperTextProps={{ className: classes.error }}
                                                />
                                            </React.Fragment>
                                    }
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Update User</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                    severity={snackbarMessage.severity}
                    message={snackbarMessage.message} />
        </React.Fragment>
    );
}


const mapStateToProps = (state: AdminStoreState): { usersTable: UserData[], currentUser: User, snackbarMessage: IBroadcastMessage } => {
    return {
        usersTable: selectUsersTableData(state),
        currentUser: selectCurrentUser(state),
        snackbarMessage: selectSnackbarState(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserTableReducerActions | TSnackbarActions>) => {
    return {
        updateUserAction: (data: UserData) => { dispatch<IUpdateUser>({ type: UsersTableActionTypes.UPDATE_USER, data: data }) },
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateUserModal));