import { IncompleteSFTPUserUploadFlow } from "../../components/incomplete-sftp-upload-list/incompleteSFTPUpload.types";

export enum IncompleteSFTPFlowActionTypes{
    LOAD_INCOMPLETE_SFTP_DATA = "LOAD_INCOMPLETE_SFTP_DATA",
    SELECT_INCOMPLETE_SFTP_FLOW = "SELECT_INCOMPLETE_SFTP_FLOW",
    RESET_INCOMPLETE_STATE = "RESET_INCOMPLETE_STATE",
    SET_INCOMPLETE_TABLE_CURRENT_PAGE = "SET_INCOMPLETE_TABLE_CURRENT_PAGE",
}

export interface IncompleteSFTPFlowState {
    incompleteSFTPFlowData: IncompleteSFTPUserUploadFlow[];
    selectedUserId: number | null;
    incompleteTableCurrentPageNumber: number;
}