import { Content } from "../waiting-list/waitingList.types";
import { User } from "../admin/admin.types";

export enum OrderHistoryActionTypes {
    LOAD_ORDER_HISTORY_DATA = "LOAD_ORDER_HISTORY_DATA",
    RESET_ORDER_HISTORY_STATE = "RESET_ORDER_HISTORY_STATE"
}

export interface OrderHistoryState {
    orderHistoryData: FullContentOrder[];
}

export interface FullContentOrder {
    order: Order;
    contentOrders: ContentOrder[];
    contents: Content[];
    buyer: User;
}

export interface Order {
    id: number;
    orderDate: Date;
    totalPrice: number;
    isFinished: boolean;
    userId: number;
}

export interface ContentOrder {
    id: number;
    contentId: number;
    contentPrice: number;
    adminCommission: number;
    sellerCommission: number;
    sellerIncomePercentage: number;
    orderId: number;
    size: number;
    amount: number;
    totalPrice: number;
    period: number;
}