import gql from 'graphql-tag';

export const ADD_LOCATION_OVERRIDE = gql`
    mutation addNewLocationOverrideMutation($licensePlanType: String!, $countryName: String!, $percentageFactor: Float!, $countryCode: String!) {
        addNewLocationOverride(licensePlanType: $licensePlanType, countryName: $countryName, percentageFactor: $percentageFactor, countryCode: $countryCode) {
            id
        }
    }
`

export const GET_ALL_OVERRIDES = gql`
    query getAllOverridesQuery {
        getAllLocationOverrides {
            country{
                country
            }
            id
            percentageFactor
            licensingPlanType
        }
    }
`

export const DELETE_LOCATION_OVERRIDE = gql `
    mutation deleteLocationOverrideMutation($id: Int!) {
        deleteLocationOverride(id: $id)
    }
`

export const EDIT_LOCATION_OVERRIDE = gql `
    mutation editLocationOverrideMutation($id: Int!, $licensePlanType: String!, $percentageFactor: Float!){
        editLocationOverride(id: $id, licensePlanType: $licensePlanType, percentageFactor: $percentageFactor){
            id
            licensingPlanType
        }
    }
`