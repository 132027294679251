import { connect } from 'react-redux';
import { LoginState, UserState, UserActionTypes, User } from '../../redux/admin/admin.types';
import { TUserReducerActions, IStartLogin, ILoginSuccess, ILoginFailure, IStartLogout, ILogoutSuccess, ILogoutFailure } from '../../redux/admin/admin.actions';
import { AdminStoreState } from '../../redux/root-reducer';
import { LOGIN_USER } from './queries';
import { graphql } from 'react-apollo';
import { Dispatch } from 'redux';
import { Login } from 'shared';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { LOGOUT_USER } from '../../components/navigation/queries';
import {flowRight as compose} from 'lodash';

const mapStateToProps = (state: AdminStoreState) : {admin: UserState, route: string, isAdmin: boolean} => {
    return {
        admin: state.admin,
        route: state.router.location.pathname,
        isAdmin: true
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserReducerActions | CallHistoryMethodAction>) => {
    return {
        loginUserAction: (data: LoginState) => dispatch<IStartLogin>({type: UserActionTypes.Login, data: data}),
        loginSuccessAction: (data: User) => { dispatch<ILoginSuccess>({type: UserActionTypes.LoginSuccess, data: data});},
        loginFailureAction: (message: string) => dispatch<ILoginFailure>({type: UserActionTypes.LoginError, data: message}),
        // TODO: Return to the waiting list with the next update!
        redirectToOnboarding: () => dispatch(push('/content')),
        logoutUserAction: () => dispatch<IStartLogout>({ type: UserActionTypes.LogoutUser }),
        logoutSuccessAction: () => dispatch<ILogoutSuccess>({ type: UserActionTypes.LogoutUserSuccess }),
        logoutFailureAction: (message: string) => dispatch<ILogoutFailure>({
            type: UserActionTypes.LogoutUserError,
            data: message
        }),
        redirectToHome: () => dispatch(push('/'))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(compose(graphql(LOGIN_USER, {name: "loginUserMutation"}), graphql(LOGOUT_USER, {name: 'logoutUserMutation'}))(Login));
