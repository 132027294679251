import { TContentTableReducerActions } from './content.actions';
import {ContentTableActionTypes, ContentTableState} from './contentTable.types';

const InitialState: ContentTableState = {
    currentPageNumber: 0,
    sortDirection: null,
    sortColumn: null
}

export const contentTableReducer = (state = InitialState, action: TContentTableReducerActions): ContentTableState => {
    switch(action.type) {
        case ContentTableActionTypes.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPageNumber: action.data
            };
        case ContentTableActionTypes.SET_SORT_DIRECTION:
            return {
                ...state,
                sortDirection: action.data
            };
        case ContentTableActionTypes.SET_SORT_COLUMN:
            return {
                ...state,
                sortColumn: action.data
            };
        default:
            return state;
    }
}

export default contentTableReducer;