import React, { useEffect, useState } from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { APPROVE_ARTICLE, APPROVE_CONTENT, APPROVE_CONTENT_SET, REJECT_CONTENT_SET, GET_CONTENT_SET_CONTENT_BY_ID, GET_WAITING_LIST_CONTENT, REJECT_ARTICLE, REJECT_CONTENT } from "./queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { EditContentType, WaitingListProps, ContentTypes, WaitingListTabTypes } from "./waitingList.types";
import { connect } from "react-redux";
import { AdminStoreState } from "../../redux/root-reducer";
import { ILoadedData, IToggleContentUserId, IWaitingListCurrentPageNumber, TWaitingListReducerActions } from "../../redux/waiting-list/waitingList.actions";
import { Dispatch } from "redux";
import { WaitingListActionTypes } from "../../redux/waiting-list/waitingList.types";
import { Button, FormControl, FormGroup, FormLabel, IconButton, MenuItem, Select, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import EditIcon from '@material-ui/icons/Edit';
import { SnackbarComponent, SearchBarComponent, RefreshDateTime } from "shared";
import { ContentNavigation, FilterNavigationTypes, UserUploadedContent } from "../content-list/contentList.types";
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { IAddChannelToIndividualImage, IToggleRadioButton, TContentUploadActions } from "../../redux/content-upload/content-upload.actions";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { IndividualImageCategory, RadioButtonGroup } from "../batch-upload/ContentUpload.types";
import SyncLoader from 'react-spinners/SyncLoader';
import { getCurrentPageNumber, selectWaitingListData } from "../../redux/waiting-list/waitinglist.selectors";
import CustomToolbarComponent from '../toolbar/toolbar.component'
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from "../../redux/generic/snackbar/snackbar.types";
import { IResetSnackbar, ISnackbarMessage, TSnackbarActions } from "../../redux/generic/snackbar/snackbar.actions";
import { selectSnackbarState } from "../../redux/generic/snackbar/snackbar.selectors";
import { ToolbarActions } from "../../components/toolbar/toolbar.types";
import { GET_ALL_CONTENT_COUNT } from "../../components/content-list/queries";
import { Autocomplete, InputLabel } from "@mui/material";
import { ISearchString, IToggleFilterChannel, IToggleFilterContentType, IToggleFilterContributorType, IToggleFilterLicensing, IToggleFilterSubchannel, IToggleFilterThread, IToggleFilterUploadDate, IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { ChannelFilter, SubchannelFilter, TableFilterActions, ThreadFilter } from "../../redux/table-filter/table-filter.types";
import { getSearchString, selectChannelFilter, selectContentType, selectContributorType, selectLicensingFilter, selectSubchannelFilter, selectThreadFilter, selectUploadDate } from "../../redux/table-filter/table-filter.selectors";
import { createTableContentRow } from "./helperFunctions";
import { GET_CHANNELS, GET_SUBCHANNELS, GET_THREADS } from "../../components/channels-table/queries";
import { selectRowsInWaitingList } from "../../redux/admin/admin.selectors";
import { ISelectRowsInWaitingList, TUserReducerActions } from "../../redux/admin/admin.actions";
import { UserActionTypes } from "../../redux/admin/admin.types";
import { selectWaitingListRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleWaitingListRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import './waitingList.styles.scss';
import { ISelectedSingleItem, TSingleItemReducerActions } from "../../redux/SingleItem/singleItem.actions";
import { SingleItemActionTypes } from "../../redux/SingleItem/singleItem.types";
export const WaitingList: React.FC<WaitingListProps> = ({ ...props }) => {

    const { contentType, waitingListData, licensingTypeFilter, contributorTypeFilter,
        contentTypeFilter, uploadDateFilter, channelFilter, subchannelFilter, threadFilter,
        toolbarError, rowsSelected, waitingListRefreshState, waitingListCurrentPageNumber, previousSearchString, selectRowsInWaitingListAction,
        loadedDataAction, redirectToEditPage, addIndividualImageNavigationAction,
        toggleRadioButtonAction, broadcastToolbarAction, resetSnackbarStateAction,
        toggleContentTypeFilterAction,
        toggleLicenseTypeFilterAction, togleContributorTypeFilterAction, toggleUploadDateFilterAction,
        toggleChannelFilterAction, toggleSubchannelFilterAction, toggleThreadFilterAction,
        resetTableFilterAction, toggleWaitingListRefresh, toggleContentUserIdAction, setWaitingListDataTableCurrentPage, setSearchString, setSelectedItem } = props;

    const today = new Date();

    //QUERIES
    const [fetchData, { data, loading, refetch }] = useLazyQuery(GET_WAITING_LIST_CONTENT, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        onError(error) {
            setToolbarAction(ToolbarActions.FETCH_ERROR);
        }
    });

    const [fetchContentCount, { data: countData, refetch: countRefetch }] = useLazyQuery(GET_ALL_CONTENT_COUNT, {
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
    });

    const { data: dataChannel, error: error1 } = useQuery(GET_CHANNELS);
    const [fetchSubchannels, { data: dataSub }] = useLazyQuery(GET_SUBCHANNELS);
    const [fetchThreads, { data: dataThread }] = useLazyQuery(GET_THREADS);

    //MUTATIONS
    const [approveContentMutation] = useMutation(APPROVE_CONTENT);
    const [rejectContentMutation] = useMutation(REJECT_CONTENT);
    const [approveContentSetMutation] = useMutation(APPROVE_CONTENT_SET);
    const [rejectContentSetMutation] = useMutation(REJECT_CONTENT_SET);
    const [approveArticleMutation] = useMutation(APPROVE_ARTICLE);
    const [rejectArticleMutation] = useMutation(REJECT_ARTICLE);

    //USE STATE HOOKS
    const [allUploadedContentCount, setAllUploadedContentCount] = useState({
        singleContents: 0,
        contentSets: 0,
        articles: 0
    });

    const [allUploadedContent, setAllUploadedContent] = useState<UserUploadedContent[]>([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [toolbarAction, setToolbarAction] = useState(ToolbarActions.NO_ACTION);

    const [pendingContentCount, setPendingContentCount] = useState<number>(0);

    const [channels, setChannels] = useState<ChannelFilter[]>([]);
    const [subchannels, setSubchannels] = useState<SubchannelFilter[]>([]);
    const [threads, setThreads] = useState<ThreadFilter[]>([]);

    const [selectedChannel, setSelectedChannel] = useState<ChannelFilter>({ id: 0, title: "", code: "" });
    const [selectedSubchannel, setSelectedSubchannel] = useState<SubchannelFilter>({ id: 0, title: "" });
    const [selectedThread, setSelectedThread] = useState<ThreadFilter>({ id: 0, title: "" });

    const [startUploadDate, setStartUploadDate] = useState<string | null>(null);
    const [endUploadDate, setEndUploadDate] = useState<string | null>(null);

    const [tabIndex, setTabIndex] = useState<number>(0);

    //USE EFFECT HOOKS
    useEffect(()=>{
        if(uploadDateFilter.length > 0 && uploadDateFilter[0]){
          setStartUploadDate(new Date(uploadDateFilter[0]).toISOString().slice(0,10));
        }
        if(uploadDateFilter.length > 0 && uploadDateFilter[1]){
          setEndUploadDate(new Date(uploadDateFilter[1]).toISOString().slice(0,10));
        }
      },[uploadDateFilter])

    useEffect(() => {
        if (toolbarError.severity && toolbarError.message) {
            setShowSnackbar(true);
        }
    }, [toolbarError]);

    useEffect(() => {
        if (toolbarAction === ToolbarActions.APPROVE) {
            broadcastToolbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Content successfully approved and started processing. It will appear in the Content table in 1-2 mins."
            });
        }
        else if (toolbarAction === ToolbarActions.ERROR_IN_APPROVE) {
            broadcastToolbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when approving content"
            });
        }
        else if (toolbarAction === ToolbarActions.WARNING) {
            broadcastToolbarAction({
                severity: SeveritySnackbarEnum.warning,
                message: "There is a thread which is not approved in content navigation"
            });
        }
        else if (toolbarAction === ToolbarActions.REJECT) {
            broadcastToolbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Content was successfully rejected"
            });
        }
        else if (toolbarAction === ToolbarActions.ERROR_IN_REJECT) {
            broadcastToolbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when rejecting content"
            })
        } else if (toolbarAction === ToolbarActions.FETCH_ERROR) {
            broadcastToolbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Error when fetching waiting list content."
            });
        }
    }, [toolbarAction]);

    useEffect(() => {
        if (showSnackbar) {
            callRefetch();
        }
    }, [showSnackbar]);

    useEffect(() => {
        if (contentType === WaitingListTabTypes.FMC) {
            fetchData({ variables: { pageNumber: 0, contentPerPage: rowsSelected, isFMC: true, searchString: "" } });
            fetchContentCount({ variables: { contributorId: null, status: null, isFMC: true } });
            setTabIndex(0);
        } else if (contentType === WaitingListTabTypes.STOCK) {
            fetchContentCount({ variables: { contributorId: null, status: null, isFMC: false } })
            fetchData({ variables: { pageNumber: 0, contentPerPage: rowsSelected, isFMC: false, searchString: "" } });
            setTabIndex(1);
        }
    }, [contentType]);

    useEffect(() => {
        if (data && data.getWaitingListContentByPage) {
            setAllUploadedContent(data.getWaitingListContentByPage.userUploadedContent);
            setPendingContentCount(data.getWaitingListContentByPage.allResultsCount);
            loadedDataAction(data.getWaitingListContentByPage.userUploadedContent);
        }
    }, [data]);

    useEffect(() => {
        if (countData && countData.getAllContentCountInDatatables) {
            setAllUploadedContentCount({
                singleContents: countData.getAllContentCountInDatatables.singleContentCount,
                contentSets: countData.getAllContentCountInDatatables.contentSetsCount,
                articles: countData.getAllContentCountInDatatables.articlesCount
            });
        }
    }, [countData]);

    useEffect(() => {
        if (startUploadDate && !endUploadDate) {
            toggleUploadDateFilterAction([new Date(startUploadDate), today]);
        } else if (startUploadDate && endUploadDate) {
            let endDate = new Date(endUploadDate);
            endDate.setHours(today.getHours());
            toggleUploadDateFilterAction([new Date(startUploadDate), new Date(endDate)]);
        } else if (!startUploadDate && !endUploadDate) {
            toggleUploadDateFilterAction([]);
        }
    }, [startUploadDate, endUploadDate]);

    useEffect(() => {
        if (dataChannel && dataChannel.getAllChannels) {
            dataChannel.getAllChannels.sort((a: ChannelFilter, b: ChannelFilter) => a.title.localeCompare(b.title))
            setChannels(dataChannel.getAllChannels);
        }
    }, [dataChannel]);

    useEffect(() => {
        if (dataSub && dataSub.getAllSubChannelsByChannelId) {
            setSubchannels(dataSub.getAllSubChannelsByChannelId);
        }
    }, [dataSub]);

    useEffect(() => {
        if (dataThread && dataThread.getAllThreadsBySubChannelId) {
            setThreads(dataThread.getAllThreadsBySubChannelId);
        }
    }, [dataThread]);

    const callSearch = (page: number,searchPhrase=previousSearchString): void => {
        fetchData({
            variables: {
                pageNumber: searchPhrase ? 0 : page,
                contentPerPage: rowsSelected,
                isFMC: contentType === WaitingListTabTypes.FMC ? true : false,
                searchString: searchPhrase || "",
                licensingType: licensingTypeFilter,
                contributorType: contributorTypeFilter,
                contentType: contentTypeFilter,
                uploadDate: uploadDateFilter,
                channelId: channelFilter && channelFilter.id ? channelFilter.id : 0,
                subchannelId: subchannelFilter && subchannelFilter.id ? subchannelFilter.id : 0,
                threadId: threadFilter && threadFilter.id ? threadFilter.id : 0,
                channelTitle: channelFilter && channelFilter.title ? channelFilter.title : '',
                subchannelTitle: subchannelFilter && subchannelFilter.title ? subchannelFilter.title : '',
                threadTitle: threadFilter && threadFilter.title ? threadFilter.title : ''
            }
        });
    }

    useEffect(() => {
        callSearch(waitingListCurrentPageNumber);
    }, [rowsSelected, licensingTypeFilter, contributorTypeFilter, contentTypeFilter, uploadDateFilter, channelFilter, subchannelFilter, threadFilter])

    //FUNCTIONS
    const callRefetch = () => {
        callSearch(waitingListCurrentPageNumber);
        fetchContentCount();
    }

    const approveContent = (contentId: number, type: string) => {
        if (type === ContentTypes.CONTENT_SET) {
            const contentSet = allUploadedContent.find((set: any) => set.contentSetId === contentId);
            if (contentSet) {
                approveContentSetMutation({ variables: { setId: contentId } })
                    .then((result: any) => {
                        setToolbarAction(ToolbarActions.APPROVE);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.WARNING);
                    });
            }
        } else if (type === ContentTypes.ARTICLE) {
            let selectedArticle = allUploadedContent.find((article: any) => article.articleId === contentId);
            if (selectedArticle) {
                approveArticleMutation({
                    variables: { articleId: selectedArticle.articleId }
                }).then((result: any) => {
                    setToolbarAction(ToolbarActions.APPROVE);
                }).catch((error: any) => {
                    setToolbarAction(ToolbarActions.WARNING);
                });
            }
        } else if (type === ContentTypes.IMAGE) {
            approveContentMutation({
                variables: {
                    contentId: contentId
                }
            }).then((result: any) => {
                setToolbarAction(ToolbarActions.APPROVE);
            }).catch((error: any) => {
                setToolbarAction(ToolbarActions.WARNING);
            });
        }
    }

    const rejectContent = (contentId: number, type: string) => {
        if (type === ContentTypes.CONTENT_SET) {
            let contentSet = allUploadedContent.find((set: any) => set.contentSetId === contentId);
            if (contentSet) {
                rejectContentSetMutation({ variables: { setId: contentId } })
                    .then((result: any) => {
                        setToolbarAction(ToolbarActions.REJECT);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_REJECT);
                    });
            }
        } else if (type === ContentTypes.ARTICLE) {
            let selectedArticle = allUploadedContent.find((article: any) => article.articleId === contentId);
            if (selectedArticle) {
                rejectArticleMutation({
                    variables: { articleId: selectedArticle.articleId }
                }).then((result: any) => {
                    setToolbarAction(ToolbarActions.REJECT)
                }).catch((error: any) => {
                    setToolbarAction(ToolbarActions.ERROR_IN_REJECT)
                })
            }
        } else if (type === ContentTypes.IMAGE) {
            rejectContentMutation({
                variables: { contentId: contentId }
            }).then((result: any) => {
                setToolbarAction(ToolbarActions.REJECT)
            }).catch((error: any) => {
                setToolbarAction(ToolbarActions.ERROR_IN_REJECT)
            })
        }
    }

    const handleClose = () => {
        setShowSnackbar(false);
        setToolbarAction(ToolbarActions.NO_ACTION);
    };

    const ToggleEditContent = (contentId: number, type: string) => {
        if (type === ContentTypes.IMAGE) {
            let editTypeObj: EditContentType = {
                tabIndex: tabIndex,
                id: contentId,
                type: type.toLowerCase()
            }
            let image = allUploadedContent.find((image: UserUploadedContent) => image.content.id === contentId);
            
            if (image) {
                const navigations: IndividualImageCategory = {
                    selectedThreads: image.contentNavigations.map((nav: ContentNavigation) => { return nav.thread.id }),
                    contentId: contentId
                }
                toggleRadioButtonAction(RadioButtonGroup.INDIVIDUAL);
                addIndividualImageNavigationAction(navigations);
                setSelectedItem(navigations);
                redirectToEditPage(editTypeObj);
            }
        } else if (type === ContentTypes.CONTENT_SET) {
            let contentSet = allUploadedContent.find((set: UserUploadedContent) => set.contentSetId === contentId);
            if (contentSet) {
                toggleRadioButtonAction(RadioButtonGroup.SET);
                let contSetObj: EditContentType = {
                    tabIndex: tabIndex,
                    id: contentId,
                    type: type.toLowerCase()
                }
                redirectToEditPage(contSetObj);
            }
        } else if (type === ContentTypes.ARTICLE) {
            let selectedArticle = allUploadedContent.find((article: UserUploadedContent) => article.articleId === contentId);

            if (selectedArticle) {
                toggleRadioButtonAction(RadioButtonGroup.SET);
                let articleTypeObj = {
                    tabIndex: tabIndex,
                    id: contentId,
                    type: type.toLowerCase()
                }
                redirectToEditPage(articleTypeObj);
            }
        }
    };

    const handleChangeChannels = (event: any, newValue: any) => {
        if (channels) {
            const channel = channels.filter(option => {
                return option.title === newValue;
            })[0]
            setSelectedChannel(channel);
            toggleChannelFilterAction(channel);
            toggleSubchannelFilterAction({ id: 0, title: '' });
            toggleThreadFilterAction({ id: 0, title: '' })
            setSelectedSubchannel({ id: 0, title: '' });
            setSelectedThread({ id: 0, title: '' });
            if (channel && channel.id) {
                fetchSubchannels({ variables: { id: channel.id } })
            }
        }
    }

    const handleChangeSubchannels = (event: any, newValue: any) => {
        if (subchannels) {
            const subchannel = subchannels.filter(option => {
                return option.title === newValue;
            })[0]
            toggleSubchannelFilterAction(subchannel);
            toggleThreadFilterAction({ id: 0, title: '' });
            setSelectedSubchannel(subchannel);
            setSelectedThread({ id: 0, title: '' });
            if (subchannel && subchannel.id) {
                fetchThreads({ variables: { id: subchannel.id } })
            }
        }
    }

    const handleChangeThreads = (event: any, newValue: any) => {
        if (threads) {
            const thread = threads.filter(option => {
                return option.title === newValue;
            })[0]
            toggleThreadFilterAction(thread);
            setSelectedThread(thread);
        }
    };

    const onSubmitSearch = (newSearchString: string) => {
        fetchData({ variables: { pageNumber: 0, contentPerPage: rowsSelected, isFMC: contentType === WaitingListTabTypes.FMC ? true : false, searchString: newSearchString } });
        setSearchString(newSearchString)
    };
    const handleResetSearch = () => {
        callSearch(waitingListCurrentPageNumber,"");
        setSearchString("")
    }

    const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
        return <SearchBarComponent  lastSearchValue={previousSearchString} handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
    }

    //MUI datatbles column definition
    const getNavigationFilter: any = ()=>{
        if (channelFilter.title && subchannelFilter.title && threadFilter.title) {
          return [`${channelFilter.title}`, `${subchannelFilter.title}`, `${threadFilter.title}`];
        } else if (channelFilter.title && subchannelFilter.title) {
          return [`${channelFilter.title}`, `${subchannelFilter.title}`];
        } else if (channelFilter.title) {
          return [`${channelFilter.title}`];
        } else if (subchannelFilter.title) {
          return [`${subchannelFilter.title}`];
        } else if (threadFilter.title) {
          return [`${threadFilter.title}`];
        } else{
          return null;
        }
      }
      const getUploadDateFilter: any = ()=>{
        if (uploadDateFilter[0] && uploadDateFilter[1]) {
                  return [`${new Date(uploadDateFilter[0]).toISOString().slice(0, 10)}`,`${new Date(uploadDateFilter[1]).toISOString().slice(0, 10)}`];
                } else if (uploadDateFilter[0]) {
                  return [`${new Date(uploadDateFilter[0]).toISOString().slice(0, 10)}`];
                } else if (uploadDateFilter[1]) {
                  return [`${new Date(uploadDateFilter[1]).toISOString().slice(0, 10)}`];
                }
                return "";
      }
    const contentColumns: any = [
        {
            name: "Thumbnail",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <div>
                            <img src={tableMeta.rowData[0]} alt={value}
                                style={{
                                    display: "block",
                                    width: '50px',
                                    height: '50px'
                                }}
                            />
                        </div>
                    )
                }
            }
        },
        {
            name: "ID",
            options: {
                viewColumns: false,
                sort: false,
                display: 'false' as 'false',
                filter: false
            }
        },
        {
            name: "Code",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "Headline/Title",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "Type",
            options: {
                reset: true,
                sort: false,
                filterType: 'custom' as 'custom',
                filterList: contentTypeFilter ? [contentTypeFilter] : null,
                customFilterListOptions: {
                    render: (v: any) => v,
                    update: (filterList: any, filterPos: any, index: any) => {
                        filterList[index].splice(filterPos, 1);
                        if (filterList[index].length === 0) {
                            toggleContentTypeFilterAction('')
                        }
                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    display: (filterList: any, onChange: any, index: any, column: any) => {
                        const optionValues = ['set', 'article', 'image'];
                        return (
                            <FormControl>
                                <InputLabel htmlFor='select-multiple-chip'>
                                    Content type
                                </InputLabel>
                                <Select
                                    value={filterList[index]}
                                    renderValue={(selected: any) => selected}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        toggleContentTypeFilterAction(filterList[index][0]);
                                        onChange(filterList[index], index, column);
                                    }}
                                >
                                    {optionValues.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }
                }
            }
        },
        {
            name: "Contributor ID",
            options: {
                viewColumns: false,
                sort: false,
                display: 'false' as 'false',
                filter: false
            }
        },
        {
            name: "Contributor username",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "Contributor name",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "Contributor type",
            options: {
                reset: true,
                sort: false,
                filterType: 'custom' as 'custom',
                filterList: contributorTypeFilter ? [contributorTypeFilter] : null,
                customFilterListOptions: {
                    render: (v: any) => v,
                    update: (filterList: any, filterPos: any, index: any) => {
                        filterList[index].splice(filterPos, 1);
                        if (filterList[index].length === 0) {
                            togleContributorTypeFilterAction('')
                        }
                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    display: (filterList: any, onChange: any, index: any, column: any) => {
                        const optionValues = ['Agency', 'Individual'];
                        return (
                            <FormControl>
                                <InputLabel htmlFor='select-multiple-chip'>
                                    Contributor type
                                </InputLabel>
                                <Select
                                    value={filterList[index]}
                                    renderValue={(selected: any) => selected}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        togleContributorTypeFilterAction(filterList[index][0]);
                                        onChange(filterList[index], index, column);
                                    }}
                                >
                                    {optionValues.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }
                }
            }
        },
        {
            name: "Navigation",
            options: {
                reset: true,
                sort: false,
                filterType: "custom" as "custom",
                filterList: getNavigationFilter() ? getNavigationFilter() : null,
                customFilterListOptions: {
                    render: (v: any) => {
                        if (v[0] && v[1] && v[2]) {
                            return `Channel: ${v[0]}, Subchannel: ${v[1]}, Thread: ${v[2]}`;
                        } else if (v[0] && v[1]) {
                            return `Channel: ${v[0]}, Subchannel: ${v[1]}`;
                        } else if (v[0]) {
                            return `Channel: ${v[0]}`;
                        } else if (v[1]) {
                            return `Subchannel: ${v[1]}`;
                        } else if (v[2]) {
                            return `Thread: ${v[2]}`;
                        }
                        return " ";
                    },
                    update: (filterList: any, filterPos: any, index: any) => {
                        toggleChannelFilterAction({ id: 0, title: '', code: '' });
                        toggleSubchannelFilterAction({ id: 0, title: '' });
                        toggleThreadFilterAction({ id: 0, title: '' });
                        setSelectedChannel({ id: 0, title: '', code: '' });
                        setSelectedSubchannel({ id: 0, title: '' });
                        setSelectedThread({ id: 0, title: '' });
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    }
                },
                filterOptions: {
                    display: (filterList: any, onChange: any, index: any, column: any) => {
                        return (
                            <div>
                                <FormLabel>Navigation</FormLabel>
                                <FormGroup row>
                                    <Autocomplete
                                        id="channel-autocomplete"
                                        disableClearable
                                        options={channels.map((channel) => channel.title)}
                                        style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                                        onChange={(event: any, value: any): any => {
                                            filterList[index][0] = value;
                                            handleChangeChannels(event, value);
                                            onChange(filterList[index], index, column);
                                        }}
                                        value={selectedChannel && selectedChannel.title ? selectedChannel.title : channelFilter.title || ''}
                                        renderInput={(params: any): any =>
                                            <TextField
                                                {...params}
                                                id="channel"
                                                label="Channel"
                                                type="text"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name={FilterNavigationTypes.CHANNEL_FILTER} className='text-field'
                                            />
                                        }
                                    />
                                    <Autocomplete
                                        id="subchannel-autocomplete"
                                        disableClearable
                                        options={subchannels.map((subchannel) => subchannel.title)}
                                        style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                                        onChange={(event: any, value: any): any => {
                                            filterList[index][1] = value;
                                            handleChangeSubchannels(event, value);
                                            onChange(filterList[index], index, column);
                                        }}
                                        value={selectedSubchannel && selectedSubchannel.title ? selectedSubchannel.title : subchannelFilter.title || ''}
                                        renderInput={(params: any): any =>
                                            <TextField
                                                {...params}
                                                id="subchannel"
                                                label="Subchannel"
                                                type="text"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name={FilterNavigationTypes.SUBCHANNEL_FILTER} className='text-field'
                                            />
                                        }
                                    />
                                    <Autocomplete
                                        id="thread-autocomplete"
                                        options={threads.map((thread) => thread.title)}
                                        disableClearable
                                        style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                                        onChange={(event: any, value: any): any => {
                                            filterList[index][2] = value;
                                            handleChangeThreads(event, value);
                                            onChange(filterList[index], index, column);
                                        }}
                                        value={selectedThread && selectedThread.title ? selectedThread.title : threadFilter.title || ""}
                                        renderInput={(params: any): any =>
                                            <TextField
                                                {...params}
                                                id="thread"
                                                label="Thread"
                                                type="text"
                                                autoComplete="off"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                name={FilterNavigationTypes.THREAD_FILTER} className='text-field'
                                            />
                                        }
                                    />
                                </FormGroup>
                            </div>
                        )
                    }
                }
            }
        },
        {
            name: "Upload date",
            options: {
                reset: true,
                sort: false,
                filterType: "custom" as "custom",
                filterList: getUploadDateFilter() || null,
                customFilterListOptions: {
                    render: (v: any) => {
                        if (v[0] && v[1]) {
                            return `Start date: ${v[0]}, End date: ${v[1]}`;
                        } else if (v[0]) {
                            return `Start date: ${v[0]}`;
                        } else if (v[1]) {
                            return `End date: ${v[1]}`;
                        }
                        return " ";
                    },
                    update: (filterList: any, filterPos: any, index: any) => {
                        toggleUploadDateFilterAction([]);
                        setStartUploadDate(null);
                        setEndUploadDate(null);
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    display: (filterList: any, onChange: any, index: any, column: any) => (
                        <div>
                            <FormLabel>Upload date</FormLabel>
                            <FormGroup row>
                                <TextField
                                    id="startDate"
                                    label="From"
                                    type="date"
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={filterList[index][0] || ""}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        setStartUploadDate(filterList[index][0]);
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: "45%", marginRight: "5%" }}
                                />
                                <TextField
                                    id="endDate"
                                    label="To"
                                    type="date"
                                    autoComplete="off"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={filterList[index][1] || ""}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        setEndUploadDate(filterList[index][1]);
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: "45%", marginRight: "5%" }}
                                />
                            </FormGroup>
                        </div>
                    )
                }
            }
        },
        {
            name: "License type",
            options: {
                reset: true,
                sort: false,
                filterType: 'custom' as 'custom',
                filterList: licensingTypeFilter ? [licensingTypeFilter] : null,
                customFilterListOptions: {
                    render: (v: any) => v,
                    update: (filterList: any, filterPos: any, index: any) => {
                        filterList[index].splice(filterPos, 1);
                        if (filterList[index].length === 0) {
                            toggleLicenseTypeFilterAction('')
                        }
                        return filterList;
                    }
                },
                filterOptions: {
                    names: [],
                    display: (filterList: any, onChange: any, index: any, column: any) => {
                        const optionValues = ['standard', 'premium', 'editorial'];
                        return (
                            <FormControl>
                                <InputLabel htmlFor='select-multiple-chip'>
                                    License type
                                </InputLabel>
                                <Select
                                    value={filterList[index]}
                                    renderValue={(selected: any) => selected}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        toggleLicenseTypeFilterAction(filterList[index][0]);
                                        onChange(filterList[index], index, column);
                                    }}
                                >
                                    {optionValues.map(item => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }
                }
            }
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <div>
                            <Button size='small' startIcon={<FontAwesomeIcon className='icon-button' icon={faCheck} />}
                                onClick={event => {
                                    event.stopPropagation();
                                    approveContent(tableMeta.rowData[1], tableMeta.rowData[4])
                                }}>
                                Approve
                            </Button>

                            <Button size='small' startIcon={<FontAwesomeIcon className='icon-button' icon={faTimes} />}
                                onClick={event => {
                                    event.stopPropagation();
                                    rejectContent(tableMeta.rowData[1], tableMeta.rowData[4]);
                                }}>
                                Reject
                            </Button>

                        </div>
                    )
                }
            }
        },
        {
            name: "Update",
            options: {
                filter: false,
                sort: false,
                empty: false,
                customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
                    return (
                        <div>
                            <IconButton onClick={() => {
                                resetSnackbarStateAction();
                                toggleContentUserIdAction(tableMeta.rowData[5]);
                                ToggleEditContent(tableMeta.rowData[1], tableMeta.rowData[4])
                            }}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    )
                }
            }
        }
    ];

    //Mui datatbles options definition
    const options: MUIDataTableOptions = {
        count: pendingContentCount,
        rowsPerPage: rowsSelected,
        page: waitingListCurrentPageNumber,
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: true,
        elevation: 3,
        jumpToPage: true,
        fixedHeader: false,
        filterType: 'dropdown',
        responsive: 'vertical',
        tableBodyMaxHeight: 'calc(100%)',
        tableBodyHeight: 'calc(100%-50px)',
        download: true,
        print: false,
        sort: true,
        serverSide: true,
        searchOpen: previousSearchString ? true : false,
        searchText: previousSearchString,
        textLabels: {
            body: {
                noMatch: loading ?
                    <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
                        size={20} color={"#36D2B3"} loading={loading} />
                    :
                    'Sorry, no matching records found for now',
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

            const selectedRowsIds = selectedRows.data.map((i: any) => {
                return i.dataIndex;
            })

            const activeRows = displayData.filter((rowData: any) => {
                return selectedRowsIds.includes(rowData.dataIndex)
            })

            return (
                <CustomToolbarComponent
                    activeRows={activeRows}
                    refetch={refetch}
                />
            )

        },
        onChangePage: (currentPage: number) => {
            callSearch(currentPage);
            setWaitingListDataTableCurrentPage(currentPage);
        },
        customSearchRender: customSearchRender,
        onFilterChange: (changedColumn: any, filterList: any, type: any) => {
            if (type === 'reset') {
                resetTableFilterAction();
                setSelectedChannel({ id: 0, title: '', code: '' });
                setSelectedSubchannel({ id: 0, title: '' });
                setSelectedThread({ id: 0, title: '' });
            }
        },
        onChangeRowsPerPage: (numberOfRows: number) => {
            selectRowsInWaitingListAction(numberOfRows);
        }
    };

    return (
        <div>
            <div className="info-container">
                <span className='results-container'>{allUploadedContentCount.singleContents} Images | {allUploadedContentCount.contentSets} Content sets | {allUploadedContentCount.articles} Articles</span>
                <div className="refetch-container">
                    <RefreshDateTime loading={loading} autoRefreshOn={waitingListRefreshState}
                        refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleWaitingListRefresh} />
                </div>
            </div>
            <MUIDataTable
                title={""}
                columns={contentColumns} options={options}
                data={waitingListData!.map((item: UserUploadedContent) => createTableContentRow(item))}
            />
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                severity={toolbarError.severity} message={toolbarError.message} />
        </div>
    );
}

const mapStateToProps = (state: AdminStoreState): {
    waitingListData: UserUploadedContent[]; toolbarError: IBroadcastMessage; licensingTypeFilter: string;
    contributorTypeFilter: string; contentTypeFilter: string;
    uploadDateFilter: Date[]; channelFilter: ChannelFilter; subchannelFilter: SubchannelFilter;
    threadFilter: ThreadFilter; rowsSelected: number; waitingListRefreshState: boolean; waitingListCurrentPageNumber: number; previousSearchString: string | undefined
} => {
    return {
        waitingListData: selectWaitingListData(state),
        toolbarError: selectSnackbarState(state),
        licensingTypeFilter: selectLicensingFilter(state),
        contributorTypeFilter: selectContributorType(state),
        contentTypeFilter: selectContentType(state),
        uploadDateFilter: selectUploadDate(state),
        channelFilter: selectChannelFilter(state),
        subchannelFilter: selectSubchannelFilter(state),
        threadFilter: selectThreadFilter(state),
        rowsSelected: selectRowsInWaitingList(state),
        waitingListRefreshState: selectWaitingListRefresh(state),
        waitingListCurrentPageNumber: getCurrentPageNumber(state),
        previousSearchString: getSearchString(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TWaitingListReducerActions | TRefreshTablesActions | TContentUploadActions | CallHistoryMethodAction | TSnackbarActions | TTableFiltersReducerActions | TUserReducerActions | TSingleItemReducerActions>) => {
    return {
        loadedDataAction: (data: UserUploadedContent[]) => { dispatch<ILoadedData>({ type: WaitingListActionTypes.LOADED_WAITING_LIST_DATA, data: data }) },
        redirectToEditPage: (data: EditContentType) => dispatch(push(`/edit-waiting-list/${data.tabIndex}/${data.type}/${data.id}`)),
        toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({ type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON, data: data }),
        addIndividualImageNavigationAction: (data: IndividualImageCategory) => dispatch<IAddChannelToIndividualImage>({ type: ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL, data: data }),
        broadcastToolbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data }),
        resetSnackbarStateAction: () => dispatch<IResetSnackbar>({ type: SnackbarActionTypes.RESET_MESSAGE }),
        toggleContentTypeFilterAction: (data: string) => dispatch<IToggleFilterContentType>({ type: TableFilterActions.TOGGLE_FILTER_CONTENT_TYPE, data: data }),
        toggleLicenseTypeFilterAction: (data: string) => dispatch<IToggleFilterLicensing>({ type: TableFilterActions.TOGGLE_FILTER_LICENSING, data: data }),
        togleContributorTypeFilterAction: (data: string) => dispatch<IToggleFilterContributorType>({ type: TableFilterActions.TOGGLE_FILTER_CONTRIBUTOR_TYPE, data: data }),
        toggleUploadDateFilterAction: (data: Date[]) => dispatch<IToggleFilterUploadDate>({ type: TableFilterActions.TOGGLE_FILTER_UPLOAD_DATE, data: data }),
        toggleChannelFilterAction: (data: ChannelFilter) => dispatch<IToggleFilterChannel>({ type: TableFilterActions.TOGGLE_FILTER_CHANNEL, data: data }),
        toggleSubchannelFilterAction: (data: SubchannelFilter) => dispatch<IToggleFilterSubchannel>({ type: TableFilterActions.TOGGLE_FILTER_SUBCHANNEL, data: data }),
        toggleThreadFilterAction: (data: ThreadFilter) => dispatch<IToggleFilterThread>({ type: TableFilterActions.TOGGLE_FILTER_THREAD, data: data }),
        resetTableFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_FILTERS }),
        selectRowsInWaitingListAction: (data: number) => dispatch<ISelectRowsInWaitingList>({ type: UserActionTypes.SelectRowsInWaitingList, data: data }),
        toggleWaitingListRefresh: (data: boolean) => dispatch<IToggleWaitingListRefresh>({
            type: TablesRefreshActionTypes.WAITING_LIST_REFRESH,
            data: data
        }),
        toggleContentUserIdAction: (data: number) => dispatch<IToggleContentUserId>({ type: WaitingListActionTypes.TOGGLE_CONTENT_USER_ID, data: data }),
        setWaitingListDataTableCurrentPage: (data: number) => dispatch<IWaitingListCurrentPageNumber>({ type: WaitingListActionTypes.SET_WAITING_TABLE_CURRENT_PAGE, data: data}),
        setSearchString: (data: string) => dispatch<ISearchString>({ type: TableFilterActions.SET_SEARCH_STRING, data: data }),
        setSelectedItem:(data: IndividualImageCategory) => dispatch<ISelectedSingleItem>({ type: SingleItemActionTypes.SET_SELECTED_ITEM, data: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingList);

