import React, { } from 'react';
import { AppBar, Tabs, Tab, makeStyles, Tooltip } from '@material-ui/core';
import { ArticleTabsProps, ContentSet, Article } from './ContentUpload.types';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectChangesToBeSaved, selectContentSets } from '../../redux/content-upload/content-upload.selectors';
import { Dispatch } from 'redux';
import { TContentUploadActions, IUploadArticle } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './AddArticle.styles.scss';
import TabPanel from './ArticleTabPanel';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';

const useStyles = makeStyles((theme) => ({
    tabsPanel: {
        backgroundColor: 'rgb(12, 175, 149) !important',
        minHeight: '68px'
    },
    root: {
        maxWidth: '200px',
        textOverflow: 'ellipsis',
        minHeight: '68px'
    },
    wrapper: {
        color: 'black',
        borderRadius: '10px',
        paddingLeft: '3%',
        paddingRight: '3%',
        backgroundColor: 'white',
        width: 'auto'
    },
    textColorPrimary: {
        color: 'white !important'
    },
    textColorInherit: {
        opacity: '1'
    }
}));

const ArticleTabsComponent: React.FC<ArticleTabsProps> = ({ ...props }) => {
    const classes = useStyles();
    const { contentSetsState, changesToBeSaved, broadcastSnackbarAction } = props;
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (!changesToBeSaved) {
            setValue(newValue);
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.warning,
                message: "Changes to article are not saved. Saved them in order to continue"
            });
        }
    }

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className='article-tabs-container'>
            <AppBar position="static" className={classes.tabsPanel}>
                <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="auto"
                    aria-label="simple tabs example">
                    {contentSetsState ? contentSetsState.map((set: ContentSet) => (
                        <Tooltip key={`tab_${set.id}`} title={set.title} disableFocusListener disableTouchListener>
                            <Tab label={set.title.length > 15 ? set.title.substring(0, 15).concat('...') : set.title} style={{
                                background: set.coverPhoto ? `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${set.coverPhoto?.url})` : 'none',
                                backgroundSize: 'cover'
                            }}
                                classes={{ root: classes.root, wrapper: classes.wrapper, textColorInherit: classes.textColorInherit, textColorPrimary: classes.textColorPrimary }}
                                {...a11yProps(contentSetsState.indexOf(set))} />
                        </Tooltip>))
                        : null}
                </Tabs>
            </AppBar>
            {contentSetsState.map((set: ContentSet) => (
                <TabPanel key={`set_${set.id}`} value={value} index={contentSetsState.indexOf(set)} />
            ))}
        </div>
    )
}

const mapStateToProps = (state: AdminStoreState): { contentSetsState: ContentSet[], changesToBeSaved: boolean } => {
    return {
        contentSetsState: selectContentSets(state),
        changesToBeSaved: selectChangesToBeSaved(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions>) => {
    return {
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>({ type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data }),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTabsComponent);