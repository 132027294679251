import gql from "graphql-tag";

export const GET_WAITING_LIST_CONTENT = gql`
    query getWaitingListContentByPage($pageNumber: Int!, $contentPerPage: Int!, $isFMC: Boolean!, $searchString: String, $licensingType: String, $contributorType: String,
        $contentType: String, $uploadDate: [DateTime!], $channelId: Int, $subchannelId: Int, $threadId: Int,
        $channelTitle: String, $subchannelTitle: String, $threadTitle: String) {
        getWaitingListContentByPage(pageNumber: $pageNumber, contentPerPage: $contentPerPage, isFMC: $isFMC, input: {searchString: $searchString, licensingType: $licensingType, contributorType: $contributorType, 
            contentType: $contentType, uploadDate: $uploadDate, channelId: $channelId, subchannelId: $subchannelId, threadId: $threadId,
            channelTitle: $channelTitle, subchannelTitle: $subchannelTitle, threadTitle: $threadTitle}) {
            allResultsCount
            userUploadedContent {
                articleId
                contentSetId
                title
                type
                uploadDate
                content {
                    id
                    code
                    pathToFileThumbnail
                    licensingType
                }
                contentNavigations {
                    channel {
                        id
                        title
                    }
                    subChannel {
                        id
                        title
                    }
                    thread {
                        id
                        title
                    }
                }
                contributor {
                    id
                    username
                    role
                    person {
                        id
                        firstName
                        lastName
                    }
                    company {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const APPROVE_CONTENT = gql`
    mutation approveContentMutation($contentId: Int!) {
        approveContent(contentId: $contentId)
    }
`;

export const PENDING_CONTENT = gql`
    mutation pendingContentMutation($contentId: Int!) {
        pendingContent(contentId: $contentId)
    }
`;

export const REJECT_CONTENT = gql`
    mutation rejectContentMutation($contentId: Int!) {
        rejectContent(contentId: $contentId)
    }
`;

// export const REJECT_CONTENTS = gql`
//     mutation rejectContentsMutation($contentIds: Int!) {
//         rejectContents(contentIds: $contentIds)
//     }
// `;

export const GET_CONTENT_BY_ID = gql`
query getContentById($contentId: Int!, $approved: Boolean) {
    getContentById(contentId: $contentId, approved: $approved) {
      id
      pathToFileCompressed
      code
      bucketKey
      __typename
    }
  }
`;

export const GET_CONTENT_SET_CONTENT_BY_ID = gql`
    query getContentSetContentQuery($contentSetId: Int!, $approved: Boolean) {
        getContentSetContentById(contentSetId: $contentSetId, approved: $approved) {
            contentSet {
                contentSetId
                title
                coverId
            }
            content {
                id
                code
                pathToFileCompressed
                pathToFileThumbnail
                bucketKey
                status
            }
            contentNavigation {
                id
                title
            }
        }
    }
`;

export const GET_ARTICLE_CONTENT_BY_ID = gql`
    query getArticleAndSetContentQuery($articleId: Int!) {
        getArticleById(articleId: $articleId) {
            articleId
            title
            audioId
            contentSetId
            description
        }
    }
`;

export const GET_AUDIO_CONTENT_BY_ID = gql`
    query getAudioContentByIdQuery($audioId: Int!) {
        getAudioContentById(audioId: $audioId) {
            id
            bucketKey
            code
            objectName
            pathToFile
        }
    }
`;

export const APPROVE_ARTICLE = gql`
    mutation approveAndPublishArticle($articleId: Int!) {
        approveAndPublishArticle(articleId: $articleId)
    }
`;

export const REJECT_ARTICLE = gql`
    mutation rejectArticle($articleId: Int!) {
        rejectArticle(articleId: $articleId)
    }
`;

export const APPROVE_CONTENT_SET = gql`
    mutation approveAndPublishContentSet($setId: Int!) {
        approveAndPublishContentSet(setId: $setId)
    }
`

export const REJECT_CONTENT_SET = gql`
    mutation rejectContentSet($setId: Int!) {
        rejectContentSet(setId: $setId)
    }
`

export const DELETE_CONTENT = gql`
    mutation deleteContentByTypeMutation($contentType: String!, $contentId: Int!) {
        deleteContentByType(contentType: $contentType, contentId: $contentId)
    }
`

export const DELETE_ARTICLE = gql`
    mutation deleteArticle($articleId: Int!) {
        deleteArticle(articleId: $articleId)
    }
`;