import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectContentUpload = (state: AdminStoreState) => state.contentUpload;

export const selectUploadedFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.uploadedFiles
);

export const selectDroppedFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.droppedFiles
);

export const selectContentSets = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.contentSets
);

export const selectRadioButtonValue = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.selectedRadioButton
);

export const selectArticles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.articles
);

export const selectAudioFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.audioFiles
);

export const selectIndividualImagesCategory = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.individualImagesCategory
);

export const selectAudioFileResponse = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.audioFilesResponses
)

export const selectMovedContents = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.movedContents
)


export const selectCopiedContents = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.copiedContents
)

export const selectMultipleMovedContents = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.movedMultipleContents
)

export const selectMultipleCopiedContents = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.copiedMultipleContents
)

export const selectChangesToBeSaved = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.changesToBeSaved
)

export const selectedIncompleteSFTPSet = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.selectedIncompleteSFTPSet
)

