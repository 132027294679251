import { Channel, SubChannel, Thread } from "../../components/channels-table/channelsTable.types";

export enum ChannelsTableActionTypes {
    LoadChannels = "LOAD_CHANNELS",
    LoadSubChannels = "LOAD_SUBCHANNELS",
    LoadThreads = "LOAD_THREADS",
    ResetThreads = "RESET_THREADS",
    AddChannel = "ADD_CHANNEL",
    UpdateChannel = "UPDATE_CHANNEL",
    DeleteChannel = "DELETE_CHANNEL",
}

export interface ChannelsTableState {
    selectedChannelId: number;
    selectedSubChannelId: number;
    selectedThreadId: number;
    channels: Channel[];
    subchannels: SubChannel[];
    threads: Thread[];
}

export interface UpdateActionData {
    id: number;
    title?: string;
    channelId?: number;
    subChannelId?: number;
    category: string;
}