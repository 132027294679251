import React, { useEffect, useState } from 'react'
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { dialogStyles } from '../../components/users-table/usersAdminTable.types';
import { LicensePlanTypes } from '../../components/license-table/LicenseAdminTable.types';
import { AddLocationOverrideTypes, useStyles } from './locationOverride.types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { ADD_LOCATION_OVERRIDE } from './queries';
import { SeveritySnackbarEnum } from '../../redux/generic/snackbar/snackbar.types';
import { RapidApiCountryDto } from '../../globals/locations/location.types';
import axios from 'axios';
import {config} from "../../config";

const AddLocationOverrideModal: React.FC<AddLocationOverrideTypes> = ({...props}) => {
    const { refetch, tableData, broadcastSnackbarAction } = props;
    const [openModal, setOpenModal] = useState(false);
    const [planType, setPlanType] = useState(LicensePlanTypes.Subscription);
    const [countries, setCountries] = useState<RapidApiCountryDto[]>();
    const [response, setResponseState] = useState("");
    const [selectedCountry, setSelectedCountry] = useState<RapidApiCountryDto>();
    const classes = useStyles();
    
    const validationSchema = Yup.object({
        country: Yup.string().required("You must select country."),
        percentageFactor: Yup.number().required("You must enter percentage factor.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            )
    });

    const [addNewLocationOverride] = useMutation(ADD_LOCATION_OVERRIDE);

    const { handleSubmit, handleChange, values, errors, resetForm} = useFormik({
        initialValues: {
            licensePlanType: '',
            country: '',
            percentageFactor: 0
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const {licensePlanType, country, percentageFactor} = values;
            checkLocationOverride();
            resetForm({});
        }
    })

    useEffect(() => {
        if (planType === LicensePlanTypes.Subscription) {
            setPlanType(LicensePlanTypes.Subscription)
        }
        else {
            setPlanType(LicensePlanTypes.Credit)
        }
    }, [planType]);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${config.REACT_APP_ADMIN_SERVER_HOST}/countries`,
        })
            .then((res: any) => setCountries(res.data))
            .catch((error: any) => console.log(error))
    }, [])

    const checkLocationOverride = () => {
        const existRow = tableData.filter(data => data.country.country === selectedCountry!.country && data.licensingPlanType === planType).length > 0;

        if (existRow) {
            setResponseState("This country license already exists");
        } else {
            addNewLocationOverride({
                variables: {
                    licensePlanType: planType,
                    countryName: selectedCountry!.country,
                    percentageFactor: values.percentageFactor,
                    countryCode: selectedCountry!.countryCode
                }
            }).then((result: any) => {
                refetch();
                handleClose();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "New country license override added successfully!"
                })
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            })
        }
    };

    const addLocationOverride = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleOnClickType = (event: any) => {
        setPlanType(event?.target.value);
    };

    const handleOnClickCountry = (country: RapidApiCountryDto) => {
        setSelectedCountry(country);
    };

    return (
        <React.Fragment>
            <Tooltip title={"Add new override"}>
                <IconButton onClick={addLocationOverride}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={openModal} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={openModal}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>
                            <div className='add-user-container'>
                                <h1 className='title'>Add new location override</h1>
                                {response ? <div className='error-box'>Something went wrong</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <RadioGroup name="type" defaultValue={LicensePlanTypes.Subscription} onClick={handleOnClickType}>
                                                <FormControlLabel value={LicensePlanTypes.Subscription} control={<Radio color="default" />} label={LicensePlanTypes.Subscription} />
                                                <FormControlLabel value={LicensePlanTypes.Credit} control={<Radio color="default" />} label={LicensePlanTypes.Credit} />
                                            </RadioGroup>
                                            <FormControl>
                                                <InputLabel>Country</InputLabel>
                                                <Select name="country" onChange={handleChange}
                                                    value={values.country}>
                                                    {countries !== undefined ? countries.map((country: any) => {
                                                        return <MenuItem key={country.country} value={country.country} onClick={() => handleOnClickCountry(country)}>{country.country}</MenuItem>
                                                    }) : <MenuItem value="No countries">No countries</MenuItem>}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                autoComplete="off" 
                                                type="number"
                                                name="percentageFactor"
                                                onChange={handleChange} 
                                                label="Price"
                                                variant="standard"
                                                defaultValue={0}
                                                error={errors.percentageFactor === ''}
                                                helperText={errors.percentageFactor ? errors.percentageFactor : null}
                                                FormHelperTextProps={{ className: classes.error }} 
                                            />
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Add override</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    )
};

export default AddLocationOverrideModal;