import React, { useEffect, useState } from 'react';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LicensingCountries, LocationOverrideTypes } from './locationOverride.types';
import AddNewLocationOverride from './addLocationOverrideModal'
import { DELETE_LOCATION_OVERRIDE, GET_ALL_OVERRIDES } from './queries';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Dispatch } from 'redux';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { connect } from 'react-redux';
import { SnackbarComponent } from "shared";
import EditLocationOverrideModal from './editLocationOverrideModal';
import { RefreshDateTime } from 'shared';
import { selectLocationOverrideRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleLocationOverrideRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";

const LocationOverrideTable: React.FC<LocationOverrideTypes> = ({ ...props }) => {
    const { licenseType, snackbarAction, locationOverrideRefreshState, 
        broadcastSnackbarAction, toggleLocationOverrideRefresh } = props;
    const [locationOverrides, setLocationOverrides] = useState<LicensingCountries[]>([]);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const { data, loading, error, refetch } = useQuery(GET_ALL_OVERRIDES, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (data) {
            setLocationOverrides(data.getAllLocationOverrides);
        }
    }, [data]);

    useEffect(() => {
        if (snackbarAction.severity && snackbarAction.message) {
            setShowSnackbar(true);
        }
    }, [snackbarAction]);

    const [deleteLocationOverrideMutation] = useMutation(DELETE_LOCATION_OVERRIDE);
    const deleteLocationOverride = (id: number) => {
        deleteLocationOverrideMutation({
            variables: {
                id: id
            }
        }).then(() => {
            refetch().then(() => {
                broadcastSnackbarAction({ severity: SeveritySnackbarEnum.success, message: "Country license override deleted successfully!" })
            })

        }).catch(() => {
            broadcastSnackbarAction({ severity: SeveritySnackbarEnum.error, message: "Error while deleting country license override!" })
        })
    }

    const createTableRow = (item: LicensingCountries) => {
        return [
            item.id,
            item.licensingPlanType,
            item.country.country,
            item.percentageFactor * 100 + '%'
        ];
    };

    const handleClose = () => {
        setShowSnackbar(false);
    }

    const columns = [
        {
            name: "ID",
            options: {
                display: 'false' as 'false',
                viewColumns: false,
            }
        },
        "Plan type",
        "Country",
        "Percentage factor",
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <EditLocationOverrideModal tableMeta={tableMeta.rowData} loadedData={locationOverrides} broadcastSnackbarAction={broadcastSnackbarAction} refetch={refetch} />
                    )
                }
            }
        },
        {
            name: "Delete",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <IconButton onClick={() => deleteLocationOverride(tableMeta.rowData[0])}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    )
                }
            }
        },
    ];

    const options: MUIDataTableOptions = {
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: true,
        fixedHeader: false,
        filterType: 'dropdown',
        responsive: 'standard',
        download: true,
        print: false,
        searchPlaceholder: "Search " + licenseType,
        customToolbar: (tableMeta: any) => {
            return (
                <AddNewLocationOverride refetch={refetch} tableData={locationOverrides} broadcastSnackbarAction={broadcastSnackbarAction} />
            )
        }
    }
    return (
        <React.Fragment>
            <div className='refetch-container'>
                <RefreshDateTime loading={loading} autoRefreshOn={locationOverrideRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleLocationOverrideRefresh}/>
            </div>
            <MUIDataTable title={""} data={locationOverrides.map((item: LicensingCountries) => createTableRow(item))}
                columns={columns} options={options}
            />
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                severity={snackbarAction.severity} message={snackbarAction.message} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: AdminStoreState): { snackbarAction: IBroadcastMessage, locationOverrideRefreshState: boolean } => {
    return {
        snackbarAction: selectSnackbarState(state),
        locationOverrideRefreshState: selectLocationOverrideRefresh(state)
    }
};

const mapDispatchToProps = (dispatch: Dispatch<TSnackbarActions | TRefreshTablesActions>) => {
    return {
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data }),
        toggleLocationOverrideRefresh: (data: boolean) => dispatch<IToggleLocationOverrideRefresh>({
            type: TablesRefreshActionTypes.LOCATION_OVERRIDE_REFRESH,
            data: data
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationOverrideTable);