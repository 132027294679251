import { Company, Person } from "redux/users-table/usersTable.types";

export enum UserActionTypes {
    Login = "LOGIN_START",
    LoginSuccess = "LOGIN_SUCCESS",
    LoginError = "LOGIN_FAILED",
    LogoutUser = "LOGOUT_START",
    LogoutUserSuccess = "LOGOUT_SUCCESS",
    LogoutUserError = "LOGOUT_FAILED",
    SelectTabFromNavBar = "SELECT_TAB_FROM_NAVBAR",
    SelectRowsInContentList = "SELECT_ROWS_IN_CONTENT_LIST",
    SelectRowsInWaitingList = "SELECT_ROWS_IN_WAITING_LIST"
}

export interface LoginState {
    email: string;
    password?: string;
}

export interface UserState {
    awaitingAuthentication: boolean;
    authenticated: boolean;
    registeredUser: boolean;
    currentUser: User;
    selectedTabFromNavigation: any;
    contentListRowsSelected: number;
    waitingListRowsSelected: number;
}

export interface User {
    id: number;
    email: string;
    token: string;
    firstName: string;
    lastName: string;
    username?:string;
    role?: string;
    subscriptions?: any;
    organizationName?: any;
    company?: Company;
    person?: Person;
    preferredCurrency?: string;
    incomePercentage: number;
    logInTime?: Date;
    logInMaxAge?: number;
}
export interface LoadedState
{
    isloaded:false;
}