import { AdminStoreState } from "redux/root-reducer";
import { createSelector } from "reselect";

const editContentStates = (state: AdminStoreState) => state.editContent;

export const getSelectedImage = createSelector(
    [editContentStates],
    (editContent) => editContent.selectedImage
);

export const getSelectedImages = createSelector(
    [editContentStates],
    (editContent) => editContent.selectedImages
);

export const getMultiselectImages = createSelector(
    [editContentStates],
    (editContent) => editContent.multiselectImages
);

export const getDownloadingContentIds = createSelector(
    [editContentStates],
    (editContent) => editContent.downloadingContentIds
);

export const getDownloadedContentIds = createSelector(
    [editContentStates],
    (editContent) => editContent.downloadedContentIds
);

export const getImageAngles = createSelector(
    [editContentStates],
    (editContent) => editContent.imageAngles
);