import React, {useState} from "react";
import {IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles} from "@material-ui/core";
import {CHANGE_PASSWORD} from './queries';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ChangeUserPassword, dialogStyles,styles } from './usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import { TUserTableReducerActions, IChangeUserPassword } from '../../redux/users-table/usersTable.actions';
import { UsersTableActionTypes, UpdateUserPassword } from '../../redux/users-table/usersTable.types'
import * as Yup from 'yup';
import {PASSWORD_REGEX} from "shared"
import {useTranslation} from "react-i18next";
import { useFormik } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import './usersAdminTable.styles.scss'

const ChangePassword: React.FC<ChangeUserPassword> = ({...props}) => {
    const {classes, email, changePasswordAction } = props;
    const [response, setResponseState] = useState("");
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        email: props.email,
    })
    const {t, i18n} = useTranslation();
    const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);
    const updatePassword = (): void => {
        const {password, oldPassword, confirmPassword} = values;
        changePasswordMutation({
            variables: {
                email: state.email, password:password, oldPassword: oldPassword
            }
        }).then((result: any) => {
            changePasswordAction({email: state.email, password:password, oldPassword: oldPassword });
            handleCloseRegister();
        }).catch((error: any) => {
            setResponseState(error.message.split(":")[1]);
        });
    };
    const validationSchema = Yup.object({
        password: Yup.string().required("Password is required.").matches(
            PASSWORD_REGEX,
            "Field required at least 8 Characters, one Uppercase letter, one Number and one special case Character"),
        oldPassword: Yup.string().required('Password is required').matches(
            PASSWORD_REGEX,
            "Field required at least 8 Characters, one Uppercase letter, one Number and one special case Character"),
        confirmPassword: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  "Both password need to be the same"
                )
              })
    });

    const {handleSubmit,handleChange,values, errors} = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
            oldPassword: ""
        }, 
        validationSchema,
        onSubmit(values) {
            const {password, confirmPassword,oldPassword} = values;

            if(password === confirmPassword) {
                updatePassword();
            }
        }
    })

    const changePasswordModal = () => {
        setOpen(true);
    }

    const handleCloseRegister = () => {
        setOpen(false);
    }

    
    return (
      <React.Fragment>
        <Tooltip title={"Change Password "}>
          <div onClick={changePasswordModal}>
            Change Password
          </div>
        </Tooltip>
          <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleCloseRegister} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                        <IconButton className='close-button close-button' aria-label='google' onClick={handleCloseRegister}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes}/>
                            </IconButton>
                            
                            <div className='add-user-container'>
                                <h1 className='title'>Change Password</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <TextField type='password' autoComplete='off' name="oldPassword" value={values.oldPassword} placeholder='OldPassword' onChange={handleChange}  error={errors.oldPassword === ""} 
                                    helperText={errors.oldPassword ? errors.oldPassword : null} 
                                    InputLabelProps={{shrink: false}}
                                    FormHelperTextProps={{className: classes.error}}/>
                                    <TextField type='password' autoComplete='off' name="password" value={values.password} placeholder='New Password' onChange={handleChange} error={errors.password === ""} 
                                    helperText={errors.password ? errors.password : null} 
                                    InputLabelProps={{shrink: false}}
                                    FormHelperTextProps={{className: classes.error}}/>
                                     <TextField type='password' autoComplete='off' name="confirmPassword" value={values.confirmPassword} placeholder='Confirm Password' onChange={handleChange} error={errors.confirmPassword === ""} 
                                    helperText={errors.confirmPassword ? errors.confirmPassword : null} 
                                    InputLabelProps={{shrink: false}}
                                    FormHelperTextProps={{className: classes.error}}/>
                                    <Button type='submit' className='submit-button' variant='contained'>Update</Button>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
      </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<TUserTableReducerActions>) => {
    return {
        changePasswordAction: (data: UpdateUserPassword) => { dispatch<IChangeUserPassword>({type: UsersTableActionTypes.CHANGE_PASSWORD, data: data})}
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ChangePassword));