import gql from "graphql-tag";

export const ADD_LOCATION_OVERRIDE = gql`
  mutation addNewLocationOverrideMutation(
    $licensePlanType: String!
    $countryName: String!
    $percentageFactor: Float!
    $countryCode: String!
  ) {
    addNewLocationOverride(
      licensePlanType: $licensePlanType
      countryName: $countryName
      percentageFactor: $percentageFactor
      countryCode: $countryCode
    ) {
      id
    }
  }
`;

export const GET_ALL_OVERRIDES = gql`
  query getAllOverridesQuery {
    getAllLocationOverrides {
      country {
        country
      }
      id
      percentageFactor
      licensingPlanType
    }
  }
`;

export const DELETE_LOCATION_OVERRIDE = gql`
  mutation deleteLocationOverrideMutation($id: Int!) {
    deleteLocationOverride(id: $id)
  }
`;

export const EDIT_LOCATION_OVERRIDE = gql`
  mutation editLocationOverrideMutation(
    $id: Int!
    $licensePlanType: String!
    $percentageFactor: Float!
  ) {
    editLocationOverride(
      id: $id
      licensePlanType: $licensePlanType
      percentageFactor: $percentageFactor
    ) {
      id
      licensingPlanType
    }
  }
`;

export const ADD_NEW_MW_USER_COMMISSION = gql`
  mutation addNewCommission($percentage: Float!, $amount: Float!) {
    addNewCommission(percentage: $percentage, amount: $amount)
  }
`;

export const GET_ALL_MW_USER_COMMISSIONS = gql`
  query getAllCommission($pageNumber: Int!, $contentPerPage: Int!) {
    getAllCommission(pageNumber: $pageNumber, contentPerPage: $contentPerPage) {
      total
      commissions {
        id
        percentage
        amount
        hurdleLevel
        createdAt
      }
    }
  }
`;

export const UPDATE_MW_USER_COMMISSION = gql`
  mutation updateCommission($id: Int!, $percentage: Float!, $amount: Float!) {
    updateCommission(id: $id, percentage: $percentage, amount: $amount)
  }
`;

export const UPDATE_WI_USER_COMMISSION = gql`
  mutation updateWIUsersCommissions(
    $userId: Int
    $percentage: Float!
    $universal: Boolean!
  ) {
    updateWIUsersCommissions(
      userId: $userId
      percentage: $percentage
      universal: $universal
    )
  }
`;

export const CURRENT_USER = gql`
    query getCurrentUser {
        currentUser {
            id
            email
            role
            defaultCommission
            incomePercentage
        }
    }
`;
