import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_EDITORIAL_RATE } from './queries';
import { styles, UpdateEditorialRatesProps } from './LicenseAdminTable.types';
import { dialogStyles, defaultToolbarStyles } from '../users-table/usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SingleContentTypes, EditorialSizes } from './LicenseAdminTable.types';


const UpdateEditorialRateModal: React.FC<UpdateEditorialRatesProps> = ({ ...props }) => {
    const { id, refetch, data, classes } = props;

    const currentRate = data[2];
    const currentContentType = data[1];
    const currentSize = data[3];

    const [response, setResponseState] = useState("");
    const [open, setOpen] = useState(false);
    const [contentType, setContentType] = useState(data[1]);
    const [size, setSize] = useState(data[3]);
    const [initialRate, setInitialRate] = useState(currentRate.slice(1))

    const validationSchema = Yup.object({
        rate: Yup.number().required("Add an number.")
        .test(
            'Is positive?',
            'The price must be greater than 0!',
            (value: any) => value > 0
        ),
    });

    const [updateEditorialRateMutation] = useMutation(UPDATE_EDITORIAL_RATE);
    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            rate: initialRate,
            contentType: contentType,
            size: size
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { rate } = values;
            updateEditorialRateMutation({
                variables: {
                    id: id,
                    contentType: contentType.toLowerCase(),
                    rate: parseFloat(values.rate),
                    size: size.toLowerCase()
                }
            }).then((result: any) => {
                if (result.data.updateEditorialRate) {
                    refetch();
                    handleClose();
                }
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        }
    })
    const handleClose = () => {

        setOpen(false);
    }

    const updateUserModal = () => {
        setOpen(true);
        setContentType(currentContentType);
        setSize(currentSize);
        setInitialRate(currentRate.slice(1))
    }

    const handleSelectPeriod = (e: any) => {
        if (e.target.name === "contentType") {
            setContentType(e.target.value)
        }
        if (e.target.name === "size") {
            setSize(e.target.value)
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={"Update"}>
                <IconButton onClick={updateUserModal}>
                    <CreateIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Update plan</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <FormControl>
                                                <InputLabel >Content type</InputLabel>
                                                <Select name="contentType" onChange={handleSelectPeriod} value={contentType.toLowerCase()} defaultValue={contentType.toLowerCase()}>
                                                    <MenuItem value={SingleContentTypes.Image}>{SingleContentTypes.Image}</MenuItem>
                                                    <MenuItem disabled value={SingleContentTypes.Video}>{SingleContentTypes.Video}</MenuItem>
                                                    <MenuItem disabled value={SingleContentTypes.Audio}>{SingleContentTypes.Audio}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel >Size</InputLabel>
                                                <Select name="size" onChange={handleSelectPeriod} value={size.toLowerCase()} defaultValue={size.toLowerCase()}>
                                                    <MenuItem value={EditorialSizes.Small}>{EditorialSizes.Small}</MenuItem>
                                                    <MenuItem value={EditorialSizes.Medium}>{EditorialSizes.Medium}</MenuItem>
                                                    <MenuItem value={EditorialSizes.Large}>{EditorialSizes.Large}</MenuItem>
                                                    <MenuItem value={EditorialSizes.OnlineOnly}>{EditorialSizes.OnlineOnly}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField 
                                                autoComplete="off" 
                                                type="decimal" 
                                                className='select-label' 
                                                name="rate" 
                                                onChange={handleChange} 
                                                label="Rate" 
                                                variant="standard" 
                                                defaultValue={initialRate} 
                                                error={errors.rate === ""}
                                                helperText={errors.rate ? errors.rate : null}
                                                FormHelperTextProps={{ className: classes.error }}/>
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Update plan</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}



export default (withStyles(styles)(UpdateEditorialRateModal));