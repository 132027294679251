import React, { useEffect, useRef, useState } from 'react';
import { GET_CHANNELS } from '../channels-table/queries';
import EditContentMetadata from '../batch-upload/EditContentMetadata.component';
import { EditWaitingListProps, RouteParamsContentTypes, TIME_BEFORE_REDIRECT } from './waitingList.types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { Article, AudioFile, ContentSet, IndividualImageCategory, RadioButtonGroup, UploadedFileResponse } from "../batch-upload/ContentUpload.types";
import {
    IAddAudioFileResponse,
    IApproveSingleContent,
    IDeleteAudioFile, IPendingSingleContent,
    IRejectSingleContent,
    IResetContentUpload,
    IResetCopiedContents,
    IResetMovedContents,
    IResetMultiselection,
    IToggleRadioButton,
    IUpdateSet,
    IUploadArticle,
    IUploadFiles,
    IUploadSet,
    TContentUploadActions
} from "../../redux/content-upload/content-upload.actions";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { Dispatch } from 'redux';
import {
    APPROVE_ARTICLE,
    APPROVE_CONTENT,
    PENDING_CONTENT,
    GET_ARTICLE_CONTENT_BY_ID,
    GET_AUDIO_CONTENT_BY_ID,
    GET_CONTENT_BY_ID,
    GET_CONTENT_SET_CONTENT_BY_ID,
    REJECT_ARTICLE,
    REJECT_CONTENT,
    DELETE_ARTICLE, REJECT_CONTENT_SET
} from './queries';
import SyncLoader from "react-spinners/SyncLoader";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { SnackbarComponent } from 'shared';
import './editWaitingList.styles.scss';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import {
    selectArticles,
    selectAudioFileResponse,
    selectAudioFiles,
    selectContentSets,
    selectCopiedContents,
    selectIndividualImagesCategory,
    selectMovedContents,
    selectMultipleCopiedContents,
    selectMultipleMovedContents,
    selectRadioButtonValue
} from '../../redux/content-upload/content-upload.selectors';
import { AdminStoreState } from '../../redux/root-reducer';
import ArticleTabsComponent from '../batch-upload/ArticleTabsComponent';
import { selectCurrentUser, selectCurrentUserId } from '../../redux/admin/admin.selectors';
import { uploadToBucket } from '../batch-upload/uploadToBucketRequest';
import {
    UPDATE_ARTICLE,
    EDIT_THREADS_IN_CONTENT_SET,
    EDIT_THREADS_IN_CONTENT,
    MOVE_CONTENT_TO_SET,
    UPDATE_CONTENT_SET,
    MOVE_MULTIPLE_CONTENT_TO_SET,
    COPY_CONTENT_TO_SET, COPY_MULTIPLE_CONTENT_TO_SET
} from '../content-list/queries';
import {
    CopiedContentToSets, CopiedMultipleContents,
    MovedContentToSets,
    MovedMultipleContents
} from '../batch-upload/EditContentMetadata.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { IResetSnackbar, ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { IToggleResetFilter, TTableFiltersReducerActions } from '../../redux/table-filter/table-filter.actions';
import { TableFilterActions } from '../../redux/table-filter/table-filter.types';
import { selectContentUserId } from '../../redux/waiting-list/waitinglist.selectors';
import { ADD_ARTICLE, DELETE_CONTENT, ROTATE_IMAGE } from '../batch-upload/queries';
import {config as conf} from "../../config";
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { getImageAngles, getSelectedImage, getSelectedImages } from '../../redux/EditContent/editContent.selectors';
import { useLocation } from 'react-router-dom';
import {ToolbarActions} from "../toolbar/toolbar.types";
import axios from 'axios';
import fileDownload from 'js-file-download';
import {  IMultiselectImages, IResetImageAngle, ISelectedImage, ISelectedImages, TEditContentReducerActions } from '../../redux/EditContent/editContent.actions';
import { EditContentActionTypes, ImageAngle } from '../../redux/EditContent/editContent.types';
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { User } from '../../redux/admin/admin.types';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress color="inherit" variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="common.white"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

const EditWaitingList: React.FC<EditWaitingListProps> = ({ ...props }) => {
    const { routeParams, contentSets, selectedRadioButton, articles, audioFiles, userId,
        audioFilesResponses, individualImageCategories, movedContents, copiedContents, snackbar, contentUserId, userSelectedImage, userSelectedImages, imageAngles, currentUser,
        uploadFilesAction, updateSetAction, resetReduxState, resetSnackbar, broadcastSnackbarAction,
        returnToWaitingList, rejectSingleFileAction, pendingSingleFileAction, approveSingleFileAction, addAudioFileResponse,
        uploadArticleAction, toggleRadioButtonAction, uploadSetAction, resetMovedContentsAction, resetCopiedContentsAction,
        movedMupltipleContents, copiedMultipleContents, resetMultiselectionAction, resetTableFilterAction, setMultiselectImages, setUserSelectedImage, resetImageAngles,
        setUserSelectedImages, } = props;

    const [open, setOpen] = React.useState(false);
    const [downloadFileName, setDownloadFileName] = useState<string>('WI_Images');
    const [processingForDownload, setProcessingForDownload] = useState<boolean>(false);
    const [downloadUrl, setDownloadUrl] = useState<any>();
    const [dataQuery, setDataQuery] = useState(GET_CHANNELS);
    const [fetchData, { data, loading, error }] = useLazyQuery(GET_CHANNELS, { fetchPolicy: 'no-cache' });
    const [getContentById, { data: contentData, loading: contentLoading, error: contentError }] = useLazyQuery(GET_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [getContentSetById, { data: setData, loading: setLoading, error: setError }] = useLazyQuery(GET_CONTENT_SET_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [getArticleById, { data: articleData, loading: articleLoading, error: articleError }] = useLazyQuery(GET_ARTICLE_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [getAudioById, { data: audioData, loading: audioLoading, error: audioError }] = useLazyQuery(GET_AUDIO_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [type] = useState(routeParams.type);
    const [articleId, setArticleId] = useState(routeParams.type === RouteParamsContentTypes.ARTICLE ? parseInt(routeParams.id) : 0);
    const [contentSetId, setContentSetId] = useState(routeParams.type === RouteParamsContentTypes.CONTENT_SET ? parseInt(routeParams.id) : 0);
    const [contentId, setContentId] = useState(routeParams.type === RouteParamsContentTypes.IMAGE ? parseInt(routeParams.id) : 0);
    const [channels, setChannels] = useState([]);
    const [audioId, setAudioId] = useState(0);
    const [audioObject, setAudioObject] = useState<any>({});
    const [newArticle, setNewArticle] = useState(false);
    const [redirectToMetadataStep, setRedirectToMetadataStep] = useState(false);
    const [activeMetadataStep, setActiveMetadataStep] = useState(true);
    const [enableMultiselect, setEnableMultiselect] = useState(false);
    const [duplicateFileIds, setDuplicateFileIds] = useState([]);
    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [toolbarAction, setToolbarAction] = useState(ToolbarActions.NO_ACTION);
    const location = useLocation();
    const [rejectContentSetMutation] = useMutation(REJECT_CONTENT_SET);

    const url = `${conf.REACT_APP_ADMIN_SERVER_HOST}/graphql`;
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'content-type': 'multipart/form-data'
        }
    }

    const [moveToContentSetMutation] = useMutation(MOVE_CONTENT_TO_SET);
    const [copyToContentSetMutation] = useMutation(COPY_CONTENT_TO_SET);

    const moveToContentSet = async (currentSetId: number, movedContentId: number, newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        moveToContentSetMutation({ variables: { currentSetId, movedContentId, newSetId, newSetTitle, threadIds } })
            .then((result: any) => {
                if (result.data.moveContentToSet) {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.success,
                        message: "Content was successfully approved. Redirecting to waiting list..."
                    });
                    setTimeout(() => {
                        resetReduxState();
                        resetSnackbar();
                        returnToWaitingList(parseInt(routeParams.tabIndex));
                    }, TIME_BEFORE_REDIRECT);
                    return Promise.resolve(result.data.moveContentToSet);
                }
            }).catch((error: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when updating " + type
                });
            })
    }

    const copyToContentSet = async (currentSetId: number, copyContentId: number, newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        copyToContentSetMutation({ variables: { currentSetId, copyContentId, newSetId, newSetTitle, threadIds } })
            .then((result: any) => {
                if (result.data.copyContentToSet) {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.success,
                        message: "Content was successfully approved. Redirecting to waiting list..."
                    });
                    setTimeout(() => {
                        resetReduxState();
                        resetSnackbar();
                        returnToWaitingList(parseInt(routeParams.tabIndex));
                    }, TIME_BEFORE_REDIRECT);
                    return Promise.resolve(result.data.copyContentToSet);
                }
            }).catch((error: any) => {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when updating " + type
            });
        })
    }

    const [moveMultipleContentToSetMutation] = useMutation(MOVE_MULTIPLE_CONTENT_TO_SET);
    const [copyMultipleContentToSetMutation] = useMutation(COPY_MULTIPLE_CONTENT_TO_SET);

    const moveMultipleContentToSet = async (currentSetId: number, movedContentIds: number[], newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        moveMultipleContentToSetMutation({ variables: { currentSetId, movedContentIds, newSetId, newSetTitle, threadIds } })
            .then((results: any) => {
                //update set
                resetMovedContentsAction();
                const set = contentSets[0];
                updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
            }).catch((error: any) => {
                resetMovedContentsAction();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when moving content to new set. Try again."
                });
            });
    }

    const copyMultipleContentToSet = async (currentSetId: number, copyContentIds: number[], newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        copyMultipleContentToSetMutation({ variables: { currentSetId, copyContentIds, newSetId, newSetTitle, threadIds } })
            .then((results: any) => {
                //update set
                resetCopiedContentsAction();
                const set = contentSets[0];
                updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
            }).catch((error: any) => {
                const duplicates = error.graphQLErrors[0].extensions.duplicateCopyContentIds;
                // resetCopiedContentsAction();

                if(duplicates.length > 0){
                    const duplicateIndexes = duplicates.map((item: {[key: string]: string})=> Number(Object.keys(item)[0])+1);
                    const duplicateValues = duplicates.map((item: {[key: string]: string})=> Object.values(item)[0]);

                    setDuplicateFileIds(duplicateValues);

                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        // message: `From your selection order ${duplicateIndexes.toString()} content(s) is/are already in the selected content set.`
                        message: "Yellow marked items already exist in destination content set."
                    });
                }else{
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: "Something went wrong when copying " + type
                    });
                }
        });
    }

    const getContentToBeMovedIds = (): number[] => {
        const contentIds = movedContents.map((content: MovedContentToSets) => {
            return content.contentId;
        })
        return contentIds;
    }

    const getContentToBeCopiedIds = (): number[] => {
        const contentIds = copiedContents.map((content: CopiedContentToSets) => {
            return content.contentId;
        })
        return contentIds;
    }

    const moveSingleContent = async (threadIds: number[]) => {
        const movedContentsPromises = movedContents.map((content: MovedContentToSets) => {
            if (content.movedSet) {
                return moveToContentSet(contentSetId, content.contentId, content.movedSet.contentSetId);
            } else if (content.newSetTitle) {
                return moveToContentSet(contentSetId, content.contentId, undefined, content.newSetTitle, threadIds);
            }
        })

        await Promise.all(movedContentsPromises)
            .then((result: any[]) => {
                if (result.every((res: any) => res === true)) {
                    resetMovedContentsAction();
                    const set = contentSets[0];
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
            }).catch((error: any) => {
                resetMovedContentsAction();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when moving content to new sets. Try again."
                });
            })
    }
    const moveMultipleContent = (threadIds: number[]) => {
        const contentIds = getContentToBeMovedIds();
        const newSetTitle = movedContents.find((content: MovedContentToSets) => { return content.newSetTitle! !== '' });
        const movedSet = movedContents.find((content: MovedContentToSets) => { return content.movedSet });

        if (newSetTitle?.newSetTitle) {
            moveMultipleContentToSet(contentSetId, contentIds, undefined, newSetTitle?.newSetTitle, threadIds);
        }
        else if (movedSet?.movedSet) {
            moveMultipleContentToSet(contentSetId, contentIds, movedSet.movedSet!.contentSetId, undefined, [movedSet.movedSet!.threadId]);
        }
    }
    const copySingleContent = async (threadIds: number[]) => {
        const copiedContentsPromises = copiedContents.map((content: CopiedContentToSets) => {
            if (content.copiedSet) {
                return copyToContentSet(contentSetId, content.contentId, content.copiedSet.contentSetId);
            }
        })

        await Promise.all(copiedContentsPromises)
            .then((result: any[]) => {
                if (result.every((res: any) => res === true)) {
                    resetMovedContentsAction();
                    const set = contentSets[0];
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
            }).catch((error: any) => {
                resetMovedContentsAction();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when moving content to new sets. Try again."
                });
            })
    }



    const copyMultipleContent = (threadIds: number[]) => {
        const contentIds = getContentToBeCopiedIds();
        const newSetTitle = copiedContents.find((content: CopiedContentToSets) => { return content.newSetTitle! !== '' });
        const copiedSet = copiedContents.find((content: CopiedContentToSets) => { return content.copiedSet });

        if (newSetTitle?.newSetTitle) {
            copyMultipleContentToSet(contentSetId, contentIds, undefined, newSetTitle?.newSetTitle, threadIds);
        }
        else if (copiedSet?.copiedSet) {
            copyMultipleContentToSet(contentSetId, contentIds, copiedSet.copiedSet!.contentSetId, undefined, [copiedSet.copiedSet!.threadId]);
        }
    }

    const [rotateImageMutation] = useMutation(ROTATE_IMAGE);
    const rotateImage = (contents: ImageAngle[]): void => {
      rotateImageMutation({
        variables: {
            contents
        },
      });
    };

    const [updateContentSetMutation] = useMutation(UPDATE_CONTENT_SET, {
        onCompleted(data) {
            if (data.updateContentSet) {
                if(imageAngles.length > 0)
                {
                    rotateImage(imageAngles);
                    resetImageAngles();
                }
                resetImageAngles();
                if (type === RouteParamsContentTypes.CONTENT_SET && newArticle) {
                    approveArticle(articleId);
                }

                if (type === RouteParamsContentTypes.ARTICLE && articles.length > 0) {
                    approveArticle(articleId);
                } else {
                    if(isDraft){
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.success,
                            message: "Content was successfully Saved. Redirecting to waiting list..."
                        });
                    }else {
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.success,
                            message: "Content was successfully approved. Redirecting to waiting list..."
                        });
                    }
                    setTimeout(() => {
                        resetReduxState();
                        resetSnackbar();
                        returnToWaitingList(parseInt(routeParams.tabIndex));
                    }, TIME_BEFORE_REDIRECT);
                }
            }
        }, onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when updating content set"
            });
        }
    });
    const updateContentSet = (setId: number, setTitle: string, coverId: number) => {
        updateContentSetMutation({ variables: { setId, setName: setTitle, coverId } })
    }

    const [addArticleMutation] = useMutation(ADD_ARTICLE, {
        onCompleted(data) {
            if (data.addArticle) {
                setArticleId(data.addArticle);
            }
        },
        onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to add new article to content set"
            });
        }
    });

    const addArticle = (contentSetId: number, title: string, text: string, audioId?: any) => {
        addArticleMutation({
            variables: {
                contentSetId, title, text,
                audioId: audioId ? audioId : null
            }
        })
    }

    const [updateArticleMutation] = useMutation(UPDATE_ARTICLE, {
        onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to update article. Try again?"
            });
        }
    });

    const updateArticle = (articleId: number, title: string, text: string, audioId?: any) => {
        updateArticleMutation({
            variables: {
                articleId, title, description: text,
                audioId: audioId ? audioId : null
            }
        })
    }

    const [approveArticleMutation] = useMutation(APPROVE_ARTICLE);
    const approveArticle = (articleId: number) => {
        approveArticleMutation({
            variables: { articleId: articleId }
        }).then((result: any) => {
            if (result.data.approveAndPublishArticle) {
                if(imageAngles.length > 0)
                {
                    rotateImage(imageAngles);
                    resetImageAngles();
                }
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Content was successfully approved. Redirecting to waiting list..."
                });
                setTimeout(() => {
                    resetReduxState();
                    resetSnackbar();
                    returnToWaitingList(parseInt(routeParams.tabIndex));
                }, TIME_BEFORE_REDIRECT);
            }
        }).catch((error: any) => {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: error.graphQLErrors[0].message
            });
        })
    }

    const [rejectArticleMutation] = useMutation(REJECT_ARTICLE);
    const rejectArticle = (articleId: number): Promise<any> => {
        return rejectArticleMutation({ variables: { articleId: articleId } })
            .then((result: any) => {
                if (result.data.rejectArticle) {
                    return Promise.resolve(result);
                }
            }).catch((error: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when rejecting article"
                });
                return Promise.reject(error);
            })
    }

    const [deleteFileMutation] = useMutation(DELETE_CONTENT);
    const deleteFile = (id: number) => {
        return deleteFileMutation({
            variables: { id: id }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        })
    }

    const [deleteArticleMutation] = useMutation(DELETE_ARTICLE);
    const deleteArticle = (articleId: number) => {
        return deleteArticleMutation({ variables: { articleId } })
            .then((result: any) => {
                return Promise.resolve(result.data.deleteArticle);
            }).catch((error: any) => {
                return Promise.reject(error);
            })
    }

    const [approveContentMutation] = useMutation(APPROVE_CONTENT);
    const approveContent = (id: number) => {
        return approveContentMutation({ variables: { contentId: id } })
            .then((response: any) => {
                if (response.data.approveContent && type === RouteParamsContentTypes.IMAGE) {
                    if(imageAngles.length > 0)
                    {
                        rotateImage(imageAngles);
                        resetImageAngles();
                    }
                    resetImageAngles();
                    if (type === RouteParamsContentTypes.IMAGE) {
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.success,
                            message: "Content was successfully approved. Redirecting to waiting list..."
                        });
                        setTimeout(() => {
                            resetReduxState();
                            resetSnackbar();
                            returnToWaitingList(parseInt(routeParams.tabIndex));
                        }, TIME_BEFORE_REDIRECT);
                    } else {
                        return Promise.resolve(response.data.approveContent);
                    }
                }
            }).catch((error: any) => {
                if (type === RouteParamsContentTypes.IMAGE) {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: error.graphQLErrors[0].message
                    });
                }
                else {
                    return Promise.reject(error);
                }
            })
    }
    const [pendingContentMutation] = useMutation(PENDING_CONTENT);
    const setContentToPending = (id: number) => {
        return pendingContentMutation({ variables: { contentId: id } })
            .then((response: any) => {
                // if (response.data.approveContent && type === RouteParamsContentTypes.IMAGE) {
                //     if (type === RouteParamsContentTypes.IMAGE) {
                //         broadcastSnackbarAction({
                //             severity: SeveritySnackbarEnum.success,
                //             message: "Content was successfully approved. Redirecting to waiting list..."
                //         });
                //         setTimeout(() => {
                //             resetReduxState();
                //             resetSnackbar();
                //             returnToWaitingList(parseInt(routeParams.tabIndex));
                //         }, TIME_BEFORE_REDIRECT);
                //     } else {
                //         return Promise.resolve(response.data.approveContent);
                //     }
                // }
            }).catch((error: any) => {
                if (type === RouteParamsContentTypes.IMAGE) {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: error.graphQLErrors[0].message
                    });
                }
                else {
                    return Promise.reject(error);
                }
            })
    }
    const [rejectContentMutation] = useMutation(REJECT_CONTENT);
    const rejectContent = (id: number): Promise<any> => {
        return rejectContentMutation({ variables: { contentId: id } })
            .then((response: any) => {
                if (response.data.rejectContent && type === RouteParamsContentTypes.IMAGE) {
                    if (type === RouteParamsContentTypes.IMAGE) {
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.success,
                            message: "Content was successfully rejected. Redirecting to waiting list..."
                        });
                        setTimeout(() => {
                            resetReduxState();
                            resetSnackbar();
                            returnToWaitingList(parseInt(routeParams.tabIndex));
                        }, TIME_BEFORE_REDIRECT);
                    } else {
                        return Promise.resolve(response.data.rejectContent);
                    }
                }
            }).catch((error: any) => {
                if (type === RouteParamsContentTypes.IMAGE) {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: error.graphQLErrors[0].message
                    });
                }
                else {
                    return Promise.reject(error);
                }
            })
    }

    // const [rejectContentsMutation] = useMutation(REJECT_CONTENTS);
    // const rejectContents = (ids: number[]): Promise<any> => {
    //     return rejectContentsMutation({ variables: { contentIds: ids } })
    //         .then((response: any) => {
    //             if (response.data.rejectContent && type === RouteParamsContentTypes.IMAGE) {
    //                 if (type === RouteParamsContentTypes.IMAGE) {
    //                     broadcastSnackbarAction({
    //                         severity: SeveritySnackbarEnum.success,
    //                         message: "Content was successfully rejected. Redirecting to waiting list..."
    //                     });
    //                     setTimeout(() => {
    //                         resetReduxState();
    //                         resetSnackbar();
    //                         returnToWaitingList(parseInt(routeParams.tabIndex));
    //                     }, TIME_BEFORE_REDIRECT);
    //                 } else {
    //                     return Promise.resolve(response.data.rejectContent);
    //                 }
    //             }
    //         }).catch((error: any) => {
    //             if (type === RouteParamsContentTypes.IMAGE) {
    //                 broadcastSnackbarAction({
    //                     severity: SeveritySnackbarEnum.error,
    //                     message: error.graphQLErrors[0].message
    //                 });
    //             }
    //             else {
    //                 return Promise.reject(error);
    //             }
    //         })
    // }

    const [editThreadsInContentSetMutation] = useMutation(EDIT_THREADS_IN_CONTENT_SET, {
        async onCompleted(data) {
            const set = contentSets[0];

            const approvedList = [];
            const rejectedList = [];

            const allPromises = set.files.map(async (file: UploadedFileResponse) => {
                if(isDraft){
                    if(file.isApproved === false ){
                        rejectedList.push(file.id);
                        return await rejectContent(file.id);
                    }
                    if((file.isApproved === undefined && file.status === 'rejected')){
                        approvedList.push(file.id);
                        return await setContentToPending(file.id);
                    }
                }
                else{
                    if(file.isApproved === undefined){
                        approvedList.push(file.id);
                        return await approveContent(file.id);
                    }
                    if(file.isApproved === false){
                        rejectedList.push(file.id);
                        return await rejectContent(file.id);
                    }
                }
            })

            await Promise.all(allPromises).then((result: any) => {
                if (movedContents.length > 0) {
                    const threadIds = set.selectedThreadIds ? set.selectedThreadIds : [];
                    if (!enableMultiselect) {
                        moveSingleContent(threadIds);
                    }
                    else {
                        moveMultipleContent(threadIds);
                    }
                } else if(approvedList.length > 0 || rejectedList.length > 0){
                    //update set
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
                else if (copiedContents.length > 0){
                    const threadIds = set.selectedThreadIds ? set.selectedThreadIds : [];

                    if (!enableMultiselect) {
                        copySingleContent(threadIds);
                    }
                    else {
                        copyMultipleContent(threadIds);
                    }

                } else {
                    //update set
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
            })
                .catch((error) => {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: error.graphQLErrors[0].message
                    });
                })
        }, onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "You must select content navigation to approve content"
            });
        }
    });
    const editThreadsInContentSet = (threadIds: number[], contentSetId: number) => {
        editThreadsInContentSetMutation({
            variables: { threadIds: threadIds, contentSetId: contentSetId }
        })
    }

    const [editThreadsInContentMutation] = useMutation(EDIT_THREADS_IN_CONTENT);
    const editThreadsInContent = (threadIds: number[], contentSetId: number) => {
        return editThreadsInContentMutation({
            variables: { threadIds: threadIds, contentId: contentSetId }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };
    
    const startDownload = (fileName: string)=>{
        setProcessingForDownload(true);
        axios({
            url: downloadUrl,
            method: "GET",
            responseType: 'blob',
            params: {
                "token": currentUser?.token
            },
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setProcessingForDownload(false)
        }).catch(e =>{
            setProcessingForDownload(false);
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to download set"
            });
        });
    }

    const handleDownload = (e: any)=>{  
        e.preventDefault();

        if(enableMultiselect && userSelectedImages.length === 0){
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: `Please select an image to download`
            });
        }
        else{
            handleClickOpen();
        }
    }

    useEffect(()=>{
        return ()=>{
            setUserSelectedImage(undefined)
            setUserSelectedImages([])
            resetImageAngles()
        }
    },[])

    useEffect(()=>{
        if(enableMultiselect && userSelectedImages.length > 0){
            setDownloadUrl(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${userSelectedImages}]`);
        }
        else if(!enableMultiselect){
            if(routeParams.type === RouteParamsContentTypes.CONTENT_SET || routeParams.type === RouteParamsContentTypes.ARTICLE){
                const allContentIds = setData?.getContentSetContentById?.content.map((item: any)=>item?.id);
                setDownloadUrl(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${allContentIds}]`);
            }
            if(routeParams.type === RouteParamsContentTypes.IMAGE){
                setDownloadUrl(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${contentId}]`);
            }
        }
    },[userSelectedImages,userSelectedImage])

    useEffect(() => {
        if (snackbar.severity && snackbar.message) {
            setContentUploadSnackbar(true);
        }
    }, [snackbar]);

    useEffect(() => {
        if (error || contentError || setError || articleError || audioError) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: `Error while fetching: ${error}`
            });
            //more well written error here
        }
    }, [error, contentError, setError, articleError, audioError])

    useEffect(() => {
        if (data && data.getAllChannels) {
            setChannels(data.getAllChannels);
            if (type === RouteParamsContentTypes.IMAGE) {
                setDataQuery(GET_CONTENT_BY_ID);
            } else if (type === RouteParamsContentTypes.CONTENT_SET) {
                setDataQuery(GET_CONTENT_SET_CONTENT_BY_ID);
            } else if (type === RouteParamsContentTypes.ARTICLE) {
                setDataQuery(GET_ARTICLE_CONTENT_BY_ID);
            }
        }
    }, [data])

    useEffect(() => {
        if (contentData && contentData.getContentById) {
            setContentId(contentData.getContentById.id);
            const file: UploadedFileResponse = {
                id: contentData.getContentById.id,
                filename: '',
                mimetype: '',
                encoding: '',
                key: contentData.getContentById.bucketKey,
                url: contentData.getContentById.pathToFileCompressed,
                code: contentData.getContentById.code,
                blobObject: undefined,
                status: contentData.status
            }
            uploadFilesAction([file]);
        }
    }, [contentData])

    useEffect(() => {
        if (setData && setData.getContentSetContentById) {
            const files: UploadedFileResponse[] = setData.getContentSetContentById.content.map((contentFile: any) => {
                return {
                    id: contentFile.id,
                    isApproved: contentFile.status === 'rejected' ? false : undefined,
                    key: contentFile.bucketKey,
                    code: contentFile.code,
                    url: contentFile.pathToFileCompressed,
                    status: contentFile.status
                }
            });

            const coverPhotoObj = setData.getContentSetContentById.content.find(
                (content: UploadedFileResponse) => content.id === setData.getContentSetContentById.contentSet.coverId);

            setContentSetId(setData.getContentSetContentById.contentSet.contentSetId);
            const set: ContentSet = {
                id: setData.getContentSetContentById.contentSet.contentSetId.toString(),
                title: setData.getContentSetContentById.contentSet.title,
                files: files,
                coverPhoto: coverPhotoObj ? {
                    id: coverPhotoObj.id, key: coverPhotoObj.bucketKey,
                    code: coverPhotoObj.code, url: coverPhotoObj.pathToFileCompressed,
                    filename: '', mimetype: '', encoding: '', status: coverPhotoObj.status
                } : undefined,
                selectedThreadIds: setData.getContentSetContentById.contentNavigation.map((nav: any) => { return nav.id })
            };
            if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
                toggleRadioButtonAction(RadioButtonGroup.SET);
            }

            uploadSetAction(set);
        }
    }, [setData])

    useEffect(() => {
        if (articleData && articleData.getArticleById) {
            setContentSetId(articleData.getArticleById.contentSetId);
            let article: Article = {
                contentSetId: articleData.getArticleById.contentSetId.toString(),
                title: articleData.getArticleById.title,
                description: articleData.getArticleById.description,
                audioId: articleData.getArticleById.audioId ? articleData.getArticleById.audioId : null
            }
            uploadArticleAction(article);

            if (article.audioId) {
                setAudioId(article.audioId);
                setDataQuery(GET_AUDIO_CONTENT_BY_ID);
            } else {
                setDataQuery(GET_CONTENT_SET_CONTENT_BY_ID);
            }
        }
    }, [articleData])

    useEffect(() => {
        if (audioData && audioData.getAudioContentById) {
            setAudioObject(audioData.getAudioContentById);
            addAudioFileResponse(audioData.getAudioContentById);
            setDataQuery(GET_CONTENT_SET_CONTENT_BY_ID);
        }
    }, [audioData])

    useEffect(() => {
        if (dataQuery === GET_CHANNELS) {
            fetchData();
        } else if (dataQuery === GET_CONTENT_BY_ID && contentId) {
            getContentById({ variables: { contentId: contentId, approved: false } })
        } else if (dataQuery === GET_CONTENT_SET_CONTENT_BY_ID && contentSetId) {
            getContentSetById({ variables: { contentSetId: contentSetId, approved: false } });
        } else if (dataQuery === GET_ARTICLE_CONTENT_BY_ID && articleId) {
            getArticleById({ variables: { articleId: articleId } });
        } else if (dataQuery === GET_AUDIO_CONTENT_BY_ID) {
            getAudioById({ variables: { audioId: audioId } });
        }
    }, [dataQuery])

    const manageEditSingleContent = async () => {
        const promises = individualImageCategories.map(async (category: IndividualImageCategory) => {
            return await editThreadsInContent(category.selectedThreads, contentId);
        })
        Promise.all(promises).then(async (result: any) => {
            await approveContent(contentId)
        }).catch((error: any) => {
            broadcastSnackbarAction({ severity: SeveritySnackbarEnum.error, message: "You must select content navigation" })
        })
    }

    const manageEditContentSet = async (set: ContentSet | null) => {
        if (set && set.selectedThreadIds && set.coverPhoto) {
            editThreadsInContentSet(set.selectedThreadIds!, contentSetId);
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.warning,
                message: "Make sure you have set cover photo and at least 1 navigation."
            });
        }
    }

    const manageEditArticle = async (set: ContentSet | null, article: Article | null, newAudio: AudioFile | null, existingAudio: any) => {
        let articlePromise: Promise<any>[] = [];

        if (set && set.selectedThreadIds && article && set.coverPhoto) {
            editThreadsInContentSet(set.selectedThreadIds, contentSetId);

            if (newAudio) {
                //if there is a new audioFile for the article
                articlePromise = audioFiles.map(async (audioFile: any) => {
                    return await uploadToBucket(audioFile.fileWithMeta, contentUserId, url, config);
                });
            } else if (existingAudio) {
                //if there is an audio file which already exists in the db
                updateArticle(articleId, article.title, article.description, existingAudio.id);
            } else {
                //if there is no audio file available at all
                if (audioObject.id) {
                    //delete old audio file from db
                    deleteFile(audioObject.id);
                }
                updateArticle(articleId, article.title, article.description);
            }
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Error when processing article. Please refresh the page and try again"
            });
        }

        Promise.all(articlePromise).then((result: UploadedFileResponse[]) => {
            if (result.length !== 0 && article) {
                updateArticle(articleId, article.title, article.description, result[0].id);
            }
        }).catch((error: any) => {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to upload audio file and link it to current article. Try again?"
            });
        })
    }

    const saveChanges = async () => {
        const set = contentSets.length > 0 ? contentSets[0] : null;
        const article = articles.length > 0 ? articles[0] : null;
        const newAudio = audioFiles.length > 0 ? audioFiles[0] : null;
        const existingAudio = audioFilesResponses.length > 0 ? audioFilesResponses[0] : null;

        setIsDraft(true);

        if (type === RouteParamsContentTypes.ARTICLE) {
            if (article) {
                manageEditArticle(set, article, newAudio, existingAudio);
            } else {
                //if article was removed then we delete article
                deleteArticle(articleId)
                    .then((result: any) => {
                        if (result) {
                            manageEditContentSet(set);
                        }
                    }).catch((error: any) => {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.warning,
                        message: `Error when removing article: ${error.message}`
                    });
                });
            }

        } else if (type === RouteParamsContentTypes.CONTENT_SET) {
            if (newArticle && article) {
                addArticle(contentSetId, article.title, article.description, newAudio);
            }
            manageEditContentSet(set);
        }
        else if (type === RouteParamsContentTypes.IMAGE) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Content was successfully saved. Redirecting to waiting list..."
            });

            setTimeout(() => {
                returnToWaitingList(parseInt(routeParams.tabIndex));
            }, TIME_BEFORE_REDIRECT);
        }
    }

    const manageEditedContent = async () => {
        const set = contentSets.length > 0 ? contentSets[0] : null;
        const article = articles.length > 0 ? articles[0] : null;
        const newAudio = audioFiles.length > 0 ? audioFiles[0] : null;
        const existingAudio = audioFilesResponses.length > 0 ? audioFilesResponses[0] : null;

        setIsDraft(false);

        if (type === RouteParamsContentTypes.ARTICLE) {
            if (article) {
                manageEditArticle(set, article, newAudio, existingAudio);
            } else {
                //if article was removed then we delete article
                deleteArticle(articleId)
                    .then((result: any) => {
                        if (result) {
                            manageEditContentSet(set);
                        }
                    }).catch((error: any) => {
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.warning,
                            message: `Error when removing article: ${error.message}`
                        });
                    });
            }

        } else if (type === RouteParamsContentTypes.CONTENT_SET) {
            if (newArticle && article) {
                addArticle(contentSetId, article.title, article.description, newAudio);
            }
            manageEditContentSet(set);
        } else if (type === RouteParamsContentTypes.IMAGE) {
            manageEditSingleContent();
        }
    }

    const addArticleToContentSet = async () => {
        setNewArticle(true);
        setRedirectToMetadataStep(false);
        setActiveMetadataStep(false);
    }

    const rejectEditedContent = async () => {
        if (type === RouteParamsContentTypes.ARTICLE) {
            await rejectArticle(articleId)
                .then((result: any) => {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.success,
                        message: "Content was successfully rejected. Redirecting to waiting list..."
                    });
                    setTimeout(() => {
                        resetReduxState();
                        resetSnackbar();
                        returnToWaitingList(parseInt(routeParams.tabIndex));
                    }, TIME_BEFORE_REDIRECT);
                }).catch((error: any) => {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.success,
                        message: `Error occured when rejecting content. ${error.message}`
                    });
                });
        } else if (type === RouteParamsContentTypes.CONTENT_SET) {
            //content set rejection
            rejectContentSetMutation({ variables: { setId: contentSetId } })
            .then((result: any) => {
                setToolbarAction(ToolbarActions.REJECT);

                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Content was successfully rejected. Redirecting to waiting list..."
                });
                setTimeout(() => {
                    resetReduxState();
                    resetSnackbar();
                    returnToWaitingList(parseInt(routeParams.tabIndex));
                }, TIME_BEFORE_REDIRECT);

            }).catch((error: any) => {
                setToolbarAction(ToolbarActions.ERROR_IN_REJECT);
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: `Error occured when rejecting content. ${error.message}`
                });
            });
        } else if (type === RouteParamsContentTypes.IMAGE) {
            await rejectContent(contentId).then((result: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Content was successfully rejected. Redirecting to waiting list..."
                });
                setTimeout(() => {
                    resetReduxState();
                    resetSnackbar();
                    returnToWaitingList(parseInt(routeParams.tabIndex));
                }, TIME_BEFORE_REDIRECT);
            }).catch((error: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: `Error occured when rejecting content. ${error.message}`
                });
            });
        }
    }

    const redirectToStep4 = () => {
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Loading and redirecting to content set..."
        });

        setRedirectToMetadataStep(true);
        setActiveMetadataStep(true);
    }

    const returnToArticle = () => {
        setRedirectToMetadataStep(false);
        setActiveMetadataStep(false);
    }

    const approveSingleContent = (contentId: number) => {

        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Content(s) approved successfully"
        });


        if(location.pathname.split('/')[1] === 'edit-waiting-list'){
            pendingSingleFileAction(contentId);
        }else{
            approveSingleFileAction(contentId);
        }

    }

    const rejectSingleContent = (contentId: number) => {
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Content(s) rejected successfully"
        });
        rejectSingleFileAction(contentId);
    }

    const handleClose = () => {
        setContentUploadSnackbar(false);
    }

    const handleMultiSelectButton = () => {
        if (!enableMultiselect) {
            setEnableMultiselect(true);
            setMultiselectImages(true)
        }
        else {
            setEnableMultiselect(false);
            setMultiselectImages(false);
        }
    }

    const handleBackButton = () => {
        returnToWaitingList(parseInt(routeParams.tabIndex));
        resetReduxState();
        resetSnackbar();
        // resetTableFilterAction();
    }
    window.onpopstate = () => {
        returnToWaitingList(parseInt(routeParams.tabIndex));
        resetReduxState();
        resetSnackbar();
    }
    if(setData?.getContentSetContentById?.content?.length === 0 || contentData?.getContentById === null)return <ErrorPage/>;
    return (
        setLoading || contentLoading ? <SyncLoader
        css={`display: block; margin: 10px auto;
        text-align: center; width: 100%; height: 100%; z-index: 100;`}
        size={20}
        color={"#36D2B3"}
        loading={setLoading || contentLoading}/> :
        <div className="edit-waiting-list-container">
            <Box>
                <div className='button-group'>
                    {
                        redirectToMetadataStep || (redirectToMetadataStep && newArticle) ?
                            <Button className='white-button' onClick={() => {
                                returnToArticle();
                            }} variant='contained'><ArrowBackIosIcon />Back to article</Button>
                            :
                            <Button className='white-button' onClick={handleBackButton} variant='contained'><ArrowBackIosIcon />Back</Button>
                    }
                    <ButtonGroup>

                        {
                            (type === RouteParamsContentTypes.CONTENT_SET || type === RouteParamsContentTypes.ARTICLE) && selectedRadioButton === RadioButtonGroup.SET && activeMetadataStep ?
                                !enableMultiselect ?
                                    <Button size='large' className='white-button' variant='contained' onClick={handleMultiSelectButton}>Select multiple photos</Button>
                                    :
                                    <Button size='large' className='red-button' variant='contained' onClick={handleMultiSelectButton}>Discard changes</Button>
                                : null
                        }

                        {
                            type === RouteParamsContentTypes.CONTENT_SET && activeMetadataStep
                                ?
                                <Button size='large' className='white-button' onClick={() => addArticleToContentSet()}
                                    variant='contained'>Add article(optional)</Button>
                                :
                                null
                        }

                        :

                        {
                            (type === RouteParamsContentTypes.CONTENT_SET || type === RouteParamsContentTypes.ARTICLE) && !redirectToMetadataStep ?
                                <Button className='white-button'
                                    onClick={() => redirectToStep4()} variant='contained'>Edit content set</Button>
                                : null
                        }

                        <Button size='large' className='white-button' onClick={() => rejectEditedContent()}
                            variant='contained'>Reject {type ? type : ''}</Button>
                        <Button disabled={processingForDownload} size='large' className='green-button' onClick={e=>{
                            handleDownload(e)
                        }}
                            variant='contained'>  {processingForDownload ? <CircularProgress color="inherit"/> : "Download"}</Button>
                        <Button size='large' className='green-button' onClick={() => saveChanges()}
                            variant='contained'>Save Changes</Button>
                        <Button size='large' className='green-button' onClick={() => manageEditedContent()}
                                variant='contained'>Confirm & Publish</Button>
                            
                    </ButtonGroup>


                </div>
                {!redirectToMetadataStep ?

                    (type === RouteParamsContentTypes.CONTENT_SET && newArticle) || type === RouteParamsContentTypes.ARTICLE

                        ?
                        <ArticleTabsComponent />

                        :

                        <EditContentMetadata duplicateFileIds={duplicateFileIds} editSetFromAdmin={true} handleRejectImage={rejectSingleContent} editContentTable={true}
                            handleApproveImage={approveSingleContent} channels={channels} isMultiselectEnabled={enableMultiselect} isInIncomplete={false} />

                    :

                    <EditContentMetadata duplicateFileIds={duplicateFileIds} editSetFromAdmin={true} handleRejectImage={rejectSingleContent} editContentTable={true}
                        handleApproveImage={approveSingleContent} channels={channels} isMultiselectEnabled={enableMultiselect} isInIncomplete={false} />
                }
                <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                    severity={snackbar.severity}
                    message={snackbar.message} />

            </Box>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogContent>
                <DialogContentText>Please enter file name</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>{
                        setDownloadFileName(e.target.value);
                    }}
                />
                </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={()=>{    
                        startDownload(downloadFileName);
                        handleDialogClose();
                    }}>Download</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

const mapStateToProps = (state: AdminStoreState): {
    selectedRadioButton: string; contentSets: ContentSet[]; contentUserId: number;
    articles: Article[]; audioFiles: AudioFile[]; individualImageCategories: IndividualImageCategory[];
    userId: number; audioFilesResponses: any; movedContents: MovedContentToSets[];
    copiedContents: CopiedContentToSets[]; snackbar: IBroadcastMessage;
    movedMupltipleContents: MovedMultipleContents[]; copiedMultipleContents: CopiedMultipleContents[]; userSelectedImage: number | undefined; userSelectedImages: number[]; imageAngles: ImageAngle[]; currentUser: User;
} => {
    return {
        selectedRadioButton: selectRadioButtonValue(state),
        contentSets: selectContentSets(state),
        articles: selectArticles(state),
        audioFiles: selectAudioFiles(state),
        individualImageCategories: selectIndividualImagesCategory(state),
        userId: selectCurrentUserId(state),
        audioFilesResponses: selectAudioFileResponse(state),
        movedContents: selectMovedContents(state),
        copiedContents: selectCopiedContents(state),
        snackbar: selectSnackbarState(state),
        movedMupltipleContents: selectMultipleMovedContents(state),
        copiedMultipleContents: selectMultipleCopiedContents(state),
        contentUserId: selectContentUserId(state),
        userSelectedImage: getSelectedImage(state),
        userSelectedImages: getSelectedImages(state),
        imageAngles: getImageAngles(state),
        currentUser: selectCurrentUser(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | CallHistoryMethodAction | TSnackbarActions | TTableFiltersReducerActions | TEditContentReducerActions>) => {
    return {
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>({ type: ContentUploadActionTypes.UPLOAD_FILES, data: data }),
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({ type: ContentUploadActionTypes.UPLOAD_SET, data: data }),
        updateSetAction: (data: ContentSet) => dispatch<IUpdateSet>({ type: ContentUploadActionTypes.UPDATE_SET, data: data }),
        resetReduxState: () => dispatch<IResetContentUpload>({ type: ContentUploadActionTypes.RESET_CONTENT }),
        resetSnackbar: () => dispatch<IResetSnackbar>({ type: SnackbarActionTypes.RESET_MESSAGE }),
        returnToWaitingList: (selectedTabIndex: number) => dispatch(push(`/waiting-list/${selectedTabIndex}`)),
        approveSingleFileAction: (data: number) => dispatch<IApproveSingleContent>({ type: ContentUploadActionTypes.APPROVE_SINGLE_FILE, data: data }),
        pendingSingleFileAction: (data: number) => dispatch<IPendingSingleContent>({ type: ContentUploadActionTypes.PENDING_SINGLE_FILE, data: data }),
        rejectSingleFileAction: (data: number) => dispatch<IRejectSingleContent>({ type: ContentUploadActionTypes.REJECT_SINGLE_FILE, data: data }),
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>({ type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data }),
        addAudioFileResponse: (data: any) => dispatch<IAddAudioFileResponse>({ type: ContentUploadActionTypes.ADD_AUDIO_FILE_RESPONSE, data: data }),
        deleteAudioFile: (data: any) => dispatch<IDeleteAudioFile>({ type: ContentUploadActionTypes.DELETE_AUDIO_FILE, data: data }),
        toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({ type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON, data: data }),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        }),
        resetMultiselectionAction: () => dispatch<IResetMultiselection>({ type: ContentUploadActionTypes.RESET_MULTISELECTION }),
        resetTableFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_FILTERS }),
        resetMovedContentsAction: () => dispatch<IResetMovedContents>({ type: ContentUploadActionTypes.RESET_MOVED_CONTENTS }),
        resetCopiedContentsAction: () => dispatch<IResetCopiedContents>({ type: ContentUploadActionTypes.RESET_COPIED_CONTENTS }),
        setMultiselectImages: (data: boolean) =>
        dispatch<IMultiselectImages>({
          type: EditContentActionTypes.SET_MULTISELECT_IMAGES,
          data:data
        }),
        setUserSelectedImage: (data: number | undefined) =>
        dispatch<ISelectedImage>({
        type: EditContentActionTypes.SET_SELECTED_IMAGE,
        data:data
        }),
        setUserSelectedImages: (data: number[]) =>
        dispatch<ISelectedImages>({
            type: EditContentActionTypes.SET_SELECTED_IMAGES,
            data:data
        }),
        resetImageAngles: () =>
        dispatch<IResetImageAngle>({
            type: EditContentActionTypes.RESET_IMAGE_ANGLES
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWaitingList);