import React, { useEffect, useState } from 'react';
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { dialogStyles } from '../../components/users-table/usersAdminTable.types';
import { EditLocationOverrideTypes, useStyles } from './locationOverride.types';
import { LicensePlanTypes } from '../../components/license-table/LicenseAdminTable.types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EDIT_LOCATION_OVERRIDE } from './queries';
import { useMutation } from '@apollo/react-hooks';
import { SeveritySnackbarEnum } from '../../redux/generic/snackbar/snackbar.types';

const EditLocationOverrideModal: React.FC<EditLocationOverrideTypes> = ({ ...props }) => {
    const { broadcastSnackbarAction, refetch, loadedData, tableMeta } = props;

    const overrideId = tableMeta[0];
    const currentPlanType = tableMeta[1];
    const currentCountry = tableMeta[2];
    const currentFactor = tableMeta[3].slice(0, -1);

    const [openModal, setOpenModal] = useState(false);
    const [planType, setPlanType] = useState<LicensePlanTypes>();
    const [response, setResponseState] = useState("");

    const classes = useStyles();

    const [editLocationOverride] = useMutation(EDIT_LOCATION_OVERRIDE);

    const validationSchema = Yup.object({
        percentageFactor: Yup.number().required("You must enter percentage factor.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            )
    });

    const { handleSubmit, handleChange, values, errors} = useFormik({
        initialValues: {
            licensePlanType: currentPlanType,
            percentageFactor: currentFactor
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { licensePlanType, percentageFactor } = values;
            checkLocationOverride();
        }
    })

    const openUpdateModal = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickType = (e: any) => {
        setPlanType(e.target.value);
    };

    const checkLocationOverride = () => {
        const existRow = loadedData.filter(data => data.country.country === currentCountry.country && data.licensingPlanType === planType).length > 0;

        if (existRow) {
            setResponseState("This country license already exists");
        } else {
            editLocationOverride({
                variables: {
                    id: overrideId,
                    licensePlanType: planType,
                    percentageFactor: parseInt(values.percentageFactor)
                }
            }).then((result: any) => {
                refetch();
                handleClose();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Country license updated successfully!"
                })
            }).catch((error: any) => {
                //setResponseState(error.graphQLErrors[0].message);
            });
        }
    };

    useEffect(() => {
        if (planType === LicensePlanTypes.Subscription) {
            setPlanType(LicensePlanTypes.Subscription)
        }
        else {
            setPlanType(LicensePlanTypes.Credit)
        }
    }, [planType]);

    return (
        <React.Fragment>
            <Tooltip title={"Edit"}>
                <IconButton onClick={openUpdateModal}>
                    <CreateIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={openModal} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={openModal}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>
                            <div className='add-user-container'>
                                <h1 className='title'>Update override</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <RadioGroup name="type" defaultValue={planType} onClick={handleClickType}>
                                                <FormControlLabel value={LicensePlanTypes.Subscription} control={<Radio color="default" />} label={LicensePlanTypes.Subscription} />
                                                <FormControlLabel value={LicensePlanTypes.Credit} control={<Radio color="default" />} label={LicensePlanTypes.Credit} />
                                            </RadioGroup>
                                            <TextField
                                                autoComplete="off"
                                                type="text"
                                                variant="standard"
                                                defaultValue={currentCountry}
                                                disabled={true}
                                            />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="percentageFactor"
                                                onChange={handleChange}
                                                label="Price"
                                                variant="standard"
                                                value={values.percentageFactor}
                                                error={errors.percentageFactor === ''}
                                                helperText={errors.percentageFactor ? errors.percentageFactor : null}
                                                FormHelperTextProps={{ className: classes.error }}
                                            />
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Update override</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    )
}

export default EditLocationOverrideModal;