import React, { useEffect, useState } from "react";
import { ContentListProps, ContentNavigation, UserUploadedContent } from "./contentList.types";
import { GET_UPLOADED_CONTENT, GET_ALL_CONTENT_COUNT } from "./queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { FormControl, FormGroup, FormLabel, IconButton, MenuItem, Select, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { IToggleContentUserId, TWaitingListReducerActions } from "../../redux/waiting-list/waitingList.actions";
import { Dispatch } from "redux";
import { AdminStoreState } from "../../redux/root-reducer";
import { IndividualImageCategory, RadioButtonGroup, } from "../batch-upload/ContentUpload.types";
import { IAddChannelToIndividualImage, IToggleRadioButton, TContentUploadActions } from "../../redux/content-upload/content-upload.actions";
import { Autocomplete, InputLabel } from "@mui/material";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import SyncLoader from "react-spinners/SyncLoader";
import { RefreshDateTime } from 'shared';
import { ContentTypes, EditContentType } from "../waiting-list/waitingList.types";
import './contentList.styles.scss';
import { DELETE_CONTENT } from "../../components/waiting-list/queries";
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from "../../redux/generic/snackbar/snackbar.types";
import { SnackbarComponent, SearchBarComponent } from "shared";
import { ISnackbarMessage, TSnackbarActions } from "../../redux/generic/snackbar/snackbar.actions";
import { selectSnackbarState } from "../../redux/generic/snackbar/snackbar.selectors";
import { ToolbarActions } from "../../components/toolbar/toolbar.types";
import { GET_CHANNELS, GET_SUBCHANNELS, GET_THREADS } from "../../components/channels-table/queries";
import { FilterNavigationTypes } from "./contentList.types"
import { ChannelFilter, SubchannelFilter, TableFilterActions, ThreadFilter } from "../../redux/table-filter/table-filter.types";
import { ISearchString, IToggleFilterChannel, IToggleFilterContentType, IToggleFilterContributorType, IToggleFilterLicensing, IToggleFilterPublishDate, IToggleFilterSubchannel, IToggleFilterThread, IToggleFilterUploadDate, IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { getSearchString, selectChannelFilter, selectContentType, selectContributorType, selectLicensingFilter, selectPublishDate, selectSubchannelFilter, selectThreadFilter, selectUploadDate } from "../../redux/table-filter/table-filter.selectors";
import { createTableRow } from "./helperFunctions";
import { selectRowsInContentList } from "../../redux/admin/admin.selectors";
import { ISelectRowsInContentList, TUserReducerActions } from "../../redux/admin/admin.actions";
import { UserActionTypes } from "../../redux/admin/admin.types";
import { selectContentTableRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleContentTableRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { WaitingListActionTypes } from "../../redux/waiting-list/waitingList.types";
import { ContentTableActionTypes } from "../../redux/content-table/contentTable.types";
import { IContentTableCurrentPageNumber, IContentTableSortDirection, TContentTableReducerActions } from "../../redux/content-table/content.actions";
import { getCurrentPageNumber, getSortColumn, getSortDirection } from "../../redux/content-table/contentTable.selectors";
import { IContentTableSortColumn } from "../../redux/content-table/content.actions";

const ContentList: React.FC<ContentListProps> = ({ ...props }) => {
  const { toolbarError, licensingTypeFilter, contributorTypeFilter, contentTypeFilter, uploadDateFilter, publishDateFilter,
    channelFilter, subchannelFilter, threadFilter, rowsSelected, contentListRefreshState,currentPageNumber, previousSearchString, sortColumn, sortDirection, selectRowsInContentListAction, toggleContentUserIdAction,
    redirectToEditPage, toggleRadioButtonAction, addIndividualImageNavigationAction, broadcastToolbarAction, toggleContentTypeFilterAction, setSortDirection, setSortColumn,
    toggleLicenseTypeFilterAction, togleContributorTypeFilterAction, toggleUploadDateFilterAction, togglePublishDateFilterAction,
    toggleChannelFilterAction, toggleSubchannelFilterAction, toggleThreadFilterAction, resetTableFilterAction, toggleContentTableRefresh,setDataTableCurrentPage, setSearchString } = props;

  const today = new Date();
  //QUERIES
  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(GET_UPLOADED_CONTENT, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError(error) {
      setToolbarAction(ToolbarActions.FETCH_ERROR);
    }
  });

  const [allUploadedContent, setAllUploadedContent] = useState<UserUploadedContent[]>([]);
  const { data: countData, refetch: countRefetch } = useQuery(GET_ALL_CONTENT_COUNT, {
    variables: {
      contributorId: null,
      status: "approved",
      isFMC: null
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true
  });

  const { data: dataChannel, error: error1 } = useQuery(GET_CHANNELS, { fetchPolicy: 'no-cache' });
  const [fetchSubchannels, { data: dataSub }] = useLazyQuery(GET_SUBCHANNELS, { fetchPolicy: 'no-cache' });
  const [fetchThreads, { data: dataThread }] = useLazyQuery(GET_THREADS, { fetchPolicy: 'no-cache' });

  //MUTATIONS
  const [deleteContentMutation] = useMutation(DELETE_CONTENT);
  const deleteSelectedContent = (contentType: string, contentId: number) => {
    deleteContentMutation({
      variables: {
        contentType: contentType,
        contentId: contentId
      }
    })
      .then((result: any) => {
        setToolbarAction(ToolbarActions.APPROVE);
      })
      .catch((error: any) => {
        setErrorResponseMessage(error.graphQLErrors[0].message);
        setToolbarAction(ToolbarActions.ERROR_IN_APPROVE);
      });
  };

  //USE STATE HOOKS
  const [allUploadedContentCount, setAllUploadedContentCount] = useState({
    singleContents: 0,
    contentSets: 0,
    articles: 0
  })
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [approvedContentCount, setApprovedContentCount] = useState<number>(1);

  const [toolbarAction, setToolbarAction] = useState(ToolbarActions.NO_ACTION);
  const [errorResponseMessage, setErrorResponseMessage] = useState<string>('');

  const [channels, setChannels] = useState<ChannelFilter[]>([]);
  const [subchannels, setSubchannels] = useState<SubchannelFilter[]>([]);
  const [threads, setThreads] = useState<ThreadFilter[]>([]);

  const [selectedChannel, setSelectedChannel] = useState<ChannelFilter>({ id: 0, title: "", code: "" });
  const [selectedSubchannel, setSelectedSubchannel] = useState<SubchannelFilter>({ id: 0, title: "" });
  const [selectedThread, setSelectedThread] = useState<ThreadFilter>({ id: 0, title: "" });

  const [startUploadDate, setStartUploadDate] = useState<string | null>(null);
  const [endUploadDate, setEndUploadDate] = useState<string | null>(null);

  const [startPublishDate, setStartPublishDate] = useState<string | null>(null);
  const [endPublishDate, setEndPublishDate] = useState<string | null>(null);

  //USE EFFECT HOOKS
  useEffect(() => {
    if (toolbarError.severity && toolbarError.message) {
      setShowSnackbar(true);
    }
  }, [toolbarError]);

  useEffect(()=>{
    if(uploadDateFilter.length > 0 && uploadDateFilter[0]){
      setStartUploadDate(new Date(uploadDateFilter[0]).toISOString().slice(0,10));
    }
    if(uploadDateFilter.length > 0 && uploadDateFilter[1]){
      setEndUploadDate(new Date(uploadDateFilter[1]).toISOString().slice(0,10));
    }
  },[uploadDateFilter])

  useEffect(()=>{
    if(publishDateFilter.length > 0 && publishDateFilter[0]){
      setStartPublishDate(new Date(publishDateFilter[0]).toISOString().slice(0,10));
    }
    if(publishDateFilter.length > 0 && publishDateFilter[1]){
      setEndPublishDate(new Date(publishDateFilter[1]).toISOString().slice(0,10));
    }
  },[publishDateFilter])

  useEffect(() => {
    if (toolbarAction === ToolbarActions.APPROVE) {
      broadcastToolbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Content deleted successfully"
      });
    } else if (toolbarAction === ToolbarActions.ERROR_IN_APPROVE) {
      broadcastToolbarAction({
        severity: SeveritySnackbarEnum.error,
        message: errorResponseMessage
      });
    } else if (toolbarAction === ToolbarActions.FETCH_ERROR) {
      broadcastToolbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "Error when fetching content list content."
      });
    }
  }, [toolbarAction]);

  useEffect(() => {
    if (showSnackbar && toolbarError.severity === SeveritySnackbarEnum.success) {
      callRefetch();
    }
  }, [showSnackbar, toolbarError]);

  useEffect(() => {
    if (startUploadDate && !endUploadDate) {
      toggleUploadDateFilterAction([new Date(startUploadDate), today]);
    } else if (startUploadDate && endUploadDate) {
      let endDate = new Date(endUploadDate);
      endDate.setHours(today.getHours());
      toggleUploadDateFilterAction([new Date(startUploadDate), new Date(endDate)]);
    } else if (!startUploadDate && !endUploadDate) {
      toggleUploadDateFilterAction([]);
    }
  }, [startUploadDate, endUploadDate]);

  useEffect(() => {
    if (startPublishDate && !endPublishDate) {
      togglePublishDateFilterAction([new Date(startPublishDate), today]);
    } else if (startPublishDate && endPublishDate) {
      let endDate = new Date(endPublishDate);
      endDate.setHours(today.getHours());
      togglePublishDateFilterAction([new Date(startPublishDate), new Date(endDate)]);
    } else if (!startPublishDate && !endPublishDate) {
      togglePublishDateFilterAction([]);
    }
  }, [startPublishDate, endPublishDate]);

  useEffect(() => {
    if (dataChannel && dataChannel.getAllChannels) {
      dataChannel.getAllChannels.sort((a: ChannelFilter, b: ChannelFilter) => a.title.localeCompare(b.title))
      setChannels(dataChannel.getAllChannels);
    }
  }, [dataChannel]);

  useEffect(() => {
    if (dataSub && dataSub.getAllSubChannelsByChannelId) {
      setSubchannels(dataSub.getAllSubChannelsByChannelId);
    }
  }, [dataSub]);

  useEffect(() => {
    if (dataThread && dataThread.getAllThreadsBySubChannelId) {
      setThreads(dataThread.getAllThreadsBySubChannelId);
    }
  }, [dataThread]);

  useEffect(() => {
    if (data && data.getContentList) {
      setAllUploadedContent(data.getContentList.userUploadedContent);
      setApprovedContentCount(data.getContentList.allResultsCount)
    }
  }, [data]);

  useEffect(() => {
    if (countData && countData.getAllContentCountInDatatables) {
      setAllUploadedContentCount({
        singleContents: countData.getAllContentCountInDatatables.singleContentCount,
        contentSets: countData.getAllContentCountInDatatables.contentSetsCount,
        articles: countData.getAllContentCountInDatatables.articlesCount
      });
    }
  }, [countData]);

  const callSearch = (page: number,searchPhrase=previousSearchString): void => {
    fetchData({
      variables: {
        pageNumber: searchPhrase ? 0 : page,
        contentPerPage: rowsSelected,
        searchString: searchPhrase ? decodeURI(encodeURI(searchPhrase)) : "",
        licensingType: licensingTypeFilter,
        contributorType: contributorTypeFilter,
        contentType: contentTypeFilter,
        uploadDate: uploadDateFilter,
        publishDate: publishDateFilter,
        channelId: channelFilter && channelFilter.id ? channelFilter.id : 0,
        subchannelId: subchannelFilter && subchannelFilter.id ? subchannelFilter.id : 0,
        threadId: threadFilter && threadFilter.id ? threadFilter.id : 0,
        channelTitle: channelFilter && channelFilter.title ? channelFilter.title : '',
        subchannelTitle: subchannelFilter && subchannelFilter.title ? subchannelFilter.title : '',
        threadTitle: threadFilter && threadFilter.title ? threadFilter.title : '',
        direction: sortDirection || null,
        sortBy: sortColumn || null
      }
    });
  }

  useEffect(() => {
    callSearch(currentPageNumber);
  }, [rowsSelected, licensingTypeFilter, contributorTypeFilter, contentTypeFilter, uploadDateFilter, publishDateFilter, channelFilter, subchannelFilter, threadFilter, sortDirection, sortColumn, currentPageNumber])

  //FUNCTIONS
  const callRefetch = () => {
    callSearch(currentPageNumber);
    countRefetch();
  }



  const handleChangeChannels = (event: any, newValue: any) => {
    if (channels) {
      const channel = channels.filter(option => {
        return option.title === newValue;
      })[0]
      setSelectedChannel(channel ? channel : { id: 0, title: '', code: '' });
      toggleChannelFilterAction(channel ? channel : { id: 0, title: '', code: '' });
      toggleSubchannelFilterAction({ id: 0, title: '' });
      toggleThreadFilterAction({ id: 0, title: '' })
      setSelectedSubchannel({ id: 0, title: '' });
      setSelectedThread({ id: 0, title: '' });
      if (channel && channel.id) {
        fetchSubchannels({ variables: { id: channel.id } })
      }
    }
  }

  const handleChangeSubchannels = (event: any, newValue: any) => {
    if (subchannels) {
      const subchannel = subchannels.filter(option => {
        return option.title === newValue;
      })[0]
      toggleSubchannelFilterAction(subchannel);
      toggleThreadFilterAction({ id: 0, title: '' });
      setSelectedSubchannel(subchannel);
      setSelectedThread({ id: 0, title: '' });
      if (subchannel && subchannel.id) {
        fetchThreads({ variables: { id: subchannel.id } })
      }
    }
  }

  const handleChangeThreads = (event: any, newValue: any) => {
    if (threads) {
      const thread = threads.filter(option => {
        return option.title === newValue;
      })[0]
      toggleThreadFilterAction(thread);
      setSelectedThread(thread);
    }
  }

  const onSubmitSearch = (newSearchString: string) => {
    fetchData({ variables: { pageNumber: 0, contentPerPage: rowsSelected, searchString: decodeURI(encodeURI(newSearchString)) } });
    setSearchString(newSearchString);
  };

  const handleResetSearch = () => {
    callSearch(currentPageNumber,"");
    setSearchString("");
  }

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    setToolbarAction(ToolbarActions.NO_ACTION);
    setErrorResponseMessage('');
  };

  const ToggleEditContent = (contentId: number, type: string) => {
    if (type === ContentTypes.IMAGE) {
      let editTypeObj: EditContentType = {
        id: contentId,
        type: type.toLowerCase()
      };
      let image = allUploadedContent.find(
        image => image.content.id === contentId
      );
      if (image) {
        let navigations: IndividualImageCategory = {
          selectedThreads: image.contentNavigations.map(
            (nav: ContentNavigation) => {
              return nav.thread.id;
            }
          ),
          contentId: contentId
        };
        toggleRadioButtonAction(RadioButtonGroup.INDIVIDUAL);
        addIndividualImageNavigationAction(navigations);
        redirectToEditPage(editTypeObj);
      }

    } else if (type === ContentTypes.CONTENT_SET) {
      let contentSet = allUploadedContent.find((set: UserUploadedContent) => set.contentSetId === contentId);
      if (contentSet) {
        toggleRadioButtonAction(RadioButtonGroup.SET);
        let contSetObj: EditContentType = {
          id: contentId,
          type: type.toLowerCase()
        };

        redirectToEditPage(contSetObj);
      }
    } else if (type === ContentTypes.ARTICLE) {
      let selectedArticle = allUploadedContent.find((article: UserUploadedContent) => article.articleId === contentId);

      if (selectedArticle) {
        toggleRadioButtonAction(RadioButtonGroup.SET);
        let articleTypeObj = {
          id: contentId,
          type: type.toLowerCase()
        };
        redirectToEditPage(articleTypeObj);
      }
    }
  };

  const onRowsDelete = (rowsDeleted: any) => {
    rowsDeleted.data.map((row: any) => {
      let contentType = allUploadedContent[row.dataIndex].type;
      let contentId: number;
      if (contentType === ContentTypes.CONTENT_SET) {
        contentId = allUploadedContent[row.dataIndex].contentSetId!;
      } else if (contentType === ContentTypes.ARTICLE) {
        contentId = allUploadedContent[row.dataIndex].articleId!;
      } else {
        contentId = allUploadedContent[row.dataIndex].content.id;
      }

      deleteSelectedContent(contentType, contentId);
    });
  };

  const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
    return <SearchBarComponent lastSearchValue={previousSearchString} handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
  };
  //MUI DATATABLE COLUMNS DEFINITION
  const getNavigationFilter: any = ()=>{
    if (channelFilter.title && subchannelFilter.title && threadFilter.title) {
      return [`${channelFilter.title}`, `${subchannelFilter.title}`, `${threadFilter.title}`];
    } else if (channelFilter.title && subchannelFilter.title) {
      return [`${channelFilter.title}`, `${subchannelFilter.title}`];
    } else if (channelFilter.title) {
      return [`${channelFilter.title}`];
    } else if (subchannelFilter.title) {
      return [`${subchannelFilter.title}`];
    } else if (threadFilter.title) {
      return [`${threadFilter.title}`];
    } else{
      return null;
    }
  }
  const getUploadDateFilter: any = ()=>{
    if (uploadDateFilter[0] && uploadDateFilter[1]) {
              return [`${new Date(uploadDateFilter[0]).toISOString().slice(0, 10)}`,`${new Date(uploadDateFilter[1]).toISOString().slice(0, 10)}`];
            } else if (uploadDateFilter[0]) {
              return [`${new Date(uploadDateFilter[0]).toISOString().slice(0, 10)}`];
            } else if (uploadDateFilter[1]) {
              return [`${new Date(uploadDateFilter[1]).toISOString().slice(0, 10)}`];
            }
            return "";
  }
  const getPublishDateFilter: any = ()=>{
    if (publishDateFilter[0] && publishDateFilter[1]) {
              return [`${new Date(publishDateFilter[0]).toISOString().slice(0, 10)}`, `${new Date(publishDateFilter[1]).toISOString().slice(0, 10)}`];
            } else if (publishDateFilter[0]) {
              return [`${new Date(publishDateFilter[0]).toISOString().slice(0, 10)}`];
            } else if (publishDateFilter[1]) {
              return [`${new Date(publishDateFilter[1]).toISOString().slice(0, 10)}`];
            }
            return "";
  }
  const columns: any = [
    {
      name: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return (
            <img
              src={tableMeta.rowData[0]}
              alt={value}
              style={{
                display: "block",
                width: "50px",
                height: "50px"
              }}
            />
          );
        }
      }
    },
    {
      name: "ID",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        display: "false" as "false"
      }
    },
    {
      name: "Code",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "Headline/Title",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "Type",
      options: {
        reset: true,
        sort: false,
        filterType: 'custom' as 'custom',
        filterList: contentTypeFilter ? [contentTypeFilter] : null,
        customFilterListOptions: {
          render: (v: any) => v,
          update: (filterList: any, filterPos: any, index: any) => {
            filterList[index].splice(filterPos, 1);
            if (filterList[index].length === 0) {
              toggleContentTypeFilterAction('')
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const optionValues = ['set', 'article', 'image'];
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>
                  Content type
                </InputLabel>
                <Select
                  value={filterList[index]}
                  renderValue={(selected: any) => selected}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    toggleContentTypeFilterAction(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      name: "Contributor ID",
      options: {
        viewColumns: false,
        sort: false,
        display: 'false' as 'false',
        filter: false
      }
    },
    {
      name: "Contributor username",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "Contributor name",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "Contributor type",
      options: {
        reset: true,
        sort: false,
        filterList: contributorTypeFilter ? [contributorTypeFilter] : null,
        filterType: 'custom' as 'custom',
        customFilterListOptions: {
          render: (v: any) => v,
          update: (filterList: any, filterPos: any, index: any) => {
            filterList[index].splice(filterPos, 1);
            if (filterList[index].length === 0) {
              togleContributorTypeFilterAction('')
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const optionValues = ['Agency', 'Individual'];
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>
                  Contributor type
                </InputLabel>
                <Select
                  value={filterList[index]}
                  renderValue={(selected: any) => selected}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    togleContributorTypeFilterAction(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      name: "Upload date",
      options: {
        reset: true,
        sort: true,
        filterType: "custom" as "custom",
        filterList: getUploadDateFilter() || null,
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1]) {
              return `Start date: ${v[0]}, End date: ${v[1]}`;
            } else if (v[0]) {
              return `Start date: ${v[0]}`;
            } else if (v[1]) {
              return `End date: ${v[1]}`;
            }
            return " ";
          },
          update: (filterList: any, filterPos: any, index: any) => {
            toggleUploadDateFilterAction([]);
            setStartUploadDate(null);
            setEndUploadDate(null);
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => (
            <div>
              <FormLabel>Upload date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="From"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ""}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    setStartUploadDate(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  id="endDate"
                  label="To"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ""}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    setEndUploadDate(filterList[index][1]);
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: "Publish date",
      options: {
        reset: true,
        sort: true,
        filterType: "custom" as "custom",
        filterList: getPublishDateFilter() || null,
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1]) {
              return `Start date: ${v[0]}, End date: ${v[1]}`;
            } else if (v[0]) {
              return `Start date: ${v[0]}`;
            } else if (v[1]) {
              return `End date: ${v[1]}`;
            }
            return " ";
          },
          update: (filterList: any, filterPos: any, index: any) => {
            togglePublishDateFilterAction([]);
            setStartPublishDate(null);
            setEndPublishDate(null);
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => (
            <div>
              <FormLabel>Publish date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="From"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ""}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    setStartPublishDate(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  id="endDate"
                  label="To"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ""}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    setEndPublishDate(filterList[index][1])
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: "License",
      options: {
        reset: true,
        sort: false,
        filterList: licensingTypeFilter ? [licensingTypeFilter] : null,
        filterType: 'custom' as 'custom',
        customFilterListOptions: {
          render: (v: any) => v,
          update: (filterList: any, filterPos: any, index: any) => {
            filterList[index].splice(filterPos, 1);
            if (filterList[index].length === 0) {
              toggleLicenseTypeFilterAction('')
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const optionValues = ['standard', 'premium', 'editorial'];
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>
                  License type
                </InputLabel>
                <Select
                  value={filterList[index]}
                  renderValue={(selected: any) => selected}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    toggleLicenseTypeFilterAction(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      name: "Navigation",
      options: {
        reset: true,
        sort: false,
        filterList: getNavigationFilter() ? getNavigationFilter() : null,
        filterType: "custom" as "custom",
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1] && v[2]) {
              return `Channel: ${v[0]}, Subchannel: ${v[1]}, Thread: ${v[2]}`;
            } else if (v[0] && v[1]) {
              return `Channel: ${v[0]}, Subchannel: ${v[1]}`;
            } else if (v[0]) {
              return `Channel: ${v[0]}`;
            } else if (v[1]) {
              return `Subchannel: ${v[1]}`;
            } else if (v[2]) {
              return `Thread: ${v[2]}`;
            }
            return;
          },
          update: (filterList: any, filterPos: any, index: any) => {
            toggleChannelFilterAction({ id: 0, title: '', code: '' });
            toggleSubchannelFilterAction({ id: 0, title: '' });
            toggleThreadFilterAction({ id: 0, title: '' });
            setSelectedChannel({ id: 0, title: '', code: '' });
            setSelectedSubchannel({ id: 0, title: '' });
            setSelectedThread({ id: 0, title: '' });
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            return (
              <div>
                <FormLabel>Navigation</FormLabel>
                <FormGroup row>
                  <Autocomplete
                    id="channel-autocomplete"
                    disableClearable
                    
                    options={channels.map((channel) => channel.title)}
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    onChange={(event: any, value: any): any => {
                      filterList[index][0] = value;
                      handleChangeChannels(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    value={selectedChannel && selectedChannel.title ? selectedChannel.title : channelFilter.title || ''}
                    renderInput={(params: any): any =>
                      <TextField
                        {...params}
                        id="channel"
                        label="Channel"
                        type="text"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={FilterNavigationTypes.CHANNEL_FILTER} className='text-field'
                      />
                    }
                  />
                  <Autocomplete
                    id="subchannel-autocomplete"
                    options={subchannels.map((subchannel) => subchannel.title)}
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    disableClearable
                    onChange={(event: any, value: any): any => {
                      filterList[index][1] = value;
                      handleChangeSubchannels(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    value={selectedSubchannel && selectedSubchannel.title ? selectedSubchannel.title : subchannelFilter.title || ''}
                    renderInput={(params: any): any =>
                      <TextField
                        {...params}
                        id="subchannel"
                        label="Subchannel"
                        type="text"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={FilterNavigationTypes.SUBCHANNEL_FILTER} className='text-field'
                      />
                    }
                  />
                  <Autocomplete
                    id="thread-autocomplete"
                    options={threads.map((thread) => thread.title)}
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    disableClearable
                    onChange={(event: any, value: any): any => {
                      filterList[index][2] = value;
                      handleChangeThreads(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    value={selectedThread && selectedThread.title ? selectedThread.title : threadFilter.title || ""}
                    renderInput={(params: any): any =>
                      <TextField
                        {...params}
                        id="thread"
                        label="Thread"
                        type="text"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={FilterNavigationTypes.THREAD_FILTER} className='text-field'
                      />
                    }
                  />
                </FormGroup>
              </div>
            )
          }
        }
      }
    },
    {
      name: "External links",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return (
            <p
              style={{
                display: "block",
                width: "150px",
                wordWrap: "break-word"
              }}
            >{value}</p>
          );
        }
      },
    },
    {
      name: "External link message",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return (
            <p
              style={{
                display: "block",
                width: "75px",
                wordWrap: "break-word"
              }}
            >{value}</p>
          );
        }
      }
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return (
            <div>
              <IconButton onClick={() => { 
                toggleContentUserIdAction(tableMeta.rowData[5]);
                ToggleEditContent(tableMeta.rowData[1], tableMeta.rowData[4])}
                }>
                <EditIcon />
              </IconButton>
            </div>
          );
        }
      }
    }
  ];

  //MUI DATATABLE OPTIONS
  const options: MUIDataTableOptions = {
    count: approvedContentCount,
    page: currentPageNumber,
    rowsPerPage: rowsSelected,
    jumpToPage: true,
    selectableRowsHeader: true,
    serverSide: true,
    selectableRows: "multiple",
    selectableRowsOnClick: false,
    filter: true,
    fixedHeader: false,
    elevation: 3,
    filterType: "dropdown",
    responsive: "vertical",
    tableBodyMaxHeight: "calc(100%)",
    tableBodyHeight: "calc(100%-50px)",
    download: true,
    print: false,
    searchOpen: previousSearchString ? true : false,
    searchText: previousSearchString,
    textLabels: {
      body: {
        noMatch: loading ? (
          <SyncLoader
            css={`
              display: block;
              margin: auto 0;
              width: 100%;
              height: 100%;
              z-index: 100;
            `}
            size={20}
            color={"#36D2B3"}
            loading={loading}
          />
        ) : (
          "Sorry, no matching records found for now"
        )
      }
    },

    customSearchRender: customSearchRender,
    onRowsDelete: onRowsDelete,
    onChangePage: (currentPage: number) => {

      callSearch(currentPage);
      setDataTableCurrentPage(currentPage);
    },
    onFilterChange: (changedColumn: any, filterList: any, type: any) => {
      if (type === 'reset') {
        resetTableFilterAction();
        setSelectedChannel({ id: 0, title: '', code: '' });
        setSelectedSubchannel({ id: 0, title: '' });
        setSelectedThread({ id: 0, title: '' });
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      selectRowsInContentListAction(numberOfRows);
    },
    onTableChange: (action: any, tableState: any)=>{
      if(action === 'sort'){
        setSortDirection(tableState.sortOrder.direction)
        setSortColumn(tableState.sortOrder.name === 'Upload date' ? 'upload_date' : 'publish_date') ;
        setDataTableCurrentPage(0);
        selectRowsInContentListAction(10);
      }
    }
  };
  return (
    <div>
      <div className="info-container">
        <span className='results-container'>{allUploadedContentCount.singleContents} Images | {allUploadedContentCount.contentSets} Content sets | {allUploadedContentCount.articles} Articles</span>
        <div className="refetch-container">
          <RefreshDateTime loading={loading} autoRefreshOn={contentListRefreshState}
            refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleContentTableRefresh} />
        </div>
      </div>
      <MUIDataTable title={""} columns={columns} options={options} data={allUploadedContent.map((item: UserUploadedContent) => createTableRow(item))}/>
      <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleCloseSnackbar} severity={toolbarError.severity} message={toolbarError.message} />
    </div>
  );
};
const mapStateToProps = (state: AdminStoreState): {
  toolbarError: IBroadcastMessage; licensingTypeFilter: string; contributorTypeFilter: string; contentTypeFilter: string;
  uploadDateFilter: Date[]; publishDateFilter: Date[]; channelFilter: ChannelFilter; subchannelFilter: SubchannelFilter; threadFilter: ThreadFilter;
  rowsSelected: number; contentListRefreshState: boolean; currentPageNumber: number; previousSearchString: string | undefined; sortDirection: string | null; sortColumn: string | null;
} => {
  return {
    toolbarError: selectSnackbarState(state),
    licensingTypeFilter: selectLicensingFilter(state),
    contributorTypeFilter: selectContributorType(state),
    contentTypeFilter: selectContentType(state),
    uploadDateFilter: selectUploadDate(state),
    publishDateFilter: selectPublishDate(state),
    channelFilter: selectChannelFilter(state),
    subchannelFilter: selectSubchannelFilter(state),
    threadFilter: selectThreadFilter(state),
    rowsSelected: selectRowsInContentList(state),
    contentListRefreshState: selectContentTableRefresh(state),
    currentPageNumber: getCurrentPageNumber(state),
    sortDirection: getSortDirection(state),
    sortColumn: getSortColumn(state),
    previousSearchString: getSearchString(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TContentTableReducerActions | TWaitingListReducerActions | TContentUploadActions | TRefreshTablesActions | CallHistoryMethodAction | TSnackbarActions | TTableFiltersReducerActions | TUserReducerActions>) => {
  return {
    redirectToEditPage: (data: EditContentType) => dispatch(push(`/edit-content-data/${data.type}/${data.id}`)),
    toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({ type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON, data: data }),
    addIndividualImageNavigationAction: (data: IndividualImageCategory) => dispatch<IAddChannelToIndividualImage>({ type: ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL, data: data }),
    broadcastToolbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data }),
    toggleContentTypeFilterAction: (data: string) => dispatch<IToggleFilterContentType>({ type: TableFilterActions.TOGGLE_FILTER_CONTENT_TYPE, data: data }),
    toggleLicenseTypeFilterAction: (data: string) => dispatch<IToggleFilterLicensing>({ type: TableFilterActions.TOGGLE_FILTER_LICENSING, data: data }),
    togleContributorTypeFilterAction: (data: string) => dispatch<IToggleFilterContributorType>({ type: TableFilterActions.TOGGLE_FILTER_CONTRIBUTOR_TYPE, data: data }),
    toggleUploadDateFilterAction: (data: Date[]) => dispatch<IToggleFilterUploadDate>({ type: TableFilterActions.TOGGLE_FILTER_UPLOAD_DATE, data: data }),
    togglePublishDateFilterAction: (data: Date[]) => dispatch<IToggleFilterPublishDate>({ type: TableFilterActions.TOGGLE_FILTER_PUBLISH_DATE, data: data }),
    toggleChannelFilterAction: (data: ChannelFilter) => dispatch<IToggleFilterChannel>({ type: TableFilterActions.TOGGLE_FILTER_CHANNEL, data: data }),
    toggleSubchannelFilterAction: (data: SubchannelFilter) => dispatch<IToggleFilterSubchannel>({ type: TableFilterActions.TOGGLE_FILTER_SUBCHANNEL, data: data }),
    toggleThreadFilterAction: (data: ThreadFilter) => dispatch<IToggleFilterThread>({ type: TableFilterActions.TOGGLE_FILTER_THREAD, data: data }),
    setSearchString: (data: string) => dispatch<ISearchString>({ type: TableFilterActions.SET_SEARCH_STRING, data: data }),
    resetTableFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_FILTERS }),
    selectRowsInContentListAction: (data: number) => dispatch<ISelectRowsInContentList>({ type: UserActionTypes.SelectRowsInContentList, data: data }),
    toggleContentTableRefresh: (data: boolean) => dispatch<IToggleContentTableRefresh>({ type: TablesRefreshActionTypes.CONTENT_TABLE_REFRESH, data: data }),
    toggleContentUserIdAction: (data: number) => dispatch<IToggleContentUserId>({ type: WaitingListActionTypes.TOGGLE_CONTENT_USER_ID, data: data}),
    setDataTableCurrentPage: (data: number) => dispatch<IContentTableCurrentPageNumber>({ type: ContentTableActionTypes.SET_CURRENT_PAGE, data: data}),
    setSortDirection: (data: any) => dispatch<IContentTableSortDirection>({ type: ContentTableActionTypes.SET_SORT_DIRECTION, data: data}),
    setSortColumn: (data: any) => dispatch<IContentTableSortColumn>({ type: ContentTableActionTypes.SET_SORT_COLUMN, data: data})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);
