import React, { useEffect, useState } from 'react';
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, FormLabel, withStyles } from "@material-ui/core";
import ImportExportSharpIcon from '@material-ui/icons/ImportExportSharp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dialogStyles } from '../../components/users-table/usersAdminTable.types';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GENERATE_REPORT, SEND_GENERATED_REPORT, GET_LAST_EXPORT_DATE } from './queries';
import { ExportReportModalProps, styles } from './reportsTable.types';
import './reports.styles.scss';
import { Autocomplete, InputLabel } from "@mui/material";
import { User } from '../../redux/admin/admin.types';
import { ALL_CONTRIBUTORS } from '../../components/users-table/queries';
import { AdminStoreState } from '../../redux/root-reducer';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { Dispatch } from 'redux';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { connect } from 'react-redux';
import { SnackbarComponent } from 'shared';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ExportReportModal: React.FC<ExportReportModalProps> = ({ ...props }) => {
    const { reportError, refetch, broadcastReportsErrorAction, classes } = props;
    const [fetchContributors, { data: contributorsData }] = useLazyQuery(ALL_CONTRIBUTORS);

    const [openModal, setOpenModal] = useState(false);

    const [contributorOptions, setContributorOptions] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [lastExportDate, setLastExportDate] = useState<string>("");
    const [incomePercentage, setIncomePercentage] = useState(0);

    const [showSnackbar, setShowSnackbar] = useState(false);

    const [pdfLink, setPDFLink] = useState<string>('');

    const [fetchLastExportDate, { data, loading, error }] = useLazyQuery(GET_LAST_EXPORT_DATE, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    const validationSchema = Yup.object({
        incomePercentage: Yup.number().required("This field is required")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            ),
    })

    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            incomePercentage: 0
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { incomePercentage } = values;
        }

    });

    const [generateReportMutation] = useMutation(GENERATE_REPORT);
    const generateReport = (startDate: Date, endDate: Date, userId: number, incomePercentage: number) => {
        return generateReportMutation({
            variables: {
                startDate: startDate,
                endDate: endDate,
                userId: userId,
                incomePercentage: incomePercentage
            }
        })
    };

    const [sendGeneratedReportMutation] = useMutation(SEND_GENERATED_REPORT);
    const sendGeneratedReport = (startDate: Date, endDate: Date, userId: number, incomePercentage: number) => {
        return sendGeneratedReportMutation({
            variables: {
                startDate: startDate,
                endDate: endDate,
                userId: userId,
                incomePercentage: incomePercentage
            }
        })
    };

    useEffect(() => {
        if (reportError.severity && reportError.message) {
            setShowSnackbar(true);
        }
    }, [reportError]);

    useEffect(() => {
        if (showSnackbar) {
            refetch();
        }
    }, [showSnackbar]);

    useEffect(() => {
        if (data && data.getLastExportDate) {
            setLastExportDate(data.getLastExportDate.date);
        }
    }, [data]);

    useEffect(() => {
        if (openModal) {
            fetchContributors();
        }
    }, [openModal]);

    useEffect(() => {
        if (contributorsData && contributorsData.getAllContributors) {
            setContributorOptions(contributorsData.getAllContributors);
        }
    }, [contributorsData]);

    useEffect(() => {
        if (selectedUser) {
            fetchLastExportDate({ variables: { userId: selectedUser.id } })
        }
    }, [selectedUser])

    useEffect(() => {
        if (selectedUser) {
            setIncomePercentage(selectedUser.incomePercentage)
        }
    }, [selectedUser])

    useEffect(() => {
        if (values.incomePercentage !== 0){
            setIncomePercentage(parseFloat(values.incomePercentage.toString()))
        }
    }, [values.incomePercentage])

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setSelectedUser(undefined);
        setOpenModal(false);
    }

    const handleCloseAfterSend = () => {
        setSelectedUser(undefined);
        setOpenModal(false);
        refetch();
    }

    const handleClose = () => {
        setShowSnackbar(false);
    }

    const handleOnClickContributor = (contributor: User) => {
        setSelectedUser(contributor);
       
    }

    const handleAutoCompleteAgencyChange = (event: any, newValue: any) => {
        const user = contributorOptions.filter(option => {
            return option.email === newValue
        })[0];
        handleOnClickContributor(user);
    }

    const handleAutoCompleteAgencyInputChange = (event: any, value: string) => {
        if (value === "") {
            const user = contributorOptions.filter(option => {
                return option.email === value
            })[0];
            handleOnClickContributor(user);
        }
    }

    const handleDateSelection = (event: any) => {
        const name = event.target.name;

        if (name === "startDate") {
            setStartDate(event.target.value)
        }
        else if (name === "endDate") {
            setEndDate(event.target.value)
        }
    }

    const handleSendButton = async () => {
        if (selectedUser && startDate && endDate) {
            await sendGeneratedReport(startDate, endDate, selectedUser.id, incomePercentage)
                .then(() => {
                    broadcastReportsErrorAction({ severity: SeveritySnackbarEnum.success, message: "Report sent successfully" });
                    handleCloseAfterSend();
                })
        }
        else {
            broadcastReportsErrorAction({ severity: SeveritySnackbarEnum.error, message: "Please select user and date range." })
        }
    }

    const handleGenerateButton = async () => {
        if (selectedUser && startDate && endDate) {
            const cloudFrontLink = await generateReport(startDate, endDate, selectedUser.id, incomePercentage)
                .then((result: any) => {
                    setPDFLink(result.data.generateReport);
                })
                .catch((error: any) => {
                    broadcastReportsErrorAction({ severity: SeveritySnackbarEnum.error, message: "Error when generating report" })
                })
            return cloudFrontLink;
        }
        else {
            broadcastReportsErrorAction({ severity: SeveritySnackbarEnum.error, message: "Please select user and date range." })
        }
    }

    return (
        <React.Fragment>
            <Button onClick={handleOpenModal}>
                Generate
                <ImportExportSharpIcon />
            </Button>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleCloseModal} open={openModal} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={openModal}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleCloseModal}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='export-report-container'>
                                <h1 className='title'>Generate report</h1>
                                    <div className='row-group'>
                                        <FormLabel htmlFor="contributerEmail" className='select-label'>User: </FormLabel>
                                        <Autocomplete freeSolo id="autocomplete-free-solo"
                                            style={{ width: '100%' }}
                                            disableClearable
                                            value={selectedUser && selectedUser.email ? selectedUser.email : ""}
                                            options={contributorOptions.map((option) => option.email)}
                                            onChange={handleAutoCompleteAgencyChange}
                                            onInputChange={handleAutoCompleteAgencyInputChange}
                                            renderInput={(params: any): any => (
                                                <TextField
                                                    {...params}
                                                    autoComplete="off"
                                                    variant='standard'
                                                    value={selectedUser && selectedUser.email ? selectedUser.email : ""}
                                                    name="contributerEmail"
                                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='row-group'>
                                        <FormLabel htmlFor="prefferedCurrency" className='select-label'>Preffered currency: </FormLabel>
                                        <TextField type='text' autoComplete='off' hiddenLabel={true} disabled={true}
                                            name='prefferedCurrency' variant='standard'
                                            value={selectedUser && selectedUser.preferredCurrency ? selectedUser.preferredCurrency : "No preferred currency"}
                                        />
                                    </div>
                                    <div className='row-group'>
                                        <FormLabel htmlFor="incomePercentage" className='select-label'>Income percentage: </FormLabel>
                                        <TextField type='text' autoComplete='off' hiddenLabel={true}
                                            name='incomePercentage' variant='standard'
                                            onChange={handleChange}
                                            value={values.incomePercentage !== 0 ? values.incomePercentage : incomePercentage}
                                            error={errors.incomePercentage === ""}
                                            helperText={errors.incomePercentage ? errors.incomePercentage : null}
                                            FormHelperTextProps={{ className: classes.error }}
                                        />
                                    </div>
                                    <div className='row-group'>
                                        <FormLabel htmlFor="startDate" className='select-label'>From: </FormLabel>
                                        <TextField
                                            id="startDate"
                                            type="date"
                                            name='startDate'
                                            autoComplete="off"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={startDate}
                                            onChange={handleDateSelection}
                                            style={{marginLeft: '27%' }}
                                        />
                                    </div>
                                    <div className='row-group'>
                                        <FormLabel htmlFor="endDate" className='select-label'>To: </FormLabel>
                                        <TextField
                                            id="endDate"
                                            type="date"
                                            name='endDate'
                                            autoComplete="off"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={endDate}
                                            onChange={handleDateSelection}
                                            style={{  marginLeft: '35%' }}
                                        />
                                    </div>
                                    <div className='row-group'>
                                        <FormLabel htmlFor="lastExportDate" className='select-label'>Last export date: </FormLabel>
                                        <TextField autoComplete='off' hiddenLabel={true} disabled={true}
                                            name='lastExportDate' variant='standard'
                                            value={lastExportDate ? lastExportDate.slice(0, 10) : "No last export date"}
                                        />
                                    </div>
                                    {
                                        pdfLink ?
                                            <div className='pdf-link'>
                                                <a href={pdfLink}>Download report</a>
                                            </div>
                                            : null
                                    }
                                    <div className='button-group'>
                                        <Button className='submit-button' variant='contained' onClick={handleGenerateButton}>Generate</Button>
                                        <Button className='submit-button' variant='contained' onClick={handleSendButton}>Send</Button>
                                    </div>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                severity={reportError.severity}
                message={reportError.message} />
        </React.Fragment>
    )
}

const mapStateToProps = (state: AdminStoreState): { reportError: IBroadcastMessage } => {
    return {
        reportError: selectSnackbarState(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSnackbarActions>) => {
    return {
        broadcastReportsErrorAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExportReportModal));