import gql from "graphql-tag";

export const GET_ALL_POST_REPORTS = `
query Reports($pages: Int, $limit: Int) {
	reports(pages: $pages, limit: $limit) {
	  edges {
		id
		postId
		contentId
		contentIds
		fromUserId
		text
		type
		contentType
		targetedUserId
		createdAt
		updatedAt
		status
		originUser {
		  id
		  firstName
		  lastName
		  username
		  organizationName
		  picture
		  email
		  bio
		  profileStatus
		  isUploadedContentApproved
		  isContentCommentEnabled
		  counts
		  isFriendOn
		  isFollowOn
		  postCount
		  isDelete
		  followCount
		  followerCount
		  wiUserId
		  postCountByFilter
		}
		targetUser {
		  id
		  firstName
		  lastName
		  username
		  organizationName
		  picture
		  email
		  bio
		  profileStatus
		  isUploadedContentApproved
		  isContentCommentEnabled
		  counts
		  isFriendOn
		  isFollowOn
		  postCount
		  isDelete
		  followCount
		  followerCount
		  wiUserId
		  postCountByFilter
		}
		post {
		  id
		  path_to_file_thumbnail
		  title
		  body
		  object_name
		  timestamp
		  contentType
		  commentCount
		  likeCount
		  liked
		  isActive
		  images {
			file
		  }
		  postStatus
		  contentLink
		  mwPostId
		}
		threadId
		contentSetId
		articleId
	  }
	  totalCount
	}
  }
`
export const UPDATE_POST_REPORT_STATUS = `
mutation UpdateReportStatus($updateReportStatusId: Int!, $status: String!) {
  updateReportStatus(id: $updateReportStatusId, status: $status) {
    id
    postId
    contentIds
    fromUserId
    text
    type
    contentType
    targetedUserId
    createdAt
    updatedAt
    status
  }
}
`

export const GET_CONTENT_INFO = `
	query getFullContentInfoQuery($contentId: Int!, $userId: Int) {
		getFullContentInfo(contentId: $contentId, userId: $userId) {
			user {
				username
				role
				person {
					id
					firstName
					lastName
				}
				company {
					id
					name
				}
			}
			content {
				id
				pathToFileCompressed
				pathToFileThumbnail
				headline
				publishDate
				caption
				type
				licensingType
				externalLink
				externalLinkMessage
				code
			}
			location {
				country {
					country
				}
				city {
					city
				}
				province {
					stateProvince
				}
			}
			copyright {
				byLine
			}
			contentRate {
				flatRate
			}
		}
	}
`;

export const GET_CONTENT_SET_BY_ARTICLE_ID = `
query getFullContentInContentSetByArticleIdQuery(
	$articleId: Int!
	$userId: Int
  ) {
	getFullContentInContentSetByArticleId(
	  articleId: $articleId
	  userId: $userId
	) {
	  content {
		content {
		  id
		  pathToFileCompressed
		  pathToFileThumbnail
		  isOwned
		  licensingType
		  size
		}
	  }
	  contentSet {
		title
	  }
	  article {
		title
		description
		audioId
	  }
	  audioFile {
		id
		pathToFile
		headline
		publishDate
		caption
		type
		licensingType
		externalLink
		externalLinkMessage
	  }
	}
  }
  
`;

export const GET_CONTENT_SET_BY_SET_ID = `
query getFullContentInContentSetQuery($contentSetId: Int!, $userId: Int) {
	getFullContentInContentSet(contentSetId: $contentSetId, userId: $userId) {
	  content {
		content {
		  id
		  pathToFileCompressed
		  pathToFileThumbnail
		  headline
		  isOwned
		  licensingType
		  size
		}
		contentRate {
			flatRate
		}
	  }
	  contentSet {
		title
	  }
	}
  }
`;
