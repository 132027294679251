import gql from "graphql-tag";

export const GET_ALL_INCOMPLETE_SFTP_FLOWS = gql`
query getAllIncompleteSFTPFolderList($pageNumber: Int!, $contentPerPage: Int!) {
  getAllIncompleteSFTPFolderList(
    pageNumber: $pageNumber
    contentPerPage: $contentPerPage
  ) {
    total
    incompleteSets{
      contentSet
      contents
      lastModifiedDate
      userInfo{
        id
        username
        role
        companyName
        companyType
        firstName
        lastName
      }
    }
  }
}
`;

export const GET_SFTP_FOLDERS = gql`
  query getSetFolderNames($sftpFolder: String!) {
    getSetFolderNames(sftpFolder: $sftpFolder)
  }
`;

export const UPLOAD_SFTP_SET_FOLDER = gql`
  mutation uploadSFTPSetFolder($sftpSetFolder: String!, $userId: Int!) {
    uploadSFTPSetFolder(sftpSetFolder: $sftpSetFolder, userId: $userId) {
        key
        value {
          id
          filename
          mimetype
          code
          url
        }
    }
  }
`;

export const COMPLETE_INCOMPLETE_FLOW = gql`
    mutation completeUserIncompleteUploadFlow($incompleteFlowId: String!, $newSetsAndContentIds: [ContentSetUploadResponseInput!]!, $newArticleIds: [Int!]!) {
        completeUserIncompleteUploadFlow(incompleteFlowId: $incompleteFlowId, newSetsAndContentIds: $newSetsAndContentIds, newArticleIds: $newArticleIds)
    }
`;

export const UPDATE_INCOMPLETE_FLOW = gql`
    mutation updateUserIncompleteUploadFlow($incompleteFlowId: String!, $contentIds: [Int!]!, $contentSetIds: [Int!]!) {
        updateUserIncompleteUploadFlow(incompleteFlowId: $incompleteFlowId, contentIds: $contentIds, contentSetIds: $contentSetIds)
    }
`;

export const DELETE_INCOMPLETE_FLOW = gql`
    mutation deleteIncompleteFlowMutation($incompleteFlowId: String!) {
        deleteIncompleteFlow(incompleteFlowId: $incompleteFlowId)
    }
`;

export const CLEAN_SFTP_TEMP_FOLDER = gql`
  mutation cleanSingleTempFolder($id: Int!, $folderName: String!) {
    cleanSingleTempFolder(id: $id folderName: $folderName)
  }
`;