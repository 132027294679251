import React, {useState, useEffect} from "react";
import {IconButton, Dialog, Button, Backdrop, Tooltip, Fade, TextField, MuiThemeProvider, Select, Radio, FormLabel, RadioGroup, FormControlLabel, withStyles} from "@material-ui/core";
import { UpdateChannelProps, TableNames, Channel, CHANNEL_TITLE_REGEX, styles, Thread } from "./channelsTable.types";
import EditIcon from '@material-ui/icons/Edit';
import {GET_SUBCHANNELS, GET_THREADS, GET_THREAD_PATH, UPDATE_CHANNEL, UPDATE_SUBCHANNEL, UPDATE_THREAD} from './queries';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { Alert, AlertTitle } from '@material-ui/lab';
import { channelsTableTheme } from "./channelsTable.types";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Dispatch } from "redux";
import { TChannelsTableActions, IUpdateChannel, ILoadThreads } from "../../redux/channels-table/channels.actions";
import { ChannelsTableActionTypes, UpdateActionData } from "../../redux/channels-table/channels.types";
import { connect } from "react-redux";
import { AdminStoreState } from "../../redux/root-reducer";
import SyncLoader from 'react-spinners/SyncLoader';
import { selectCurrentChannels, selectedChannelId } from "../../redux/channels-table/channels.selectors";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const UpdateChannelModal: React.FC<UpdateChannelProps> = ({...props}) => {
    const {tableName, selectedItem, selectFormChannels, selectedChannel, classes, updateChannelAction, loadThreadsAction} = props;
    const openUpdateChannelModal = false;
    const [open, setOpen] = useState(openUpdateChannelModal);
    const [dataQuery, setDataQuery] = useState(GET_SUBCHANNELS);
    const [selectedRadioButton, setSelectedRadioButton] = useState(tableName.toLowerCase().slice(0, -1));
    const [state, setState] = useState({
        underTheChannel: 0,
        underTheSubChannel: 0
    });
    const [response, setResponseState] = useState("");
    const [disabledRadioButtons, setDisabledRadioButtons] = useState(false)
    const [selectFormSubChannels, setSelectFormSubChannels] = useState([]);
    const [fetchDataQuery, {data, loading, error, refetch}] = useLazyQuery(dataQuery, {fetchPolicy: 'no-cache'});
 
    useEffect(() => {
        if(data && data.getAllSubChannelsByChannelId) {
            if(data.getAllSubChannelsByChannelId.length > 0 && selectedRadioButton !== 'thread') {
                setDisabledRadioButtons(true);
            }
            setSelectFormSubChannels(data.getAllSubChannelsByChannelId);

        } else if(data && data.getThreadPath) {
            setDataQuery(GET_SUBCHANNELS);
            setFieldValue("title", selectedItem.title)
            setState({underTheChannel: data.getThreadPath.channelId, underTheSubChannel: data.getThreadPath.id});
            fetchDataQuery({variables: {id: data.getThreadPath.channelId}});
        } else if(data && data.getAllThreadsBySubChannelId) {
            if(data.getAllThreadsBySubChannelId.length > 0){
                setDisabledRadioButtons(true);
            }
        }
    }, [data]);

    useEffect(() => {
        if(dataQuery === GET_THREADS) {
            fetchDataQuery({variables: {id: selectedItem.subChannelId}});
        } else if(dataQuery === GET_THREAD_PATH) {
            fetchDataQuery({variables: {subChannelId: selectedItem.subChannelId}});
        }
    }, [dataQuery]);

    useEffect(() => {
        if (data && data.getAllThreadsBySubChannelId) {
            if (data.getAllThreadsBySubChannelId.length > 0) {
                loadThreadsAction(data.getAllThreadsBySubChannelId);
            } else
                loadThreadsAction(selectedItem.id);
        }
    }, [data])


    const [updateChannelMutation, { loading: loadingUpdateChannel }] = useMutation(UPDATE_CHANNEL);
    const [updateSubChannelMutation, { loading: loadingUpdateSubchannel }] = useMutation(UPDATE_SUBCHANNEL);
    const [updateThreadMutation, { loading: loadingUpdateThread }] = useMutation(UPDATE_THREAD);
    const update = (title: string): void => {
        if(tableName === TableNames.Channels) {
            updateChannelMutation({
                variables: {
                    title: title,
                    id: selectedItem.id
                }
            }).then((result: any) => {
                if(result.data.updateChannel) {
                    const temp = result.data.updateChannel;
                    updateChannelAction({id: temp.id, title: temp.title, channelId: state.underTheChannel, subChannelId: state.underTheSubChannel, category: selectedRadioButton});
                }
                handleClose();
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        } else if(tableName === TableNames.SubChannels) {
            updateSubChannelMutation({
                variables: {
                    title: title,
                    id: selectedItem.id,
                    channelId: state.underTheChannel,
                    subChannelId: state.underTheSubChannel,
                    category: selectedRadioButton.toUpperCase()
                }
            }).then((result: any) => {
                if(result.data.updateSubChannel) {
                    const temp = result.data.updateSubChannel;
                    updateChannelAction({id: temp.id, title: temp.title, channelId: state.underTheChannel, subChannelId: state.underTheSubChannel, category: selectedRadioButton});
                }
                handleClose();
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        } else if (tableName === TableNames.Threads) {
            updateThreadMutation({
                variables: {
                    title: title,
                    id: selectedItem.id,
                    channelId: state.underTheChannel,
                    subChannelId: state.underTheSubChannel,
                    category: selectedRadioButton.toUpperCase()
                }
            }).then((result: any) => {
                if(result.data.updateThread) {
                    const temp = result.data.updateThread;
                    updateChannelAction({id: temp.id, title: temp.title, channelId: state.underTheChannel, subChannelId: state.underTheSubChannel, category: selectedRadioButton});
                }
                setDataQuery(GET_THREADS)
                handleClose();
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        }
        setFieldValue("title", "")
    };

    const handleRadioChange = (event: any) => {
        event.preventDefault();
        setSelectedRadioButton(event.target.value);
        
        if(event.target.value === 'thread') {
            setDataQuery(GET_SUBCHANNELS);
            fetchDataQuery({variables: {id: state.underTheChannel}});
        }
    }

    const handleSelectChange = (event: any) => {
        event.preventDefault();
        const id = parseInt(event.target.value, 10);

        if(event.target.name === 'channels') {
            setState({...state, underTheChannel: id});
            if(selectedRadioButton === 'thread') {
                setDataQuery(GET_SUBCHANNELS);
                fetchDataQuery({variables: {id: id}});
            }
        }
        else if(event.target.name === 'subchannels') {
            setState({...state, underTheSubChannel: id});
        }
    }

    const UpdateChannel = () => {
        setOpen(true); 
        if(tableName === TableNames.Channels) {
            setDataQuery(GET_SUBCHANNELS);
            setFieldValue("title", selectedItem.title)
            fetchDataQuery({variables: {id: selectedItem.id}});
        } else if(tableName === TableNames.SubChannels && selectedItem.channelId) {
            setDataQuery(GET_THREADS);
            setFieldValue("title", selectedItem.title)
            setState({...state, underTheChannel: selectedItem.channelId});
            fetchDataQuery({variables: {id: selectedItem.id}});
        } else if(tableName === TableNames.Threads && selectedItem.subChannelId) {
            setDataQuery(GET_THREAD_PATH);
            if(selectedItem.hasContent) {
                setDisabledRadioButtons(true);
            }
            setFieldValue("title", selectedItem.title)
        } 
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedRadioButton(tableName.toLowerCase().slice(0, -1));
        setFieldValue("title", "")
        setState({underTheChannel: 0, underTheSubChannel: 0});
        setSelectFormSubChannels([]);
    }

    const validationSchema = Yup.object({
        title: Yup.string().matches(
            CHANNEL_TITLE_REGEX,
            "Field must not contain special characters as ^ or ~"),
    });

    const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormik({
        initialValues: {
            title: ""
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            try {
                const { title } = values;
                if((selectedRadioButton === 'subchannel' && state.underTheChannel === 0) || 
                        (selectedRadioButton === 'thread' && state.underTheSubChannel === 0)) {
                            setResponseState("Please select the location for the currently edited " + selectedRadioButton);
                    }
                    else {
                        update(title);
                    }
            } catch (error) {
                setResponseState(error.graphQLErrors[0].message);
            }
        },
    });

    return (
        <React.Fragment>
            <Tooltip title="Update">
                <IconButton onClick={UpdateChannel}>
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={channelsTableTheme}>
                  <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                      BackdropProps={{ timeout: 800 }}>
                      <Fade in={open}>
                          <div>
                              <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                  <FontAwesomeIcon className='icon-button' icon={faTimes}/>
                              </IconButton>
                              
                              <div className='add-user-container'>
                                  <h1 className='title'>Edit {selectedItem.title}</h1>
                                    { response ? 
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {response}
                                    </Alert> : null}
                                  <form className='add-user-form' onSubmit={handleSubmit}>
                                    <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} color={"#36D2B3"} loading={loading || loadingUpdateSubchannel || loadingUpdateChannel || loadingUpdateThread}/>
                                        <div>
                                            <FormLabel >Type:</FormLabel>
                                            <RadioGroup aria-label="channels" name="channels" value={selectedRadioButton} onChange={handleRadioChange}>
                                                <FormControlLabel  value="channel" control={<Radio disabled={disabledRadioButtons}/>} label="Channel" />
                                                <FormControlLabel  value="subchannel" control={<Radio disabled={disabledRadioButtons}/>} label="Subchannel" />
                                                <FormControlLabel  value="thread"  control={<Radio disabled={disabledRadioButtons}/>} label="Thread" />
                                            </RadioGroup>
                                        </div>
                                        <div>
                                            <FormLabel className='select-label' htmlFor="channel">Name:</FormLabel>
                                            <TextField autoComplete="off" 
                                                    type='text' 
                                                    onChange={handleChange} 
                                                    hiddenLabel={true} 
                                                    name='title'
                                                    variant='standard' 
                                                    value={values.title}
                                                    error={errors.title === ""}
                                                    helperText={errors.title ? errors.title : null}
                                                    InputLabelProps={{ shrink: false }}
                                                    FormHelperTextProps={{ className: classes.error }} />
                                        </div>
                                        {selectedRadioButton !== 'channel' ? <div>
                                            <FormLabel htmlFor="channel">Under the Channel:</FormLabel>
                                            <Select native value={state.underTheChannel} onChange={handleSelectChange}
                                                inputProps={{ name: 'channels', id: 'channel'}}>
                                                    {selectFormChannels ? selectFormChannels.map((channel: any) => {
                                                    return <option key={channel.id} aria-label={channel.title} value={channel.id}>{channel.title}</option>
                                                    }): null}
                                            </Select>
                                        </div> : null}
                                        {selectedRadioButton === 'thread' ? <div>
                                            <FormLabel htmlFor="subchannel">Under the Subchannel:</FormLabel>
                                            <Select native value={state.underTheSubChannel} onChange={handleSelectChange}
                                                inputProps={{ name: 'subchannels', id: 'subchannel'}}>
                                                {selectFormSubChannels ? selectFormSubChannels.map((channel: any) => {
                                                    return <option key={channel.id} aria-label={channel.title} value={channel.id}>{channel.title}</option>
                                                    }): null}
                                            </Select>
                                        </div> : null}
                                        <div className='button-group'>
                                            <Button className='cancel-button' onClick={handleClose} variant='contained'>Cancel</Button>
                                            <Button type='submit' className='submit-button' variant='contained'>Save</Button>
                                        </div>
                                  </form>
                              </div>
                          </div>
                      </Fade>
                  </Dialog>
              </MuiThemeProvider>
        </React.Fragment>
      );
}

const mapStateToProps = (state: AdminStoreState) : { selectFormChannels: Channel[], selectedChannel: number } => {
    return {
        selectFormChannels: selectCurrentChannels(state),
        selectedChannel: selectedChannelId(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TChannelsTableActions>) => {
    return {
        updateChannelAction: (data: UpdateActionData) => {dispatch<IUpdateChannel>({type: ChannelsTableActionTypes.UpdateChannel, data: data})},
        loadThreadsAction: (data: Thread[] | number) => {
            dispatch<ILoadThreads>({ type: ChannelsTableActionTypes.LoadThreads, data: data })
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateChannelModal));