import React, { useState, useEffect } from 'react';
import { ChannelsTableProps, channelsTableTheme, Channel, SubChannel, Thread, TableNames } from './channelsTable.types';
import { GET_SUBCHANNELS, GET_THREADS } from './queries';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    InputBase,
    Popover,
    Typography,
    Container,
    makeStyles
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Dispatch } from 'redux';
import SearchIcon from '@material-ui/icons/Search';
import { ChannelsTableActionTypes } from '../../redux/channels-table/channels.types';
import {
    TChannelsTableActions,
    ILoadSubchannels,
    ILoadThreads,
    IResetThreads,
} from '../../redux/channels-table/channels.actions';
import './channelsTable.styles.scss';
import { useLazyQuery } from '@apollo/react-hooks';
import SyncLoader from 'react-spinners/SyncLoader';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import AddNewChannelModal from './addNewChannelModal';
import UpdateChannelModal from './updateChannelModal';
import DeleteChannelModal from './deleteChannelModal';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectedChannelId, selectedSubChannelId } from '../../redux/channels-table/channels.selectors';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const ChannelsTable: React.FC<ChannelsTableProps> = ({ ...props }) => {
    const { channelData, tableName, selectedChannelId, selectedSubChannelId, loadSubchannelsAction, loadThreadsAction, resetThreadsAction } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [selectedItemId, setSelectedItemId] = useState(
        TableNames.Channels ?
            selectedChannelId :
            (TableNames.SubChannels ?
                selectedSubChannelId : 0)
    );
    const [searchFilter, setSearchFilter] = useState("");
    const [fetchSubchannelData, { data: subchannelData, loading, error }] = useLazyQuery(GET_SUBCHANNELS, { fetchPolicy: 'cache-and-network' });
    const [fetchThreadData, {data: threadData, loading: loadingThread, error: threadError}] = useLazyQuery(GET_THREADS, {fetchPolicy: 'cache-and-network'})

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const LoadChildren = (channel: Channel) => {
        if (tableName === TableNames.Channels) {
            resetThreadsAction();
            setSelectedItemId(channel.id);
            fetchSubchannelData({
                variables: {
                    id: channel.id
                }
            });
        } else if (tableName === TableNames.SubChannels) {
            setSelectedItemId(channel.id);
            fetchThreadData({
                variables: {
                    id: channel.id
                }
            });
        }
    }

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;
        setSearchFilter(value);
    }

    useEffect(() => {
        if (subchannelData && subchannelData.getAllSubChannelsByChannelId) {
            if (subchannelData.getAllSubChannelsByChannelId.length > 0)
                loadSubchannelsAction(subchannelData.getAllSubChannelsByChannelId);
            else
                loadSubchannelsAction(selectedItemId);
        }
    }, [subchannelData]);

    useEffect(() => {
        if (threadData && threadData.getAllThreadsBySubChannelId) {
            if (threadData.getAllThreadsBySubChannelId.length > 0) {
                loadThreadsAction(threadData.getAllThreadsBySubChannelId);
            } else
                loadThreadsAction(selectedItemId);
        }
    }, [threadData]);

    return (
        <MuiThemeProvider theme={channelsTableTheme}>
            <Box m={1}>
                <div className='header-container'>
                    <span className='table-header'>{tableName + " (" + channelData.length + ")"} </span>
                    <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} color={"#36D2B3"}
                        loading={loading} />
                    <AddNewChannelModal tableName={tableName} />
                </div>
                {tableName === TableNames.SubChannels || tableName === TableNames.Threads ?
                    <div className='search-container'>
                        <div className='search-icon'>
                            <SearchIcon />
                        </div>
                        <InputBase placeholder="Search…" disabled={channelData.length === 0}
                            value={searchFilter} inputProps={{ 'aria-label': 'search' }}
                            onChange={handleSearchChange} />
                    </div> : null}
                <div className='table-content-container'>
                    {
                        channelData.length === 0 && !loading ? <List component="nav" aria-label="wil-channel">
                            <ListItem button={false}>
                                <ListItemText primary="No content shown." />
                            </ListItem>
                            <Divider />
                        </List>
                            :
                            // TODO: extract the filtering and sorting in a separate function
                            (channelData as any[]).filter(channel => channel.title.toLowerCase().includes(searchFilter.toLowerCase()))
                            .sort((channel1: any, channel2: any) => {
                                var nameA = channel1.title.toUpperCase();
                                var nameB = channel2.title.toUpperCase();
                    
                                if (nameA < nameB) {
                                  return -1;
                                }
                                if (nameA > nameB) {
                                  return 1;
                                }
                                return 0;
                            })
                            .map((channel: any) => {

                                return <div key={channel.id}>
                                    <ListItem component="div" aria-label="wil-channel">
                                        <ListItem button selected={channel.id === selectedItemId}
                                            onClick={() => LoadChildren(channel)}>
                                            <ListItemText primary={channel.title} secondary={"ID: " + channel.id} />
                                            {tableName === TableNames.Threads && channel.hasContent === false ?
                                                <Container
                                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen}
                                                    onMouseLeave={handlePopoverClose}
                                                >
                                                    <ErrorIcon style={{ color: "red", textAlign: "right" }}></ErrorIcon>
                                                    <Popover
                                                        id="mouse-over-popover"
                                                        className={classes.popover}
                                                        classes={{
                                                            paper: classes.paper,
                                                        }}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        onClose={handlePopoverClose}
                                                        disableRestoreFocus
                                                    >
                                                        <Typography style={{ color: 'red' }}>No Content</Typography>
                                                    </Popover>
                                                </Container>
                                                : null}
                                        </ListItem>
                                        <UpdateChannelModal tableName={tableName} selectedItem={channel} />
                                        <DeleteChannelModal tableName={tableName} selectedItemId={channel.id} />
                                    </ListItem>
                                    <Divider />
                                </div>
                            })
                    }
                </div>
            </Box>
        </MuiThemeProvider>
    );
}

const mapStateToProps = (state: AdminStoreState): { selectedChannelId: number, selectedSubChannelId: number } => {
    return {
        selectedChannelId: selectedChannelId(state),
        selectedSubChannelId: selectedSubChannelId(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TChannelsTableActions>) => {
    return {
        loadSubchannelsAction: (data: SubChannel[] | number) => {
            dispatch<ILoadSubchannels>({ type: ChannelsTableActionTypes.LoadSubChannels, data: data })
        },
        loadThreadsAction: (data: Thread[] | number) => {
            dispatch<ILoadThreads>({ type: ChannelsTableActionTypes.LoadThreads, data: data })
        },
        resetThreadsAction: () => {
            dispatch<IResetThreads>({ type: ChannelsTableActionTypes.ResetThreads })
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsTable);