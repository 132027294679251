import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {adminStore, history, persistedAdminStore} from './redux/store';
import ApolloClient from "apollo-boost";
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ApolloHooksProvider} from "react-apollo-hooks";
import { PersistGate } from 'redux-persist/integration/react';
import {config} from "./config";

const adminClient = new ApolloClient({
    uri: `${config.REACT_APP_ADMIN_SERVER_HOST}/graphql`,
    credentials: 'include'
});

ReactDOM.render(
    <ApolloProvider client={adminClient}>
        <ApolloHooksProvider client={adminClient}>
            <Provider store={adminStore}>
                <PersistGate loading={null} persistor={persistedAdminStore}>
                    <App />
                </PersistGate>
            </Provider>
        </ApolloHooksProvider>
    </ApolloProvider>,
    document.getElementById('root')
);