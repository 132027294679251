import { RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from "../../globals/locations/location.types"

export const doesCountryExistInRapidApi = (countries: RapidApiCountryDto[], selectedCountry: RapidApiCountryDto): boolean => {
    if (selectedCountry) {
        const isValid = countries.find(country => {
            return country.country === selectedCountry.country && country.countryCode === selectedCountry.countryCode;
        })

        if (isValid) {
            return true;
        }
    }
    return false;
}

export const doesProvinceExistInRapidApi = (provinces: RapidApiProvinceDto[], selectedProvince: RapidApiProvinceDto): boolean => {
    if (selectedProvince) {
        const isValid = provinces.find(province => {
            return province.province === selectedProvince.province && province.isoCode === selectedProvince.isoCode;
        })

        if (isValid) {
            return true;
        }
    }
    return false;
}

export const doesCityExistInRapidApi = (cities: RapidApiCityDto[], selectedCity: RapidApiCityDto): boolean => {
    if (selectedCity) {
        const isValid = cities.find(city => {
            return city.city === selectedCity.city;
        })

        if (isValid) {
            return true
        }
    }

    return false;
}