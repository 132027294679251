import { User } from "../../redux/admin/admin.types";
import {UserUploadedContent} from "../../components/content-list/contentList.types";

export enum WaitingListActionTypes {
    LOADED_WAITING_LIST_DATA = "LOADED_WAITING_LIST_DATA",
    APPROVE_CONTENT = "APPROVE_CONTENT",
    REJECT_CONTENT = "REJECT_CONTENT",
    UPDATE_METADATA = "UPDATE_METADATA",
    TOGGLE_ADDCONTENT_MODAL = "TOGGLE_ADDCONTENT_MODAL",
    TOGGLE_CONTENT_USER_ID = "TOGGLE_CONTENT_USER_ID",
    SET_WAITING_TABLE_CURRENT_PAGE = "SET_WAITING_TABLE_CURRENT_PAGE",
}

export interface WaitingListData {
    data: UserUploadedContent;
}

export interface Content {
    id: number;
    type: string;
    headline: string;
    publishDate: Date;
    pathToFileCompressed: string;
    pathToFileThumbnail: string;
    code: string;
    licensingType: string;
    user?: User;
}

export interface Contributor {
    id: number;
    firstName: string;
    lastName: string;
}

export interface WaitingListState {
    waitingListData: UserUploadedContent[];
    isModalOpen: boolean;
    contentUserId: number;
    waitingListCurrentPageNumber: number;
}