import React, { useEffect } from 'react';
import { LoginComponentProps } from './loginPage.types';
import LoginContainer from '../login-admin/LoginContainer';
import './loginPage.styles.scss';

const LoginComponent: React.FC<LoginComponentProps> = ({currentUser, history}) => {

    useEffect(() => {
        if(currentUser && currentUser.email) {
            history.push('/users');
        }
    })

    return (
        <div className='login-container'>
            <LoginContainer/>
        </div>
    )
}

export default LoginComponent;