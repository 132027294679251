import { WithStyles } from "@material-ui/core";

export interface LicencePlan {
  id: number;
  amount: number;
  type: string;
  totalPrice: number;
  period?: number;
  licensingType: string;
}

export interface FlatRate {
  id: number;
  licensingType: string;
  contentType: string;
  role: string;
  flatRate: number;
}

export interface Editorial {
  id: number;
  rate: number;
  contentType: string;
  size: string;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  licenseType: string;
}
export interface DataTableProps {
  licenseType: string;
  licenseTableRefreshState: boolean;
  toggleLicenseTableRefresh: (data: boolean) => void;
}

export enum LicensePlanTypes {
  Subscription = 'Subscription',
  Credit = 'Credit'
}

export enum LicenseTableTabTypes {
  EDITORIAL = 'Editorial',
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
  FLAT_RATES = 'Flat rates',
  OVERRIDES = 'Overrides',
  COMMISSIONS = 'Commissions',
}

export interface NewLicenseProps extends WithStyles<typeof styles> {
  licenseType: string;
  refetch: () => void;
}

export interface UpdateLicensePlanProps extends WithStyles<typeof styles> {
  licenseType: string;
  id: number;
  refetch: () => void;
  data: any;
}

export interface NewContentRateProps extends WithStyles<typeof styles> {
  rowIndex: any;
  licenseTable: FlatRate[];
  addPlanAction: (data: FlatRate) => void;
  loadedDataAction: (data: FlatRate[]) => void;
  reFetch: () => void;
}
export interface UpdateContentRatesProps extends WithStyles<typeof styles> {
  id: number;
  data: any;
  refetch: () => void;
}
export interface NewEditorialRateProps extends WithStyles<typeof styles> {
  licenseTable: Editorial[];
  refetch: () => void;
}
export interface UpdateEditorialRatesProps extends WithStyles<typeof styles> {
  id: number;
  refetch: () => void;
  data: any;
}

export enum SingleContentTypes {
  Image = "image",
  Video = "video",
  Audio = "audio"
}

export enum LicenseTypeValues {
  Editorial = "editorial",
  Standard = "standard",
  Premium = "premium"
}
export enum EditorialSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
  OnlineOnly = "online only",
}

export enum RoleTypesMessages {
  pro_organization = "PM-Organization",
  pro_freelancer = "PM-Freelancer",
  mw_user= "MW-User"
}

export const styles = {
  error: {
    color: 'red',
    fontSize: '10px',
    width: '240px'
  }
}