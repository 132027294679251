import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectTableRefreshStates = (state: AdminStoreState) => state.tablesRefresh;

export const selectLocationOverrideRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.locationOverrideRefresh
);

export const selectWaitingListRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.waitingListRefresh
);

export const selectUsersTableRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.usersTableRefresh
);

export const selectSuggestThreadRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.suggestThreadRefresh
);

export const selectReportsTableRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.reportsTableRefresh
);

export const selectPostReportsTableRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.postReportsTableRefresh
);

export const selectOrderHistoryRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.orderHistoryTableRefresh
);

export const selectLicenseTableRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.licenseTableRefresh
);

export const selectIncompleteTableRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.incompleteTableRefresh
);

export const selectContentTableRefresh = createSelector(
    [selectTableRefreshStates],
    (tables) => tables.contentTableRefresh
);