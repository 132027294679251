import React from 'react'
import { UserUploadedContent } from "../../components/content-list/contentList.types";
import { RoleTypes } from "../../components/users-table/usersAdminTable.types";
import { ContentTypes } from "./waitingList.types";
import Moment from "react-moment";

const convertRole = (item: any) => {
    if (item.contributor.role === RoleTypes.Contributor_agency) {
        return 'Agency';
    }
    else {
        return 'Individual'
    }
}

const getName = (item: UserUploadedContent) => {
    if (item.contributor) {
        if (item.contributor.role === RoleTypes.Pro_organization || item.contributor.role === RoleTypes.Contributor_agency) {
            return item.contributor.company ? item.contributor.company.name : "N/A";
        }
        else {
            return item.contributor.person ? item.contributor.person.firstName + " " + item.contributor.person.lastName : "N/A";
        }
    }
}

const getItemIdByType = (item: UserUploadedContent) => {
    if (item.type === ContentTypes.CONTENT_SET) {
        return item.contentSetId;
    } else if (item.type === ContentTypes.ARTICLE) {
        return item.articleId;
    }
    else {
        return item.content ? item.content.id : '';
    }
}

export const createTableContentRow = (item: UserUploadedContent) => {
    return [
        item.content ? item.content.pathToFileThumbnail : '',
        getItemIdByType(item),
        item.content ? item.content.code : '',
        item.title,
        item.type,
        item.contributor.id,
        item.contributor ? item.contributor.username : "N/A",
        getName(item),
        item.contributor ? convertRole(item) : 'N/A',
        item.contentNavigations ? item.contentNavigations.map(nav => {
            return nav.channel.title + ' => ' + nav.subChannel.title + ' => ' + nav.thread.title + "\n"
        }) : '',
        <Moment format="DD/MM/YYYY">{item.uploadDate}</Moment>,
        item.content ? item.content.licensingType : ""
    ];
};