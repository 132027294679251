import { MenuItem } from "@material-ui/core"
import React, { ReactNode } from "react"

export const getEnumValues = (myEnum: any):ReactNode => {
    return (Object.keys(myEnum) as (keyof typeof myEnum)[]).map(
        (key: any) => {
             return <option key={key} value={key}>{myEnum[key]}</option>
        }
    )
        
}