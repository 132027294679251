import React from 'react';
import { HomeComponentProps } from './Home.types';
import './Home.styles.scss';

const HomeComponent: React.FC<HomeComponentProps> = ({...props}) => {
    return (
        <div></div>
    )
}

export default HomeComponent;