import React from "react";
import { IconButton, Dialog, Select, Button, Backdrop, Fade, TextField, MuiThemeProvider, InputLabel, withStyles, FormLabel, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationModalProps } from "./incompleteUpload.types";
import { dialogStyles, styles } from '../users-table/usersAdminTable.types';
import "./incompleteUpload.styles.scss";

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ ...props }) => {
    const { incompleteFlowId, open, handleClose, deleteFlow } = props;


    const onDelete =  () => {
        deleteFlow(incompleteFlowId);
        handleClose();
    }

    return (
        <React.Fragment>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title delete-modal-heading'>Are you sure you want to delete this flow</h1>
                                <div>
                                    <Button className='submit-button buttons' variant='contained' onClick= {onDelete}>Yes</Button>
                                    <Button className='submit-button buttons' variant='contained' onClick= {handleClose} >No</Button>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}

export default (withStyles(styles)(ConfirmationModal));