import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectChannels = (state: AdminStoreState) => state.channelsTable;

export const selectCurrentChannels = createSelector(
    [selectChannels],
    (channels) => channels.channels
);

export const selectCurrentSubChannels = createSelector(
    [selectChannels],
    (channels) => channels.subchannels
);

export const selectCurrentThreads = createSelector(
    [selectChannels],
    (channels) => channels.threads
);

export const selectedChannelId = createSelector(
    [selectChannels],
    (channels) => channels.selectedChannelId
);

export const selectedSubChannelId = createSelector(
    [selectChannels],
    (channels) => channels.selectedSubChannelId
);

export const selectedThreadId = createSelector(
    [selectChannels],
    (channels) => channels.selectedThreadId
);