import gql from "graphql-tag";

export const GET_ALL_INCOMPLETE_FLOWS = gql`
    query getAllIncompleteFlowsQuery {
        getAllIncompleteFlows {
            incompleteFlowId
            lastEditedDate
            flowTitle
            contributor {
                id
                email
                username
                role
                person {
                    id
                    firstName
                    lastName
                }
                company {
                    id
                    name
                }     
            }
            contents {
                id
                code
                headline
                publishDate
                pathToFileCompressed
                pathToFileThumbnail
                objectName
                externalLink
                bucketKey
            }
            contentSets {
                contentSet {
                    contentSetId
                    title
                    coverId
                }
                content {
                    id
                    code
                    headline
                    publishDate
                    pathToFileCompressed
                    pathToFileThumbnail
                    objectName
                    externalLink
                    bucketKey
                }
                contentNavigation {
                    id
                    title
                }
            }
            articles {
                articleId
                contentSetId
                title
                description
                audioId
            }
            audioFiles {
                id
                userId
                pathToFile
                objectName
                externalLink
                bucketKey
            }
        }
    }
`;

export const COMPLETE_INCOMPLETE_FLOW = gql`
    mutation completeUserIncompleteUploadFlow($incompleteFlowId: String!, $newSetsAndContentIds: [ContentSetUploadResponseInput!]!, $newArticleIds: [Int!]!) {
        completeUserIncompleteUploadFlow(incompleteFlowId: $incompleteFlowId, newSetsAndContentIds: $newSetsAndContentIds, newArticleIds: $newArticleIds)
    }
`;

export const UPDATE_INCOMPLETE_FLOW = gql`
    mutation updateUserIncompleteUploadFlow($incompleteFlowId: String!, $contentIds: [Int!]!, $contentSetIds: [Int!]!) {
        updateUserIncompleteUploadFlow(incompleteFlowId: $incompleteFlowId, contentIds: $contentIds, contentSetIds: $contentSetIds)
    }
`;

export const DELETE_INCOMPLETE_FLOW = gql`
    mutation deleteIncompleteFlowMutation($incompleteFlowId: String!) {
        deleteIncompleteFlow(incompleteFlowId: $incompleteFlowId)
    }
`;