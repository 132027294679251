import React, { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "./imageSlider.styles.scss";
import { IconButton } from "@mui/material";
import SyncLoader from "react-spinners/SyncLoader";

interface ImageSliderProps {
  images: string[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);

  const handleClickLeft = () => {
    setCurrentImageIndex((prev) => {
      return prev > 0 ? prev - 1 : images.length - 1;
    });
  };

  const handleClickRight = () => {
    setCurrentImageIndex((prev) => {
      return prev < images.length - 1 ? prev + 1 : 0;
    });
  };

  console.log("images", images);
  return (
    <div className="slider-container">
      {images.length > 1 ? <IconButton className="slider-button" onClick={handleClickLeft}>
        <ArrowBackIosIcon />
      </IconButton> : <div></div>}
      <div className="slider-content">
      {images.length > 1 && loaded ?<p className="image-counter">{currentImageIndex + 1}/{images.length}</p>:<p></p>}
      <div className="slider-image-container">
        <img
          className="slider-image"
          src={images[currentImageIndex]}
          onLoad={() => setLoaded(true)}
        />
        {!loaded && (
          <div className="loader-container">
            <SyncLoader
              css={`
                display: block;
                margin: 0 auto;
                border-color: red;
              `}
              size={10}
              color={"#36D2B3"}
              loading={!loaded}
            />
          </div>
        )}
      </div>
      </div>
      
      {images.length > 1 ? <IconButton className="slider-button" onClick={handleClickRight}>
        <ArrowForwardIosIcon />
      </IconButton> : <div></div>}
    </div>
  );
};

export default ImageSlider;
