import React, { useState, useEffect } from 'react';
import {
    ManageContentProps,
    manageContentTheme,
    ContentSet,
    Article,
    UploadedFileResponse,
    RadioButtonGroup,
    ContentUploadMethods
} from './ContentUpload.types';
import { AdminStoreState } from '../../redux/root-reducer';
import {
    selectUploadedFiles,
    selectContentSets,
    selectRadioButtonValue,
    selectArticles
} from '../../redux/content-upload/content-upload.selectors';
import AddIcon from '@material-ui/icons/Add';
import { Button, Accordion, AccordionSummary, AccordionDetails, AccordionActions, ListItem, MuiThemeProvider, makeStyles, TextField, InputAdornment, IconButton, FormControlLabel, MenuItem, Zoom, Popper, Grow, Paper, MenuList, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dispatch } from 'redux';
import { TContentUploadActions, IUploadSet, IUpdateSet, IUploadFiles, IDeleteFiles, IDeleteArticle, IDeleteSet } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { EditableTextField } from 'shared';
import './ManageContent.styles.scss';
import { v4 as uuidv4 } from 'uuid';
import { SnackbarComponent } from 'shared';
import PopperComponent from './PopperComponent';
import Image from 'react-async-image';
import DoneIcon from '@material-ui/icons/Done';
import { ConfirmationDialog } from 'shared';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';

const useStyles = makeStyles((theme) => ({
    coverPhoto: {
        backgroundColor: 'rgb(12, 175, 149)'
    }
}));

const ManageContent: React.FC<ManageContentProps> = ({ ...props }) => {
    const { selectedRadioButton, snackbar, articles, uploadedFiles, contentSetsState, selectedContentUploadMethod,
        uploadSetAction, updateSetAction, deleteSetAction, uploadFilesAction, deleteArticleAction,
        deleteFilesAction, broadcastSnackbarAction } = props;
    const [selectedContentSetImages, setSelectedContentSetImages] = useState<number[]>([]);
    const [selectedSetId, setSelectedSetId] = useState<string>('');
    const [selectedImages, setSelectedImages] = useState<number[]>([]);
    const [contentSets, setContentSets] = useState<ContentSet[]>([]);
    const [contentSetTitle, setContentSetTitle] = useState('');
    const [newSetTitle, setNewSetTitle] = useState<string>('');
    const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);

    const [removeSetConfirmationDialog, setRemoveSetConfirmationDialog] = useState(false);
    const [removeSetId, setRemoveSetId] = useState("");
    const classes = useStyles();

    useEffect(() => {
        setContentSets(contentSetsState);
    }, [contentSetsState]);

    useEffect(() => {
        if (snackbar.severity && snackbar.message) {
            setContentUploadSnackbar(true);
        }
    }, [snackbar])

    const toggleUploadFilesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElem(anchorElem ? null : event.currentTarget);
    }

    const handleClose = () => {
        setContentUploadSnackbar(false);
    };

    const handleFilesClick = (id: number) => {
        if (!selectedImages.includes(id)) {
            setSelectedImages([...selectedImages, id]);
            setSelectedContentSetImages([]);
        } else {
            setSelectedImages(selectedImages.filter(item => item !== id));
        }
    }

    const handleContentSetClick = (setId: string, fileId: number) => {
        if (selectedSetId === '') {
            setSelectedSetId(setId);
        }
        else if (selectedSetId !== setId) {
            setSelectedContentSetImages([]);
            setSelectedSetId(setId);
        }

        if (!selectedContentSetImages.includes(fileId)) {
            setSelectedContentSetImages([...selectedContentSetImages, fileId]);
            setSelectedImages([]);
        } else {
            setSelectedContentSetImages(
                selectedContentSetImages.filter((item) => item !== fileId)
            );
        }
    }

    const selectAllPhotosInContentSet = (setId: string) => {
        setSelectedSetId(setId);
        setSelectedImages([]);
        let selectedSet = contentSets.find((set: ContentSet) => set.id === setId);
        if (selectedSet) {
            let setIds = selectedSet.files.map((file: UploadedFileResponse) => { return file.id });
            setSelectedContentSetImages(setIds);
        }
    }

    const unselectAllPhotos = () => {
        setNewSetTitle("");
        setSelectedSetId("");
        setSelectedImages([]);
        setSelectedContentSetImages([]);
    }

    const selectAllUploadedPhotos = () => {
        setSelectedSetId("");
        setSelectedContentSetImages([]);
        let uploadedFilesIds = uploadedFiles.map((file: UploadedFileResponse) => { return file.id });
        setSelectedImages(uploadedFilesIds);
    }

    const createNewSet = () => {
        const set: ContentSet = {
            id: uuidv4(),
            title: "",
            files: uploadedFiles.filter((file: UploadedFileResponse) =>
                selectedImages.includes(file.id)
            ),
        };
        setContentSets([...contentSets, set]);
        setSelectedImages([]);
        uploadSetAction(set);
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "New content set created successfully"
        });
    }

    const handleChange = (event: any) => {
        event.preventDefault();

        if (event.target.name === 'newSet') {
            setNewSetTitle(event.target.value);
        }
    }

    const changeSetTitle = (set: ContentSet, text: string) => {
        if (text.length <= 1 || text.length >= 500) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.warning,
                message: "A content set title must contain at least 1 symbol and not exceed 500 symbols"
            });
        } else if (contentSets.find((contentSet: ContentSet) => contentSet.title === text && contentSet.id !== set.id)) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "A content set with this title already exists. Choose a different name"
            });
        } else {
            const updatedSet: ContentSet = {
                id: set.id,
                title: text,
                files: set.files,
                coverPhoto: set.coverPhoto,
            };

            updateSetAction(updatedSet);
        }

        setContentSetTitle("");
    }

    const moveToNewSet = (selectedUploadedImages?: number[]) => {
        if (selectedUploadedImages && newSetTitle) {
            if (newSetTitle.length <= 1 || newSetTitle.length >= 500) {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.warning,
                    message: "A content set title must contain at least 1 symbol and not exceed 500 symbols"
                });
                return;
            }

            const uploadedImages = uploadedFiles.filter((file) =>
                selectedImages.includes(file.id)
            );

            const newSet: ContentSet = {
                id: uuidv4(),
                title: newSetTitle,
                files: uploadedImages,
            };

            uploadSetAction(newSet);
            deleteFilesAction(selectedImages);

        } else {
            const setWithSelectedFiles = contentSets.find((set: ContentSet) => set.id === selectedSetId);
            const selectedFiles = setWithSelectedFiles!.files.filter((file: UploadedFileResponse) => selectedContentSetImages.includes(file.id));

            if (setWithSelectedFiles?.coverPhoto && selectedFiles.includes(setWithSelectedFiles?.coverPhoto)) {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Cannot move cover photo to new set. First change the cover photo"
                });
                unselectAllPhotos();
                return;
            }

            const newSet: ContentSet = {
                id: uuidv4(),
                title: newSetTitle,
                files: selectedFiles,
            };

            uploadSetAction(newSet);

            const updatedOldSet: ContentSet = {
                id: setWithSelectedFiles!.id,
                title: setWithSelectedFiles!.title,
                files: setWithSelectedFiles!.files.filter(
                    (file: UploadedFileResponse) =>
                        !selectedContentSetImages.includes(file.id)
                ),
                coverPhoto: setWithSelectedFiles?.coverPhoto,
            };

            updateSetAction(updatedOldSet);

            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Photos moved successfully"
            });
        }
        unselectAllPhotos();
    }

    const moveToExistingSet = (selectedSet: ContentSet, selectedUploadedImages?: number[]) => {
        if (selectedUploadedImages) {
            const uploadedImages = uploadedFiles.filter((file) =>
                selectedImages.includes(file.id)
            );

            if (selectedSet) {
                const updatedSet: ContentSet = {
                    id: selectedSet.id,
                    title: selectedSet.title,
                    files: selectedSet.files.concat(uploadedImages),
                    coverPhoto: selectedSet.coverPhoto,
                };

                updateSetAction(updatedSet);
            }

            deleteFilesAction(selectedUploadedImages);
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Photos moved successfully"
            });
            return;
        }

        const setWithSelectedFiles = contentSets.find((set: ContentSet) => set.id === selectedSetId);
        const selectedFiles = setWithSelectedFiles!.files.filter((file: UploadedFileResponse) => selectedContentSetImages.includes(file.id));

        if (setWithSelectedFiles?.coverPhoto && selectedFiles.includes(setWithSelectedFiles?.coverPhoto)) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Cannot move cover photo to new set. First change the cover photo"
            });
            setSelectedContentSetImages([]);
            setSelectedSetId("");
            setNewSetTitle("");
            return;
        }

        if (selectedSet) {
            const updatedSet: ContentSet = {
                id: selectedSet.id,
                title: selectedSet.title,
                files: selectedSet.files.concat(selectedFiles),
                coverPhoto: selectedSet.coverPhoto,
            };

            updateSetAction(updatedSet);

            const updatedOldSet: ContentSet = {
                id: setWithSelectedFiles!.id,
                title: setWithSelectedFiles!.title,
                files: setWithSelectedFiles!.files.filter(
                    (file: UploadedFileResponse) =>
                        !selectedContentSetImages.includes(file.id)
                ),
                coverPhoto: setWithSelectedFiles?.coverPhoto,
            };
            if (updatedOldSet.files.length > 0) {
                updateSetAction(updatedOldSet);
            } else {
                removeContentSet(updatedOldSet.id);
            }

            setSelectedContentSetImages([]);
            setSelectedSetId("");
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.success,
                message: "Photos moved successfully"
            });
        }
    }

    const selectCoverPhoto = (selectedPhotoId: number, set: ContentSet) => {
        let allSets = contentSets;

        let setIndex = allSets.findIndex((contentSet: ContentSet) => contentSet.id === set.id);
        let coverPhotoFile = set.files.find((item: UploadedFileResponse) => item.id === selectedPhotoId);

        if (setIndex !== -1) {
            allSets[setIndex].coverPhoto = coverPhotoFile;
            updateSetAction(allSets[setIndex]);
        }

        setSelectedContentSetImages([]);
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Cover photo set successfully"
        });
    }

    const removeContentSet = (id: string) => {
        setRemoveSetId(id);
        setRemoveSetConfirmationDialog(true);
    };

    const handleRemoveSetDialogAgree = () => {
        setRemoveSetConfirmationDialog(false);

        if (removeSetId !== "") {
            const setToRemove = contentSets.find((set: ContentSet) => set.id === removeSetId);
            const articleExists = articles.find((article: Article) => article.contentSetId === removeSetId);

            if (setToRemove) {
                uploadFilesAction(setToRemove.files);
                deleteSetAction(setToRemove.id);
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Content set removed successfully"
                });
            }

            if (articleExists) {
                deleteArticleAction(articleExists.contentSetId);
            }
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to find content set"
            });
        }
    }

    const handleRemoveSetDialogClose = () => {
        setRemoveSetConfirmationDialog(false);
        setRemoveSetId("");
    }

    return (
        <MuiThemeProvider theme={manageContentTheme}>
            <div className='content-sets-container'>
                {contentSets.map((set: ContentSet) => (
                    <Zoom in={true} key={set.id}>
                        <Accordion defaultExpanded={false}
                            TransitionProps={{ unmountOnExit: selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id='category-image-set'>
                                <Tooltip title={set.title} disableFocusListener disableTouchListener>
                                    <FormControlLabel aria-label='content-set-title'
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <EditableTextField value={set.title} name="textfield" autoFocus={true}
                                                onSubmit={(text: string) => changeSetTitle(set, text)} />
                                        } label='' />
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='images-container'>
                                    {set.files.map((file: UploadedFileResponse) => (
                                        <div key={file.id} className="preview-box">
                                            <ListItem button selected={selectedContentSetImages.includes(file.id)}
                                                classes={{ gutters: set.coverPhoto?.id === file.id ? classes.coverPhoto : '' }}
                                                onClick={() => handleContentSetClick(set.id, file.id)} aria-label="selectable-image">
                                                <Image src={file.url} className="image" loading='auto'
                                                    placeholder={<div className="placeholder">Failed to display...</div>} />
                                            </ListItem>
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                            <AccordionActions>
                                <div className='manage-content-buttons'>
                                    <PopperComponent currentSetId={set.id} selectedContentSetImages={selectedContentSetImages}
                                        selectedSetId={selectedSetId} contentSets={contentSets} newSetTitle={newSetTitle}
                                        moveToExistingSet={moveToExistingSet} moveToNewSet={moveToNewSet} handleChange={handleChange} />

                                    {selectedSetId === set.id && selectedContentSetImages.length === set.files.length ?
                                        <Button size="small" className="white-button"
                                            onClick={() => unselectAllPhotos()} variant="contained"
                                            component="span">Unselect all</Button>
                                        :
                                        <Button size="small" className="white-button"
                                            onClick={() => selectAllPhotosInContentSet(set.id)} variant="contained"
                                            component="span">Select all</Button>
                                    }
                                    <Button size='small' className='white-button'
                                        disabled={selectedContentSetImages.length !== 1 || selectedSetId !== set.id}
                                        onClick={() => selectCoverPhoto(selectedContentSetImages[0], set)}
                                        variant='contained' component="span">
                                        Select as Cover
                                    </Button>

                                    <Button size="small" className='white-button'
                                        onClick={() => removeContentSet(set.id)} variant='contained' component="span">
                                        Remove Content Set
                                    </Button>
                                </div>
                            </AccordionActions>
                        </Accordion>
                    </Zoom>
                ))}

                <div className='uploaded-files-container'>
                    {uploadedFiles.length > 0 ? (
                        <label className="uploaded-files">
                            Uploaded files need to be arranged in content sets before proceeding to next step
                        </label>
                    ) : null}
                    {uploadedFiles.map((file: UploadedFileResponse) => (
                        <div className="preview-box" key={file.id}>
                            <ListItem
                                button
                                selected={selectedImages.includes(file.id)}
                                onClick={() => handleFilesClick(file.id)}
                                aria-label="selectable-image"
                            >
                                <Image src={file.url} className="image" loading='auto'
                                    placeholder={<div className="placeholder">Failed to display...</div>} />
                            </ListItem>
                        </div>
                    ))}
                </div>

                {selectedRadioButton === RadioButtonGroup.SET ? (
                    <div className="manage-content-buttons">
                        {selectedImages.length === uploadedFiles.length && selectedImages.length > 0 ?
                            <Button
                                size="small"
                                className="white-button"
                                onClick={() => unselectAllPhotos()}
                                variant="contained"
                                component="span">
                                Unselect all
                            </Button>
                            :
                            <Button
                                size="small"
                                className="white-button"
                                disabled={uploadedFiles.length === 0}
                                onClick={() => selectAllUploadedPhotos()}
                                variant="contained"
                                component="span">
                                Select all
                            </Button>
                        }
                        <Button
                            size="small"
                            className="white-button"
                            hidden={uploadedFiles.length === 0}
                            disabled={uploadedFiles.length === 0}
                            onClick={createNewSet}
                            variant="contained"
                            component="span"
                            endIcon={<AddIcon />}
                        >
                            Add to set
                        </Button>
                        <Button
                            size="small"
                            className="white-button"
                            aria-controls="move-to-set-menu"
                            aria-haspopup="true"
                            disabled={selectedImages.length === 0}
                            onClick={toggleUploadFilesMenuClick}
                            variant="contained"
                            component="span"
                            endIcon={<ExpandMoreIcon />}
                        >
                            Move to set
                        </Button>
                        <Popper
                            open={Boolean(anchorElem)}
                            anchorEl={anchorElem}
                            role={undefined}
                            transition
                            disablePortal
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                >
                                    <Paper>
                                        <MenuList
                                            autoFocusItem={Boolean(anchorElem)}
                                            id="menu-list-grow"
                                        >
                                            {contentSets!.map((set: ContentSet) => (
                                                <MenuItem
                                                    key={set.id}
                                                    onClick={() => {
                                                        setAnchorElem(null);
                                                        moveToExistingSet(set, selectedImages);
                                                    }}
                                                >
                                                    {set.title}
                                                </MenuItem>
                                            ))}
                                            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                                                <TextField
                                                    autoComplete="off"
                                                    variant="standard"
                                                    name="newSet"
                                                    value={newSetTitle}
                                                    placeholder="New Set"
                                                    data-shrink={false}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment:
                                                            newSetTitle.length > 0 ? (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setAnchorElem(null);
                                                                            moveToNewSet(selectedImages);
                                                                        }}
                                                                    >
                                                                        <DoneIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ) : null,
                                                    }}
                                                />
                                            </MenuItem>
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                ) : null}
            </div>
            <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                severity={snackbar.severity}
                message={snackbar.message} />
            <ConfirmationDialog open={removeSetConfirmationDialog} title="Removal of content set"
                contentText="Are you sure you want to remove this content set? If there is an article linked to this content set it will be
             removed as well and all files will be marked as unsorted"
                rejectButtonText="No"
                acceptButtonText="Yes"
                handleClose={handleRemoveSetDialogClose}
                handleConfirmationDialogAgree={handleRemoveSetDialogAgree} />
        </MuiThemeProvider>
    )
}

const mapStateToProps = (state: AdminStoreState): {
    uploadedFiles: UploadedFileResponse[],
    contentSetsState: ContentSet[], selectedRadioButton: string,
    articles: Article[], snackbar: IBroadcastMessage
} => {
    return {
        uploadedFiles: selectUploadedFiles(state),
        contentSetsState: selectContentSets(state),
        selectedRadioButton: selectRadioButtonValue(state),
        articles: selectArticles(state),
        snackbar: selectSnackbarState(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions>) => {
    return {
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({ type: ContentUploadActionTypes.UPLOAD_SET, data: data }),
        updateSetAction: (data: ContentSet) => dispatch<IUpdateSet>({ type: ContentUploadActionTypes.UPDATE_SET, data: data }),
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>({ type: ContentUploadActionTypes.UPLOAD_FILES, data: data }),
        deleteFilesAction: (data: number[]) => dispatch<IDeleteFiles>({ type: ContentUploadActionTypes.DELETE_FILES, data: data }),
        deleteSetAction: (data: string) =>
            dispatch<IDeleteSet>({
                type: ContentUploadActionTypes.DELETE_SET,
                data: data
            }),
        deleteArticleAction: (data: string) => dispatch<IDeleteArticle>({ type: ContentUploadActionTypes.DELETE_ARTICLE, data: data }),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageContent);
