import gql from "graphql-tag";

export const GET_FULL_ORDER_HISTORY = gql`
query getFullOrderHistory {
    getFullOrderHistory {
        order {
            id
            orderDate
            totalPrice
            isFinished
            userId
        }
        contentOrders {
            id
            contentId
            contentPrice
              sellerCommission
              adminCommission
              sellerIncomePercentage
              userType
            orderId
            size
            amount
            totalPrice
            period
        }
        contents {
            id
            type
            headline
            publishDate
            pathToFileCompressed
            pathToFileThumbnail
            licensingType
            code
            user {
                username
                role
                person {
                    id
                    firstName
                    lastName
                }
                company {
                    id
                    name
                }
            }
        }
        buyer {
            username
            role
            person {
                id
                firstName
                lastName
            }
            company {
                id
                name
            }
        }
    }
}
`;