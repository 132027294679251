import { TOrderHistoryReducerActions } from "./orderHistory.actions";
import { OrderHistoryActionTypes, OrderHistoryState } from "./orderHistory.types";

const InitialState: OrderHistoryState = {
    orderHistoryData: []
}

export const orderHistoryReducer = (state = InitialState, action: TOrderHistoryReducerActions) : OrderHistoryState => {
    switch(action.type) {
        case OrderHistoryActionTypes.LOAD_ORDER_HISTORY_DATA: {
            return {
                ...state,
                orderHistoryData: action.data
            }
        }
        case OrderHistoryActionTypes.RESET_ORDER_HISTORY_STATE: {
            return {
                orderHistoryData: []
            }
        }
        default:
            return state;
    }
}

export default orderHistoryReducer;