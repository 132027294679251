import {Channel, SubChannel, Thread} from "../channels-table/channelsTable.types";
import {Content, EditContentType} from "../waiting-list/waitingList.types";
import { Article, AudioFile, ContentSet, IndividualImageCategory, UploadedFileResponse } from '../batch-upload/ContentUpload.types';
import {User} from "../../redux/admin/admin.types";
import {CopiedContentToSets, MovedContentToSets} from "../batch-upload/EditContentMetadata.types";
import { IBroadcastMessage } from "../../redux/generic/snackbar/snackbar.types";
import { ChannelFilter, SubchannelFilter, ThreadFilter } from "../../redux/table-filter/table-filter.types";
import { ImageAngle } from "../../redux/EditContent/editContent.types";

export const TIME_BEFORE_REDIRECT = 2500;
export interface ContentListProps {
    toolbarError: IBroadcastMessage;
    licensingTypeFilter: string;
    contributorTypeFilter: string;
    contentTypeFilter: string;
    uploadDateFilter: Date[];
    publishDateFilter: Date[];
    channelFilter: ChannelFilter;
    subchannelFilter: SubchannelFilter;
    threadFilter: ThreadFilter;
    rowsSelected: number;
    contentListRefreshState: boolean;
    currentPageNumber: number;
    previousSearchString: string | undefined;
    sortDirection: string | null;
    sortColumn: string | null;
    setSortDirection: (data: any) => void;
    setSortColumn: (data: any) => void;
    selectRowsInContentListAction: (data: number) => void;
    toggleLicenseTypeFilterAction: (data: string) => void;
    toggleContentTypeFilterAction: (data: string) => void;
    togleContributorTypeFilterAction: (data: string) => void;
    toggleUploadDateFilterAction: (data: Date[]) => void;
    togglePublishDateFilterAction: (data: Date[]) => void;
    toggleChannelFilterAction: (data: ChannelFilter) => void;
    toggleSubchannelFilterAction: (data: SubchannelFilter) => void;
    toggleThreadFilterAction: (data: ThreadFilter) => void;
    redirectToEditPage: (data: EditContentType) => void;
    toggleRadioButtonAction: (data: string) => void;
    addIndividualImageNavigationAction: (data: IndividualImageCategory) => void;
    broadcastToolbarAction: (data: IBroadcastMessage) => void;
    resetTableFilterAction: () => void;
    toggleContentTableRefresh: (data: boolean) => void;
    toggleContentUserIdAction: (data: number) => void;
    setDataTableCurrentPage: (data: number) => void;
    setSearchString: (data: string) => void;
}

export interface UserUploadedContent {
    contentNavigations: ContentNavigation[];
    content: Content;
    type: string;
    title: string;
    status: string;
    uploadDate: Date;
    publishDate: Date;
    location: Location;
    contentSetId?: number;
    articleId?: number;
    contributor: User;
}

export interface ContentNavigation {
    channel: Channel;
    subChannel: SubChannel;
    thread: Thread;
}

export interface EditContentProps {
    routeParams: any;
    contentSets: ContentSet[];
    selectedRadioButton: string;
    articles: Article[];
    audioFiles: AudioFile[];
    userId: number;
    audioFilesResponses: any;
    individualImageCategories: IndividualImageCategory[];
    movedContents: MovedContentToSets[];
    copiedContents: CopiedContentToSets[];
    snackbar: IBroadcastMessage;
    contentUserId: number;
    currentUser: User;
    userSelectedImage: number | undefined;
    userSelectedImages: number[];
    imageAngles: ImageAngle[];
    deleteSingleContentAction: (data: number) => void;
    uploadFilesAction: (data: UploadedFileResponse[]) => void;
    uploadSetAction: (data: ContentSet) => void;
    updateSetAction: (data: ContentSet) => void;
    uploadArticleAction: (data: Article) => void;
    addAudioFileResponse: (data: any) => void;
    resetReduxState: () => void;
    resetSnackbar: () => void;
    deleteAudioFile: (data: any) => void;
    returnToContent: () => void;
    toggleRadioButtonAction: (data: string) => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
    resetTableFiltersAction: () => void;
    resetMovedContentsAction: () => void;
    resetCopiedContentsAction: () => void;
    setMultiselectImages: (data: boolean) => void;
    setUserSelectedImage: (data: number | undefined) => void;
    setUserSelectedImages: (data: number[]) => void;
    resetImageAngles: () => void;
}

export enum FilterNavigationTypes {
    CHANNEL_FILTER = "channel-filter",
    SUBCHANNEL_FILTER = "subchannel-filter",
    THREAD_FILTER = "thread-filter",
}

export enum ChannelTypes {
    CHANNEL= "channel",
    SUBCHANNEL = "subchannel",
    THREAD = "thread",
}