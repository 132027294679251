import React, { useEffect, useState } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ADD_FLAT_RATE, GET_FLAT_RATES } from './queries';
import { FlatRate, LicenseTypeValues, NewContentRateProps, RoleTypesMessages, SingleContentTypes } from './LicenseAdminTable.types';
import { dialogStyles, defaultToolbarStyles, styles } from '../users-table/usersAdminTable.types';
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AdminStoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IAddPlan, TLicenseTableReducerActions, ILоadedData } from "../../redux/licence/licenseTable.actions";
import { LicenseTableActionTypes } from "../../redux/licence/licenseTable.types";
import { Alert, AlertTitle } from '@material-ui/lab';
import { getEnumValues } from '../../components/users-table/helperFunctions';

const AddNewContentRate: React.FC<NewContentRateProps> = ({ ...props }) => {
    const { reFetch, classes, licenseTable, addPlanAction, loadedDataAction } = props;
    const openRegisterInitial = false;
    const [open, setOpen] = useState(openRegisterInitial);
    const [response, setResponseState] = useState("");
    const [licenseType, setLicenseType] = useState("");
    const [contentType, setContentType] = useState("");
    const [userRole, setUSerRole] = useState("");
    const [dataQuery, setDataQuery] = useState(GET_FLAT_RATES);
    const [fetchData, { data, loading, refetch }] = useLazyQuery(dataQuery, {
        fetchPolicy: 'no-cache'
    })

    const [addContentRateMutation] = useMutation(ADD_FLAT_RATE);
    const validationSchema = Yup.object({
        flatRate: Yup.number().required("You must enter content price.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            ),
    });

    useEffect(() => {
        if (data && data.getContentRates) {
            loadedDataAction(data.getContentRates);
        }
    }, [data])

    useEffect(() => {
        fetchData();
    }, [dataQuery])

    const getNumberFromVarialbes = (variable: any) => {
        if (variable !== null) {
            return parseInt(variable.toString())
        }
        else {
            return null
        }

    }
    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            licenseType: "",
            flatRate: 0,
            contentType: "",
            role: "",
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { flatRate } = values;
            checkLicenseTypeRate()
        }
    })

    const addNewUser = () => {
        setOpen(true);
    }

    const checkLicenseTypeRate = () => {
        const existRow = licenseTable.filter(flatRate => flatRate.contentType === contentType && flatRate.licensingType === licenseType && flatRate.role === userRole).length > 0;

        if (existRow) {
            setResponseState("This license type rate already exists");
        } else {
            addContentRateMutation({
                variables: {
                    licenseType: licenseType,
                    contentType: contentType,
                    role: userRole,
                    flatRate: getNumberFromVarialbes(values.flatRate)
                }
            }).then((result: any) => {
                addPlanAction({ id: result.data.addContentRate.id, licensingType: licenseType, contentType: contentType, role: userRole, flatRate: values.flatRate })
                if (result.data.addContentRate) {
                    handleClose();
                    reFetch();
                }
            }).catch((error: any) => {
                //setResponseState(error.graphQLErrors[0].message);
            });
        }
    }
    const handleSelectPeriod = (e: any) => {
        if (e.target.name === "licenseType") {
            setLicenseType(e.target.value)
        }
        if (e.target.name === "contentType") {
            setContentType(e.target.value)
        }
        if (e.target.name === "userRole") {
            setUSerRole(e.target.value)
        }

    }


    const handleClose = () => {
        setOpen(false);
        setResponseState("")
    }

    return (
        <React.Fragment>
            <Tooltip title={"Add new rate"}>
                <IconButton onClick={addNewUser}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>
                            {response ?
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {response}
                                </Alert> : null}
                            <div className='add-user-container'>
                                <h1 className='title'>Add new plan</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <FormControl>
                                                <InputLabel >License type</InputLabel>
                                                <Select name="licenseType" onChange={handleSelectPeriod} defaultValue="">
                                                    <MenuItem value={LicenseTypeValues.Editorial}>Editorial</MenuItem>
                                                    <MenuItem value={LicenseTypeValues.Standard}>Standard</MenuItem>
                                                    <MenuItem value={LicenseTypeValues.Premium}>Premium</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel >Content type</InputLabel>
                                                <Select name="contentType" onChange={handleSelectPeriod} defaultValue="">
                                                    <MenuItem value={SingleContentTypes.Image}>Image</MenuItem>
                                                    <MenuItem value={SingleContentTypes.Audio}>Audio</MenuItem>
                                                    <MenuItem disabled value={SingleContentTypes.Video}>Video</MenuItem>
                                                    
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel >User role</InputLabel>
                                                <Select name="userRole" onChange={handleSelectPeriod} defaultValue="">
                                                    {getEnumValues(RoleTypesMessages)}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                autoComplete="off" type="number"
                                                className='select-label' name="flatRate"
                                                onChange={handleChange} label="Price"
                                                variant="standard"
                                                defaultValue={0}
                                                error={errors.flatRate === ''}
                                                helperText={errors.flatRate ? errors.flatRate : null}
                                                FormHelperTextProps={{ className: classes.error }} />

                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Add plan</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}

const mapStateToProps = (state: AdminStoreState): { licenseTable: FlatRate[] } => {
    return {
        licenseTable: state.licenseTable.tableData
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TLicenseTableReducerActions>) => {
    return {
        addPlanAction: (data: FlatRate) => { dispatch<IAddPlan>({ type: LicenseTableActionTypes.ADD_PLAN, data: data }) },
        loadedDataAction: (data: FlatRate[]) => { dispatch<ILоadedData>({ type: LicenseTableActionTypes.LOADED_LICENSE_TABLE_DATA, data: data }) },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNewContentRate));