import React, { useEffect, useState } from 'react';
import Dropzone, { IFileWithMeta, IInputProps, ILayoutProps, IPreviewProps } from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { AudioFile } from './ContentUpload.types';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { AdminStoreState } from 'redux/root-reducer';
import { Dispatch } from 'redux';
import SyncLoader from 'react-spinners/SyncLoader';
import { IAddAudioFile, IDeleteAudioFile, IRemoveAudioFileResponse, TContentUploadActions } from 'redux/content-upload/content-upload.actions';
import { connect } from 'react-redux';
import { selectAudioFileResponse, selectAudioFiles } from '../../redux/content-upload/content-upload.selectors';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactAudioPlayer from 'react-audio-player';
import './AddAudioInput.styles.scss';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';

export interface AudioInputProps {
  articleId: string;
  audioFiles: AudioFile[];
  audioId?: number;
  audioFilesResponse: any;
  addAudioFileAction: (file: any) => void;
  removeAudioFileAction: (data: string) => void;
  removeAudioFileResponseAction: (data: number) => void;
  broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

const AddAudioInput: React.FC<AudioInputProps> = ({...props}) => {
    const {articleId, audioFiles, audioId, audioFilesResponse, addAudioFileAction, removeAudioFileAction, 
      removeAudioFileResponseAction, broadcastSnackbarAction} = props;
    const [chosenAudioFile, setAudioFile] = useState<Partial<File>>({});
    const [audioFileUrl, setAudioFileUrl] = useState('');
    const [currentAudioFileResponse, setCurrentAudioFileResponse] = useState<any>();
    const [droppedAudioFile, setDroppedFile] = useState<Partial<AudioFile>>({});
    const [loadingIndicator, setLoadingIndicator] = useState(false);

    useEffect(() => {
      const hasAudioFileUploaded = audioFiles.find(file => file.articleId === articleId);
      const hasAudioFileResponse = audioFilesResponse.find((audio: any) => audio.id === audioId);
      if(hasAudioFileUploaded) {
        setDroppedFile(hasAudioFileUploaded);
        setAudioFileUrl(URL.createObjectURL(hasAudioFileUploaded.fileWithMeta.file));
      } else if(hasAudioFileResponse) {
        setDroppedFile({});
        setCurrentAudioFileResponse(hasAudioFileResponse)
      } else {
        setDroppedFile({});
        setCurrentAudioFileResponse({});
      }
    }, [audioFiles, audioFilesResponse])

    const Input = ({ accept, onFiles, getFilesFromEvent }: IInputProps) => {
      return (
        <div>
          {(droppedAudioFile && droppedAudioFile.fileWithMeta) || (currentAudioFileResponse && currentAudioFileResponse.id) ? 
            null
          : 
          <div>
            <input style={{ display: 'none' }} id='contained-button-audio-file' type="file" accept={accept} onChange={e => {
                getFilesFromEvent(e).then(chosenFiles => {
                  onFiles(chosenFiles)
                })
              }}
            />
            <label htmlFor="contained-button-audio-file">
                <Button size='small' className='white-button' variant='contained' component='span' endIcon={<CloudUploadIcon/>}>
                    Upload audio file
                </Button>
            </label>
          </div>
          } 
        </div>
      )
    }

    const InputWithFiles = ({}: IInputProps) => {
      return (
          <div className='submit-label'>
          </div>
      )
    }
    
    const DragAndDropPreview = ({}: IPreviewProps) => {
      return (
          <div className="preview-box">
          </div>
      )
    }

    const Layout = ({ input, dropzoneProps, extra: { maxFiles } }: ILayoutProps) => {
      return (
        <div className='audio-input-container'>
            <div {...dropzoneProps} className='audio-input-label'>
                {audioFiles.length < maxFiles && input}
            </div>

            {droppedAudioFile && droppedAudioFile.fileObject ? 
              <div className='preview-container'>
                {/* <span className='preview-filename'>{droppedAudioFile.fileWithMeta!.file.name}</span> */}
                  <div className="audio-player">
                    <div className="file-name">{droppedAudioFile.fileWithMeta!.file.name}</div>
                      <ReactAudioPlayer
                      src={audioFileUrl}
                      controls
                      controlsList="nodownload"
                      preload="metadata"
                      />
                  </div>
                  <div className='preview-status'> 
                    {loadingIndicator ? 
                    <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={10} 
                        color={"#36D2B3"} loading={loadingIndicator}/>
                        :
                    <span className='preview-button' onClick={() => removeDroppedFile(droppedAudioFile.articleId)}>
                      <CloseIcon/>
                    </span>
                    }
                  </div>
              </div> 
            : null}

            {currentAudioFileResponse && currentAudioFileResponse.id ? 
              <div className='preview-container'>
                {/* <span className='preview-filename'>{currentAudioFileResponse.objectName}</span> */}
                  <div className="audio-player">
                    <div className="file-name">{currentAudioFileResponse.objectName}</div>
                      <ReactAudioPlayer
                      src={currentAudioFileResponse.pathToFile}
                      controls
                      controlsList="nodownload"
                      preload="metadata"
                      />
                  </div>
                  <div className='preview-status'> 
                    {loadingIndicator ? 
                      <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={10} 
                          color={"#36D2B3"} loading={loadingIndicator}/>
                          :
                      <span className='preview-button' onClick={() => removeAudioFileResponse(currentAudioFileResponse.id)}>
                        <CloseIcon/>
                      </span>
                    }
                  </div>
              </div> 
            : null}
        </div>
      )
    }

    const removeDroppedFile = (articleId: string | undefined) => {
      if(articleId) {
        removeAudioFileAction(articleId);
        setAudioFile({});
        setAudioFileUrl('');
        broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
          message:"Audio file removed successfully"});
      } else {
        broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
          message:"Failed to remove audio file"});
      }
      
    }

    const removeAudioFileResponse = (audioId: number) => {
      removeAudioFileResponseAction(audioId);
      broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
        message:"Audio file removed successfully"});
    }

    const getFilesFromEvent = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>) : Promise<any> => {
      return new Promise(resolve => {
          getDroppedOrSelectedFiles(event).then((chosenFiles: any[]) => {
            setAudioFile(chosenFiles[0]);
            resolve(chosenFiles.map(f => f.fileObject));
          }).catch((error: any) => {
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
              message:"Failed to upload audio file. Try uploading again"});
        })
      })
    }

    const onChangeStatus = (file: IFileWithMeta, status: any) => {
        if (status === 'preparing') {
          setLoadingIndicator(true);
          let audioFile: AudioFile = {
              articleId: articleId,
              fileWithMeta: file, 
              fileObject: chosenAudioFile
          };

          setDroppedFile(audioFile);
        } else if (status === 'done') {
          setLoadingIndicator(false);
          broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
            message:"Audio file uploaded successfully"});

          let audioFile: AudioFile = {
            articleId: articleId,
            fileWithMeta: file, 
            fileObject: chosenAudioFile
          };
          addAudioFileAction(audioFile);
        }
    }
  
    return (
      <Dropzone
        accept="audio/*"
        InputComponent={Input}
        multiple={false}
        LayoutComponent={Layout}
        PreviewComponent={DragAndDropPreview}
        getFilesFromEvent={getFilesFromEvent}
        inputWithFilesContent={InputWithFiles}
        onChangeStatus={onChangeStatus}
        classNames={{dropzone: 'audio-input-container'}}
      />
    )
  }

  const mapStateToProps = (state: AdminStoreState): {audioFiles: AudioFile[], audioFilesResponse: any} => {
    return {
        audioFiles: selectAudioFiles(state),
        audioFilesResponse: selectAudioFileResponse(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions>) => {
    return {
        addAudioFileAction: (data: any) => dispatch<IAddAudioFile>({type: ContentUploadActionTypes.ADD_AUDIO_FILE, data: data}),
        removeAudioFileAction: (data: string) => dispatch<IDeleteAudioFile>({type: ContentUploadActionTypes.DELETE_AUDIO_FILE, data: data}),
        removeAudioFileResponseAction: (data: number) => dispatch<IRemoveAudioFileResponse>({type: ContentUploadActionTypes.REMOVE_AUDIO_FILE_RESPONSE, data: data}),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAudioInput);