import gql from "graphql-tag";


export const GET_UPLOADED_CONTENT = gql`
query getContentList(
    $pageNumber: Int!
    $contentPerPage: Int!
    $searchString: String
    $licensingType: String
    $contributorType: String
    $contentType: String
    $uploadDate: [DateTime!]
    $publishDate: [DateTime!]
    $channelId: Int
    $subchannelId: Int
    $threadId: Int
    $channelTitle: String
    $subchannelTitle: String
    $threadTitle: String
     $sortBy: String
     $direction: String
  ) {
    getContentList(
      pageNumber: $pageNumber
      contentPerPage: $contentPerPage
      input: {
        searchString: $searchString
        licensingType: $licensingType
        contributorType: $contributorType
        contentType: $contentType
        uploadDate: $uploadDate
        publishDate: $publishDate
        channelId: $channelId
        subchannelId: $subchannelId
        threadId: $threadId
        channelTitle: $channelTitle
        subchannelTitle: $subchannelTitle
        threadTitle: $threadTitle
        sortBy: $sortBy
        direction: $direction
      }
    ) {
      allResultsCount
      userUploadedContent {
        articleId
        contentSetId
        title
        type
        publishDate
        uploadDate
        content {
          id
          code
          pathToFileCompressed
          pathToFileThumbnail
          externalLink
          externalLinkMessage
          licensingType
        }
        contentNavigations {
          channel {
            id
            title
          }
          subChannel {
            id
            title
          }
          thread {
            id
            title
          }
        }
        contributor {
          id
          username
          role
          person {
            id
            firstName
            lastName
          }
          company {
            id
            name
          }
        }
      }
    }
  }
  
`;

export const UPDATE_ARTICLE = gql`
    mutation updateArticle($articleId: Int!, $title: String!, $description: String!, $audioId: Int) {
        updateArticle(input: {articleId: $articleId, title: $title, description: $description, audioId: $audioId})
    }
`;

export const EDIT_THREADS_IN_CONTENT_SET = gql`
  mutation editThreadsInContentSet($threadIds: [Int!]!, $contentSetId: Int!) {
    editThreadsInContentSet(threadIds: $threadIds, contentSetId: $contentSetId) {
      id
      threadId
      contentSetId
    }
  }
`;

export const EDIT_THREADS_IN_CONTENT = gql`
  mutation editThreadsInContent($threadIds: [Int!]!, $contentId: Int!) {
    editThreadsInContent(threadIds: $threadIds, contentId: $contentId) {
      id
      threadId
    }
  }
`;

export const UPDATE_CONTENT_SET = gql`
    mutation updateContentSet($setId: Int!, $setName: String!, $coverId: Int) {
        updateContentSet(setId: $setId, setName: $setName, coverId: $coverId)
    }
`;

export const MOVE_CONTENT_TO_SET = gql`
    mutation moveContentToSet($currentSetId: Int!, $movedContentId: Int!, $newSetId: Int, $newSetTitle: String, $threadIds: [Int!]) {
        moveContentToSet(currentSetId: $currentSetId, movedContentId: $movedContentId, newSetId: $newSetId, newSetTitle: $newSetTitle, threadIds: $threadIds)
    }
`;


export const COPY_CONTENT_TO_SET = gql`
    mutation copyContentToSet($currentSetId: Int!, $copyContentId: Int!, $newSetId: Int, $newSetTitle: String, $threadIds: [Int!]) {
      copyContentToSet(
        currentSetId: $currentSetId
        copyContentId: $copyContentId
        newSetId: $newSetId
        newSetTitle: $newSetTitle
        threadIds: $threadIds
      )
    }
`;

export const MOVE_MULTIPLE_CONTENT_TO_SET = gql`
    mutation moveMultipleContentToSet($currentSetId: Int!, $movedContentIds: [Int!]!, $newSetId: Int, $newSetTitle: String, $threadIds: [Int!]) {
        moveMultipleContentToSet(currentSetId: $currentSetId, movedContentIds: $movedContentIds, newSetId: $newSetId, newSetTitle: $newSetTitle, threadIds: $threadIds)
    }
`;


export const COPY_MULTIPLE_CONTENT_TO_SET = gql`
    mutation copyMultipleContentToSet($currentSetId: Int!, $copyContentIds: [Int!]!, $newSetId: Int, $newSetTitle: String, $threadIds: [Int!]) {
        copyMultipleContentToSet(currentSetId: $currentSetId, copyContentIds: $copyContentIds, newSetId: $newSetId, newSetTitle: $newSetTitle, threadIds: $threadIds)
    }
`;

export const SEARCH_CONTENT_SET = gql`
    query searchContentSetsByTitle($searchString: String!) {
        searchContentSetsByTitle(searchString: $searchString) {
            coverId
            contentSetId
            title
            threadId
            threadTitle
        }
    }
`;

export const GET_ALL_CONTENT_COUNT = gql `
    query getAllContentCountInDatatables($contributorId: Int, $status: String, $isFMC: Boolean) {
        getAllContentCountInDatatables(contributorId: $contributorId, status: $status, isFMC: $isFMC){
            articlesCount
            contentSetsCount
            singleContentCount
        }
    }
`