import React, {useEffect, useState} from 'react';
import { Stepper, Step, StepLabel, Button, RadioGroup, FormControlLabel, Radio, MuiThemeProvider, Paper, LinearProgress, makeStyles, withStyles, RadioProps, Link} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ContentUploadPageProps, contentUploadTheme, UploadedFileResponse, ContentSet, RadioButtonGroup, Article, DroppedFile, UploadSteps, AudioFile, IndividualImageCategory, ContentUploadMethods, ContentSetUploadResponse, ContentSetUploadResponseInput, TIME_BEFORE_REDIRECT } from './ContentUpload.types';
import EditContentMetadata from './EditContentMetadata.component';
import DragAndDropContentStep from './DragAndDropContentStep';
import ArticleTabsComponent from './ArticleTabsComponent';
import ManageContent from './ManageContent';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectUploadedFiles, selectContentSets, selectDroppedFiles, selectArticles, selectRadioButtonValue, selectAudioFiles, selectIndividualImagesCategory, selectedIncompleteSFTPSet } from '../../redux/content-upload/content-upload.selectors';
import { connect } from 'react-redux';
import { selectCurrentUserId } from '../../redux/admin/admin.selectors';
import { Dispatch } from 'redux';
import SyncLoader from 'react-spinners/SyncLoader';
import { IRemoveFile, IResetContentUpload, IToggleRadioButton, IUpdateArticle, IUpdateSet, IUploadFiles, IUploadSet, TContentUploadActions } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_ARTICLE, ASSIGN_CONTENT_SET, CONTENT_SET_TO_THREAD, CONTENT_TO_THREAD, ROTATE_IMAGE, SAVE_USER_INCOMPLETE_FLOW, UPLOAD_SET } from './queries';
import { v4 as uuidv4 } from 'uuid';
import { GET_CHANNELS } from '../channels-table/queries';
import {SnackbarComponent, ConfirmationDialog} from 'shared';
import { uploadToBucket } from './uploadToBucketRequest';
import './ContentUpload.styles.scss';
import { selectCurrentIncompleteFlow } from '../../redux/incomplete-upload/incompleteUpload.selectors';
import { IncompleteUserUploadFlow } from '../incomplete-upload-list/incompleteUpload.types';
import { COMPLETE_INCOMPLETE_FLOW, UPDATE_INCOMPLETE_FLOW } from '../incomplete-upload-list/queries';
import {EDIT_THREADS_IN_CONTENT_SET, UPDATE_ARTICLE, UPDATE_CONTENT_SET } from '../content-list/queries';
import { IResetSnackbar, ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import {config as conf} from "../../config";
import { selectIncompleteSFTPSetContributorId } from '../../redux/incomplete-sftp-upload/incompleteSFTPUpload.selectors';
import { CLEAN_SFTP_TEMP_FOLDER } from '../../components/incomplete-sftp-upload-list/queries';
import { EditContentActionTypes, ImageAngle } from '../../redux/EditContent/editContent.types';
import { getImageAngles } from '../../redux/EditContent/editContent.selectors';
import { IResetImageAngle, TEditContentReducerActions } from '../../redux/EditContent/editContent.actions';

const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: '10px !important'
    }
}));

const GreenRadio = withStyles({
    root: {
      color: 'rgb(12, 175, 149) !important',
      '&$checked': {
        color: 'rgb(12, 175, 149)',
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

const ContentUploadPage: React.FC<ContentUploadPageProps> = ({...props}) => {
    const classes = useStyles();
    const { uploadedFiles, articles, droppedFiles, audioFiles, individualImageCategories, selectedIncompleteSFTPSet,
        contentSets, userId, selectedIncompleteFlow, history, snackbar, incompleteSFTPSetContributorId, imageAngles, uploadFilesAction, uploadSetAction, updateSetAction, resetImageAngles,
        updateArticleAction, toggleRadioButtonAction, removeDroppedFile, resetReduxState, resetSnackbar, broadcastSnackbarAction} = props;

    const [steps, setSteps] = useState([UploadSteps.PICK_ITEMS, UploadSteps.REVIEW_DATA])
    const [activeStep, setActiveStep] = useState(1);
    const [selectedRadioButton, setSelectedRadioButton] = useState(RadioButtonGroup.SET);
    const [selectedContentUploadMethod, setSelectedContentUploadMethod] = useState(ContentUploadMethods.BROWSER_UPLOAD);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const {data, loading, error} = useQuery(GET_CHANNELS);
    const [channels, setAllChannels] = useState([]);

    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);
    const [uploadingImagesIndicator, setUploadingImagesIndicator] = useState(false);
    const [sftpLoadingIndicator, setSFTPLoadingIndicator] = useState(false);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [clearFolderDialogOpen, setClearFolderDialogOpen] = useState(false);
    const [incompleteFlowDialogOpen, setIncompleteFlowDialogOpen] = useState(false);
    const [savingProgressAsIncomplete, setSavingProgressAsIncomplete] = useState(false);
    const [uploadingNewFiles, setUploadingNewFiles] = useState(false);
    const [contentUploadType,setContentUploadType] = useState<any>();
    var uploadedFilesCounter = 0;
    var newSetIds: ContentSetUploadResponseInput[] = [];
    var newArticleIds: number[] = [];
    
    const path = require('path');
    const url = `${conf.REACT_APP_ADMIN_SERVER_HOST}/graphql`;
    const config = {
        headers: { 
            'Access-Control-Allow-Origin': '*',
            'content-type': 'multipart/form-data'
        }
    }

    useEffect(()=>{
        return ()=>{
            resetImageAngles();
        }
    },[]);

    useEffect(()=>{
        if(window.location.href.includes("incomplete-sftp-upload-flow")){
            setContentUploadType(ContentUploadMethods.SFTP_UPLOAD)
        }
        else{
            setContentUploadType(ContentUploadMethods.BROWSER_UPLOAD)
        }
    },[])
    useEffect(()=>{
        if(selectedRadioButton===RadioButtonGroup.SET){
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
        }
        else setSteps([UploadSteps.PICK_ITEMS, UploadSteps.REVIEW_DATA]);
    },[]);

    useEffect(() => {
        if (data && data.getAllChannels) {
            setAllChannels(data.getAllChannels);
        }
    }, [data])

    useEffect(() => {
        if(snackbar.severity && snackbar.message) {
            setContentUploadSnackbar(true);
        }
    }, [snackbar])

    useEffect(() => {
        if(savingProgressAsIncomplete) {
            if (contentSets.length > 0) {
                handleContentSetUpload();
            } else if(uploadedFiles.length > 0 && individualImageCategories.length > 0) {
                //if it has both uploaded files and some navigations
                handleAssignToThread();
            } else {
                //only has files uploaded
                const contentIds = uploadedFiles.map((file: UploadedFileResponse) => {return file.id});
                if(selectedIncompleteFlow) {
                    updateIncompleteFlow(selectedIncompleteFlow.flowTitle, contentIds, []);
                }
            }
        }
    }, [savingProgressAsIncomplete])

    const [rotateImageMutation] = useMutation(ROTATE_IMAGE);
    const rotateImage = (contents: ImageAngle[]): void => {
      rotateImageMutation({
        variables: {
            contents
        },
      });
    };

    const [updateArticleMutation] = useMutation(UPDATE_ARTICLE);
    const updateArticle = (contentId: number, title: string, description: string, audioId?: any) => {
        return updateArticleMutation({variables: {articleId: contentId, title, description, 
            audioId: audioId ? audioId : null}
        }).then((result: any) => {
            return Promise.resolve(result.data.updateArticle);
        }).catch((error: any) => {
            return Promise.reject(error.response);
        });
    }
    
    const [editThreadsInContentSetMutation] = useMutation(EDIT_THREADS_IN_CONTENT_SET);
    const editThreadsInContentSet = async (threadIds: number[], contentSetId: number) => {
        return editThreadsInContentSetMutation({
            variables: {threadIds, contentSetId}
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        });
    }

    const [addArticleMutation] = useMutation(ADD_ARTICLE);
    const addArticle = (contentSetId: number, text: string, title: string, audioId?: number): Promise<number> => {
        return addArticleMutation({
            variables: {contentSetId: contentSetId, text: text, title: title, audioId: audioId}
        }).then((result: any) => {
            if (result.data.addArticle) {
                return Promise.resolve(result.data.addArticle);
            }
        }).catch((error: any) => {
            return Promise.reject(error);
        });
    }

    const [contentToThreadMutation] = useMutation(CONTENT_TO_THREAD);
    const contentToThread = (threadIds: number[], contentId: number): Promise<any> => {
        return contentToThreadMutation({
            variables: { threadIds: threadIds, contentId: contentId }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        });
    }

    const [contentSetToThreadMutation] = useMutation(CONTENT_SET_TO_THREAD);
    const contentSetToThread = (threadIds: number[], contentSetId: number) => {
        return contentSetToThreadMutation({
            variables: {threadIds: threadIds, contentSetId: contentSetId}
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        })
    }

    const [updateContentSetMutation] = useMutation(UPDATE_CONTENT_SET);
    const updateContentSet = (setId: number, setTitle: string, coverId: number) => {
        return updateContentSetMutation({variables: {setId, setName: setTitle, coverId}})
        .then((response: any) => {
            return Promise.resolve(response.data.updateContentSet);
        })
        .catch((error: any) => {
            return Promise.reject(error.response);
        });
    }

    const [uploadContentSetMutation] = useMutation(UPLOAD_SET);
    const uploadSet = (userId: string, contentSet: ContentSet) => {
        const contentSetIds = contentSet.files.map((file) => {return file.id});
        return uploadContentSetMutation({
            variables: {userId: userId, contentIds: contentSetIds, setName: contentSet.title,
                coverId: contentSet.coverPhoto ? contentSet.coverPhoto.id : null}
        }).then((response: any) => {
            let temp = contentSet;

            if(response.data.uploadSet) {
                let responseProps = response.data.uploadSet;
                temp.uploadResponse = responseProps;
                
            }
            return Promise.resolve(temp);
        })
        .catch((error: any) => {
            return Promise.reject(error.response);
        });
    }

    const [saveIncompleteUploadFlowMutation] = useMutation(SAVE_USER_INCOMPLETE_FLOW, {onCompleted(data) {
        if(data.saveUserIncompleteUploadFlow) {
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                message:"Changes saved successfully to World illustrated."});

            setTimeout(() => {
                    resetReduxState();
                    resetSnackbar();
                    history.push('/incomplete-upload')
                }, TIME_BEFORE_REDIRECT);
        }
        setSavingProgressAsIncomplete(false);
    }, onError(err) {
        setSavingProgressAsIncomplete(false);
        broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
            message:'Failed to save content to World Illustrated. Try again'});
        setTimeout(() => {
            resetReduxState();
            resetSnackbar();
            history.push('/incomplete-upload')
        }, TIME_BEFORE_REDIRECT);
    }});

    const saveIncompleteFlow = (userId: number, flowTitle: string, contentIds: number[], contentSetIds: number[]) => {
        return saveIncompleteUploadFlowMutation({
            variables: {userId, flowTitle, contentIds, contentSetIds}
        })
    }

    const [completeFlowMutation] = useMutation(COMPLETE_INCOMPLETE_FLOW, {onCompleted(data) {
        if(data.completeUserIncompleteUploadFlow && selectedIncompleteFlow) {
            if(imageAngles.length > 0)
            {
                rotateImage(imageAngles);
                resetImageAngles();
            }
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                message:'Successfully completed flow ' + selectedIncompleteFlow?.flowTitle + '. Redirecting...'});

            setTimeout(() => {
                resetReduxState();
                resetSnackbar();
                setLoadingIndicator(false);
                history.push('/incomplete-upload')
            }
            , TIME_BEFORE_REDIRECT);
        }
    }, onError(err) {
        broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
            message:'Failed to complete flow ' + selectedIncompleteFlow?.flowTitle + '. Reopen and try again.'});

        setTimeout(() => {
            resetReduxState();
            resetSnackbar();
            setLoadingIndicator(false);
            history.push('/incomplete-upload')
        }
        , TIME_BEFORE_REDIRECT);
    }});
    const completeFlow = (incompleteFlowId: string, newSetsAndContentIds: ContentSetUploadResponse[], newArticleIds: number[]) => {
        return completeFlowMutation({variables: {incompleteFlowId, newSetsAndContentIds, newArticleIds}});
    }

    const [updateIncompleteFlowMutation] = useMutation(UPDATE_INCOMPLETE_FLOW);
    const updateIncompleteFlow = (incompleteFlowId: string, contentIds: number[], contentSetIds: number[]) => {
        return updateIncompleteFlowMutation({variables: {incompleteFlowId, contentIds, contentSetIds}});
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            //if complete is pressed
            setLoadingIndicator(true);
            if(selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD) {
                if (contentSets.find(set => set.selectedThreadIds === undefined || set.selectedThreadIds.length === 0) && contentSets.length > 0) {
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                        message:"At least one channel navigation must be selected per image or content set before completing the upload"});
                    setLoadingIndicator(false);
                    return;
                } else {
                    setClearFolderDialogOpen(true);
                }
            } else if (selectedRadioButton === RadioButtonGroup.SET && selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD) {
                if (contentSets.find(set => set.selectedThreadIds === undefined || set.selectedThreadIds.length === 0) && contentSets.length > 0) {
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                        message:"At least one channel navigation must be selected per image or content set before completing the upload"});
                    setLoadingIndicator(false);
                    return;
                } else {
                    //upload new content and add all of it to completeFlowMutation and perform the update in the backend
                    handleContentSetUpload();
                }
            } else if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL && selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD) {
                if (individualImageCategories.length !== uploadedFiles.length && uploadedFiles.length > 0) {
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                        message:"At least one channel navigation must be selected per image or content set before completing the upload"});  
                    setLoadingIndicator(false);
                    return;
                } else {
                    handleAssignToThread();
                }
            }
        } else if (activeStep === steps.length - 3 && selectedRadioButton === RadioButtonGroup.SET) {
            //step 2 going to step 3
            if (contentSets.find(set => set.coverPhoto === undefined) || contentSets.find(set => set.title === "")) {
                broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                    message:"All content sets must have a title and cover photo specified."});
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if(activeStep === 0 && selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD) {
            //set confirmation dialog state to true
            setConfirmationDialogOpen(true);
            //then if confirmed setActiveStep to next value
        } else if(activeStep === 0 && droppedFiles.length > 0) {
            handleUpload();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleAssignToThread = () => {
        setLoadingMessage("Linking content to channels...");
        let assignPromises: Promise<any>[] = [];

        if(selectedRadioButton === RadioButtonGroup.SET && contentSets) {
            assignPromises = contentSets.map(async (set: ContentSet) => {
                if(set.selectedThreadIds) {
                    //use editThreadsInContentSet instead
                    return await editThreadsInContentSet(set.selectedThreadIds, 
                        set.uploadResponse ? set.uploadResponse.contentSetId : parseInt(set.id));
                }        
            })
        } else if(selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
            assignPromises = individualImageCategories.map(async (imageCategory: IndividualImageCategory) => {
                return await contentToThread(imageCategory.selectedThreads, imageCategory.contentId);
            })
        }

        Promise.all(assignPromises).then((result: any) => {
            setLoadingMessage('');
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                message:"Content uploaded successfully to World illustrated. Redirecting to next page..."});

            if(selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD) {
                setClearFolderDialogOpen(true);
            } else if(savingProgressAsIncomplete) {
                //call save mutation
                const contentIds = uploadedFiles.map((file: UploadedFileResponse) => {return file.id});
                const contentSetIds = contentSets.map((contentSet: ContentSet) => {
                    return contentSet.uploadResponse ? contentSet.uploadResponse?.contentSetId : 0
                });

                if(selectedIncompleteFlow) {
                    updateIncompleteFlow(selectedIncompleteFlow.flowTitle, contentIds, 
                        contentSetIds.filter((setId: number) => {return setId !== 0}));
                }
            } else if(selectedIncompleteFlow) {
                completeFlow(selectedIncompleteFlow.incompleteFlowId, newSetIds, newArticleIds );
            } else {
                broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                    message:"Content uploaded successfully to World illustrated. Redirecting to next page..."});
                
                setTimeout(() => {
                        resetReduxState();
                        resetSnackbar();
                        setLoadingIndicator(false);
                        history.push('/incomplete-upload')
                    }
                    , TIME_BEFORE_REDIRECT);
            }
        }).catch((error: any) => {
            setLoadingIndicator(false);
            setLoadingMessage('');
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                message:'Failed to link threads to content. Retry?'});
        })
    }

    const handleAudioFilesUpload = () => {
        let failedAudioFiles: string[] = [];

        if (audioFiles.length !== 0 && selectedIncompleteFlow) {
            setLoadingMessage("Uploading audio files...");
            const allPromises = audioFiles.map(async file => {
                return await uploadToBucket(file.fileWithMeta, selectedIncompleteFlow.contributor.id, url, config)
                    .then(async (singleResponse: UploadedFileResponse | string) => {
                        if(typeof singleResponse === 'string') {
                            failedAudioFiles.push(file.fileWithMeta.file.name);
                        }
                        return Promise.resolve(singleResponse);
                    });
            });

            Promise.all(allPromises).then((result: any) => {
                result.map((fileResponse: UploadedFileResponse) => {
                    if (fileResponse.blobObject) {
                        let uploadedAudioFile = audioFiles.find(file => file.fileWithMeta.file.name === fileResponse.blobObject!.name);
                        if (uploadedAudioFile) {
                            let articleForAudioFile = articles.find(article => article.contentSetId === uploadedAudioFile?.articleId);
                            if (articleForAudioFile) {
                                articleForAudioFile.audioId = fileResponse.id;
                                updateArticleAction(articleForAudioFile);
                            }
                        }
                    }
                })

                handleArticleUpload();
            }).catch((error) => {
                setLoadingIndicator(false);
                setLoadingMessage('');
                broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                    message:'Audio file upload failed. Retry?'});
            });
        } else {
            if(failedAudioFiles.length > 0) {
                broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                    message: `Failed to upload certain audio files: ${failedAudioFiles.join(", ")}`});
            }
            handleArticleUpload();
        }
    }

    const handleArticleUpload = () => {
        setLoadingMessage("Uploading articles...");
        let allPromises: Promise<any>[] = [];
        if(articles.length > 0 && selectedIncompleteFlow) {
            allPromises = articles.map(async article => {
                let set = contentSets.find(set => set.id === article.contentSetId);
                let articleExistsInFlow = selectedIncompleteFlow.articles.find((flowArticle) => flowArticle.contentSetId.toString() === article.contentSetId);
                if(articleExistsInFlow) {
                    newArticleIds.push(articleExistsInFlow.articleId!);
                    return await updateArticle(articleExistsInFlow.articleId!, article.title, article.description, article.audioId);
                }
                else if(set) {
                    return await addArticle(set.uploadResponse ? set.uploadResponse.contentSetId : parseInt(set.id), 
                        article.description, article.title, article.audioId);
                }
            })
        }

        Promise.all(allPromises).then((result: any) => {
            //fetch new articleIds from result
            const newIds = result.filter((res: any) => typeof res !== 'boolean');
            newArticleIds = [].concat(newIds);
            handleAssignToThread();
        }).catch((error: any) => {
            setLoadingIndicator(false);
            setLoadingMessage('');
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                message: 'Articles upload failed. Retry?'});
        });
    }

    const updateProgressValue = async (counter: number) => {
        return new Promise((resolve, reject) => {
            setProgressBarValue((counter * 100) / droppedFiles.length);
            resolve((uploadedFilesCounter * 100) / droppedFiles.length);
        })
    }

    const handleUpload = () => {
        setUploadingImagesIndicator(true);
        setLoadingMessage(`Uploading ${uploadedFilesCounter} of ${droppedFiles.length}...`);
        let failedFiles: string[] = [];

        if (selectedIncompleteFlow) {
            const allPromises = droppedFiles.map(async file => {
                return await uploadToBucket(file.fileWithMeta, selectedIncompleteFlow.contributor.id, url, config)
                    .then(async (singleResponse: UploadedFileResponse) => {
                        uploadedFilesCounter++;
                        await updateProgressValue(uploadedFilesCounter);
                        removeDroppedFile(parseInt(file.fileWithMeta.meta.id, 10));
                        setLoadingMessage(`Uploading ${uploadedFilesCounter} of ${droppedFiles.length}...`);
                        return Promise.resolve(singleResponse);
                    }).catch((error: any) => {
                        failedFiles.push(file.fileWithMeta.file.name);
                    });
            });

            Promise.all(allPromises).then((results: any) => {
                const successfulResults = results.filter((result: any) => {
                    return result != null;
                });

                if (selectedRadioButton === RadioButtonGroup.SET) {
                    arrangeInSets(droppedFiles, successfulResults);
                } else {
                    uploadFilesAction(successfulResults);
                }

            }).catch((error) => {
                setUploadingImagesIndicator(false);
                broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                    message: 'An error occured while uploading. '});
                setLoadingMessage("");
            }).finally(() => {
                if(failedFiles.length > 0) {
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                        message: `Failed to upload certain files: ${failedFiles.join(", ")}`});
                }
                setUploadingImagesIndicator(false);
                setLoadingMessage("");
                uploadedFilesCounter = 0;
            });
        }
    }

    const [cleanTempFolderMutation] = useMutation(CLEAN_SFTP_TEMP_FOLDER);
    const cleanTempFolder = (userId: number, folderName: string): Promise<boolean> => {
        return cleanTempFolderMutation({ variables: { id: userId, folderName } })
            .then((response: any) => {
                return Promise.resolve(response.data.cleanSingleTempFolder);
            }).catch((error: any) => {
                return Promise.reject(error);
            })
    }


    const [assignContentSetMutation] = useMutation(ASSIGN_CONTENT_SET);
    const assignContentSet = (threadIds: number[], contentSetId: number, subchannelId?: number, suggestedThreadTitle?: string, userId?: number): Promise<any> => {
        return assignContentSetMutation({
            variables: { threadIds: threadIds, contentSetId: contentSetId, subchannelId: subchannelId, suggestedThreadTitle: suggestedThreadTitle, userId: userId }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            broadcastSnackbarAction({ severity: SeveritySnackbarEnum.error, message: error.graphQLErrors[0].message })
            return Promise.reject(error);
        })
    }


    const handleContentSetUpload = () => {
        setLoadingMessage('Uploading content sets...');
        if(contentUploadType === ContentUploadMethods.BROWSER_UPLOAD){
            if (selectedIncompleteFlow && selectedIncompleteFlow.contributor.id) {
                const allPromises = contentSets.map(async set => {
                    //if set is found in incompleteFlow data, update it, else, upload it
                    if(selectedIncompleteFlow && selectedIncompleteFlow.contentSets.find((uploadedSet) => uploadedSet.contentSet.contentSetId === parseInt(set.id))) {
                        newSetIds.push({contentSetId: parseInt(set.id), contentIds: set.files.map(file => {return file.id})});
                        return await updateContentSet(parseInt(set.id), set.title, set.coverPhoto!.id);
                    } else {
                        return await uploadSet(selectedIncompleteFlow?.contributor.id.toString(), set);
                    }
                })
    
                Promise.all(allPromises).then((result: any) => {
                    const filteredResults = result.filter((res: any) => typeof res !== 'boolean');
                    filteredResults.map((set: ContentSet) => {
                       if(set.id && set.uploadResponse) {
                            newSetIds.push({contentSetId: set.uploadResponse.contentSetId, contentIds: set.files.map(file => {return file.id})});
                            uploadSetAction(set);
                       }
                    })
    
                    handleAudioFilesUpload();
                }).catch((error: any) => {
                    setLoadingIndicator(false);
                    setLoadingMessage('');
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                        message: 'Content sets upload failed. Retry?'});
                })
            }
        }
        else if(contentUploadType === ContentUploadMethods.SFTP_UPLOAD){
            uploadSet(incompleteSFTPSetContributorId.toString(), selectedIncompleteSFTPSet).then((result: any) => {
                if(result.uploadResponse.contentSetId) {
                    newSetIds.push({contentSetId: result.uploadResponse.contentSetId, contentIds: result.uploadResponse.contentIds});
                    uploadSetAction(result);
                    assignContentSet(contentSets[0].selectedThreadIds || [],result.uploadResponse.contentSetId);
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                        message:"SFTP Content uploaded successfully to Waiting list. Redirecting..."});
                    setTimeout(() => {
                        setLoadingIndicator(false);
                        setLoadingMessage('');
                        resetReduxState();
                        resetSnackbar();
                        cleanTempFolder(parseInt(incompleteSFTPSetContributorId),result.title);
                        history.push('/incomplete-sftp-upload')
                    }, TIME_BEFORE_REDIRECT);
                }

                
            }).catch((error: any) => {
                console.log("error",error)
                setLoadingIndicator(false);
                setLoadingMessage('');
                broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                    message: 'Content sets upload failed. Retry?'});
            })
        }
        
    }

    const hasDuplicates = (droppedFiles: DroppedFile[] | undefined) => {
        const values = droppedFiles!.map((droppedFile) => {
            return droppedFile.fileWithMeta.file.name;
        })
        const duplicate = values!.some(function(file, idx){ 
            const fileinx = values!.indexOf(file);
            return fileinx !== idx 
        });

        return duplicate;
    }

    const removeDuplicates = (droppedFiles: DroppedFile[] | undefined) => {
        const hasDuplicateValues = hasDuplicates(droppedFiles);

        if(hasDuplicateValues) {
            const uniqueFiles = droppedFiles!.reduce((unique: DroppedFile[], o) => {
                if(!unique.some(obj => obj.fileWithMeta.file.name === o.fileWithMeta.file.name)) {
                    unique.push(o);
                }
                return unique;
            }, [])
            return uniqueFiles;
        }
        return droppedFiles;
    }
    
    const arrangeInSets =  (allFiles: DroppedFile[] | undefined, uploadedFiles: UploadedFileResponse[]) => {
        const newSets: ContentSet[] = [];

        const uniqueAllFiles = removeDuplicates(allFiles);
        for (let i = 0; i < uniqueAllFiles!.length; i++) {
            const parentDirectory = path.basename(path.dirname(uniqueAllFiles![i].fileObject.fullPath));

            if (newSets.filter(set => set.title === parentDirectory).length < 1 && parentDirectory !== ".") {
                let contentSet: ContentSet = {
                    id: uuidv4(),
                    title: parentDirectory,
                    files: []
                }
                newSets.push(contentSet);
            }

            let uploadedFile = uploadedFiles.find(file => {
                if (file.blobObject) {
                    return file.blobObject.name === uniqueAllFiles![i].fileWithMeta.file.name
                }
            });

            if (uploadedFile) {
                if (parentDirectory !== "." && uniqueAllFiles![i].fileObject) {
                    try {
                        newSets.find(set => set.title === parentDirectory)!.files.push(uploadedFile);
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    uploadFilesAction([uploadedFile]);
                }
            }
        }

        newSets.map((set: ContentSet) => {
            uploadSetAction(set);
        }) 
    }
    
    const handleRadioChange = (event: any) => {
        event.preventDefault();
        let value = event.target.value;
        setSelectedRadioButton(value);
        toggleRadioButtonAction(value);

        if(value === RadioButtonGroup.INDIVIDUAL) {
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.REVIEW_DATA]);
        } else if(value === RadioButtonGroup.SET) {
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
        }

    };

    const handleUploadSFTP = (event: any) => {
        event.preventDefault();
        setSelectedContentUploadMethod(ContentUploadMethods.SFTP_UPLOAD)
        setSelectedRadioButton(RadioButtonGroup.SET);
        toggleRadioButtonAction(RadioButtonGroup.SET);

        setSteps([UploadSteps.SFTP_STEP, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
    }

    const handleUploadBrowser = (event: any) => {
        event.preventDefault();
        setSelectedContentUploadMethod(ContentUploadMethods.BROWSER_UPLOAD);
        setSelectedRadioButton(RadioButtonGroup.SET);
        toggleRadioButtonAction(RadioButtonGroup.SET);

        setSteps([UploadSteps.PICK_ITEMS, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
    }

    const handleIncompleteUploadFlow = () => {
        setIncompleteFlowDialogOpen(true);
    }

    const getStepContent = (index: number) => {
        switch(steps[index]) {
            // case UploadSteps.SFTP_STEP: 
            //     return <UploadViaSFTP/>;
            case UploadSteps.PICK_ITEMS:
                return <DragAndDropContentStep setUploadingNewFiles={setUploadingNewFiles}/>;
            case UploadSteps.MANAGE_CONTENT:
                return <ManageContent selectedContentUploadMethod={selectedContentUploadMethod}/>;
            case UploadSteps.ADD_ARTICLE:
                return <ArticleTabsComponent/>;
            case UploadSteps.REVIEW_DATA: 
                return <EditContentMetadata duplicateFileIds={[]} editSetFromAdmin={false} channels={channels} editContentTable={false} isInIncomplete={true}/>;
            default:
                return null;
        }
    }

    const handleClose = () => {    
        setContentUploadSnackbar(false);
    };
    
    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
        setClearFolderDialogOpen(false);
        setSavingProgressAsIncomplete(false);
    };

    const handleIncompleteFlowDialogAgree = (value: string) => {
        setIncompleteFlowDialogOpen(false);
        setSavingProgressAsIncomplete(true);
        setLoadingIndicator(true);
        //useEffect must catch the update now
    }

    return (
        <div className='content-upload-container'>
            <MuiThemeProvider theme={contentUploadTheme}>
                <div className='container-left'>
                    <RadioGroup row 
                        aria-label="channels" name="channels" 
                        value={selectedRadioButton} onChange={handleRadioChange}>
                        <FormControlLabel value={RadioButtonGroup.INDIVIDUAL} 
                            control={<GreenRadio size='small'/>} 
                            label="Individual items" 
                            classes={{label: classes.label}}
                            disabled={activeStep > 0 || selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD}/>
                        <FormControlLabel value={RadioButtonGroup.SET} 
                            control={<GreenRadio size='small'/>} 
                            label="Content set" 
                            classes={{label: classes.label}}
                            disabled={activeStep > 0 || selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD}/>
                    </RadioGroup>
                    <div className='upload-information'>
                        <label className='header-label'>Upload as public content information</label>
                        <p>The uploaded content will be published after admin approval. After that it will be visible to
                            everyone using the site. You can check out the submission guide below for more information about the rules
                            and requirements for content approval.</p>
                        
                        {selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD ?
                            <Button
                                size="small"
                                className="submission-green-button"
                                variant="contained"
                                disabled={activeStep !== 0}
                                onClick={handleUploadSFTP}
                            >
                                Upload via FTP
                            </Button> : 
                            <Button
                            size="small"
                            className="submission-white-button"
                            variant="contained"
                            onClick={handleUploadBrowser}
                            >
                                Upload via browser tool
                            </Button>
                        }
                        <Button size='small' className='submission-white-button' 
                            variant='contained'
                            href={`${conf.REACT_APP_CLOUDFRONT_PATH}/assets/pdf/GUIDELINES.pdf`}
                            download='Submission-Guide.pdf'>
                                Download submission guide
                        </Button>

                        {/* {activeStep !== 0 && selectedRadioButton === RadioButtonGroup.SET ?
                            <Button
                                size="small"
                                className="submission-white-button"
                                disabled={uploadingImagesIndicator}
                                onClick={handleIncompleteUploadFlow}
                                variant="contained"
                            >
                                Save upload flow
                            </Button>
                        : null} */}

                        {uploadingNewFiles || uploadingImagesIndicator || loadingIndicator ? 
                            <SyncLoader
                            css={`display: block; margin: 10px auto;
                            text-align: center; width: 100%; height: 100%; z-index: 100;`}
                            size={20}
                            color={"#36D2B3"}
                            loading={uploadingNewFiles }
                            />
                        : 
                        <div className='button-group-progress'>
                            <Button size='small' disabled={activeStep === 0 || (contentUploadType === ContentUploadMethods.SFTP_UPLOAD && activeStep === 1)} onClick={handleBack} className='white-button' variant='contained'> 
                                <ArrowBackIcon/> Back
                            </Button>
                            <Button size='small' className='green-button' 
                                disabled={(droppedFiles.length === 0 && 
                                    uploadedFiles.length === 0 && 
                                    contentSets.length === 0 &&
                                    selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD) || 
                                    (selectedRadioButton === RadioButtonGroup.SET
                                    && uploadedFiles.length > 0 && activeStep === 1)} 
                                onClick={handleNext} variant='contained'
                                endIcon={activeStep === steps.length - 1 ? null : <ArrowForwardIcon/>}>
                                {activeStep === steps.length - 1 ? 'Complete' : 'Next step'}
                            </Button>
                        </div>
                        }

                        <div className='loading-indicator'>
                            {uploadingImagesIndicator ? 
                                <LinearProgress variant="determinate" value={progressBarValue} />
                            : null}

                            {sftpLoadingIndicator ? 
                                <LinearProgress variant="indeterminate"/>
                            : null}

                            <SyncLoader
                                css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
                                size={20}
                                color={"#36D2B3"}
                                loading={loadingIndicator}
                            />
                            <div className='loader-message'>{loadingMessage}</div>
                        </div>
                    </div>
                </div>

                <div className='stepper-container'>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label: any) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Paper elevation={3}>
                        {loadingIndicator ? 
                            null
                            :
                            getStepContent(activeStep)
                        }
                    </Paper>
                </div>
            </MuiThemeProvider>
            <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                               severity={snackbar.severity}
                               message={snackbar.message}/>

            <ConfirmationDialog open={incompleteFlowDialogOpen} title="Save your upload changes?"
                contentText="Your changes to your current upload flow will be saved and you can continue with later on"
                rejectButtonText="No, i don't want to save"
                acceptButtonText="Yes, save changes"
                enableTextField={true}
                textFieldLabel="Upload flow title"
                handleClose={handleConfirmationDialogClose}
                handleConfirmationDialogAgree={handleIncompleteFlowDialogAgree}/>
        </div>
    )
}

const mapStateToProps = (state: AdminStoreState): {uploadedFiles: UploadedFileResponse[], 
    articles: Article[], droppedFiles: DroppedFile[], contentSets: ContentSet[], 
    audioFiles: AudioFile[], userId: number | undefined, 
    individualImageCategories: IndividualImageCategory[], selectedRadioButtonValue: string, incompleteSFTPSetContributorId: any;
    selectedIncompleteFlow: IncompleteUserUploadFlow | undefined, snackbar: IBroadcastMessage; selectedIncompleteSFTPSet: any; imageAngles: ImageAngle[]; } => {
    return {
        uploadedFiles: selectUploadedFiles(state),
        articles: selectArticles(state),
        droppedFiles: selectDroppedFiles(state),
        contentSets: selectContentSets(state),
        audioFiles: selectAudioFiles(state),
        userId: selectCurrentUserId(state),
        individualImageCategories: selectIndividualImagesCategory(state),
        selectedRadioButtonValue: selectRadioButtonValue(state),
        selectedIncompleteFlow: selectCurrentIncompleteFlow(state),
        snackbar: selectSnackbarState(state),
        selectedIncompleteSFTPSet: selectedIncompleteSFTPSet(state),
        incompleteSFTPSetContributorId: selectIncompleteSFTPSetContributorId(state),
        imageAngles: getImageAngles(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions | TEditContentReducerActions>) => {
    return {
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>({type: ContentUploadActionTypes.UPLOAD_FILES, data: data}),
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({type: ContentUploadActionTypes.UPLOAD_SET, data: data}),
        updateSetAction: (data: ContentSet) => dispatch<IUpdateSet>({type: ContentUploadActionTypes.UPDATE_SET, data: data}),
        updateArticleAction: (data: Article) => dispatch<IUpdateArticle>({type: ContentUploadActionTypes.UPDATE_ARTICLE, data: data}),
        toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON, data: data}),
        removeDroppedFile: (data: number) => dispatch<IRemoveFile>({type: ContentUploadActionTypes.REMOVE_FILE, data: data}),
        resetReduxState: () => dispatch<IResetContentUpload>({type: ContentUploadActionTypes.RESET_CONTENT}),
        resetSnackbar: () => dispatch<IResetSnackbar>({type: SnackbarActionTypes.RESET_MESSAGE}),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        }),
        resetImageAngles: () =>
        dispatch<IResetImageAngle>({
            type: EditContentActionTypes.RESET_IMAGE_ANGLES
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentUploadPage);
