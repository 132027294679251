import {ChannelsTableState, ChannelsTableActionTypes} from './channels.types';
import { Channel, SubChannel, Thread} from "../../components/channels-table/channelsTable.types";
import { TChannelsTableActions } from './channels.actions';

const InitialState: ChannelsTableState = {
    selectedChannelId: 0,
    selectedSubChannelId: 0,
    selectedThreadId: 0,
    channels: [],
    subchannels: [],
    threads: []
};

export const channelsTableReducer = (state = InitialState, action: TChannelsTableActions) : ChannelsTableState => {
    switch(action.type) {
        case ChannelsTableActionTypes.LoadChannels:
            return {
                ...state,
                selectedChannelId: action.data[0] ? action.data[0].id : 0, 
                channels: action.data.sort(function(userData1, userData2) {return userData1.id - userData2.id})
            };
        case ChannelsTableActionTypes.LoadSubChannels:
            if (typeof action.data === 'number') {
                return {
                    ...state,
                    selectedChannelId: action.data,
                    subchannels: []            
                };
            }
            else {
                return {
                    ...state,
                    selectedChannelId: action.data[0] ? action.data[0].channelId : 0,
                    subchannels: action.data.sort(function(userData1, userData2) {return userData1.id - userData2.id})            
                };
            }
                
        case ChannelsTableActionTypes.LoadThreads:
            if (typeof action.data === 'number') {
                return {
                    ...state,
                    selectedSubChannelId: action.data,
                    threads: []
                };
            }
            else {
                return {
                    ...state,
                    selectedSubChannelId: action.data[0] ? action.data[0].subChannelId : 0,
                    threads: action.data.sort(function(userData1, userData2) {return userData1.id - userData2.id})
                };
            }
        case ChannelsTableActionTypes.ResetThreads:
            return {
                ...state,
                subchannels: [],
                threads: [],
                selectedSubChannelId: 0,
                selectedThreadId: 0,
            };
        case ChannelsTableActionTypes.AddChannel:
            if(action.data.channelId) {
                return {
                    ...state,
                    subchannels: state.subchannels.concat(action.data as SubChannel)
                }
            } else if(action.data.subChannelId) {
                return {
                    ...state,
                    threads: state.threads.concat(action.data as Thread)
                }
            } else if(action.data as Channel) {
                return { 
                    ...state,
                    channels: state.channels.concat(action.data as Channel)
                }
            }
        case ChannelsTableActionTypes.UpdateChannel:
            if(action.data.category === 'channel') {
                return {
                    ...state,
                    selectedChannelId: action.data.id,
                    selectedSubChannelId: 0
                }
            } else if(action.data.category === 'subchannel') {
                return {
                    ...state,
                    selectedChannelId: action.data.channelId,
                    selectedSubChannelId: action.data.id
                }
            } else if(action.data.category === 'thread') {
                return {
                    ...state,
                    selectedChannelId: action.data.channelId,
                    selectedSubChannelId: action.data.subChannelId,
                    selectedThreadId: action.data.id
                }
            }
        case ChannelsTableActionTypes.DeleteChannel:
            if(action.data.deleteSubChannel) {
                return {
                    ...state,
                    subchannels: state.subchannels.filter(sub => sub.id !== action.data.deleteSubChannel)
                }
            }
            else if (action.data.deleteThread) {
                return {
                    ...state,
                    threads: state.threads.filter(thread =>  thread.id !== action.data.deleteThread)
                }
            }
            else if (action.data.deleteChannel) {
                return {
                    ...state,
                    channels: state.channels.filter(channel => channel.id !==  action.data.deleteChannel)
                }
            }
        default:
            return state;
    }
}

export default channelsTableReducer;