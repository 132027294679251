import { createTheme } from "@material-ui/core/styles";
import { UserData, UpdateUserPassword } from '../../redux/users-table/usersTable.types'
import { WithStyles } from "@material-ui/core/styles";
import { User } from "../../redux/admin/admin.types";
import { IBroadcastMessage } from "../../redux/generic/snackbar/snackbar.types";

export enum RoleTypes {
  Contributor_individual = "contributor_individual",
  Contributor_agency = "contributor_agency",
  Pro_organization = "pro_organization",
  Pro_freelancer = "pro_freelancer",
  MW_User = "mw_user",
  Member = "member",
  Admin = "admin",
  SuperAdmin = "superadmin"
}

export enum RoleTypesMessages {
  contributor_individual = "C-Individual",
  contributor_agency = "C-Agency",
  pro_organization = "PM-Organization",
  pro_freelancer = "PM-Freelancer",
  admin = "Admin",
  superadmin = "Superadmin"
}
export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  userType: string;
}

export interface DataTableProps {
  currentUser: User;
  userType: string;
  usersTable: UserData[];
  index: number;
  snackbar: IBroadcastMessage;
  usersTableRefreshState: boolean;
  loginSuccessAction: (data: User) => void;
  loadedDataAction: (data: UserData[]) => void;
  deleteUserAction: (data: number[]) => void;
  broadcastSnackbarAction: (data: IBroadcastMessage) => void;
  toggleUsersTableRefresh: (data: boolean) => void;
}

export interface NewUserProps extends WithStyles<typeof styles>{
  userType: string;
  snackbarMessage: IBroadcastMessage;
  addUserAction: (data: UserData) => void;
  broadcastSnackbarAction: (data: IBroadcastMessage) => void;
  refetch: () => void;
}

export interface UpdateUserProps extends WithStyles<typeof styles>{
  rowIndex: any;
  usersTable: UserData[];
  currentUser: User;
  snackbarMessage: IBroadcastMessage;
  updateUserAction: (data: UserData) => void;
  broadcastSnackbarAction: (data: IBroadcastMessage) => void;
  refetch: () => void;
}

export const dialogStyles = createTheme({
  overrides: {
      MuiDialog: {
          root: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row'
          },
          paper: {
              borderRadius: '25px'
          }
      },
      MuiFormGroup:
      {
        root:
        {
            display:"display"
        }
      },
      MuiInputBase:
      {
        root:
        {
          marginTop:"10px"
        }
      }
  }
});


export const adminTabPanelStyle = createTheme({
  overrides: {
    MuiAppBar: {
      root: {
        color: "black",
        backgroundColor: 'white'
      }
    }
  }
});


export const defaultToolbarStyles = {
  iconButton: {
  },
};
export const styles = {
  error: {
    color: 'red',
    fontSize: '10px',
    width: '240px'
  }
}

export interface ChangeUserPassword extends WithStyles<typeof styles> {
  email: string;
  changePasswordAction: (data: UpdateUserPassword) => void;
}

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;