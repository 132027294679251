import { AdminStoreState } from 'redux/root-reducer';
import { createSelector } from 'reselect';

const selectTableFilter = (state: AdminStoreState) => state.tableFilters;

export const selectLicensingFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.licensingType
);

export const selectContributorType = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.contributorType
);

export const selectContentType = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.contentType
);

export const selectUploadDate = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.uploadDate
);

export const selectPublishDate = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.publishDate
);

export const selectChannelFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.channelFilter
);

export const selectSubchannelFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.subchannelFilter
);

export const selectThreadFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.threadFilter
)

export const selectFlowTitle = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.flowTitle
)

export const selectUsername = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.username
)

export const selectContributorName = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.contributorName
)

export const selectNumberOfContents = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.numberOfContents
)

export const selectNumberOfContentSets = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.numberOfContentSets
)

export const selectNumberOfArticles = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.numberOfArticles
)

export const getSearchString = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.searchString
)