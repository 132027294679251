import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import './toolbar.styles.scss';
import { APPROVE_ARTICLE, APPROVE_CONTENT, APPROVE_CONTENT_SET, REJECT_ARTICLE, REJECT_CONTENT, REJECT_CONTENT_SET } from '../waiting-list/queries';
import { useMutation } from '@apollo/react-hooks';
import { CustomToolbarProps, ToolbarActions } from './toolbar.types';
import { ContentTypes } from '../waiting-list/waitingList.types';
import { UserUploadedContent } from '../content-list/contentList.types';
import { connect } from 'react-redux';
import { selectWaitingListData } from '../../redux/waiting-list/waitinglist.selectors';
import { AdminStoreState } from '../../redux/root-reducer';
import { SnackbarComponent } from "shared";
import { Dispatch } from 'redux';
import { TContentUploadActions } from '../../redux/content-upload/content-upload.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';

const CustomToolbarComponent: React.FC<CustomToolbarProps> = ({ ...props }) => {
    const { activeRows, waitingListData, refetch, toolbarError, broadcastToolbarErrorAction } = props;

    const [approveContentMutation] = useMutation(APPROVE_CONTENT);
    const [rejectContentMutation] = useMutation(REJECT_CONTENT);
    const [approveArticleMutation] = useMutation(APPROVE_ARTICLE);
    const [rejectArticleMutation] = useMutation(REJECT_ARTICLE);
    const [approveContentSetMutation] = useMutation(APPROVE_CONTENT_SET);
    const [rejectContentSetMutation] = useMutation(REJECT_CONTENT_SET);

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [toolbarAction, setToolbarAction] = useState(ToolbarActions.NO_ACTION);

    useEffect(() => {
        if (showSnackbar) {
            refetch();
        }
    }, [showSnackbar]);

    useEffect(() => {
        if (toolbarError.severity && toolbarError.message) {
            setShowSnackbar(true);
        }
    }, [toolbarError]);

    useEffect(() => {
        if (toolbarAction === ToolbarActions.APPROVE) {
            broadcastToolbarErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: "Content was succesfully approved"
            });
        } else if (toolbarAction === ToolbarActions.REJECT) {
            broadcastToolbarErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: "Items were succesfully rejected"
            });
        } else if (toolbarAction === ToolbarActions.ERROR_IN_APPROVE) {
            broadcastToolbarErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when approved content"
            });
        } else if (toolbarAction === ToolbarActions.ERROR_IN_REJECT) {
            broadcastToolbarErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when rejecting items"
            });
        } else {
            setShowSnackbar(false);
        }
    }, [toolbarAction]);

    const approveSelectedContent = () => {
        activeRows.map((row: any) => {
            const selectedContentType = row.data[4];
            const selectedContentId = row.data[1];
            if (selectedContentType === ContentTypes.CONTENT_SET) {
                let contentSet = waitingListData.find((set: any) => set.contentSetId === selectedContentId);
                if (contentSet) {
                    approveContentSetMutation({
                        variables: { setId: contentSet.contentSetId }
                    }).then((result: any) => {
                        setToolbarAction(ToolbarActions.APPROVE);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_APPROVE);
                    });
                }
            }
            else if (selectedContentType === ContentTypes.ARTICLE) {
                let selectedArticle = waitingListData.find((article: any) => article.articleId === selectedContentId);
                if (selectedArticle) {
                    approveArticleMutation({
                        variables: { articleId: selectedArticle.articleId }
                    }).then((result: any) => {
                        setToolbarAction(ToolbarActions.APPROVE);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_APPROVE);
                    });
                }
            }
            else if (selectedContentType === ContentTypes.IMAGE) {
                let image = waitingListData.find((image: any) => image.content.id === selectedContentId);
                if (image) {
                    approveContentMutation({
                        variables: {
                            contentId: image.content.id
                        }
                    }).then((result: any) => {
                        setToolbarAction(ToolbarActions.APPROVE);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_APPROVE);
                    });
                }
            }
        })
    }


    const rejectSelectedContent = () => {
        activeRows.map((row: any) => {
            const selectedContentType = row.data[4];
            const selectedContentId = row.data[1];
            if (selectedContentType === ContentTypes.CONTENT_SET) {
                let contentSet = waitingListData.find((set: any) => set.contentSetId === selectedContentId);
                if (contentSet) {
                    rejectContentSetMutation({
                        variables: { setId: contentSet.contentSetId }
                    }).then((result: any) => {
                        setToolbarAction(ToolbarActions.REJECT);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_REJECT);
                    });
                }
            }
            else if (selectedContentType === ContentTypes.ARTICLE) {
                let selectedArticle = waitingListData.find((article: any) => article.articleId === selectedContentId);
                if (selectedArticle) {
                    rejectArticleMutation({
                        variables: { articleId: selectedArticle.articleId }
                    }).then((result: any) => {
                        setToolbarAction(ToolbarActions.REJECT);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_REJECT);
                    });
                }
            }
            else if (selectedContentType === ContentTypes.IMAGE) {
                let image = waitingListData.find((image: any) => image.content.id === selectedContentId);
                if (image) {
                    rejectContentMutation({
                        variables: { contentId: image.content.id }
                    }).then((result: any) => {
                        setToolbarAction(ToolbarActions.REJECT);
                    }).catch((error: any) => {
                        setToolbarAction(ToolbarActions.ERROR_IN_REJECT);
                    });
                }
            }
        })
    }

    const handleClose = () => {
        setShowSnackbar(false);
    };

    return (
        <div className='button-container'>
            <Button
                size='medium'
                className='green-button'
                variant='contained'
                onClick={event => {
                    event.stopPropagation();
                    approveSelectedContent();
                }}>Approve</Button>
            <Button
                size='medium'
                className='red-button'
                variant='contained'
                onClick={event => {
                    event.stopPropagation();
                    rejectSelectedContent();
                }}>Reject</Button>
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                severity={toolbarError.severity} message={toolbarError.message} />
        </div>
    )

}
const mapStateToProps = (state: AdminStoreState): { waitingListData: UserUploadedContent[], toolbarError: IBroadcastMessage } => {
    return {
        waitingListData: selectWaitingListData(state),
        toolbarError: selectSnackbarState(state)
    }
};

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions>) => {
    return {
        broadcastToolbarErrorAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomToolbarComponent);