import React from 'react'
import { RoleTypes } from "../../components/users-table/usersAdminTable.types";
import { ContentTypes } from "../../components/waiting-list/waitingList.types";
import { UserUploadedContent } from "./contentList.types";
import Moment from "react-moment";
const convertRole = (item: UserUploadedContent) => {
  if (item.contributor.role === RoleTypes.Contributor_agency) {
    return "Agency";
  } else {
    return "Individual";
  }
};
const getName = (item: UserUploadedContent) => {
  if (item.contributor.role === RoleTypes.Contributor_individual) {
    return item.contributor && item.contributor.person
      ? item.contributor.person.firstName +
      " " +
      item.contributor.person.lastName
      : "N/A";
  } else if (item.contributor.role === RoleTypes.Contributor_agency) {
    return item.contributor && item.contributor.company
      ? item.contributor.company.name
      : "N/A";
  }
};

const getItemIdByType = (item: UserUploadedContent) => {
  if (item.type === ContentTypes.CONTENT_SET) {
    return item.contentSetId;
  } else if (item.type === ContentTypes.ARTICLE) {
    return item.articleId;
  } else {
    return item.content ? item.content.id : "";
  }
};

export const createTableRow = (item: UserUploadedContent) => {
  return [
    item.content.pathToFileThumbnail,
    getItemIdByType(item),
    item.content.code,
    item.title,
    item.type,
    item.contributor.id,
    item.contributor ? item.contributor.username : "N/A",
    getName(item),
    convertRole(item),
    <Moment format="DD/MM/YYYY">{item.uploadDate}</Moment>,
    item.publishDate ? (
      <Moment format="DD/MM/YYYY">{item.publishDate}</Moment>
    ) : (
      "not published"
    ),
    item.content.licensingType,
    item.contentNavigations.map(nav => {
      return (
        nav.channel.title +
        " => " +
        nav.subChannel.title +
        " => " +
        nav.thread.title +
        "\n"
      );
    }),
    decodeURI(item.content.externalLink),
    decodeURI(item.content.externalLinkMessage)
  ];
};