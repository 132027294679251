import React, { useState, useEffect } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles, RadioGroup, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_FLAT_RATE } from './queries';
import { RoleTypesMessages, styles, UpdateContentRatesProps } from './LicenseAdminTable.types';
import { dialogStyles, defaultToolbarStyles, RoleTypes } from '../users-table/usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SingleContentTypes, LicenseTypeValues } from './LicenseAdminTable.types';
import { getEnumValues } from "../../components/users-table/helperFunctions";

const UpdateContentRateModal: React.FC<UpdateContentRatesProps> = ({ ...props }) => {
    const { id, refetch, data, classes } = props;
    const [response, setResponseState] = useState("");
    const [open, setOpen] = useState(false);
    const [licenseType, setLicenseType] = useState(data[1]);
    const [contentType, setContentType] = useState(data[2]);
    const [initialFlatRate, setInitialFlatRate] = useState(data[4].slice(1))

    const getRoleFromData = (role: string) => {
        switch (role) {
            case RoleTypesMessages.pro_organization:
                return RoleTypes.Pro_organization
            case RoleTypesMessages.pro_freelancer:
                return RoleTypes.Pro_freelancer
            case RoleTypesMessages.mw_user:
                return RoleTypes.MW_User
            default:
                return RoleTypes.Member
        }
    }
    const [userRole, setUSerRole] = useState(getRoleFromData(data[3]));
    const validationSchema = Yup.object({
        flatRate: Yup.number().required("Add an number.")
        .test(
            'Is positive?',
            'The price must be greater than 0!',
            (value: any) => value > 0
        ),
    });
    const [updateContentRateMutation] = useMutation(UPDATE_FLAT_RATE);
    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            licenseType: licenseType,
            flatRate: initialFlatRate,
            contentType: contentType,
            role: userRole,
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            
            const { flatRate } = values;
            updateContentRateMutation({
                variables: {
                    id: id,
                    licenseType: licenseType.toLowerCase(),
                    contentType: contentType.toLowerCase(),
                    role: userRole.toLowerCase(),
                    flatRate: flatRate
                }
            }).then((result: any) => {
                if (result.data.updateContentRate) {
                    refetch();
                    handleClose();
                }
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        }
    })
    const handleClose = () => {

        setOpen(false);
    }

    const openContentRateModal = () => {
        setOpen(true);
        setContentType(data[2]);
        setLicenseType(data[1]);
        setUSerRole(getRoleFromData(data[3]));
        setInitialFlatRate(data[4].slice(1))
    }

    const handleSelectPeriod = (e: any) => {
        if (e.target.name === "licenseType") {
            setLicenseType(e.target.value)
        }
        if (e.target.name === "contentType") {
            setContentType(e.target.value)
        }
        if (e.target.name === "userRole") {
            setUSerRole(e.target.value)
        }
        if (e.target.name === "flatRate") {
            setInitialFlatRate(e.target.value)
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={"Update"}>
                <IconButton onClick={openContentRateModal}>
                    <CreateIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Update plan</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <FormControl>
                                                <InputLabel >License type</InputLabel>
                                                <Select name="licenseType" onChange={handleSelectPeriod} value={licenseType.toLowerCase()} defaultValue={licenseType.toLowerCase()}>
                                                    <MenuItem value={LicenseTypeValues.Editorial}>{LicenseTypeValues.Editorial}</MenuItem>
                                                    <MenuItem value={LicenseTypeValues.Standard}>{LicenseTypeValues.Standard}</MenuItem>
                                                    <MenuItem value={LicenseTypeValues.Premium}>{LicenseTypeValues.Premium}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel >Content type</InputLabel>
                                                <Select name="contentType" onChange={handleSelectPeriod} value={contentType.toLowerCase()} defaultValue={contentType.toLowerCase()}>
                                                    <MenuItem value={SingleContentTypes.Image}>{SingleContentTypes.Image}</MenuItem>
                                                    <MenuItem value={SingleContentTypes.Video}>{SingleContentTypes.Video}</MenuItem>
                                                    <MenuItem value={SingleContentTypes.Audio}>{SingleContentTypes.Audio}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel >User role</InputLabel>
                                                <Select name="userRole" onChange={handleSelectPeriod} value={userRole} defaultValue={userRole}>
                                                    {getEnumValues(RoleTypesMessages)}
                                                </Select>
                                            </FormControl>
                                            <TextField 
                                                autoComplete="off" 
                                                type="number" 
                                                className='select-label' 
                                                name="flatRate" 
                                                onChange={handleChange} 
                                                label="Rate" 
                                                variant="standard"  
                                                defaultValue={initialFlatRate} 
                                                error={errors.flatRate === ""}
                                                helperText={errors.flatRate ? errors.flatRate : null}
                                                FormHelperTextProps={{ className: classes.error }}/>
                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Update plan</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}



export default (withStyles(styles)(UpdateContentRateModal));