import React, { useState, useEffect } from 'react';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { MuiThemeProvider, IconButton } from '@material-ui/core';
import { DataTableProps, LicencePlan, FlatRate, Editorial, LicenseTableTabTypes } from './LicenseAdminTable.types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_ALL_LICENSING_PLANS, DELETE_LICENSING_PLAN, GET_FLAT_RATES, DELETE_FLAT_RATE, GET_EDITORIAL_RATES, DELETE_EDITORIAL_RATE } from './queries';
import AddNewLicenseModal from './AddNewLicenseModal';
import UpdateLicenseModal from './UpdateLicenseModal';
import AddEditorialRate from './AddNewEditorialRate';
import './licenseAdminTable.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddNewContentRate from './AddNewContentRate';
import UpdateContentRateModal from './UpdateContentRateModal';
import UpdateEditorialRateModal from './UpdateEditorialRateModal';
import { RoleTypes } from "../users-table/usersAdminTable.types";
import { RefreshDateTime } from 'shared';
import { selectLicenseTableRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleLicenseTableRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import { AdminStoreState } from '../../redux/root-reducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

const LicenseDataTableComponent: React.FC<DataTableProps> = ({ ...props }) => {

    const { licenseType, licenseTableRefreshState, toggleLicenseTableRefresh } = props;
    const [currentType, setCurrentType] = useState(licenseType);

    // TODO: remove unused variables
    const [response, setResponseState] = useState("");
    const [queryData, setQueryData] = useState<LicencePlan[]>([]);
    const [rates, setRates] = useState<FlatRate[]>([]);
    const [editorial, setEditorial] = useState<Editorial[]>([])

    const [queryName, setQueryName] = useState(GET_ALL_LICENSING_PLANS);
    const [dataQuery, { data, loading, error, refetch }] = useLazyQuery(queryName, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    const [currentPlans, setCurrentPlans] = useState<LicencePlan[]>([]);

    const getLicenseType = (licenseType: string) => {
        return licenseType.toLocaleLowerCase();
    }

    useEffect(() => {
        if (queryName === GET_ALL_LICENSING_PLANS) {
            dataQuery({ variables: { licenseTypeId: getLicenseType(licenseType) } });
        }
        if (queryName === GET_FLAT_RATES || queryName === GET_EDITORIAL_RATES) {
            dataQuery();
        }

    }, [dataQuery, licenseType]);

    useEffect(() => {
        if (data && data.getLicensingPlans) {
            setQueryData(data.getLicensingPlans);
        }
        if (data && data.getContentRates) {
            setRates(data.getContentRates);
        }
        if (data && data.getEditorialRates) {
            setEditorial(data.getEditorialRates);
        }
    }, [data, licenseType]);

    useEffect(() => {
        let plans = []
        let type = getLicenseType(licenseType)
        for (let i = 0; i < queryData.length; i++) {
            if (queryData[i].licensingType === type) {
                plans.push(queryData[i]);
            }
        }
        setCurrentPlans(plans);
    }, [licenseType, queryData]);

    useEffect(() => {
        setCurrentType(licenseType);
        if (licenseType === LicenseTableTabTypes.FLAT_RATES) {
            setQueryName(GET_FLAT_RATES);
        }
        else if (licenseType === LicenseTableTabTypes.EDITORIAL) {
            setQueryName(GET_EDITORIAL_RATES);
        }
        else {
            setQueryName(GET_ALL_LICENSING_PLANS);
        }
    }, [licenseType]);

    const [deleteLicensePlanMutation] = useMutation(DELETE_LICENSING_PLAN);
    const deleteLicensingPlan = (id: number): void => {
        deleteLicensePlanMutation({
            variables: {
                id: id
            }
        }).then((result: any) => {
            refetch().then(r => {
            });
        }).catch((error: any) => {
            setResponseState(error.graphQLErrors[0].message);
        })
    };

    const [deleteContentRateMutation] = useMutation(DELETE_FLAT_RATE);
    const deleteContentRate = (id: number): void => {
        deleteContentRateMutation({
            variables: {
                id: id
            }
        }).then((result: any) => {
            refetch().then(r => {
            });
        }).catch((error: any) => {
            setResponseState(error.graphQLErrors[0].message);
        })
    };

    const [deleteEditorialRateMutation] = useMutation(DELETE_EDITORIAL_RATE);
    const deleteEditorialRate = (id: number): void => {
        deleteEditorialRateMutation({
            variables: {
                id: id
            }
        }).then((result: any) => {
            refetch().then(r => {
            });
        }).catch((error: any) => {
            setResponseState(error.graphQLErrors[0].message);
        })
    };

    const handleDeletePlan = (id: number) => {
        if (licenseType ===  LicenseTableTabTypes.FLAT_RATES) {
            deleteContentRate(id);
        }
        else if (licenseType === LicenseTableTabTypes.EDITORIAL) {
            deleteEditorialRate(id);
        }
        else {
            deleteLicensingPlan(id);
        }

    }

    const convertRole = (role: string) => {
        if (role) {
            let typeRole = "";
            if (role === RoleTypes.Contributor_individual || role === RoleTypes.Contributor_agency) {
                typeRole = "C-";
            }
            if (role === RoleTypes.Pro_organization || role === RoleTypes.Pro_freelancer) {
                typeRole = "PM-";
            }
            if (role === RoleTypes.MW_User) {
                typeRole = "MW-";
            }
            let splitRole = role.split("_");
            return typeRole + cpaitalizeFirstLetter(splitRole[splitRole.length - 1]);
        }
    }

    // TODO: Extract to a generic file

    const cpaitalizeFirstLetter = (item: string) => {
        return item.charAt(0).toUpperCase() + item.slice(1);
    }
    
    const columns = [
        {
            name: "ID",
            options: {
                display: 'false' as 'false',
                viewColumns: false,
            }
        },
        "Type",
        "Amount",
        "Period",
        "Total price US",
        "Unit price",
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <UpdateLicenseModal licenseType={licenseType} id={tableMeta.rowData[0]} data={tableMeta.rowData} refetch={refetch} />
                    )
                }
            }
        },
        {
            name: "Delete",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <IconButton className="delete-plan" onClick={() => handleDeletePlan(tableMeta.rowData[0])}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    )
                }
            }
        },
    ];

    const columnFlat = [
        {
            name: "ID",
            options: {
                display: 'false' as 'false',
                viewColumns: false,
            }
        },
        "License type",
        "Content type",
        "User role",
        "Price",
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <UpdateContentRateModal id={tableMeta.rowData[0]} data={tableMeta.rowData} refetch={refetch} />
                    )
                }
            }
        },
        {
            name: "Delete",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <IconButton className="delete-plan" onClick={() => handleDeletePlan(tableMeta.rowData[0])}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    )
                }
            }
        },
    ];
    const columnEditorial = [
        {
            name: "ID",
            options: {
                display: 'false' as 'false',
                viewColumns: false,
            }
        },
        "Content type",
        "Price",
        "Size",
        {
            name: "Edit",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <UpdateEditorialRateModal id={tableMeta.rowData[0]} data={tableMeta.rowData} refetch={refetch} />
                    )
                }
            }
        },
        {
            name: "Delete",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (
                        <IconButton className="delete-plan" onClick={() => handleDeletePlan(tableMeta.rowData[0])}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    )
                }
            }
        },
    ]
    const options: MUIDataTableOptions = {
        selectableRowsHeader: true,
        selectableRows: "multiple",
        selectableRowsOnClick: false,
        filter: true,
        fixedHeader: false,
        filterType: 'dropdown',
        responsive: 'standard',
        download: true,
        print: false,
        customToolbar: (tableMeta: any) => {
            return (
                currentType ===  LicenseTableTabTypes.FLAT_RATES ? <AddNewContentRate rowIndex={tableMeta.rowIndex} reFetch={refetch} /> : currentType ===  LicenseTableTabTypes.EDITORIAL ? <AddEditorialRate refetch={refetch} licenseTable={editorial} /> : <AddNewLicenseModal licenseType={licenseType} refetch={refetch} />
            )
        },
        searchPlaceholder: "Search " + licenseType,
    };

    const getUnitPrice = (totalPrice: number, amount: number) => {
        let unitPrice = totalPrice / amount
        return getPriceDecimals(unitPrice)
    }

    const getPriceDecimals = (price: number) => {
        return parseFloat(price.toString()).toFixed(2)
    }


    return (
        <div className="users-container">
            <div className='refetch-container'>
                <RefreshDateTime loading={loading} autoRefreshOn={licenseTableRefreshState} 
                    refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleLicenseTableRefresh}/>
            </div>
            <MUIDataTable title={""} data={currentType === LicenseTableTabTypes.FLAT_RATES ? rates.map(item => {

                return [item.id, cpaitalizeFirstLetter(item.licensingType), cpaitalizeFirstLetter(item.contentType), convertRole(item.role), "$" + getPriceDecimals(item.flatRate)]

            }) :
                currentType === LicenseTableTabTypes.EDITORIAL ? editorial.map(item => {
                    return [item.id, cpaitalizeFirstLetter(item.contentType), "$" + getPriceDecimals(item.rate), cpaitalizeFirstLetter(item.size)]
                }) :
                    currentPlans.map(item => {
                        return [item.id, item.type, item.amount, item.period !== null ? item.period + " days" : "N/A", "$" + getPriceDecimals(item.totalPrice), "$" + getUnitPrice(item.totalPrice, item.amount)]

                    })}
                columns={currentType === LicenseTableTabTypes.FLAT_RATES ? columnFlat : currentType === LicenseTableTabTypes.EDITORIAL ? columnEditorial : columns} options={options} />
        </div>
    );
}

const mapStateToProps = (state: AdminStoreState): {licenseTableRefreshState: boolean} => {
    return {
        licenseTableRefreshState: selectLicenseTableRefresh(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TRefreshTablesActions>) => {
    return {
        toggleLicenseTableRefresh: (data: boolean) => dispatch<IToggleLicenseTableRefresh>({
            type: TablesRefreshActionTypes.LICENCE_TABLE_REFRESH,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDataTableComponent);