import React, { useEffect } from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Navbar from './components/navigation/Navbar';
import UsersAdminTabPanel from './components/users-table/UsersAdminTabPanel';
import LoginComponent from './components/login-component/login.component';
import { User } from './redux/admin/admin.types';
import ChannelsPage from './components/channels-table/channelsPage';
import './App.scss';
import { AdminStoreState } from 'redux/root-reducer';
import { selectCurrentUser } from './redux/admin/admin.selectors';
import { history } from './redux/store';
import ContentUploadPage from "./components/batch-upload/ContentUploadPage";
import EditContent from './components/content-list/editContent.component';
import EditWaitingList from './components/waiting-list/editWaitingList.component'
import OrderHistoryTable from './components/order-history-table/orderHistoryTable';
import WaitingListTabs from "./components/waiting-list/waitingListTabs";
import ContentList from "./components/content-list/contentList";
import LicenseAdminTabPanel from './components/license-table/LicenseAdminTabPanel'
import HomeComponent from './components/home-component/Home';
import IncompleteUploadList from './components/incomplete-upload-list/incompleteUploadList';
import { IncompleteUserUploadFlow } from './components/incomplete-upload-list/incompleteUpload.types';
import { selectCurrentIncompleteFlow } from './redux/incomplete-upload/incompleteUpload.selectors';
import ReportsTable from './components/reports-table/reportsTable';
import {ErrorPage} from './components/ErrorPage/ErrorPage';
import { selectCurrentIncompleteSFTPFlow } from './redux/incomplete-sftp-upload/incompleteSFTPUpload.selectors';
import { IncompleteSFTPUserUploadFlow } from './components/incomplete-sftp-upload-list/incompleteSFTPUpload.types';
import incompleteSFTPUploadList from './components/incomplete-sftp-upload-list/incompleteSFTPUploadList';
import postReportsTable from './components/post-reports-table/postReportsTable';

const App = ({ ...props }) => {
  const { currentUser, selectedIncompleteFlow, selectedIncompleteSFTPFlow } = props;

  const renderWaitingListTabs = (routeProps: RouteComponentProps) => {
    return <WaitingListTabs routeParams={routeProps.match.params} />;
  }

  const renderEditWaitingListPage = (routeProps: RouteComponentProps) => {
    return <EditWaitingList routeParams={routeProps.match.params} />;
  }

  const renderEditContentPage = (routeProps: RouteComponentProps) => {
    return <EditContent routeParams={routeProps.match.params} />;
  }

  const renderContentUploadPage = (routeProps: RouteComponentProps) => {
    if (selectedIncompleteFlow) {
      return <ContentUploadPage history={history} />
    } else {
      history.push('/incomplete-upload');
    }
  }
  
  const renderSFTPContentUploadPage = (routeProps: RouteComponentProps) => {
    if (selectedIncompleteSFTPFlow) {
      return <ContentUploadPage history={history} />
    } else {
      history.push('/incomplete-upload');
    }
  }

  return (
    <div className="App">
      <ConnectedRouter history={history}>
        {currentUser.email ? <Navbar /> : <LoginComponent history={history} currentUser={currentUser} />}
        <Switch>
          {/*TODO: Replace with private routes!*/}
          <Route exact path="/" render={() => (
            <React.Fragment>
              <HomeComponent />
            </React.Fragment>
          )}
          />
          <Route exact path='/channels-table' component={ChannelsPage} />
          <Route exact={false} path='/users' component={UsersAdminTabPanel}  />
          <Route exact={false} path='/content-upload' component={ContentUploadPage}  />
          <Route exact path='/waiting-list/:waitingListTabIndex' render={routeProps => renderWaitingListTabs({ ...routeProps })} />
          {/* todo: pass the tab index on edit so that on coming back to redirect to the specified place */}
          <Route exact path='/edit-waiting-list/:tabIndex/:type/:id' render={routeProps => renderEditWaitingListPage({ ...routeProps })} />
          <Route exact path='/edit-content-data/:type/:id' render={routeProps => renderEditContentPage({ ...routeProps })} />
          <Route exact path='/content' component={ContentList} />
          <Route exact path='/sales' component={OrderHistoryTable} />
          <Route exact path='/license' component={LicenseAdminTabPanel} />
          <Route exact path='/incomplete-upload' component={IncompleteUploadList} />
          <Route exact path='/incomplete-sftp-upload' component={incompleteSFTPUploadList} />
          <Route exact path='/incomplete-upload-flow/:id' render={routeProps => renderContentUploadPage({ ...routeProps })} />
          <Route exact path='/incomplete-sftp-upload-flow/:id' render={routeProps => renderSFTPContentUploadPage({ ...routeProps })} />
          <Route exact path='/reports' component={ReportsTable} />
          <Route exact path='/post-reports' component={postReportsTable} />
          <Route path='*' component={ErrorPage}/>
        </Switch>
      </ConnectedRouter>

    </div>
  );
}

const mapStateToProps = (state: AdminStoreState): {
  currentUser: User;
  selectedIncompleteFlow: IncompleteUserUploadFlow | undefined;
  selectedIncompleteSFTPFlow: IncompleteSFTPUserUploadFlow | undefined;
} => {
  return {
    currentUser: selectCurrentUser(state),
    selectedIncompleteFlow: selectCurrentIncompleteFlow(state),
    selectedIncompleteSFTPFlow: selectCurrentIncompleteSFTPFlow(state),
  }
}

export default connect(mapStateToProps, null)(App);
