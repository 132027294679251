import React, {useState} from "react";
import {IconButton, Dialog, Button, Backdrop, Tooltip, Fade, MuiThemeProvider, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import { TableNames, DeleteChannelProps } from "./channelsTable.types";
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, AlertTitle } from '@material-ui/lab';
import {DELETE_CHANNEL, DELETE_SUBCHANNEL, DELETE_THREAD} from './queries';
import { dialogStyles } from "../users-table/usersAdminTable.types";
import { useMutation } from "@apollo/react-hooks";
import { Dispatch } from "redux";
import { TChannelsTableActions, IDeleteChannel } from "../../redux/channels-table/channels.actions";
import { ChannelsTableActionTypes } from "../../redux/channels-table/channels.types";
import { connect } from "react-redux";
import { useEffect } from "react";

const DeleteChannelModal: React.FC<DeleteChannelProps> = ({...props}) => {
    const {tableName, selectedItemId, deleteChannelAction} = props;
    const openDeleteChannelModal = false;
    const [value, setValue] = useState(false);
    const [open, setOpen] = useState(openDeleteChannelModal);
    const [response, setResponseState] = useState("");
    const [resultData, setResultData] = useState<any>();

    const [deleteChannelMutation] = useMutation(DELETE_CHANNEL);
    const [deleteSubChannelMutation] = useMutation(DELETE_SUBCHANNEL);
    const [deleteThreadMutation] = useMutation(DELETE_THREAD);

    const deleteChannel = (): void => {
        if(tableName === TableNames.Channels) {
            deleteChannelMutation({
                variables: {
                    id: selectedItemId
                }
            }).then((result: any) => {
                setResultData(result.data)
                handleCancel();
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        } else if (tableName === TableNames.SubChannels) {
            deleteSubChannelMutation({
                variables: {
                    id: selectedItemId
                }
            }).then((result: any) => {
                setResultData(result.data)
                handleCancel();
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        } else if (tableName === TableNames.Threads) {
            deleteThreadMutation({
                variables: {
                    id: selectedItemId
                }
            }).then((result: any) => {
                setResultData(result.data)
                handleCancel();
            }).catch((error: any) => {
                setResponseState(error.graphQLErrors[0].message);
            });
        }
        
    }

    useEffect(() => {
        if(resultData) { 
            deleteChannelAction(resultData);
        }
    }, [resultData]);
    
    const handleClose = (newValue?: boolean) => {
        setOpen(false);
    
        if (newValue) {
          setValue(newValue);
        }
    };

    const openDeleteModal = () => {
        setResponseState('');
        setOpen(true);
    }

    const handleCancel = () => {
        setOpen(false);
    };
    
    const handleOk = () => {
        setValue(true);
        deleteChannel();
    };

    return (
    <React.Fragment>
        <Tooltip title="Delete">
            <IconButton onClick={openDeleteModal}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
        <MuiThemeProvider theme={dialogStyles}>
            <Dialog closeAfterTransition={true} onClose={() => handleClose(value)} open={open} aria-labelledby="confirmation-dialog"
                aria-describedby="confirmation-dialog" BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 800 }}>
                <Fade in={open}>
                    <div>
                        {/* <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                            <FontAwesomeIcon className='icon-button' icon={faTimes}/>
                        </IconButton> */}
                        
                        <DialogTitle id="confirmation-dialog-title">Delete channel?</DialogTitle>
                        { response ? 
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {response}
                        </Alert> : null}
                        <DialogContent dividers>
                            Are you sure you want to delete this channel?
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleCancel} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleOk} color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </div>
                </Fade>
            </Dialog>
        </MuiThemeProvider>
    </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<TChannelsTableActions>) => {
    return {
        deleteChannelAction: (data: any) => {dispatch<IDeleteChannel>({type: ChannelsTableActionTypes.DeleteChannel, data: data})}
    }
}

export default connect(null, mapDispatchToProps)(DeleteChannelModal);