import React from 'react';
import {ContentSet, Article, TabPanelProps} from './ContentUpload.types';
import { AdminStoreState } from '../../redux/root-reducer';
import {selectArticles, selectContentSets} from '../../redux/content-upload/content-upload.selectors';
import { Dispatch } from 'redux';
import { TContentUploadActions, IUploadArticle, IUpdateArticle } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './AddArticle.styles.scss';
import AddArticleComponent from './AddArticleComponent';

const TabPanel: React.FC<TabPanelProps> = ({...props}) => {
    const { contentSetsState, children, value, index, articles, updateArticleAction, uploadArticleAction, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <AddArticleComponent contentSetId={contentSetsState[index].id}/>
                </Box>
            )}
        </div>
    );
}

const mapStateToProps = (state: AdminStoreState) : { articles: Article[], contentSetsState: ContentSet[] } => {
    return {
        articles: selectArticles(state),
        contentSetsState: selectContentSets(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        updateArticleAction: (data: Article) => dispatch<IUpdateArticle>({type: ContentUploadActionTypes.UPDATE_ARTICLE, data: data}),
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>({type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabPanel);