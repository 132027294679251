import {  TEditContentReducerActions } from './editContent.actions';
import {  EditContentActionTypes, EditContentState, ImageAngle} from './editContent.types';

const InitialState: EditContentState = {
    selectedImage: undefined,
    selectedImages: [],
    multiselectImages: false,
    downloadingContentIds: [],
    downloadedContentIds: [],
    imageAngles: [],
}

export const editContentReducer = (state = InitialState, action: TEditContentReducerActions): EditContentState => {
    switch(action.type) {
        case EditContentActionTypes.SET_SELECTED_IMAGE:
            return {
                ...state,
                selectedImage: action.data
            };
        case EditContentActionTypes.SET_SELECTED_IMAGES:
            return {
                ...state,
                selectedImages: action.data
            };
        case EditContentActionTypes.SET_MULTISELECT_IMAGES:
            return {
                ...state,
                multiselectImages: action.data
            };
        case EditContentActionTypes.SET_DOWNLOADING_CONTENT_IDS:
            return {
                ...state,
                downloadingContentIds: action.data
            };
        case EditContentActionTypes.TOGGLE_ANGLE_CHANGE:
            {
                let updated = false;
                const updatedImageAngles = state.imageAngles.map((angle: ImageAngle)=>{
                    if(angle.contentId === action.data.contentId){
                        updated = true;
                        return action.data;
                    }
                    return angle;
                })
                if(!updated)updatedImageAngles.push(action.data)
                return {
                    ...state,
                    imageAngles: updatedImageAngles
                };
            }
        case EditContentActionTypes.SET_DOWNLOADED_CONTENT_IDS:
           { 
                if(!action.data.clean){
                    const newDownloadedContentIds = [...state.downloadedContentIds];
                    newDownloadedContentIds.push(action.data.id);
                    return {
                        ...state,
                        downloadedContentIds: [...newDownloadedContentIds]
                    };
                }
                else{
                    return {
                        ...state,
                        downloadedContentIds: []
                    };
                }
            }
        case EditContentActionTypes.RESET_IMAGE_ANGLES:
            return {
                ...state,
                imageAngles: []
            }
        default:
            return state;
    }
}

export default editContentReducer;