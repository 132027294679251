import React, { useEffect, useState } from "react";
import { FormGroup, FormLabel, TextField } from "@material-ui/core";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead
} from "@material-ui/core";
import { useQuery } from "react-apollo-hooks";
import Moment from "react-moment";
import {
  OrderHistoryTableProps} from "./orderHistory.types";
import SyncLoader from "react-spinners/SyncLoader";
import { AdminStoreState } from "../../redux/root-reducer";
import { RefreshDateTime } from 'shared';
import { Dispatch } from "redux";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { connect } from "react-redux";
import { GET_FULL_ORDER_HISTORY } from "./queries";
import {
  ContentOrder,
  FullContentOrder,
  OrderHistoryActionTypes
} from "../../redux/order-history/orderHistory.types";
import { selectOrderHistoryData } from "../../redux/order-history/orderHistory.selectors";
import {
  ILoadOrderHistoryData,
  TOrderHistoryReducerActions
} from "../../redux/order-history/orderHistory.actions";
import { Content } from "../../redux/waiting-list/waitingList.types";
import { User } from "../../redux/admin/admin.types";
import { selectOrderHistoryRefresh } from "../../redux/tables-refresh/tables-refresh.selectors";
import { IToggleOrderHistoryTableRefresh, TRefreshTablesActions } from "../../redux/tables-refresh/tables-refresh.actions";
import { TablesRefreshActionTypes } from "../../redux/tables-refresh/tables-refresh.types";
import "../waiting-list/editWaitingList.styles.scss";

const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({ ...props }) => {
  const { orderHistoryData, orderHistoryTableRefreshState, loadOrderHistoryData, toggleOrderHistoryTableRefresh } = props;
  const { data, loading, error, refetch } = useQuery(GET_FULL_ORDER_HISTORY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [snackbarIndicator, setSnackbarIndicator] = useState(false);
  const [filter, setFilter] = useState<any[]>([]);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);

  useEffect(() => {
    if (data && data.getFullOrderHistory) {
      loadOrderHistoryData(data.getFullOrderHistory);
    }
  }, [data, loadOrderHistoryData]);

  useEffect(() => {
    const incomes = orderHistoryData.map(order =>
      order.contentOrders.reduce(function(total, next) {
        return total + next.adminCommission;
      }, 0)
    );
    const totalOrdersIncome = incomes.reduce(
      (sum, current) => sum + current,
      0
    );
    setTotalIncome(totalOrdersIncome);

    const sales = orderHistoryData.map(order =>
      order.contentOrders.reduce(function(total, next) {
        return total + next.contentPrice;
      }, 0)
    );
    const totalOrdersSales = sales.reduce(
      (sum, current) => sum + current,
      0
    );
    setTotalSales(totalOrdersSales);
  }, [orderHistoryData]);

  const getToTwoDecimals = (price: number) => {
    return parseFloat(price.toString()).toFixed(2);
  };

  const getContentPrice = (fullOrder: ContentOrder[], id: number) => {
    const price = fullOrder.find((cont: ContentOrder) => cont.contentId === id)
      ?.contentPrice;
    if (price) {
      return getToTwoDecimals(price);
    } else {
      return 0;
    }
  };  
  
  const getContributorIncome = (fullOrder: ContentOrder[], id: number) => {
    const income = fullOrder.find((cont: ContentOrder) => cont.contentId === id)
      ?.sellerCommission;
    if (income) {
      return getToTwoDecimals(income);
    } else {
      return 0;
    }
  }; 
  
  const getContributorIncomePercentage = (fullOrder: ContentOrder[], id: number) => {
    const incomePercentage = fullOrder.find((cont: ContentOrder) => cont.contentId === id)
      ?.sellerIncomePercentage;
    if (incomePercentage) {
      return getToTwoDecimals((incomePercentage*100));
    } else {
      return 0;
    }
  };

  const columns = [
    "Order ID",
    {
      name: "Order date",
      options: {
        filterType: "custom" as "custom",
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1]) {
              return `Start date: ${v[0]}, End date: ${v[1]}`;
            } else if (v[0]) {
              return `Start date: ${v[0]}`;
            } else if (v[1]) {
              return `End date: ${v[1]}`;
            }
            return " ";
          }
        },
        filterOptions: {
          names: [],
          logic: (date: any, filters: any) => {
            let check = new Date(date.props.children);
            let from: any = new Date(filters[0]);
            let to: any = new Date(filters[1]);
            from.setDate(from.getDate());
            to.setDate(to.getDate());
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (
            filterList: any,
            onChange: any,
            index: any,
            column: any
          ) => (
            <div>
              <FormLabel>Order date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="From"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ""}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                    setFilter(filterList[1]);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  id="endDate"
                  label="To"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ""}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    "Buyer",
    "Buyer type",
    "Finished",
    "Income"
  ];

  const options: MUIDataTableOptions = {
    selectableRows: "none",
    filter: true,
    elevation: 3,
    expandableRows: true,
    expandableRowsHeader: true,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows &&
        expandedRows.data.length > 4 &&
        expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const orderId = parseInt(rowData[0]);
      const orderObject = orderHistoryData.find(
        order => order.order.id === orderId
      );
      return (
        <TableRow>
          <TableCell colSpan={rowData.length + 1}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{backgroundColor: '#bbbbbb'}}>
                  <TableCell colSpan={colSpan}>Thumbnail</TableCell>
                  <TableCell colSpan={colSpan}>Code</TableCell>
                  <TableCell colSpan={colSpan}>Contributor</TableCell>
                  <TableCell colSpan={colSpan}>Contributor type</TableCell>
                  <TableCell colSpan={colSpan * 2}>Headline</TableCell>
                  <TableCell colSpan={colSpan}>Contributor Income Percentage</TableCell>
                  <TableCell colSpan={colSpan}>Contributor income</TableCell>
                  <TableCell colSpan={colSpan}>Content price</TableCell>
                  <TableCell colSpan={colSpan}>License type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderObject
                  ? orderObject.contents.map((content: Content) => (
                      <TableRow key={content.id}>
                        <TableCell colSpan={colSpan} component="th" scope="row">
                          <div>
                            <img
                              src={content.pathToFileThumbnail}
                              alt={content.headline}
                              style={{
                                display: "block",
                                width: "90px",
                                height: "90px"
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell colSpan={colSpan}>{content.code}</TableCell>
                        <TableCell colSpan={colSpan}>
                          {getContributor(content.user)}
                        </TableCell>
                        <TableCell colSpan={colSpan}>
                          {content.user
                            ? content.user.role
                            : "No role available"}
                        </TableCell>
                        <TableCell colSpan={colSpan * 2}>
                          {content.headline}
                        </TableCell>
                        <TableCell colSpan={colSpan}>
                          {getContributorIncomePercentage(
                              orderObject.contentOrders,
                              content.id
                            )+"%"}
                        </TableCell>
                        <TableCell colSpan={colSpan}>
                          {"$"+getContributorIncome(
                              orderObject.contentOrders,
                              content.id
                            )}
                        </TableCell>
                        <TableCell colSpan={colSpan}>
                          {"$" +
                            getContentPrice(
                              orderObject.contentOrders,
                              content.id
                            )}
                        </TableCell>
                        <TableCell colSpan={colSpan}>
                          {content.licensingType}
                        </TableCell>
                      </TableRow>
                    ))
                  : "No content to show for this order..."}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    },
    jumpToPage: true,
    fixedHeader: false,
    responsive: "vertical",
    filterType: "dropdown",
    tableBodyMaxHeight: "calc(100%)",
    tableBodyHeight: "calc(100%-50px)",
    download: true,
    print: false,
    searchPlaceholder: "Search ",
    sortOrder: {
      name: "Order date",
      direction: "asc"
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <SyncLoader
            css={`
              display: block;
              margin: auto 0;
              width: 100%;
              height: 100%;
              z-index: 100;
            `}
            size={20}
            color={"#36D2B3"}
            loading={loading}
          />
        ) : (
          "No orders executed"
        )
      }
    },
    customSort: (data, dataIndex, rowIndex) => {
      if (dataIndex === 1) {
        return data.sort((a, b) => {
          const dateA = new Date(a.data[dataIndex].props.children).getTime();
          const dateB = new Date(b.data[dataIndex].props.children).getTime();
          return (dateA < dateB ? -1 : 1) * (rowIndex === "desc" ? 1 : -1);
        });
      } else {
        return data.sort((a, b) => {
          if (a.data[dataIndex] && b.data[dataIndex]) {
            return (
              a.data[dataIndex].localeCompare(b.data[dataIndex]) *
              (rowIndex === "desc" ? 1 : -1)
            );
          } else {
            return 0;
          }
        });
      }
    },
    customToolbar: () => {
      return (
        <span>
          {totalIncome ? (
            <div>
              <span style={{marginRight: '1rem'}}><b >Total Sales:</b> ${getToTwoDecimals(totalSales)}</span>
              <b>Total Income:</b> ${getToTwoDecimals(totalIncome)}
            </div>
          ) : null}
        </span>
      );
    },
    onFilterChange: (
      changedColumn,
      filterList,
      type,
      changedColumnIndex,
      displayData
    ) => {
      let newTotal = 0;
      displayData.map(displayObj => {
        newTotal += parseFloat(
          displayObj.data[displayObj.data.length - 1].split("$")[1]
        );
      });
      setTotalIncome(newTotal);
    }
  };

  //TODO - extract this for all tables in generic function
  const getContributor = (user: User | undefined) => {
    if (user && user.company) {
      return user.company.name;
    } else if (user && user.person) {
      return `${user.person.firstName} ${user.person.lastName}`;
    } else {
      return "User unavailable";
    }
  };

  const createTableRow = (item: FullContentOrder) => {
    return [
      item.order.id,
      <Moment format="DD/MM/YYYY">{item.order.orderDate}</Moment>,
      item.buyer.company
        ? item.buyer.company.name
        : item.buyer.person
        ? `${item.buyer.person.firstName} ${item.buyer.person.lastName}`
        : "N/A",
      item.buyer.role,
      item.order.isFinished ? "True" : "False",
      "$" +
        getToTwoDecimals(
          item.contentOrders.reduce(function(total, next) {
            return total + next.adminCommission;
          }, 0)
        )
    ];
  };

  return (
    <React.Fragment>
      <div className="refetch-container">
        <RefreshDateTime loading={loading} autoRefreshOn={orderHistoryTableRefreshState} 
          refreshTime={30000} refreshFunc={refetch} toggleReduxFunction={toggleOrderHistoryTableRefresh}/>
      </div>
      <MUIDataTable
        title={""}
        columns={columns}
        options={options}
        data={orderHistoryData.map((item: FullContentOrder) =>
          createTableRow(item)
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (
  state: AdminStoreState
): { orderHistoryData: FullContentOrder[], orderHistoryTableRefreshState: boolean } => {
  return {
    orderHistoryData: selectOrderHistoryData(state),
    orderHistoryTableRefreshState: selectOrderHistoryRefresh(state)
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<TOrderHistoryReducerActions | TRefreshTablesActions | CallHistoryMethodAction>
) => {
  return {
    loadOrderHistoryData: (data: FullContentOrder[]) =>
      dispatch<ILoadOrderHistoryData>({
        type: OrderHistoryActionTypes.LOAD_ORDER_HISTORY_DATA,
        data: data
      }),
    redirectToContentUploadPage: (data: string) =>
      dispatch(push(`/incomplete-upload-flow/${data}`)),
    toggleOrderHistoryTableRefresh: (data: boolean) => dispatch<IToggleOrderHistoryTableRefresh>({
      type: TablesRefreshActionTypes.ORDER_HISTORY_TABLE_REFRESH,
      data: data
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryTable);
