import { createStore, applyMiddleware } from 'redux';
import { rootAdminReducer } from './root-reducer';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import {createTransform} from "redux-persist";
import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';

export const history = createBrowserHistory();

export const rootConfig = {
        key: 'root',
        storage: storage,
        blacklist: ['modal, contentUpload']
};

export const adminStore = createStore(rootAdminReducer(history),
        applyMiddleware(thunk, routerMiddleware(history)));

export const persistedAdminStore = persistStore(adminStore);