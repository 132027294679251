import { WithStyles } from "@material-ui/core";
import { IBroadcastMessage } from "redux/generic/snackbar/snackbar.types";
import { User } from "../../redux/admin/admin.types";

export interface ReportTableProps {
    reportsTableRefreshState: boolean;
    toggleReportsTableRefresh: (data: boolean) => void;
}

export interface ReportsData {
    user: User;
    report: Report;
}

export interface Report {
    id: number;
    bucketLink: string;
    contentCount: number;
    totalPrice: number;
    date: Date;
}


export const styles = {
    error: {
      color: 'red',
      fontSize: '10px',
      width: '240px'
    }
  }
  
export interface ExportReportModalProps extends WithStyles<typeof styles>{
    reportError: IBroadcastMessage;
    broadcastReportsErrorAction: (data: IBroadcastMessage) => void;
    refetch: () => void;
}

export interface ReportData {
    bucketLink: string;
    date: Date;
    contentCount: number;
    totalPrice: number;
}
