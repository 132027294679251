import React, {useEffect, useState, useRef} from "react";
import EditIcon from "@material-ui/icons/Edit";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  InputAdornment,
  Link,
  ListItem,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Autocomplete, InputLabel } from "@mui/material";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import {
  ContentMetadataProps,
  ContentSetWithThreadData,
  ContentSource, CopiedContentToSets, CopiedMultipleContents,
  Copyright,
  FileMeta,
  GroupsNames,
  ImageMetadata,
  inputFormTheme,
  License,
  LicensingTypes,
  LocationMeta,
  MainInformation,
  MovedContentToSets,
  MovedMultipleContents,
  SelectedChannelCategory,
  Source,
} from "./EditContentMetadata.types";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {AdminStoreState} from "../../redux/root-reducer";
import {Dispatch} from "redux";
import {
  IAddChannelToContentSet,
  IAddChannelToIndividualImage,
  IMoveContentToNewSet,
  ICopyContentToNewSet,
  IRemoveContentToNewSet,
  IResetMultiselection,
  IUpdateSet,
  TContentUploadActions, ICopyMultipleContentToNewSet,
} from "../../redux/content-upload/content-upload.actions";
import {connect} from "react-redux";
import {ContentSet, IndividualImageCategory, RadioButtonGroup, UploadedFileResponse,} from "./ContentUpload.types";
import {
  selectContentSets,
  selectCopiedContents,
  selectIndividualImagesCategory,
  selectMovedContents,
  selectMultipleMovedContents,
  selectMultipleCopiedContents,
  selectRadioButtonValue,
  selectUploadedFiles,
} from "../../redux/content-upload/content-upload.selectors";
import {FETCH_METADATA} from "./metadataQueries";
import {
  CONTRIBUTOR_AGENCY,
  DELETE_KEYWORDS,
  UPDATE_CONTENT_SOURCES,
  UPDATE_COPYRIGHT,
  UPDATE_COPYRIGHTS,
  UPDATE_FILE_DETAILS,
  UPDATE_FILE_SOURCE,
  UPDATE_KEYWORDS,
  UPDATE_KEYWORDSTOSET,
  UPDATE_LICENSE_TYPE,
  UPDATE_LICENSE_TYPES,
  UPDATE_LOCATION,
  UPDATE_LOCATIONS,
  UPDATE_MAIN_INFORMATION,
  UPDATE_MAIN_INFORMATION_SET,
} from "./queries";
import {config as conf} from "../../config";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import SelectChannelsComponent from "./selectChannelsComponent";
import Moment from "react-moment";
import {ContentUploadActionTypes} from "../../redux/content-upload/content-upload.types";
import "./EditContentMetadata.styles.scss";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SyncLoader from "react-spinners/SyncLoader";
import Image from "react-async-image";
import {SEARCH_CONTENT_SET} from "../content-list/queries";
import {User} from "../../redux/admin/admin.types";
import {ConfirmationDialog, EditableTextField, SnackbarComponent} from "shared";
import {selectSnackbarState} from "../../redux/generic/snackbar/snackbar.selectors";
import {ISnackbarMessage, TSnackbarActions,} from "../../redux/generic/snackbar/snackbar.actions";
import {
  IBroadcastMessage,
  SeveritySnackbarEnum,
  SnackbarActionTypes,
} from "../../redux/generic/snackbar/snackbar.types";
import {RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto,} from "../../globals/locations/location.types";
import axios from "axios";
import {doesCityExistInRapidApi, doesCountryExistInRapidApi, doesProvinceExistInRapidApi,} from "./helperFunctions";
import {config} from "../../config";
import {useTheme} from '@mui/material/styles';
import { FilterOptionsState ,createFilterOptions} from "@material-ui/lab";
import { ContentNavigation, UserUploadedContent } from "components/content-list/contentList.types";
import { DownloadedContent, EditContentActionTypes, ImageAngle } from "../../redux/EditContent/editContent.types";
import { IDownloadedContentIds, IDownloadingContentIds, ISelectedImage, ISelectedImages, IToggleAngleChange, TEditContentReducerActions } from "../../redux/EditContent/editContent.actions";
import { selectSelectedSingleItem } from "../../redux/SingleItem/singleItem.selectors";
import { getDownloadedContentIds, getDownloadingContentIds, getImageAngles, getMultiselectImages } from '../../redux/EditContent/editContent.selectors';
import fileDownload from "js-file-download";
import { selectCurrentUser, selectCurrentUserId } from "../../redux/admin/admin.selectors";
import { RotateRight } from "@material-ui/icons";

export const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  coverPhoto: {
    backgroundColor: "rgb(12, 175, 149) !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  selectedPhoto: {
    backgroundColor: "rgb(245, 0, 87) !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
  gridListRoot: {
    maxHeight: "60vh",
  },
  gridListTileRoot: {
    height: "100% !important",
  },
}));

const EditContentMetadata: React.FC<ContentMetadataProps> = ({ ...props }) => {
  const classes = useStyles();
  const {
    duplicateFileIds,
    contentSetsState,
    editSetFromAdmin,
    selectedRadioButton,
    uploadedFiles,
    channels,
    individualImageCategories,
    isInIncomplete,
    movedContents,
    copiedContents,
    snackbar,
    selectedSingleItem,
    multiselectImages,
    downloadingContentIds,
    downloadedContentIds,
    userId,
    imageAngles,
    currentUser,
    addIndividualImageNavigation,
    updateSetAction,
    addChannelToContent,
    handleRejectImage,
    handleApproveImage,
    handleDeleteImage,
    broadcastSnackbarAction,
    moveContentToNewSetAction,
    copyContentToNewSetAction,
    copyMultipleToSet,
    removeContentToNewSetAction,
    isMultiselectEnabled,
    movedMultipleContents,
    resetMultiselectionAction,
    editContentTable,
    setUserSelectedImage,
    setUserSelectedImages,
    setDownloadingContentIds,
    setDownloadedContentIds,
    toggleAngelChangeOfSelectedImage
  } = props;
  const keywordInputRef = useRef<any>();
  const theme = useTheme();
  const [allUploadedContent, setAllUploadedContent] = useState<UserUploadedContent[]>([]);
  const [key, setKey] = useState<number>(0);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedImage, setSelectedImage] = useState<
    Partial<UploadedFileResponse>
  >({});
  const [contentSetTitle, setContentSetTitle] = useState("");
  const [angle, setAngle] = useState(0);
  const [widthOfSelectedImage, setWidthOfSelectedImage] = useState(0);
  const [selectedContentSetImages, setSelectedContentSetImages] = useState<
    number[]
  >([]);
  const [contentSetIndex, setContentSetIndex] = useState(0);
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [editMode, setEditMode] = useState({
    category: true,
    mainInfo: true,
    location: true,
    keywords: true,
    copyright: true,
    licensing: true,
  });
  const [addCategoryIndex, setAddCategoryIndex] = useState(1);
  const [imageMetadata, setImageMetadata] = useState<Partial<ImageMetadata>>(
    {}
  );
  const [keywordState, setKeywordState] = useState("");

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [downloadingSelectedImage, setDownloadingSelectedImage] = useState<boolean>(false);
  const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);
  const [deleteImageConfirmationDialog, setDeleteImageConfirmationDialog] =
    useState(false);

  //State properties for locations
  const [countries, setCountries] = useState<RapidApiCountryDto[]>([]);
  const [countryCode, setCountryCode] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<RapidApiCountryDto>({
    country: "",
    countryCode: "",
  });

  const [provinceIsoCode, setProvinceIsoCode] = useState<string>("");
  const [provinces, setProvinces] = useState<RapidApiProvinceDto[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<RapidApiProvinceDto>(
    {
      province: "",
      isoCode: "",
      countryId: "",
    }
  );

  const [cities, setCities] = useState<RapidApiCityDto[]>([]);
  const [selectedCity, setSelectedCity] = useState<RapidApiCityDto>({
    city: "",
    longitude: 0,
    latitude: 0,
  });

  // State properties for Image Category dropdown
  const [selectedChannels, setSelectedChannels] = useState<
    SelectedChannelCategory[]
  >([]);
  const [selectedSubchannels, setSelectedSubchannels] = useState<
    SelectedChannelCategory[]
  >([]);
  const [selectedThreads, setSelectedThreads] = useState<
    SelectedChannelCategory[]
  >([]);
  const [selectedDropdownData, setSelectedDropdownData] = useState({
    channel: { selectedId: 0, selectedChannelTitle: "" },
    subchannel: { selectedId: 0, selectedChannelTitle: "" },
    thread: { selectedId: 0, selectedChannelTitle: "" },
  });

  //autocomplete state hooks for options
  const [contributorOptions, setContributorOptions] = useState<User[]>([]);
  const [contentSetOptions, setContentSetOptions] = useState<
    ContentSetWithThreadData[]
  >([]);

  //autocomplete state hooks for values
  const [contentSetSearchString, setContentSetSearchString] = useState("");
  const [contentSetSearchObject, setContentSetSearchObject] =
    useState<Partial<ContentSetWithThreadData>>();
  const [newSetTitle, setNewSetTitle] = useState("");
  const [selectedMoveToSetButton, setSelectedMoveToSetButton] = useState(
    RadioButtonGroup.MOVE_TO_EXISTING_SET
  );
  //lazy queries

  const [fetchMetadata, { data }] = useLazyQuery(FETCH_METADATA, {
    fetchPolicy: "no-cache",
  });
  const [fetchAgencies, { data: agencyData }] = useLazyQuery(
    CONTRIBUTOR_AGENCY,
    { fetchPolicy: "cache-and-network" }
  );
  const [searchContentSetByTitle, { data: contentSetDatа }] =
    useLazyQuery(SEARCH_CONTENT_SET);

  const [
    takeConfirmationBeforeApplyToSet,
    setTakeConfirmationBeforeApplyToSet,
  ] = useState(false);
  const [groupName, setGroupName] = useState("");

  const handleConfirmationDialogClose = () => {
    setTakeConfirmationBeforeApplyToSet(false);
  };

  const handleConfirmationDialogAgree = () => {
    setTakeConfirmationBeforeApplyToSet(false);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    applyToSet(groupName);
  };

  const handleApplyToSet = (value: string) => {
    setTakeConfirmationBeforeApplyToSet(true);
    setGroupName(value);
  };

  const handleImageLoad = (event: any) => {
    const { naturalWidth, naturalHeight } = event.target;
    setWidthOfSelectedImage(naturalWidth);
  };

  useEffect(()=>{
    if(imageAngles.length > 0){
      const imageAngle = imageAngles.filter((angle: ImageAngle) => angle.contentId === selectedImage.id);
      if(imageAngle.length > 0){
        setAngle(imageAngle[0].rotateDegree)
      }
      else{
        setAngle(360)
      }
    }
  },[selectedImage]);

  useEffect(()=>{
    if(!multiselectImages){
      setSelectedImages([]);
      setUserSelectedImages([])
    }
  },[multiselectImages])
  
  useEffect(()=>{
    if(isMultiselectEnabled && selectedImages.length > 0){
      setUserSelectedImages(selectedImages)
    }
    else if(selectedImage?.id){
      setUserSelectedImage(selectedImage.id)
    }
  },[selectedImage,selectedImages,isMultiselectEnabled])
  useEffect(()=>{
    if (selectedSingleItem.selectedThreads.length > 0) {
        addIndividualImageNavigation(selectedSingleItem);
    }
  },[selectedSingleItem]);
  
  useEffect(() => {
    if (
      selectedRadioButton === RadioButtonGroup.SET &&
      contentSetsState &&
      contentSetsState[contentSetIndex]
    ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const setFirstItem = getContentSetFirstItem();
      if (setFirstItem) {
        setSelectedImage(setFirstItem!);
        fetchMetadata({
          variables: {
            contentId: setFirstItem!.id,
            threadIds: contentSetsState![contentSetIndex].selectedThreadIds,
          },
        });
      } else {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Failed to fetch set contents.",
        });
      }
    }
    if (
      selectedRadioButton === RadioButtonGroup.INDIVIDUAL &&
      uploadedFiles &&
      uploadedFiles.length > 0 
    ) {
      setSelectedImage(uploadedFiles[0]);
      const hasCategories = individualImageCategories.find(
        (category) => category.contentId === uploadedFiles[0].id
      );
      if(hasCategories){
        fetchMetadata({
          variables: {
            contentId: uploadedFiles[0].id,
            threadIds: hasCategories?.selectedThreads,
          },
        });
      }
    }
  }, [contentSetIndex, uploadedFiles, duplicateFileIds, individualImageCategories]);

  const [updateKeywordsMutation] = useMutation(UPDATE_KEYWORDS);
  const updateKeywords = (contentId: number, keywords: string[]): void => {
    updateKeywordsMutation({
      variables: { contentId: contentId, keywordsToBeAdded: keywords },
    })
      .then((result: any) => {
        setImageMetadata({
          ...imageMetadata,
          keywords: {
            keywordsToShow: imageMetadata.keywords?.keywordsToShow!,
            keywordsToAdd: [],
            keywordsToDelete: [],
          },
        });
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Keywords updated successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Something went wrong while updating Keywords. Please try again",
        });
      });
  };

  const [deleteKeywordsMutation] = useMutation(DELETE_KEYWORDS);
  const deleteKeywords = (contentId: number, keywordsToDelete: string[]) => {
    deleteKeywordsMutation({
      variables: {
        contentId: contentId,
        keywordsToBeDeleted: keywordsToDelete,
      },
    })
      .then((result: any) => {
        setImageMetadata({
          ...imageMetadata,
          keywords: {
            keywordsToShow: imageMetadata.keywords?.keywordsToShow!,
            keywordsToAdd: [],
            keywordsToDelete: [],
          },
        });
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Successfully deleted keywords",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while deleting keywords",
        });
      });
  };

  const [updateCopyrightMutation] = useMutation(UPDATE_COPYRIGHT);
  const updateCopyright = (contentId: number, input: Copyright): void => {
    updateCopyrightMutation({
      variables: {
        contentId: contentId,
        byLine: input.byLine,
        credit: input.credit,
        copyrightNotice: input.copyrightNotice,
        specialInstructions: input.specialInstructions,
        sourceUserId: input.contentSource?.userId,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Copyright updated successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Something went wrong while updating Copyright",
        });
      });
  };

  const [updateCopyrightsMutation] = useMutation(UPDATE_COPYRIGHTS);
  const updateCopyrights = (contentIds: number[], input: Copyright): void => {
    updateCopyrightsMutation({
      variables: {
        contentIds: contentIds,
        byLine: input.byLine,
        credit: input.credit,
        copyrightNotice: input.copyrightNotice,
        specialInstructions: input.specialInstructions,
        sourceUserId: input.contentSource?.userId,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Changes applied to set successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while applying changes to set",
        });
      });
  };

  const [updateFileDetailsMutation] = useMutation(UPDATE_FILE_DETAILS);
  const updateFileDetails = (contentId: number, input: FileMeta): void => {
    updateFileDetailsMutation({
      variables: {
        contentId: contentId,
        fileName: input.fileName,
        fileSize: input.fileSize,
        fileType: input.fileType,
        imageWidth: input.imageWidth,
        imageHeight: input.imageHeight,
        cameraModel: input.cameraModel,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "File details updated successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Something went wrong while updating File Details. Please try again",
        });
      });
  };

  const [updateFileSourceMutation] = useMutation(UPDATE_FILE_SOURCE);
  const updateFileSource = (contentId: number, input: Source): void => {
    updateFileSourceMutation({
      variables: {
        contentId: contentId,
        source: input.source,
        createdDate: input.createdDate,
        createdTime: input.createdTime,
        uploadedDate: input.uploadedDate,
        originalFileName: input.originalFileName,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "File sources updated successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Something went wrong while updating File Sources. Please try again",
        });
      });
  };

  const [updateLocationMutation] = useMutation(UPDATE_LOCATION);
  const updateLocation = (contentId: number, input: LocationMeta): void => {
    updateLocationMutation({
      variables: {
        contentId: contentId,
        city: input.city,
        stateProvince: input.stateProvince,
        country: input.country,
        countryCode: input.countryCode,
        stateCode: input.stateCode,
        latitude: input.latitude,
        longitude: input.longitude,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Locations updated successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Something went wrong while updating Locations. Please try again",
        });
      });
  };

  const [updateLicensingTypeMutation] = useMutation(UPDATE_LICENSE_TYPE);
  const updateLicensingType = (contentId: number, input: License): void => {
    updateLicensingTypeMutation({
      variables: {
        id: contentId,
        licensingType: input.type,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Changes saved successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Something went wrong while updating license. Please try again",
        });
      });
  };

  const [updateLicensingTypesMutation] = useMutation(UPDATE_LICENSE_TYPES);
  const updateLicensingTypes = (contentIds: number[], input: License): void => {
    updateLicensingTypesMutation({
      variables: {
        contentIdArray: contentIds,
        licensingType: input.type,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "License type updated successfully.",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Something went wrong while updating license type. Please try again",
        });
      });
  };

  const [updateKeywordsToSetMutation] = useMutation(UPDATE_KEYWORDSTOSET);
  const updateKeywordsToSet = (
    contentIds: number[],
    keywordsToAdd: string[],
    keywordsToDelete: string[]
  ): void => {
    updateKeywordsToSetMutation({
      variables: {
        contentIds: contentIds,
        keywordsToBeAdded: keywordsToAdd,
        keywordsToBeDeleted: keywordsToDelete,
      },
    })
      .then((result: any) => {
        setImageMetadata({
          ...imageMetadata,
          keywords: {
            keywordsToShow: imageMetadata.keywords?.keywordsToShow!,
            keywordsToAdd: [],
            keywordsToDelete: [],
          },
        });

        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Successfully applied keywords to set",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while applying keywords to set",
        });
      });
  };

  const [updateContentSourceToSetMutation] = useMutation(
    UPDATE_CONTENT_SOURCES
  );
  const updateContentSourceToSet = (
    contentIds: number[],
    contentSourceId: number
  ): void => {
    updateContentSourceToSetMutation({
      variables: {
        contentIds: contentIds,
        sourceUserId: contentSourceId,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Successfully applied content source to set",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while applying content source to set",
        });
      });
  };

  const changeCoverPhoto = (contentId: number, set: ContentSet): void => {
    const newCoverPhoto = set.files.find((file) => {
      if (file.id === contentId) {
        return file;
      }
    });

    if (newCoverPhoto) {
      const updatedSet: ContentSet = {
        id: set.id,
        title: set.title,
        files: set.files,
        coverPhoto: newCoverPhoto,
        selectedThreadIds: set.selectedThreadIds
      };

      updateSetAction(updatedSet);
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Cover photo changed successfully",
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "Something went wrong when changing cover photo",
      });
    }
  };

  const [updateMainInfromationMutation] = useMutation(UPDATE_MAIN_INFORMATION);
  const updateMainInfromation = (
    contentId: number,
    input: MainInformation
  ): void => {
    updateMainInfromationMutation({
      variables: {
        contentId: contentId,
        objectName: input.objectName,
        headline: input.headline,
        caption: input.caption,
        externalLink: input.externalLink,
        externalLinkMessage: input.externalLinkMessage,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Main information updated successfuly",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while updating main information",
        });
      });
  };

  const [updateMainInformationToSetMutation] = useMutation(
    UPDATE_MAIN_INFORMATION_SET
  );
  const updateMainInfromationToSet = (
    contentIds: number[],
    input: MainInformation
  ): void => {
    updateMainInformationToSetMutation({
      variables: {
        contentIds: contentIds,
        objectName: input.objectName,
        headline: input.headline,
        caption: input.caption,
        externalLink: input.externalLink,
        externalLinkMessage: input.externalLinkMessage,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Changes applied to set successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while applying changes to set",
        });
      });
  };
  const [updateLocationsMutation] = useMutation(UPDATE_LOCATIONS);
  const updateLocations = (contentIds: number[], input: LocationMeta): void => {
    updateLocationsMutation({
      variables: {
        contentIds: contentIds,
        city: input.city,
        stateProvince: input.stateProvince,
        country: input.country,
        countryCode: input.countryCode,
        stateCode: input.stateCode,
        latitude: input.latitude,
        longitude: input.longitude,
      },
    })
      .then((result: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Changes applied to set successfully",
        });
      })
      .catch((error: any) => {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Error while applying changes to set",
        });
      });
  };

  useEffect(() => {
    if (snackbar.severity && snackbar.message) {
      setContentUploadSnackbar(true);
    }
  }, [snackbar]);

  useEffect(() => {
    if (data) {
      const location = data.fetchLocation;
      const copyright = data.fetchCopyright;
      setImageMetadata({
        mainInformation: data.getContentById,
        location: {
          city: location && location.city ? location.city.city : "",
          country: location && location.country ? location.country.country : "",
          countryCode:
            location && location.country ? location.country.countryCode : "",
          stateProvince:
            location && location.province
              ? location.province.stateProvince
              : "",
          stateCode:
            location && location.province ? location.province.stateCode : "",
          longitude:
            location && location.locationCoordinate
              ? location.locationCoordinate.longitude
              : 0,
          latitude:
            location && location.locationCoordinate
              ? location.locationCoordinate.latitude
              : 0,
          zipCode:
            location && location.zipCode ? location.zipCode.zipCodeNumber : "",
        },
        keywords: {
          keywordsToShow: data.fetchKeywords,
          keywordsToAdd: [],
          keywordsToDelete: [],
        },
        copyright: {
          byLine: copyright.byLine,
          credit: copyright.credit,
          copyrightNotice: copyright.copyrightNotice,
          specialInstructions: copyright.specialInstructions,
          contentSource: {
            userId: copyright.sourceUser ? copyright.sourceUserId : -1,
            companyName: copyright.sourceUser
              ? copyright.sourceUser.company?.name
              : "",
          },
        },
        source: data.fetchFileSources,
        fileDetails: data.fetchFileDetails,
        license: {
          type: data.fetchLicensingType,
        },
      });

      const categoryResult = data.getContentNavigationByThreadId;
      if (categoryResult.length > 0) {
        const channelObjects: SelectedChannelCategory[] = categoryResult.map(
          (category: any) => ({
            selectedId: category.channel.id,
            selectedChannelTitle: category.channel.title,
          })
        );
        const subchannelObjects: SelectedChannelCategory[] = categoryResult.map(
          (category: any) => ({
            selectedId: category.subChannel.id,
            selectedChannelTitle: category.subChannel.title,
          })
        );
        const threadObjects: SelectedChannelCategory[] = categoryResult.map(
          (category: any) => ({
            selectedId: category.thread.id,
            selectedChannelTitle: category.thread.title,
          })
        );

        setSelectedChannels(channelObjects);
        setSelectedSubchannels(subchannelObjects);
        setSelectedThreads(threadObjects);

        setAddCategoryIndex(categoryResult.length + 1);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        resetThreadCategoryState();
      }
      setLoadingIndicator(false);
    }
  }, [data]);

  useEffect(() => {
    setAddCategoryIndex(selectedThreads.length + 1);
  }, [selectedThreads]);

  useEffect(() => {
    // if (editMode.copyright && contributorOptions.length === 0) {
    fetchAgencies();
    // }
  }, [editMode.copyright]);

  useEffect(() => {
    if (agencyData && agencyData.getContributorAgencies) {
      setContributorOptions(agencyData.getContributorAgencies);
    }
  }, [agencyData]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fetchCountries();
  }, []);
  useEffect(()=>{
    if(selectedImage?.id && downloadingContentIds.includes(selectedImage?.id)){
      setDownloadingSelectedImage(true);
    }
    else{
      setDownloadingSelectedImage(false);
    }
  },[selectedImage?.id, downloadingContentIds])
  useEffect(() => {
    const contentMatch = movedContents.find(
      (set: MovedContentToSets) => set.contentId === selectedImage.id!
    );


    const contentMatch2 = copiedContents.find(
      (set: CopiedContentToSets) => set.contentId === selectedImage.id!
    );

    setNewSetTitle("");
    setContentSetSearchString("");

    if (contentMatch && contentMatch.movedSet) {
      setContentSetSearchString(contentMatch.movedSet.title);
      setContentSetSearchObject(contentMatch.movedSet);
    } else if (contentMatch && contentMatch.newSetTitle) {
      setNewSetTitle(contentMatch.newSetTitle);
      setSelectedMoveToSetButton(RadioButtonGroup.MOVE_TO_NEW_SET);
    } else if (contentMatch2 && contentMatch2.copiedSet) {
      setContentSetSearchString(contentMatch2.copiedSet.title);
      setContentSetSearchObject(contentMatch2.copiedSet);
    }
  }, [selectedImage]);

  // useEffect(() => {
  //   let contentsMatch = movedMultipleContents.find(
  //     (set: MovedMultipleContents) => set.contentIds === selectedImages
  //   );
  //   setNewSetTitle("");
  //   setContentSetSearchString("");
  //
  //   if (contentsMatch && contentsMatch.movedSet) {
  //     setContentSetSearchString(contentsMatch.movedSet.title);
  //     setContentSetSearchObject(contentsMatch.movedSet);
  //   } else if (contentsMatch && contentsMatch.newSetTitle) {
  //     setNewSetTitle(contentsMatch.newSetTitle);
  //   }
  // }, [selectedImages]);

  useEffect(() => {
    if (contentSetDatа && contentSetDatа.searchContentSetsByTitle) {
      setContentSetOptions(contentSetDatа.searchContentSetsByTitle);
    }
  }, [contentSetDatа]);

  const getContentSetFirstItem = () => {
    if (
      contentSetsState &&
      contentSetsState[contentSetIndex].files.length > 0
    ) {
      return contentSetsState[contentSetIndex].files[0];
    } else {
      return null;
    }
  };

  const handleClose = () => {
    setContentUploadSnackbar(false);
  };

  const handleChange = (event: any, valueCorrectType: any, group: string) => {
    event.preventDefault();

    const name = event.target.name;


    if (group === GroupsNames.KEYWORDS) {
      setKeywordState(valueCorrectType);
    } else if (group === GroupsNames.COPYRIGHT) {
      setImageMetadata({
        ...imageMetadata,
        copyright: { ...imageMetadata.copyright!, [name]: valueCorrectType },
      });
    } else if (group === GroupsNames.FILE_DETAILS) {
      setImageMetadata({
        ...imageMetadata,
        fileDetails: {
          ...imageMetadata.fileDetails!,
          [name]: valueCorrectType,
        },
      });
    } else if (group === GroupsNames.SOURCE) {
      setImageMetadata({
        ...imageMetadata,
        source: { ...imageMetadata.source!, [name]: valueCorrectType },
      });
    } else if (group === GroupsNames.MAIN_INFO) {
      setImageMetadata({
        ...imageMetadata,
        mainInformation: {
          ...imageMetadata.mainInformation!,
          [name]: valueCorrectType,
        },
      });
    } else if (group === GroupsNames.LOCATION) {
      setImageMetadata({
        ...imageMetadata,
        location: { ...imageMetadata.location!, [name]: valueCorrectType },
      });
    } else if (group === GroupsNames.LICENSING) {
      setImageMetadata({
        ...imageMetadata,
        license: { ...imageMetadata.license!, [name]: valueCorrectType },
      });
    } else if (group === GroupsNames.SET_TITLE) {
      setContentSetTitle(valueCorrectType);
    } else if (group === GroupsNames.MOVE_TO_SET) {
      setContentSetSearchString(valueCorrectType);
      searchContentSetByTitle({
        variables: { searchString: valueCorrectType },
      });
    } else if (group === GroupsNames.NEW_SET_TITLE) {
      setNewSetTitle(valueCorrectType);
    } else if (group === GroupsNames.COPY_TO_SET) {
      setContentSetSearchString(valueCorrectType);
      searchContentSetByTitle({
        variables: { searchString: valueCorrectType },
      });
    }
  };

  const handleDelete = (chipToDelete: string) => {
    const remainingKeywords = imageMetadata.keywords?.keywordsToShow!.filter(
      (chip: any) => {
        return chip.name !== chipToDelete;
      }
    );

    setImageMetadata({
      ...imageMetadata,
      keywords: {
        keywordsToShow: remainingKeywords!,
        keywordsToAdd: imageMetadata.keywords?.keywordsToAdd,
        keywordsToDelete: [
          ...imageMetadata.keywords?.keywordsToDelete!,
          chipToDelete,
        ],
      },
    });
  };

  const checkIfKeywordIsAlreadyIncluded = (keywordName: string) => {
    if (
      imageMetadata.keywords &&
      imageMetadata.keywords.keywordsToShow.length > 0
    ) {
      const checkNames = imageMetadata.keywords.keywordsToShow.filter(
        (keyword) => {
          return keyword.name === keywordName.trimEnd().trimStart();
        }
      );
      if (checkNames.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const addKeyword = () => {

    let chip = [{ name: "", __typename: "Keyword" }];
    let chipName = [];

    if(keywordState.includes(",") || keywordState.includes(";")){
      chipName = keywordState.split(/,|;/).map(keyword => keyword.trim()).filter(keyword => keyword.trim().length > 0);
      chip = chipName.map(chip => ({ name: chip, __typename: "Keyword" }))
    }
    else{
      chip = [{ name: keywordState, __typename: "Keyword" }];
      chipName = [chip[0].name];
    }

    if (imageMetadata.keywords && imageMetadata.keywords.keywordsToShow) {
      let identicalKeywords = false;
      const existingKeywords = [];
      const newChip = [];
      const newChipName = [];
      for(let i=0;i<chipName.length;++i){
        if(checkIfKeywordIsAlreadyIncluded(chipName[i]) ||
        imageMetadata.keywords.keywordsToAdd?.includes(chipName[i])){
          existingKeywords.push(chipName[i]);
          identicalKeywords = true;
        }
        else{
          newChipName.push(chipName[i]);
          newChip.push({ name: chipName[i], __typename: "Keyword" });
        }
      }

      if (!identicalKeywords) {
        setImageMetadata({
          ...imageMetadata,
          keywords: {
            keywordsToShow: [...imageMetadata.keywords.keywordsToShow!, ...chip],
            keywordsToAdd: [...imageMetadata.keywords.keywordsToAdd!, ...chipName],
            keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
          },
        });
      } 
      else if(chipName.length > 1){
        setImageMetadata({
          ...imageMetadata,
          keywords: {
            keywordsToShow: [...imageMetadata.keywords.keywordsToShow!, ...newChip],
            keywordsToAdd: [...imageMetadata.keywords.keywordsToAdd!, ...newChipName],
            keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
          },
        });
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.warning,
          message: `Keyword ${existingKeywords} already exists`,
        });
      }
      else {
        setImageMetadata({
          ...imageMetadata,
          keywords: {
            keywordsToShow: [...imageMetadata.keywords.keywordsToShow!],
            keywordsToAdd: [...imageMetadata.keywords.keywordsToAdd!],
            keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
          },
        });
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.warning,
          message: `Keyword ${existingKeywords} already exists`,
        });
      }
    } else {
      setImageMetadata({
        ...imageMetadata,
        keywords: {
          keywordsToShow: [...chipName],
          keywordsToAdd: [...chipName],
          keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
        },
      });
    }

    setKeywordState("");
  };

  const handleEditModeChange = (value: string) => {
    setEditMode({ ...editMode, [value]: false });
  };

  const changeSetTitle = (set: ContentSet, value: string) => {
    if (value.length <= 1 || value.length >= 500) {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
          "A content set title must contain at least 1 symbol and not exceed 500 symbols",
      });
      return;
    }

    const updatedSet: ContentSet = {
      id: set.id,
      title: value,
      files: set.files,
      coverPhoto: set.coverPhoto,
      selectedThreadIds: set.selectedThreadIds,
    };

    updateSetAction(updatedSet);
    setContentSetTitle("");
  };

  const assignThreadsToContentInRedux = () => {
    const selectedDropdownThreadId = selectedDropdownData.thread.selectedId;
    if (
      selectedThreads.filter(
        (thread) => thread.selectedId === selectedDropdownThreadId
      ).length > 0
    ) {
      //if thread is already selected
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "Same thread is selected multiple times",
      });
      return;
    } else if (
      addCategoryIndex <= 3 &&
      selectedThreads.length === addCategoryIndex - 1 &&
      selectedDropdownThreadId !== 0
    ) {
      //normal flow when we assign to thread
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      assignToSelectedChannelArray();
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Category updated successfully",
      });
    } else if (
      selectedDropdownData.channel.selectedId !== 0 &&
      (selectedDropdownData.subchannel.selectedId === 0 ||
        selectedDropdownThreadId === 0)
    ) {
      //if dropdown menu is not filled properly
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "Select thread from dropdown menu",
      });
      return;
    }

    const threadIds = selectedThreads.map((thread) => {
      return thread.selectedId;
    });
    if (selectedRadioButton === RadioButtonGroup.SET) {
      const selectedContentSetId = contentSetsState![contentSetIndex].id;
      const selectedThreads =
        selectedDropdownThreadId === 0
          ? threadIds
          : threadIds.concat(selectedDropdownData.thread.selectedId);

      if (!isMultiselectEnabled && newSetTitle) {
        if (selectedThreads.length > 0) {
          moveContentToNewSetAction({
            contentId: selectedImage.id!,
            newSetNavigations: selectedThreads,
          });
          copyContentToNewSetAction({
            contentId: selectedImage.id!,
            newSetNavigations: selectedThreads,
          });
        } else {
          broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.warning,
            message: "You must set navigation",
          });
        }
      } else {
        addChannelToContent({ selectedThreads, selectedContentSetId });
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetDropdownData();
    } else if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
      if (threadIds) {
        const imageCategory: IndividualImageCategory = {
          selectedThreads:
            selectedDropdownThreadId === 0
              ? threadIds
              : threadIds.concat(selectedDropdownData.thread.selectedId),
          contentId: selectedImage.id!,
        };
        addIndividualImageNavigation(imageCategory);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        resetDropdownData();
      }
    }
  };

  const addAnotherCategory = () => {
    if (
      selectedThreads.filter(
        (thread) => thread.selectedId === selectedDropdownData.thread.selectedId
      ).length > 0
    ) {
      //if thread is already selected
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "Same thread is selected multiple times",
      });
      return;
    } else if (
      addCategoryIndex <= 3 &&
      selectedThreads.length === addCategoryIndex - 1 &&
      selectedDropdownData.thread.selectedId !== 0
    ) {
      //normal flow when we assign to thread
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      assignToSelectedChannelArray();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetDropdownData();
    } else if (addCategoryIndex > 3) {
      //when limit of 3 breadcrumbs is reached
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message: "Cannot add more than 3 navigations for each content set",
      });
      return;
    }
  };

  const SaveChanges = (value: string) => {
    setEditMode({ ...editMode, [value]: true });
    if (selectedImage.id && !isMultiselectEnabled) {
      switch (value) {
        case GroupsNames.CATEGORY:
          assignThreadsToContentInRedux();
          break;
        case GroupsNames.MAIN_INFO:
          // eslint-disable-next-line no-case-declarations
          const mainInfoMeta = imageMetadata.mainInformation;
          if (mainInfoMeta) {
            updateMainInfromation(selectedImage.id!, mainInfoMeta);
          }
          break;
        case GroupsNames.LOCATION:
          // eslint-disable-next-line no-case-declarations
          const locationMeta = imageMetadata.location;
          if (locationMeta) {
            updateLocation(selectedImage.id!, locationMeta);
          }
          break;
        case GroupsNames.KEYWORDS:
          // eslint-disable-next-line no-case-declarations
          const keywordMeta = imageMetadata.keywords;
          // eslint-disable-next-line no-case-declarations
          const contentId = selectedImage.id!;
          // eslint-disable-next-line no-case-declarations
          const keywordsToAdd = keywordMeta?.keywordsToAdd!;
          // eslint-disable-next-line no-case-declarations
          const keywordsToDelete = keywordMeta?.keywordsToDelete!;
          if (keywordMeta) {
            if (keywordsToAdd && keywordsToAdd.length > 0) {
              updateKeywords(contentId, keywordsToAdd);
            }
            if (keywordsToDelete && keywordsToDelete.length > 0) {
              deleteKeywords(contentId, keywordsToDelete);
            }
          }

          break;
        case GroupsNames.COPYRIGHT:
          // eslint-disable-next-line no-case-declarations
          const copyrightMeta = imageMetadata.copyright;
          if (copyrightMeta) {
            updateCopyright(selectedImage.id!, copyrightMeta);
          }
          break;
        case GroupsNames.SOURCE:
          // eslint-disable-next-line no-case-declarations
          const fileSourceMeta = imageMetadata.source;
          if (fileSourceMeta) {
            updateFileSource(selectedImage.id!, fileSourceMeta);
          }
          //remove
          break;
        case GroupsNames.FILE_DETAILS:
          // eslint-disable-next-line no-case-declarations
          const fileDetailsMeta = imageMetadata.fileDetails;
          if (fileDetailsMeta) {
            updateFileDetails(selectedImage.id!, fileDetailsMeta);
          }
          //remove
          break;
        case GroupsNames.LICENSING:
          // eslint-disable-next-line no-case-declarations
          const licensingMeta = imageMetadata.license;
          if (licensingMeta) {
            updateLicensingType(selectedImage.id!, licensingMeta);
          }
          break;
        default:
          return null;
      }
    } else if (selectedImages && isMultiselectEnabled) {
      switch (value) {
        case GroupsNames.CATEGORY:
          assignThreadsToContentInRedux();
          break;
        default:
          return null;
      }
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Changes saved successfully",
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message: isMultiselectEnabled
          ? "Select at least one image in order to edit its category. "
          : "Select an image in order to edit its metadata",
      });
    }
  };

  const handleSelectChannel = (
    selectedId: number,
    selectedChannelTitle: string,
    category: any
  ) => {
    setSelectedDropdownData({
      ...selectedDropdownData,
      [category]: { selectedId, selectedChannelTitle },
    });
  };

  const handleAutoCompleteMultipleContentSetChange = (
    event: any,
    newValue: any
  ) => {
    if (selectedImages.length > 0) {
      selectedImages.map((id) => {
        moveContentToNewSetAction({ contentId: id, movedSet: newValue });
      });
      setContentSetSearchString(newValue.title);
      setContentSetSearchObject(newValue);
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Assigned to move selected photo(s) to set " + newValue.title,
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
          "In order to assign a photos to new content set, you must first select at least one photo",
      });
    }
  };

  const handleAutoCompleteSigleContentSetChange = (
    event: any,
    newValue: any
  ) => {
    //newValue is ContentSetWithThreadData
    if (selectedImage.id) {
      if (
        contentSetsState![contentSetIndex].coverPhoto?.id === selectedImage.id
      ) {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Cannot assign the cover photo of current content set to another content set",
        });
        setKey(previous => previous + 1)
        setContentSetSearchString("");
        setContentSetSearchObject({});
      } else {
        moveContentToNewSetAction({
          contentId: selectedImage.id,
          movedSet: newValue,
        });
        setContentSetSearchString(newValue.title);
        setContentSetSearchObject(newValue);
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message: "Assigned to move selected photo to set " + newValue.title,
        });
      }
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
          "In order to assign a photo to new content set, you must first select a photo",
      });
    }
  };

  const handleAutoCompleteContentSetChange = (event: any, newValue: any) => {
    const coverPhotoId: any = contentSetsState ? contentSetsState[contentSetIndex!]?.coverPhoto?.id : -1;
    if (contentSetsState && selectedImages.includes(coverPhotoId)) {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "You cannot move the cover photo of a set",
      });
      setKey(previous => previous + 1);
      setContentSetSearchString("");
      setContentSetSearchObject({});
    }
    else{
      if (isMultiselectEnabled) {
        handleAutoCompleteMultipleContentSetChange(event, newValue);
      } else {
        handleAutoCompleteSigleContentSetChange(event, newValue);
      }
    }
  };

  const handleAutoCompleteMultipleContentSetCopy = (
      event: any,
      newValue: any
  ) => {
    if (selectedImages) {
      selectedImages.map((id) => {
        copyContentToNewSetAction({contentId: id, copiedSet: newValue});
      });
      setContentSetSearchString(newValue.title);
      setContentSetSearchObject(newValue);
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Assigned to copy selected photo(s) to set " + newValue.title,
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
            "In order to assign a photos to new content set, you must first select at least one photo",
      });
    }
  };

  const handleAutoCompleteSigleContentSetCopy = (
      event: any,
      newValue: any
  ) => {
    //newValue is ContentSetWithThreadData
    if (selectedImage.id) {
      copyContentToNewSetAction({
        contentId: selectedImage.id,
        copiedSet: newValue,
      });

      setContentSetSearchString(newValue.title);
      setContentSetSearchObject(newValue);
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Assigned to copy selected photo to set " + newValue.title,
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
            "In order to assign a photo to new content set, you must first select a photo",
      });
    }
  };

  const handleAutoCompleteContentSetCopy = (event: any, newValue: any) => {
    if (isMultiselectEnabled) {
      handleAutoCompleteMultipleContentSetCopy(event, newValue);
    } else {
      handleAutoCompleteSigleContentSetCopy(event, newValue);
    }
  };

  const handleAutoCompleteAgencyChange = (event: any, newValue: any) => {
    if (selectedImage.id) {
      const user = contributorOptions.filter((option) => {
        return option.company?.name === newValue;
      })[0];

      if (user) {
        const contentSource: ContentSource = {
          userId: user ? user.id : 0,
          companyName: newValue,
        };
        setImageMetadata({
          ...imageMetadata,
          copyright: {
            ...imageMetadata.copyright!,
            contentSource: contentSource,
          },
        });
      } else {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message: "Invalid contributor to set for a photo",
        });
      }
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
          "In order to assign a photo to new content set, you must first select a photo",
      });
    }
  };

  const handleAutoCompleteAgencyInputChange = (event: any, value: string) => {
    if (value === "") {
      const user = contributorOptions.filter((option) => {
        return option.company?.name === value;
      })[0];
      const contentSource: ContentSource = {
        userId: user ? user.id : 0,
        companyName: "",
      };
      setImageMetadata({
        ...imageMetadata,
        copyright: {
          ...imageMetadata.copyright!,
          contentSource: contentSource,
        },
      });
    }
  };

  const handleAutoCompleteCountriesChange = (event: any, newValue: any) => {
    if (selectedImage.id && countries) {
      const country = countries.filter((option) => {
        return option.country === newValue;
      })[0];
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOnClickCountry(country);
    }
  };

  const handleAutoCompleteProvinceChange = (event: any, newValue: any) => {
    if (selectedImage.id && selectedCountry && provinces) {
      const province = provinces.filter((option) => {
        return option.province === newValue;
      })[0];

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOnClickProvince(province);
    }
  };
  const handleAutoCompleteCityChange = (event: any, newValue: any) => {
    if (selectedImage.id && selectedCountry && cities) {
      const city = cities.filter((option) => {
        return option.city === newValue;
      })[0];

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOnClickCity(city);
    }
  };

  const handleCityChange = (value: string): void=>{
    const city: RapidApiCityDto = {
        city: value,
        latitude: 0,
        longitude: 0
    };
    handleOnClickCity(city);
}

  const handleMoveSingleImageToNewSet = (event: any, newSetTitle: string) => {
    event.preventDefault();
    if (
      selectedImage.id &&
      contentSetsState &&
      contentSetsState[contentSetIndex].selectedThreadIds!.length > 0
    ) {
      if (
        contentSetsState![contentSetIndex].coverPhoto?.id === selectedImage.id
      ) {
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Cannot assign the cover photo of current content set to another content set",
        });
        setNewSetTitle("");
      } else {
        moveContentToNewSetAction({
          contentId: selectedImage.id,
          newSetTitle: newSetTitle,
        });
        broadcastSnackbarAction({
          severity: SeveritySnackbarEnum.success,
          message:
            "Assigned selected photo to new set " +
            newSetTitle +
            " in thread " +
            contentSetsState![contentSetIndex].selectedThreadIds![0],
        });
      }
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
          "In order to assign a photo to new content set, you must first select a photo and have threads assigned to it",
      });
    }
  };


  const handleMoveMultiplePhotosToNewSet = (
    event: any,
    newSetTitle: string
  ) => {
    event.preventDefault();
    if (selectedImages) {
      selectedImages.map((id) => {
        moveContentToNewSetAction({ contentId: id, newSetTitle: newSetTitle });
      });
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Assigned selected photos to new set " + newSetTitle,
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
          "In order to assign photos to new content set, you must first select at least one photo and have threads assigned to it",
      });
    }
  };

  const handleMoveToNewSet = (event: any, newSetTitle: string) => {
    const coverPhotoId: any = contentSetsState ? contentSetsState[contentSetIndex!]?.coverPhoto?.id : -1;
    if (contentSetsState && selectedImages.includes(coverPhotoId)) {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "You cannot move the cover photo of a set",
      });
      setKey(previous => previous + 1);
      setContentSetSearchString("");
      setContentSetSearchObject({});
    }
    else{
      if (isMultiselectEnabled) {
        handleMoveMultiplePhotosToNewSet(event, newSetTitle);
      } else {
        handleMoveSingleImageToNewSet(event, newSetTitle);
      }
    }
  };


  const handleCopySingleImageToNewSet = (event: any, newSetTitle: string) => {
    event.preventDefault();
    if (
        selectedImage.id &&
        contentSetsState &&
        contentSetsState[contentSetIndex].selectedThreadIds!.length > 0
    ) {
      copyContentToNewSetAction({
        contentId: selectedImage.id,
        newSetTitle: newSetTitle,
      });
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message:
            "Assigned selected photo to new set " +
            newSetTitle +
            " in thread " +
            contentSetsState![contentSetIndex].selectedThreadIds![0],
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
            "In order to assign a photo to new content set, you must first select a photo and have threads assigned to it",
      });
    }
  };


  const handleCopyMultiplePhotosToNewSet = (
      event: any,
      newSetTitle: string
  ) => {
    event.preventDefault();
    if (selectedImages) {
      selectedImages.map((id) => {
        copyContentToNewSetAction({contentId: id, newSetTitle: newSetTitle});
      });
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.success,
        message: "Copy selected photos to new set " + newSetTitle,
      });
    } else {
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.warning,
        message:
            "In order to assign photos to new content set, you must first select at least one photo and have threads assigned to it",
      });
    }
  };


  const handleCopyToNewSet = (event: any, newSetTitle: string) => {
    if (isMultiselectEnabled) {
      handleCopyMultiplePhotosToNewSet(event, newSetTitle);
    } else {
      handleCopySingleImageToNewSet(event, newSetTitle);
    }
  };

  const handleRadioChange = (event: any) => {
    event.preventDefault();
    const value = event.target.value;

    if (value === RadioButtonGroup.MOVE_TO_EXISTING_SET) {
      setSelectedMoveToSetButton(RadioButtonGroup.MOVE_TO_EXISTING_SET);
    } else if (value === RadioButtonGroup.MOVE_TO_NEW_SET) {
      setSelectedMoveToSetButton(RadioButtonGroup.MOVE_TO_NEW_SET);
    }else if (value === RadioButtonGroup.COPY_TO_EXISTING_SET){
      setSelectedMoveToSetButton(RadioButtonGroup.COPY_TO_EXISTING_SET);
    }
  };
  const handleSingleImageClick = async (file: UploadedFileResponse) => {
    if (selectedImage.id === file.id) {
      setSelectedImage(file);
      //reset metadata
    } else {
      setLoadingIndicator(true);
      setSelectedImage(file);
      if (selectedImage.moveToNewSetTitle) {
        setSelectedMoveToSetButton(RadioButtonGroup.MOVE_TO_NEW_SET);
      } else {
        setSelectedMoveToSetButton(RadioButtonGroup.MOVE_TO_EXISTING_SET);
      }

      if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
        resetDropdownData();

        const hasCategories = individualImageCategories.find(
          (category) => category.contentId === file.id!
        );
        if (hasCategories && hasCategories.selectedThreads) {
          fetchMetadata({
            variables: {
              contentId: file.id,
              threadIds: hasCategories.selectedThreads,
            },
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          resetThreadCategoryState();
          fetchMetadata({ variables: { contentId: file.id, threadIds: [0] } });
        }
      } else if (
        selectedRadioButton === RadioButtonGroup.SET &&
        contentSetsState &&
        contentSetsState[contentSetIndex]
      ) {
        if (contentSetsState![contentSetIndex].selectedThreadIds) {
          fetchMetadata({
            variables: {
              contentId: file.id,
              threadIds: contentSetsState![contentSetIndex].selectedThreadIds,
            },
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          resetThreadCategoryState();
          fetchMetadata({ variables: { contentId: file.id, threadIds: [0] } });
        }
      }
    }
  };


  // const handleMultiImageSelection = (file: UploadedFileResponse) => {
  //   if (contentSetsState![contentSetIndex].coverPhoto?.id === file.id) {
  //     broadcastSnackbarAction({
  //       severity: SeveritySnackbarEnum.error,
  //       message: "You cannot select the cover photo of a set",
  //     });
  //   } else if (!selectedImages.includes(file.id)) {
  //     setSelectedImages([...selectedImages, file.id]);
  //     setSelectedContentSetImages([]);
  //   } else {
  //     setSelectedImages(selectedImages.filter((item) => item !== file.id));
  //   }
  // };

  const handleMultiImageSelection = (file: UploadedFileResponse) => {
     if (!selectedImages.includes(file.id)) {
      const images = [...selectedImages, file.id];
      setSelectedImages(images);
      if(contentSetSearchObject != undefined){
        const reduxSelectedImages = images.map((id)=>({"contentId" : id, copiedSet: contentSetSearchObject}));
        copyMultipleToSet(reduxSelectedImages);
      }else{
        const reduxSelectedImages = images.map((id)=>({"contentId" : id}));
        copyMultipleToSet(reduxSelectedImages);
      }


      // images.map((id) => {
      //   copyContentToNewSetAction({contentId: id});
      // });
      setSelectedContentSetImages([]);
    } else {
      const images = selectedImages.filter((item) => item !== file.id);
      setSelectedImages(images);

      if(contentSetSearchObject != undefined){
        const reduxSelectedImages = images.map((id)=>({"contentId" : id, copiedSet: contentSetSearchObject}));
        copyMultipleToSet(reduxSelectedImages);
      }else{
        const reduxSelectedImages = images.map((id)=>({"contentId" : id}));
        copyMultipleToSet(reduxSelectedImages);
      }

      // images.map((id) => {
      //   copyContentToNewSetAction({contentId: id});
      // });
    }
  };

  const handleImageClick = (file: UploadedFileResponse) => {
    if (isMultiselectEnabled) {
      handleMultiImageSelection(file);
    } else {
      handleSingleImageClick(file);
    }
  };

  const assignToSelectedChannelArray = () => {
    setSelectedChannels([...selectedChannels, selectedDropdownData.channel]);
    setSelectedSubchannels([
      ...selectedSubchannels,
      selectedDropdownData.subchannel,
    ]);
    setSelectedThreads([...selectedThreads, selectedDropdownData.thread]);
  };

  const getFilesInSetIds = (selectedContentSet: ContentSet): number[] => {
    const filesInSetsIds = selectedContentSet.files.map((file) => {
      return file.id;
    });

    return filesInSetsIds;
  };

  const applyToSet = (value: string) => {
    setEditMode({ ...editMode, [value]: true });
    const selectedContentSet = contentSetsState![contentSetIndex];

    const keywordsMeta = imageMetadata.keywords;
    const licenseTypesMeta = imageMetadata.license;
    const mainInfoMeta = imageMetadata.mainInformation;
    const copyrightMeta = imageMetadata.copyright;
    const locationMeta = imageMetadata.location;
    const contentSourceMeta = imageMetadata.copyright?.contentSource;
    const filesInSetsIds = getFilesInSetIds(selectedContentSet);

    switch (value) {
      case GroupsNames.MAIN_INFO:
        if (mainInfoMeta) {
          updateMainInfromationToSet(filesInSetsIds, mainInfoMeta);
        }
        break;
      case GroupsNames.LOCATION:
        if (locationMeta) {
          updateLocations(filesInSetsIds, locationMeta);
        }
        break;
      case GroupsNames.KEYWORDS:
        if (keywordsMeta) {
          updateKeywordsToSet(
            filesInSetsIds,
            keywordsMeta.keywordsToAdd!,
            keywordsMeta.keywordsToDelete!
          );
        }
        break;
      case GroupsNames.COPYRIGHT:
        if (copyrightMeta) {
          updateCopyrights(filesInSetsIds, copyrightMeta);
        }
        break;
      case GroupsNames.LICENSING:
        if (licenseTypesMeta) {
          updateLicensingTypes(filesInSetsIds, licenseTypesMeta);
        }
        break;
      case GroupsNames.CONTENT_SOURCE:
        if (contentSourceMeta && contentSourceMeta.userId) {
          updateContentSourceToSet(filesInSetsIds, contentSourceMeta.userId);
        }
      default:
        return null;
    }

    broadcastSnackbarAction({
      severity: SeveritySnackbarEnum.success,
      message: "Changes applied to whole content set successfully",
    });
  };

  const getBreadcrumbs = () => {
    const result: any[] = [];
    for (let i = 0; i < addCategoryIndex - 1; i++) {
      result.push(
        <div key={i} className="breadcrumb-container">
          <Breadcrumbs aria-label="navigation-breadcrumb">
            <Link color="inherit" href="#">
              {selectedChannels[i].selectedChannelTitle}
            </Link>
            <Link color="inherit" href="#">
              {selectedSubchannels[i].selectedChannelTitle}
            </Link>
            <Link color="inherit" href="#">
              {selectedThreads[i].selectedChannelTitle}
            </Link>
          </Breadcrumbs>
          <IconButton
            disabled={editMode.category}
            onClick={() => removeCategory(i)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      );
    }

    return result;
  };

  const removeCategory = (index: number) => {
    const updatedThreads = selectedThreads.filter(
      (thread) => thread.selectedId !== selectedThreads[index].selectedId
    );
    const updatedChannels = selectedChannels;
    const updatedSubChannels = selectedSubchannels;
    updatedChannels.splice(index, 1);
    updatedSubChannels.splice(index, 1);
    setAddCategoryIndex((prevState) => prevState - 1);

    if (updatedThreads) {
      const selectedThreads = updatedThreads.map((thread) => {
        return thread.selectedId;
      });
      if (selectedRadioButton === RadioButtonGroup.SET) {
        const selectedContentSetId = contentSetsState![contentSetIndex].id;
        addChannelToContent({ selectedThreads, selectedContentSetId });
      } else if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
        const imageCategory: IndividualImageCategory = {
          selectedThreads: selectedThreads,
          contentId: selectedImage.id!,
        };
        addIndividualImageNavigation(imageCategory);
      }
      setSelectedThreads(updatedThreads);
      setSelectedSubchannels(updatedSubChannels);
      setSelectedChannels(updatedChannels);
    }
  };

  const resetThreadCategoryState = () => {
    setAddCategoryIndex(1);
    setSelectedChannels([]);
    setSelectedSubchannels([]);
    setSelectedThreads([]);
  };

  const handleArrowClick = (event: any, nextCommand: string) => {
    if (nextCommand === "forward") {
      setContentSetIndex((prevState) => prevState + 1);
    } else if (nextCommand === "backward") {
      setContentSetIndex((prevState) => prevState - 1);
    }
    setEditMode({
      category: true,
      mainInfo: true,
      location: true,
      keywords: true,
      copyright: true,
      licensing: true,
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetDropdownData();
    if (nextCommand === "forward") {
      setSelectedImage(contentSetsState![contentSetIndex! + 1].files[0]);

      fetchMetadata({
        variables: {
          contentId: contentSetsState![contentSetIndex! + 1].files[0].id,
          threadIds: contentSetsState![contentSetIndex + 1].selectedThreadIds
            ? contentSetsState![contentSetIndex + 1].selectedThreadIds
            : [0],
        },
      });
    } else if (nextCommand === "backward") {
      setSelectedImage(contentSetsState![contentSetIndex! - 1].files[0]);

      fetchMetadata({
        variables: {
          contentId: contentSetsState![contentSetIndex! - 1].files[0].id,
          threadIds: contentSetsState![contentSetIndex - 1].selectedThreadIds
            ? contentSetsState![contentSetIndex - 1].selectedThreadIds
            : [0],
        },
      });
    }
  };

  const resetDropdownData = () => {
    setSelectedDropdownData({
      ...selectedDropdownData,
      channel: { selectedId: 0, selectedChannelTitle: "" },
      subchannel: { selectedId: 0, selectedChannelTitle: "" },
      thread: { selectedId: 0, selectedChannelTitle: "" },
    });
  };

  const handleOnClickCountry = (country: RapidApiCountryDto) => {
    setSelectedCountry(country);
    setImageMetadata({
      ...imageMetadata,
      location: Object.assign(
        {},
        {
          city: "",
          stateProvince: "",
          stateCode: "",
          longitude: 0,
          latitude: 0,
          zipCode: "",
          country: country.country,
          countryCode: country.countryCode,
        }
      ),
    });
  };

  const handleOnClickProvince = (province: RapidApiProvinceDto) => {
    setSelectedProvince(province);
    setImageMetadata({
      ...imageMetadata,
      location: Object.assign({}, imageMetadata.location, {
        stateProvince: province.province,
        stateCode: province.isoCode,
        city: "",
        longitude: 0,
        latitude: 0,
      }),
    });
  };

  const handleOnClickCity = (city: RapidApiCityDto) => {
    setSelectedCity(city);
  };

  const fetchCountries = () => {
    axios({
      method: "GET",
      url: `${config.REACT_APP_ADMIN_SERVER_HOST}/countries`,
    })
      .then((res: any) => setCountries(res.data))
      .catch((error: any) => console.log(error));
  };

  const fetchProvinces = (countryCode: string) => {
    axios({
      method: "GET",
      url: `${config.REACT_APP_ADMIN_SERVER_HOST}/provinces/${countryCode}`,
    })
      .then((res: any) => {
        setProvinces(res.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const fetchCities = (countryCode: string, isoCode: string) => {
    axios({
      method: "GET",
      url: `${config.REACT_APP_ADMIN_SERVER_HOST}/cities/${countryCode}/${isoCode}`,
    })
      .then((res: any) => setCities(res.data))
      .catch((error: any) => console.log(error));
  };

  useEffect(() => {
    if (data && data.fetchLocation && data.fetchLocation.country) {
      const country = data.fetchLocation.country;
      const isValid = doesCountryExistInRapidApi(countries, country);
      setSelectedCountry({
        country: isValid ? country.country : "",
        countryCode: isValid ? country.countryCode : "",
      });
      fetchProvinces(country.countryCode);
    }
  }, [data,countries]);

  useEffect(() => {
    if (data && data.fetchLocation && data.fetchLocation.province) {
      const province = data.fetchLocation.province;
      const country = data.fetchLocation.country;
      const isValidProvince = doesProvinceExistInRapidApi(provinces, province);
      setSelectedProvince({
        province: isValidProvince ? province.stateProvince : "",
        isoCode: isValidProvince ? province.stateCode : "",
        countryId: selectedCountry.countryCode,
      });
      if(country?.countryCode && province?.stateCode){
        fetchCities(country.countryCode, province.stateCode);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data && data.fetchLocation && data.fetchLocation.city) {
      const city = data.fetchLocation.city;
      const locationCoordinates = data.fetchLocation.locationCoordinate;
      const isValidCity = doesCityExistInRapidApi(cities, city);
      setSelectedCity({
        city: isValidCity ? city.city : "",
        longitude:
          isValidCity && locationCoordinates
            ? locationCoordinates.longitude
            : 0,
        latitude:
          isValidCity && locationCoordinates ? locationCoordinates.latitude : 0,
      });
    }
  }, [data]);

  useEffect(() => {
    if (selectedCountry.country !== "" && selectedCountry.countryCode !== "") {
      setCountryCode(selectedCountry.countryCode);
      setImageMetadata({
        ...imageMetadata,
        location: Object.assign({}, imageMetadata.location, {
          country: selectedCountry.country,
          countryCode: selectedCountry.countryCode,
        }),
      });
      axios({
        method: "GET",
        url: `${config.REACT_APP_ADMIN_SERVER_HOST}/provinces/${selectedCountry.countryCode}`,
      })
        .then((res: any) => {
          setProvinces(res.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedProvince.province !== "" && selectedProvince.isoCode !== "") {
      setProvinceIsoCode(selectedProvince.isoCode);
      setImageMetadata({
        ...imageMetadata,
        location: Object.assign({}, imageMetadata.location, {
          stateProvince: selectedProvince.province,
          stateCode: selectedProvince.isoCode,
        }),
      });
      axios({
        method: "GET",
        url: `${config.REACT_APP_ADMIN_SERVER_HOST}/cities/${countryCode}/${selectedProvince.isoCode}`,
      })
        .then((res: any) => setCities(res.data))
        .catch((error: any) => console.log(error));
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedCity.city !== "") {
      setImageMetadata({
        ...imageMetadata,
        location: Object.assign({}, imageMetadata.location, {
          city: selectedCity.city,
          latitude: selectedCity.latitude,
          longitude: selectedCity.longitude,
        }),
      });
    }
  }, [selectedCity]);

  const handleRemoveImageFromSetDialogClose = () => {
    setDeleteImageConfirmationDialog(false);
  };

  const handleRemoveImageFromSetDialogAgree = () => {
    handleDeleteImage!(selectedImage.id!);
    setDeleteImageConfirmationDialog(false);
  };

  const resetLocations = () => {
    setSelectedCountry({ country: "", countryCode: "" });
    setCountryCode("");
    setSelectedProvince({ province: "", isoCode: "", countryId: "" });
    setSelectedCity({ city: "", longitude: 0, latitude: 0 });
    setImageMetadata({
      ...imageMetadata,
      location: Object.assign({}, imageMetadata.location, {
        country: "",
        countryCode: "",
        stateProvince: "",
        stateCode: "",
        city: "",
        latitude: 0,
        longitude: 0,
      }),
    });
  };
  const randomIntFromInterval = (min: number, max: number)=> {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useEffect(()=>{
    if(downloadedContentIds.length ){
      
      

      let newDownloadingContentIds: number[] = [...downloadingContentIds];
      downloadedContentIds.forEach(downloadedId=>{
        
        newDownloadingContentIds = [...newDownloadingContentIds.filter(downloadingContentId => downloadingContentId !== downloadedId)]
        
      });
      
      setDownloadingContentIds([...newDownloadingContentIds]);
    }
  },[downloadedContentIds.length]);

  useEffect(()=>{
    
    if(downloadingContentIds.length === 0 && downloadedContentIds.length > 0){
      
      setDownloadedContentIds({id: 0, clean: true})
    }
  },[downloadingContentIds.length,downloadedContentIds.length])

  const startDownload = (downloadUrl: string,selectedImage: Partial<UploadedFileResponse>): void=>{
  
    if(selectedImage?.id){
      setDownloadingContentIds([...downloadingContentIds, selectedImage?.id])
    }
    axios({
        url: downloadUrl,
        method: "GET",
        responseType: "arraybuffer",
        params: {
          "token": currentUser?.token
        },
        onDownloadProgress: progressEvent => {
          const total = parseFloat(progressEvent?.target?.response?.byteLength)
          const current = progressEvent.loaded
          const percentCompleted = Math.round(current / total * 100);
          
          if(percentCompleted === 100){
            const downloadedContentIdParameter: string = progressEvent?.target?.responseURL.split("=")[1];
            const downloadedContentId: number = parseInt(downloadedContentIdParameter.substr(1,downloadedContentIdParameter.length-2))
            setDownloadedContentIds({id: downloadedContentId, clean: false})
          }
        }
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${selectedImage?.code}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error=>{
      const downloadedContentIdParameter: string = error?.config?.url.split("=")[1];
      const downloadedContentId: number = parseInt(downloadedContentIdParameter.substr(1,downloadedContentIdParameter.length-2))
      setDownloadedContentIds({id: downloadedContentId, clean: false})
      broadcastSnackbarAction({
        severity: SeveritySnackbarEnum.error,
        message: "Failed to download image"
    });
    });
  }

  const countryFilter = createFilterOptions<RapidApiCountryDto>();
  const provinceFilter = createFilterOptions<RapidApiProvinceDto>();

  const handleAngleChange = ()=>{

    setAngle((prev: number)=> {
      if(prev === 360){
        if(selectedImage?.id)toggleAngelChangeOfSelectedImage({contentId: selectedImage?.id, rotateDegree: 90});
        return 90;
      }
      else{
        if(selectedImage?.id)toggleAngelChangeOfSelectedImage({contentId: selectedImage?.id, rotateDegree: prev + 90});
        return prev + 90;
      }
      
    })
  }

  return (
    <div className="edit-metadata-container">
      <SyncLoader
        css={`
          position: fixed;
          margin: 0;
          padding: 0;
          top: 50vh;
          left: 0;
          height: 100%;
          z-index: 10;
          width: 100%;
          opacity: 0.5;
        `}
        size={20}
        color={"#36D2B3"}
        loading={loadingIndicator}
      />
      <MuiThemeProvider theme={inputFormTheme}>
        {contentSetsState || uploadedFiles ? (
          <div className="content-vertical-bar">
            <Tooltip
              title={
                contentSetsState &&
                contentSetsState[contentSetIndex] &&
                contentSetsState[contentSetIndex].title
                  ? contentSetsState![contentSetIndex].title
                  : ""
              }
              disableFocusListener
              disableTouchListener
            >
              <label className="content-set-label">
                {selectedRadioButton === RadioButtonGroup.SET &&
                contentSetsState &&
                contentSetsState[contentSetIndex] ? (
                  <FormControlLabel
                    aria-label="content-set-title"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <EditableTextField
                        id="set-name-id"
                        value={contentSetsState![contentSetIndex].title}
                        name="textfield"
                        onSubmit={(value: string) =>
                          changeSetTitle(
                            contentSetsState![contentSetIndex],
                            value
                          )
                        }
                      />
                    }
                    label=""
                  />
                ) : null}
              </label>
            </Tooltip>
            <div className="vertical-bar-buttons">
              {selectedRadioButton === RadioButtonGroup.SET &&
              contentSetsState &&
              contentSetsState.length > 1 ? (
                <React.Fragment>
                  <Button
                    size="small"
                    variant="contained"
                    color="default"
                    onClick={(event: any) =>
                      handleArrowClick(event, "backward")
                    }
                    disabled={contentSetIndex === 0}
                    startIcon={<ArrowBackIcon fontSize="small" />}
                  >
                    Previous
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    color="default"
                    onClick={(event: any) => handleArrowClick(event, "forward")}
                    disabled={contentSetIndex === contentSetsState.length - 1}
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                  >
                    Next
                  </Button>
                </React.Fragment>
              ) : null}
            </div>

            {selectedRadioButton === RadioButtonGroup.SET &&
            contentSetsState &&
            contentSetsState[contentSetIndex] ? (
              <ImageList
                rowHeight={120}
                cols={1}
                classes={{ root: classes.gridListRoot }}
              >
                {contentSetsState[contentSetIndex].files.map(
                  (file: UploadedFileResponse) => {
                    let blinkClass = "";
                    if(duplicateFileIds.includes(file.id)){
                      blinkClass = " blink"
                    }
                    return <ImageListItem
                      key={file.id+randomIntFromInterval(1,20000000)}
                      cols={1}
                      classes={{ root: classes.gridListTileRoot }}
                    >

                      <ListItem

                        data-file-id={file.id}
                        button
                        selected={
                          isMultiselectEnabled
                            ? selectedImages.includes(file.id)
                            : selectedImage.id === file.id
                        }
                        classes={{
                          gutters:
                            contentSetsState[contentSetIndex].coverPhoto?.id ===
                            file.id
                              ? classes.coverPhoto
                              : "",
                          selected: isMultiselectEnabled
                              ? classes.selectedPhoto + blinkClass
                              : "",

                        }}
                        onClick={() => handleImageClick(file)}
                        aria-label="selectable-image"
                      >
                        <>
                          {((file.status === "rejected" && file.isApproved === false) || file.isApproved === false) &&
                              <span className={'rejectedContentStatus'}>REJECTED{" "}
                                <CancelIcon
                                    style={{ fill: "red", verticalAlign: "middle" }}
                                />
                              </span> 
                          }
                          <Image
                              src={file.url}
                              className="image-container"
                              loading="auto"
                              placeholder={
                                <div className="placeholder">
                                  Failed to display...
                                </div>
                              }
                          />
                          {contentSetsState[contentSetIndex].coverPhoto?.id ===
                            file.id && <label className='cover-photo-title'>COVER PHOTO</label>}
                        </>

                      </ListItem>
                    </ImageListItem>
                  }
                )}
              </ImageList>
            ) : (
              <ImageList
                rowHeight={120}
                cols={1}
                classes={{ root: classes.gridListRoot }}
              >
                {uploadedFiles.map((file: UploadedFileResponse) => (
                  <ImageListItem
                    key={file.id+randomIntFromInterval(1,20000000)}
                    cols={1}
                    classes={{ root: classes.gridListTileRoot }}
                  >
                    <ListItem
                      button
                      selected={selectedImage.id === file.id}
                      onClick={() => handleSingleImageClick(file)}
                      aria-label="selectable-image"
                    >
                      <Image
                        src={file.url}
                        className="image-container"
                        loading="auto"
                        placeholder={
                          <div className="placeholder">
                            Failed to display...
                          </div>
                        }
                      />
                    </ListItem>
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </div>
        ) : null}
        <div className="content-image-metadata">
          <Card>
            <CardActions>
              <Button
                style={{width: '120px'}}
                size="small"
                variant="contained"
                disabled={downloadingSelectedImage}
                onClick={() => {
                  startDownload(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${selectedImage.id}]`,selectedImage);
                }}
                startIcon={<DownloadIcon style={{ paddingLeft: '4px'}}/>}>
                {downloadingSelectedImage ? <CircularProgress size={15}/> : "Download"}
              </Button>
              <Button
                style={{width: '100px'}}
                size="small"
                variant="contained"
                disabled={downloadingSelectedImage}
                onClick={() => {
                  handleAngleChange()
                }}
                startIcon={<RotateRight style={{ paddingLeft: '4px'}}/>}>
                Rotate
              </Button>
              {editSetFromAdmin &&
              selectedImage.url &&
              selectedImage.isApproved === undefined &&
              selectedRadioButton === RadioButtonGroup.SET ? (
                <div className="edit-card-button-group">
                  <Button
                    size="small"
                    variant="contained"
                    
                    disabled={
                      contentSetsState &&
                      contentSetsState[contentSetIndex!].coverPhoto!.id ===
                        selectedImage.id || (isMultiselectEnabled && selectedImages.length > 1)
                    }
                    onClick={() =>
                      {
                        if(isMultiselectEnabled && selectedImages.length === 1){
                          return changeCoverPhoto(
                            selectedImages[0]!,
                            contentSetsState![contentSetIndex]
                          )
                        }
                        return changeCoverPhoto(
                          selectedImage.id!,
                          contentSetsState![contentSetIndex]
                        )
                      }
                    }
                  >
                    Select as cover photo
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={
                      contentSetsState &&
                      contentSetsState[contentSetIndex!].coverPhoto?.id ===
                        selectedImage.id
                    }
                    onClick={() => {
                      if(isMultiselectEnabled){
                        const coverPhotoId: any = contentSetsState ? contentSetsState[contentSetIndex!]?.coverPhoto?.id : -1;
                        if (contentSetsState && selectedImages.includes(coverPhotoId)) {
                          broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.error,
                            message: "You cannot reject the cover photo of a set",
                          });
                        }
                        else{
                          selectedImages.map((id)=> handleRejectImage!(id));
                        }
                      }else{
                        handleRejectImage!(selectedImage.id!);
                      }
                    }}
                    startIcon={<CloseIcon />}
                  >
                    Reject image
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      if(isMultiselectEnabled){
                        selectedImages.map((id)=> handleApproveImage!(id));
                      }else{
                        handleApproveImage!(selectedImage.id!);
                      }
                    }}
                    startIcon={<DoneIcon />}
                  >
                    Approve Image
                  </Button>
                </div>
              ) : null}
              {!editSetFromAdmin &&
              selectedRadioButton === RadioButtonGroup.SET &&
              selectedImage.url &&
              selectedImage.isDeleted === undefined &&
              !isInIncomplete ? (
                <div className="edit-card-button-group">
                  <Button
                    size="small"
                    variant="contained"
                    disabled={
                      contentSetsState &&
                      contentSetsState[contentSetIndex!].coverPhoto!.id ===
                        selectedImage.id || (isMultiselectEnabled && selectedImages.length > 1)
                    }
                    onClick={() =>
                      {
                        if(isMultiselectEnabled && selectedImages.length === 1){
                          return changeCoverPhoto(
                            selectedImages[0]!,
                            contentSetsState![contentSetIndex]
                          )
                        }
                        return changeCoverPhoto(
                          selectedImage.id!,
                          contentSetsState![contentSetIndex]
                        )
                      }
                    }
                  >
                    Select as cover photo
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={
                      contentSetsState &&
                      contentSetsState[contentSetIndex!].coverPhoto?.id ===
                        selectedImage.id
                    }
                    onClick={() => setDeleteImageConfirmationDialog(true)}
                    endIcon={<CloseIcon />}
                  >
                    Delete image
                  </Button>
                </div>
              ) : null}

              {editSetFromAdmin && selectedImage.isApproved !== undefined ? (
                selectedImage.isApproved ? (
                  <>
                    <label className="header-label">
                      APPROVED{" "}
                      <CheckCircleIcon
                          style={{ fill: "green", verticalAlign: "middle" }}
                      />
                    </label>

                    <Button
                        size="small"
                        variant="contained"
                        // disabled={
                        //     contentSetsState &&
                        //     contentSetsState[contentSetIndex!].coverPhoto?.id ===
                        //     selectedImage.id
                        // }
                        onClick={() => {
                          if(isMultiselectEnabled){
                            selectedImages.map((id)=> handleRejectImage!(id));
                          }else{
                            handleRejectImage!(selectedImage.id!);
                          }
                        }}
                        startIcon={<CloseIcon />}
                    >
                      Reject image
                    </Button>
                  </>
                ) : (
                  <>
                    <label className="header-label">
                      REJECTED{" "}
                      <CancelIcon
                        style={{ fill: "red", verticalAlign: "middle" }}
                      />
                    </label>

                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          if(isMultiselectEnabled){
                            selectedImages.map((id)=> handleApproveImage!(id));
                          }else{
                            handleApproveImage!(selectedImage.id!);
                          }
                        }}
                        startIcon={<DoneIcon />}
                    >
                      Approve Image
                    </Button>
                  </>
                )
              ) : null}
              {!editSetFromAdmin && selectedImage.isDeleted ? (
                <label className="header-label">
                  DELETED{" "}
                  <CancelIcon
                    style={{ fill: "red", verticalAlign: "middle" }}
                  />
                </label>
              ) : null}
            </CardActions>
              <div style={{overflow: 'hidden', width: '100%', height: angle === 90 || angle === 270 ? `${widthOfSelectedImage}px` : 'auto'}}>
                <img
                  style={{width: '100%', transform: `rotate(${angle}deg)`, objectFit: 'contain', height: '100%'}}
                  src={selectedImage.url}
                  onLoad={handleImageLoad}
                />
              </div>
          </Card>

          {selectedRadioButton === RadioButtonGroup.SET &&
          selectedImage &&
          !isInIncomplete ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="category-image-set"
              >
                <label className="header-label">
                  MOVE/COPY TO OTHER CONTENT SET (OPTIONAL)
                </label>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-details">
                  <RadioGroup
                    aria-label="move-to-set"
                    name="moveToSet"
                    value={selectedMoveToSetButton}
                    onChange={handleRadioChange}
                    style={{ flexDirection: "row", justifyContent: "center" }}
                  >
                    <FormControlLabel
                      value={RadioButtonGroup.MOVE_TO_EXISTING_SET}
                      control={<Radio size="small" />}
                      label="Move to an existing set"
                    />
                    <FormControlLabel
                      value={RadioButtonGroup.MOVE_TO_NEW_SET}
                      control={<Radio size="small" />}
                      label="Move to new set"
                    />
                    <FormControlLabel
                        value={RadioButtonGroup.COPY_TO_EXISTING_SET}
                        control={<Radio size="small" />}
                        label="Copy to an existing set"
                    />
                  </RadioGroup>

                  {selectedMoveToSetButton ===
                  RadioButtonGroup.MOVE_TO_EXISTING_SET ? (
                    <div className="form-field-row">
                      <InputLabel htmlFor="agency-name-input" shrink={true}>
                        Move to content set:{" "}
                      </InputLabel>
                      <Autocomplete
                        freeSolo
                        key={key}
                        id="autocomplete-move-set"
                        style={{ width: "100%" }}
                        disableClearable
                        value={contentSetSearchObject?.title}
                        inputValue={contentSetSearchString}
                        options={contentSetOptions.sort(
                          (a, b) => -b.title.localeCompare(a.title)
                        )}
                        groupBy={(option: any): any => "Thread: " + option.threadTitle}
                        onChange={handleAutoCompleteContentSetChange}
                        getOptionLabel={(option: any): any => option.title}
                        renderInput={(params: any): any => (
                          <TextField
                            {...params}
                            variant="standard"
                            autoComplete="off"
                            value={contentSetSearchString}
                            name="contentSetSearch"
                            onChange={(e: any): any =>
                              handleChange(e, e.target.value, "moveToSet")
                            }
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                        renderOption={(props: any, option: any): any => {
                          return <div {...props} className="dropdown-option-container"><p className="dropdown-option">{option.title}</p></div>
                        }}
                      />
                    </div>
                  ) : null}

                  {selectedMoveToSetButton ===
                  RadioButtonGroup.COPY_TO_EXISTING_SET ? (
                      <div className="form-field-row">
                        <InputLabel htmlFor="agency-name-input" shrink={true}>
                          Copy to content set:{" "}
                        </InputLabel>
                        <Autocomplete
                            freeSolo
                            id="autocomplete-copy-set"
                            style={{ width: "100%" }}
                            disableClearable
                            value={contentSetSearchObject?.title}
                            inputValue={contentSetSearchString}
                            options={contentSetOptions.sort(
                                (a, b) => -b.title.localeCompare(a.title)
                            )}
                            groupBy={(option: any): any => "Thread: " + option.threadTitle}
                            onChange={handleAutoCompleteContentSetCopy}
                            getOptionLabel={(option: any): any => option.title}
                            renderInput={(params: any): any => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    autoComplete="off"
                                    value={contentSetSearchString}
                                    name="contentSetSearch"
                                    onChange={(e: any): any =>
                                        handleChange(e, e.target.value, "copyToSet")
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                      // endAdornment:
                                      //     newSetTitle.length > 0 ? (
                                      //         <InputAdornment position="end">
                                      //           <IconButton
                                      //               onClick={(e: any) =>
                                      //                   handleCopyToNewSet(e, newSetTitle)
                                      //               }
                                      //           >
                                      //             <DoneIcon fontSize="small" />
                                      //           </IconButton>
                                      //         </InputAdornment>
                                      //     ) : null,
                                    }}
                                />
                            )}
                            renderOption={(props: any, option: any): any => {
                              return <div {...props} className="dropdown-option-container"><p className="dropdown-option">{option.title}</p></div>
                            }}
                        />
                      </div>
                  ) : null}

                  {selectedMoveToSetButton ===
                  RadioButtonGroup.MOVE_TO_NEW_SET ? (
                    <div className="form-field-row">
                      <InputLabel htmlFor="new-set-name-input" shrink={true}>
                        New set name:{" "}
                      </InputLabel>
                      <TextField
                        autoComplete="off"
                        variant="standard"
                        onChange={(e: any) =>
                          handleChange(e, e.target.value, "newSetTitle")
                        }
                        // onBlur={(e: any) =>
                        //     newSetTitle.length > 0 && handleMoveToNewSet(e, newSetTitle)
                        // }
                        label=""
                        name="newSetTitle"
                        value={newSetTitle}
                        InputProps={{
                          endAdornment:
                            newSetTitle.length > 0 ? (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e: any) =>
                                    handleMoveToNewSet(e, newSetTitle)
                                  }
                                >
                                  <DoneIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </AccordionDetails>
              <AccordionActions></AccordionActions>
            </Accordion>
          ) : null}

          {/* CHANNELS AND SUBCHANNELS */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="category-image-set"
            >
              {selectedRadioButton === RadioButtonGroup.SET ? (
                <label className="header-label">
                  CATEGORY (FOR CONTENT SET)
                </label>
              ) : (
                <label className="header-label">
                  CATEGORY (FOR SINGLE CONTENT)
                </label>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {getBreadcrumbs()}
              {addCategoryIndex < 4 ? (
                <SelectChannelsComponent
                  disabled={editMode.category}
                  selectedDropdownData={selectedDropdownData}
                  handleSelect={handleSelectChannel}
                  channels={channels}
                />
              ) : null}
            </AccordionDetails>
            <AccordionActions>
              <Button
                size="small"
                variant="contained"
                disabled={
                  editMode.category ||
                  selectedDropdownData.thread.selectedId === 0
                }
                onClick={addAnotherCategory}
              >
                Add to another
              </Button>

              {editMode.category ? (
                <IconButton
                  onClick={() => handleEditModeChange(GroupsNames.CATEGORY)}
                  disabled={
                    selectedMoveToSetButton ===
                      RadioButtonGroup.MOVE_TO_EXISTING_SET &&
                    isMultiselectEnabled
                  }
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => SaveChanges(GroupsNames.CATEGORY)}
                >
                  Save Changes
                </Button>
              )}
            </AccordionActions>
          </Accordion>

          {/* MAIN INFORMATION */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="main-information-metadata"
            >
              <label className="header-label">MAIN INFORMATION</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div className="form-field-row">
                  <InputLabel htmlFor="object-name-input" shrink={true}>
                    Object Name:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.mainInfo}
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.MAIN_INFO)
                    }
                    label=""
                    name="objectName"
                    value={
                      imageMetadata.mainInformation
                        ? imageMetadata.mainInformation.objectName
                        : ""
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="headline-input" shrink={true}>
                    Headline:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.mainInfo}
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.MAIN_INFO)
                    }
                    label=""
                    name="headline"
                    value={
                      imageMetadata.mainInformation
                        ? imageMetadata.mainInformation.headline
                        : ""
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="caption-input" shrink={true}>
                    Caption:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.mainInfo}
                    multiline
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.MAIN_INFO)
                    }
                    label=""
                    name="caption"
                    value={
                      imageMetadata.mainInformation
                        ? imageMetadata.mainInformation.caption
                        : ""
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="caption-input" shrink={true}>
                    External link:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.mainInfo}
                    multiline
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.MAIN_INFO)
                    }
                    label=""
                    name="externalLink"
                    value={
                      imageMetadata.mainInformation
                        ? imageMetadata.mainInformation.externalLink
                        : ""
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="caption-input" shrink={true}>
                    External link message:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.mainInfo}
                    multiline
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.MAIN_INFO)
                    }
                    label=""
                    name="externalLinkMessage"
                    value={
                      imageMetadata.mainInformation
                        ? imageMetadata.mainInformation.externalLinkMessage
                        : ""
                    }
                  />
                </div>
              </div>
            </AccordionDetails>
            <AccordionActions>
              {selectedRadioButton === RadioButtonGroup.SET ? (
                <Button
                  size="small"
                  variant="contained"
                  disabled={editMode.mainInfo || isMultiselectEnabled}
                  onClick={() => handleApplyToSet(GroupsNames.MAIN_INFO)}
                >
                  Apply to set
                </Button>
              ) : null}
              {editMode.mainInfo ? (
                <IconButton
                  onClick={() => handleEditModeChange(GroupsNames.MAIN_INFO)}
                  disabled={isMultiselectEnabled}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => SaveChanges(GroupsNames.MAIN_INFO)}
                >
                  Save Changes
                </Button>
              )}
            </AccordionActions>
          </Accordion>

          {/* Location */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="location-metadata"
            >
              <label className="header-label">LOCATION</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div className="form-field-row">
                  <InputLabel htmlFor="country-input" shrink={true}>
                    Country:{" "}
                  </InputLabel>
                  <Autocomplete
                    id="autocomplete-country"
                    style={{ width: "100%" }}
                    value={
                      imageMetadata.location
                        ? imageMetadata.location.country
                        : ""
                    }
                    options={countries.map((option) => option.country)}
                    disabled={editMode.location}
                    disableClearable
                    onChange={handleAutoCompleteCountriesChange}
                    filterOptions={(options: any, params: FilterOptionsState<any>): any => {
                      const filtered = countryFilter(options, params);
                      return filtered;
                    }}
                    renderInput={(params: any): any => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "countryInput",
                        }}
                        id="country-input"
                        variant="standard"
                        placeholder="Enter country"
                        value={
                          imageMetadata.location
                            ? imageMetadata.location.country
                            : ""
                        }
                        disabled={editMode.location}
                      />
                    )}
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="province-input" shrink={true}>
                    Province:
                  </InputLabel>
                  <Autocomplete
                    id="autocomplete-province"
                    style={{ width: "100%" }}
                    value={
                      imageMetadata.location
                        ? imageMetadata.location.stateProvince
                        : ""
                    }
                    options={provinces.map((option) => option.province)}
                    disabled={editMode.location}
                    disableClearable
                    onChange={handleAutoCompleteProvinceChange}
                    filterOptions={(options: any, params: FilterOptionsState<any>): any => {
                      const filtered = provinceFilter(options, params);
                      return filtered;
                    }}
                    renderInput={(params: any): any => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "stateProvinceInput",
                        }}
                        id="province-input"
                        variant="standard"
                        placeholder="Enter province"
                        value={
                          imageMetadata.location
                            ? imageMetadata.location.stateProvince
                            : ""
                        }
                        disabled={editMode.location}
                      />
                    )}
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="city-input" shrink={true}>
                    City:
                  </InputLabel>
                  <Autocomplete
                    freeSolo
                    id="autocomplete-city"
                    style={{ width: "100%" }}
                    value={
                      imageMetadata.location ? imageMetadata.location.city : ""
                    }
                    options={cities.map((option) => option.city)}
                    disabled={editMode.location}
                    disableClearable
                    onChange={handleAutoCompleteCityChange}
                    renderInput={(params: any): any => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "cityInput",
                        }}
                        id="province-input"
                        variant="standard"
                        placeholder="Enter city"
                        onChange={(e)=>{
                          handleCityChange(e.target.value);
                      }}
                        value={
                          imageMetadata.location
                            ? imageMetadata.location.city
                            : ""
                        }
                        disabled={editMode.location}
                      />
                    )}
                  />
                </div>

                <div className="form-field-row">
                  <InputLabel htmlFor="country-code-input" shrink={true}>
                    Country code:
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    type="text"
                    disabled={true}
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.LOCATION)
                    }
                    name="countryCode"
                    value={
                      imageMetadata.location
                        ? imageMetadata.location.countryCode
                        : ""
                    }
                  />
                </div>
                {/*                                 
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="country-code-input"
                                        shrink={true}>Zip code: </InputLabel>
                                    <TextField variant='standard' type='text' disabled={true}
                                        onChange={(e: any) => handleChange(e, e.target.value, 'location')}
                                        name="zipCode"
                                        value={imageMetadata.location ? imageMetadata.location.zipCode : ""} />
                                </div> */}

                <div className="form-field-row">
                  <InputLabel htmlFor="latitude-input" shrink={true}>
                    GPS latitude:
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    type="number"
                    disabled={true}
                    onChange={(e: any) =>
                      handleChange(
                        e,
                        e.target.valueAsNumber,
                        GroupsNames.LOCATION
                      )
                    }
                    name="latitude"
                    value={
                      imageMetadata.location
                        ? imageMetadata.location.latitude
                        : 0
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="longitude-input" shrink={true}>
                    GPS longitude:
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    type="number"
                    disabled={true}
                    onChange={(e: any) =>
                      handleChange(
                        e,
                        e.target.valueAsNumber,
                        GroupsNames.LOCATION
                      )
                    }
                    name="longitude"
                    value={
                      imageMetadata.location
                        ? imageMetadata.location.longitude
                        : 0
                    }
                  />
                </div>
              </div>
            </AccordionDetails>
            <AccordionActions>
              {selectedRadioButton === RadioButtonGroup.SET ? (
                <Button
                  size="small"
                  disabled={editMode.location || isMultiselectEnabled}
                  variant="contained"
                  onClick={() => handleApplyToSet(GroupsNames.LOCATION)}
                >
                  Apply To Set
                </Button>
              ) : null}
              {/* <Button size='small' variant='contained' disabled={editMode.location} onClick={() => resetLocations()}>
                                    Discard changes
                                </Button> */}
              {editMode.location ? (
                <IconButton
                  onClick={() => handleEditModeChange(GroupsNames.LOCATION)}
                  disabled={isMultiselectEnabled}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => SaveChanges(GroupsNames.LOCATION)}
                >
                  Save Changes
                </Button>
              )}
            </AccordionActions>
          </Accordion>

          {/* LICENSING */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="license-metadata"
            >
              <label className="header-label">License</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="form-field-row">
                <InputLabel htmlFor="license-input" shrink={true}>
                  {" "}
                  Type:{" "}
                </InputLabel>
                <Select
                  variant="standard"
                  onChange={(e: any) =>
                    handleChange(e, e.target.value, GroupsNames.LICENSING)
                  }
                  disabled={editMode.licensing}
                  id="license-type"
                  value={
                    imageMetadata.license ? imageMetadata.license.type : ""
                  }
                  name="type"
                >
                  {Object.values(LicensingTypes).map((licensingType) => {
                    return (
                      <MenuItem key={licensingType} value={licensingType}>
                        {licensingType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </AccordionDetails>
            <AccordionActions>
              {selectedRadioButton === RadioButtonGroup.SET ? (
                <Button
                  size="small"
                  disabled={editMode.licensing || isMultiselectEnabled}
                  variant="contained"
                  onClick={() => handleApplyToSet(GroupsNames.LICENSING)}
                >
                  Apply To Set
                </Button>
              ) : null}
              {editMode.licensing ? (
                <IconButton
                  onClick={() => handleEditModeChange(GroupsNames.LICENSING)}
                  disabled={isMultiselectEnabled}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => SaveChanges(GroupsNames.LICENSING)}
                >
                  Save Changes
                </Button>
              )}
            </AccordionActions>
          </Accordion>
        </div>
        <div className="content-image-metadata">
          {/* KEYWORDS */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="keywords-metadata"
            >
              <label className="header-label">KEYWORDS</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="tag-input-container">
                {imageMetadata.keywords
                  ? imageMetadata.keywords?.keywordsToShow!.map((data: any) => {
                      return (
                        <Chip
                          key={imageMetadata.keywords?.keywordsToShow!.indexOf(
                            data
                          )}
                          variant="outlined"
                          disabled={editMode.keywords}
                          label={data.name}
                          onDelete={() => handleDelete(data.name)}
                        />
                      );
                    })
                  : null}

                <TextField
                  ref={keywordInputRef}
                  autoComplete="off"
                  variant="standard"
                  name="keywordState"
                  value={!editMode.keywords ? keywordState : ""}
                  disabled={editMode.keywords}
                  onChange={(e: any) =>
                    handleChange(e, e.target.value, GroupsNames.KEYWORDS)
                  }
                  onKeyUp={(e: any)=>{
                    if (e.key === 'Enter') {
                        addKeyword()
                  }}}
                  placeholder="Add keywords..."
                />
              </div>
            </AccordionDetails>
            <AccordionActions>
              <Button
                size="small"
                variant="contained"
                disabled={
                  keywordState.length < 3 ||
                  keywordState.length > 100 ||
                  editMode.keywords
                }
                onClick={addKeyword}
              >
                Add
              </Button>
              {selectedRadioButton === RadioButtonGroup.SET ? (
                <Button
                  size="small"
                  disabled={editMode.keywords || isMultiselectEnabled}
                  variant="contained"
                  onClick={() => handleApplyToSet(GroupsNames.KEYWORDS)}
                >
                  Apply To Set
                </Button>
              ) : null}
              {editMode.keywords ? (
                <IconButton
                  onClick={() => handleEditModeChange(GroupsNames.KEYWORDS)}
                  disabled={isMultiselectEnabled}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => SaveChanges(GroupsNames.KEYWORDS)}
                >
                  Save Changes
                </Button>
              )}
            </AccordionActions>
          </Accordion>

          {/* COPYRIGHT */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="copyright-metadata"
            >
              <label className="header-label">COPYRIGHT</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div className="form-field-row">
                  <InputLabel htmlFor="by-line-input" shrink={true}>
                    By-line:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.copyright}
                    value={
                      imageMetadata.copyright
                        ? imageMetadata.copyright.byLine
                        : ""
                    }
                    name="byLine"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.COPYRIGHT)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="credit-input" shrink={true}>
                    Credit:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.copyright}
                    value={
                      imageMetadata.copyright
                        ? imageMetadata.copyright.credit
                        : ""
                    }
                    name="credit"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.COPYRIGHT)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="copyright-notice-input" shrink={true}>
                    Copyright Notice:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.copyright}
                    value={
                      imageMetadata.copyright
                        ? imageMetadata.copyright.copyrightNotice
                        : ""
                    }
                    name="copyrightNotice"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.COPYRIGHT)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="instructions-input" shrink={true}>
                    Special Instructions:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={editMode.copyright}
                    multiline
                    value={
                      imageMetadata.copyright
                        ? imageMetadata.copyright.specialInstructions
                        : ""
                    }
                    name="specialInstructions"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.COPYRIGHT)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="agency-name-input" shrink={true}>
                    Agency name:{" "}
                  </InputLabel>
                  <Autocomplete
                    freeSolo
                    id="autocomplete-free-solo"
                    style={{ width: "100%" }}
                    disableClearable
                    disabled={editMode.copyright}
                    value={
                      imageMetadata.copyright
                        ? imageMetadata.copyright.contentSource?.companyName
                        : ""
                    }
                    options={contributorOptions.map(
                      (option) => option.company?.name
                    )}
                    onChange={handleAutoCompleteAgencyChange}
                    onInputChange={handleAutoCompleteAgencyInputChange}
                    renderInput={(params: any): any => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        variant="standard"
                        disabled={editMode.copyright}
                        value={
                          imageMetadata.copyright
                            ? imageMetadata.copyright.contentSource?.companyName
                            : ""
                        }
                        name="agencyName"
                        onChange={(e: any): any =>
                          handleChange(e, e.target.value, GroupsNames.COPYRIGHT)
                        }
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </div>
              </div>
            </AccordionDetails>
            <AccordionActions>
              {selectedRadioButton === RadioButtonGroup.SET ? (
                <React.Fragment>
                  <Button
                    size="small"
                    disabled={editMode.copyright || isMultiselectEnabled}
                    variant="contained"
                    onClick={() => handleApplyToSet(GroupsNames.COPYRIGHT)}
                  >
                    Apply to set
                  </Button>
                  <Button
                    size="small"
                    disabled={editMode.copyright || isMultiselectEnabled}
                    variant="contained"
                    onClick={() => handleApplyToSet(GroupsNames.CONTENT_SOURCE)}
                  >
                    Apply agency name to set
                  </Button>
                </React.Fragment>
              ) : null}
              {editMode.copyright ? (
                <IconButton
                  onClick={() => handleEditModeChange(GroupsNames.COPYRIGHT)}
                  disabled={isMultiselectEnabled}
                >
                  <EditIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => SaveChanges(GroupsNames.COPYRIGHT)}
                >
                  Save Changes
                </Button>
              )}
            </AccordionActions>
          </Accordion>

          {/* SOURCE */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="source-metadata"
            >
              <label className="header-label">SOURCE</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div className="form-field-row">
                  <InputLabel htmlFor="source-input" shrink={true}>
                    Source:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.source ? imageMetadata.source.source : ""
                    }
                    name="source"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.SOURCE)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="created-date-input" shrink={true}>
                    Created Date:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.source
                        ? imageMetadata.source.createdDate
                        : new Date()
                    }
                    name="createdDate"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.SOURCE)
                    }
                  >
                    <Moment format="DD.MM.YYYY">
                      {imageMetadata.source?.createdDate}
                    </Moment>
                  </TextField>
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="created-time-input" shrink={true}>
                    Created Time:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.source
                        ? imageMetadata.source.createdTime
                        : ""
                    }
                    name="createdTime"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.SOURCE)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="uploaded-input" shrink={true}>
                    Uploaded Date:{" "}
                  </InputLabel>
                  <TextField
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.source
                        ? imageMetadata.source.uploadedDate
                        : new Date()
                    }
                    name="uploadedDate"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.SOURCE)
                    }
                  >
                    <Moment format="DD.MM.YYYY">
                      {imageMetadata.source?.uploadedDate}
                    </Moment>
                  </TextField>
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="filename-input" shrink={true}>
                    Original filename:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.source
                        ? imageMetadata.source.originalFileName
                        : ""
                    }
                    name="originalFileName"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.SOURCE)
                    }
                  />
                </div>
              </div>
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>

          {/* FILE */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="file-metadata"
            >
              <label className="header-label">FILE</label>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <div className="form-field-row">
                  <InputLabel htmlFor="filename-input" shrink={true}>
                    Filename:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.fileDetails
                        ? imageMetadata.fileDetails.fileName
                        : ""
                    }
                    name="fileName"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="filesize-input" shrink={true}>
                    Filesize:{" "}
                  </InputLabel>
                  <TextField
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.fileDetails
                        ? imageMetadata.fileDetails.fileSize
                        : ""
                    }
                    name="fileSize"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="filetype-input" shrink={true}>
                    File Type:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.fileDetails
                        ? imageMetadata.fileDetails.fileType
                        : ""
                    }
                    name="fileType"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="width-input" shrink={true}>
                    Image Width:{" "}
                  </InputLabel>
                  <TextField
                    variant="standard"
                    type="number"
                    disabled={true}
                    value={
                      imageMetadata.fileDetails
                        ? imageMetadata.fileDetails.imageWidth
                        : 0
                    }
                    name="imageWidth"
                    onChange={(e: any) =>
                      handleChange(
                        e,
                        e.target.valueAsNumber,
                        GroupsNames.FILE_DETAILS
                      )
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="length-input" shrink={true}>
                    Image Height:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    type="number"
                    disabled={true}
                    value={
                      imageMetadata.fileDetails
                        ? imageMetadata.fileDetails.imageHeight
                        : 0
                    }
                    name="imageHeight"
                    onChange={(e: any) =>
                      handleChange(
                        e,
                        e.target.valueAsNumber,
                        GroupsNames.FILE_DETAILS
                      )
                    }
                  />
                </div>
                <div className="form-field-row">
                  <InputLabel htmlFor="model-input" shrink={true}>
                    Camera Model:{" "}
                  </InputLabel>
                  <TextField
                    autoComplete="off"
                    variant="standard"
                    disabled={true}
                    value={
                      imageMetadata.fileDetails
                        ? imageMetadata.fileDetails.cameraModel
                        : ""
                    }
                    name="cameraModel"
                    onChange={(e: any) =>
                      handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)
                    }
                  />
                </div>
              </div>
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <SnackbarComponent
          showSnackbar={showContentUploadSnackbar}
          handleClose={handleClose}
          severity={snackbar.severity}
          message={snackbar.message}
        />
        <ConfirmationDialog
          open={deleteImageConfirmationDialog}
          title="Delete image from set"
          contentText="Are you sure you want to delete this image?"
          rejectButtonText="No"
          acceptButtonText="Yes"
          handleClose={handleRemoveImageFromSetDialogClose}
          handleConfirmationDialogAgree={handleRemoveImageFromSetDialogAgree}
        />
      </MuiThemeProvider>
      <Dialog
      className="dialogClass"        
        open={takeConfirmationBeforeApplyToSet}
        onClose={handleConfirmationDialogClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Save your changes?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Are You sure? Previous data will be Overwritten
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmationDialogClose}>
          No
          </Button>
          <Button onClick={handleConfirmationDialogAgree} autoFocus>
          Yes, save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (
  state: AdminStoreState
): {
  contentSetsState: ContentSet[];
  selectedRadioButton: string;
  uploadedFiles: UploadedFileResponse[];
  individualImageCategories: IndividualImageCategory[];
  movedContents: MovedContentToSets[];
  copiedContents: CopiedContentToSets[];
  snackbar: IBroadcastMessage;
  movedMultipleContents: MovedMultipleContents[];
  copiedMultipleContents: CopiedMultipleContents[];
  selectedSingleItem: IndividualImageCategory;
  multiselectImages: boolean;
  downloadingContentIds: number[];
  downloadedContentIds: number[];
  userId: number;
  imageAngles: ImageAngle[];
  currentUser: User;
} => {
  return {
    contentSetsState: selectContentSets(state),
    selectedRadioButton: selectRadioButtonValue(state),
    uploadedFiles: selectUploadedFiles(state),
    individualImageCategories: selectIndividualImagesCategory(state),
    movedContents: selectMovedContents(state),
    copiedContents: selectCopiedContents(state),
    snackbar: selectSnackbarState(state),
    movedMultipleContents: selectMultipleMovedContents(state),
    copiedMultipleContents: selectMultipleCopiedContents(state),
    selectedSingleItem: selectSelectedSingleItem(state),
    multiselectImages: getMultiselectImages(state),
    downloadingContentIds: getDownloadingContentIds(state),
    downloadedContentIds: getDownloadedContentIds(state),
    userId: selectCurrentUserId(state),
    imageAngles: getImageAngles(state),
    currentUser: selectCurrentUser(state),

  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<TContentUploadActions | TSnackbarActions | TEditContentReducerActions>
) => {
  return {
    addChannelToContent: (data: any) =>
      dispatch<IAddChannelToContentSet>({
        type: ContentUploadActionTypes.ADD_CHANNEL_ID,
        data: data,
      }),
    addIndividualImageNavigation: (data: IndividualImageCategory) =>
      dispatch<IAddChannelToIndividualImage>({
        type: ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL,
        data: data,
      }),
    broadcastSnackbarAction: (data: IBroadcastMessage) =>
      dispatch<ISnackbarMessage>({
        type: SnackbarActionTypes.BROADCAST_MESSAGE,
        data: data,
      }),
    updateSetAction: (data: ContentSet) =>
      dispatch<IUpdateSet>({
        type: ContentUploadActionTypes.UPDATE_SET,
        data: data,
      }),
    moveContentToNewSetAction: (data: MovedContentToSets) =>
      dispatch<IMoveContentToNewSet>({
        type: ContentUploadActionTypes.MOVE_CONTENT_TO_NEW_SET,
        data: data,
      }),
    copyContentToNewSetAction: (data: CopiedContentToSets) =>{
      return dispatch<ICopyContentToNewSet>({
        type: ContentUploadActionTypes.COPY_CONTENT_TO_NEW_SET,
        data: data,
      });
    },
    copyMultipleToSet: (data: CopiedContentToSets[]) =>{
      return dispatch<ICopyMultipleContentToNewSet>({
        type: ContentUploadActionTypes.COPY_MULTIPLE_CONTENT_TO_NEW_SET,
        data: data,
      });
    },
    removeContentToNewSetAction: (data: MovedContentToSets) =>
      dispatch<IRemoveContentToNewSet>({
        type: ContentUploadActionTypes.REMOVE_CONTENT_TO_NEW_SET,
        data: data,
      }),
    resetMultiselectionAction: () =>
      dispatch<IResetMultiselection>({
        type: ContentUploadActionTypes.RESET_MULTISELECTION,
      }),
    setUserSelectedImage: (data: number | undefined) =>
    dispatch<ISelectedImage>({
      type: EditContentActionTypes.SET_SELECTED_IMAGE,
      data:data
    }),
    setUserSelectedImages: (data: number[]) =>
      dispatch<ISelectedImages>({
        type: EditContentActionTypes.SET_SELECTED_IMAGES,
        data:data
      }),
    toggleAngelChangeOfSelectedImage: (data: ImageAngle) =>
      dispatch<IToggleAngleChange>({
        type: EditContentActionTypes.TOGGLE_ANGLE_CHANGE,
        data:data
      }),
    setDownloadingContentIds: (data: number[]) =>
      dispatch<IDownloadingContentIds>({
        type: EditContentActionTypes.SET_DOWNLOADING_CONTENT_IDS,
        data:data
      }),
    setDownloadedContentIds: (data: DownloadedContent) =>
    dispatch<IDownloadedContentIds>({
      type: EditContentActionTypes.SET_DOWNLOADED_CONTENT_IDS,
      data:data
    }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContentMetadata);
