import { TRefreshTablesActions } from "./tables-refresh.actions";
import { TablesRefreshState, TablesRefreshActionTypes } from "./tables-refresh.types";

const InitialState: TablesRefreshState = {
    locationOverrideRefresh: false,
    waitingListRefresh: false,
    usersTableRefresh: false,
    suggestThreadRefresh: false,
    reportsTableRefresh: false,
    postReportsTableRefresh: false,
    orderHistoryTableRefresh: false,
    licenseTableRefresh: false,
    incompleteTableRefresh: false,
    contentTableRefresh: false
};

export const tablesRefreshReducer = (state = InitialState, action: TRefreshTablesActions): TablesRefreshState => {
    switch(action.type) {
        case TablesRefreshActionTypes.LOCATION_OVERRIDE_REFRESH:
            return {
                ...state,
                locationOverrideRefresh: action.data
            }
        case TablesRefreshActionTypes.WAITING_LIST_REFRESH:
            return {
                ...state,
                waitingListRefresh: action.data
            }
        case TablesRefreshActionTypes.USERS_TABLE_REFRESH:
            return {
                ...state,
                usersTableRefresh: action.data
            }
        case TablesRefreshActionTypes.SUGGEST_THREAD_REFRESH:
            return {
                ...state,
                suggestThreadRefresh: action.data
            }
        case TablesRefreshActionTypes.REPORTS_TABLE_REFRESH:
            return {
                ...state,
                reportsTableRefresh: action.data
            }
        case TablesRefreshActionTypes.POST_REPORTS_TABLE_REFRESH:
            return {
                ...state,
                postReportsTableRefresh: action.data
            }
        case TablesRefreshActionTypes.ORDER_HISTORY_TABLE_REFRESH:
            return {
                ...state,
                orderHistoryTableRefresh: action.data
            }
        case TablesRefreshActionTypes.LICENCE_TABLE_REFRESH:
            return {
                ...state,
                licenseTableRefresh: action.data
            }
        case TablesRefreshActionTypes.INCOMPLETE_TABLE_REFRESH:
            return {
                ...state,
                incompleteTableRefresh: action.data
            }
        case TablesRefreshActionTypes.CONTENT_TABLE_REFRESH:
            return {
                ...state,
                contentTableRefresh: action.data
            }
        default: 
            return state;
    }
}