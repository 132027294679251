import gql from "graphql-tag";

export const LOGOUT_USER = gql`
    mutation logoutUserMutation {
        logout
    }
`;

export const CHECK_USER = gql`
    query checkIfUserExistsQuery( $userId: Int!){
        checkIfUserExists(userId: $userId)
    }
`;