import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectWaitingList = (state: AdminStoreState) => state.waitingList;

export const selectIsModalOpen = createSelector(
    [selectWaitingList],
    (waitingList) => waitingList.isModalOpen
);

export const selectWaitingListData = createSelector(
    [selectWaitingList],
    (waitingList) => waitingList.waitingListData
);

export const selectContentUserId = createSelector(
    [selectWaitingList],
    (waitingList) => waitingList.contentUserId
);

export const getCurrentPageNumber = createSelector(
    [selectWaitingList],
    (waitingList) => waitingList.waitingListCurrentPageNumber
);