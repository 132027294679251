
export enum TablesRefreshActionTypes {
    LOCATION_OVERRIDE_REFRESH = "LOCATION_OVERRIDE_REFRESH",
    WAITING_LIST_REFRESH = "WAITING_LIST_REFRESH",
    USERS_TABLE_REFRESH = "USERS_TABLE_REFRESH",
    SUGGEST_THREAD_REFRESH = "SUGGEST_THREAD_REFRESH",
    REPORTS_TABLE_REFRESH = "REPORTS_TABLE_REFRESH",
    POST_REPORTS_TABLE_REFRESH = "POST_REPORTS_TABLE_REFRESH",
    ORDER_HISTORY_TABLE_REFRESH = "ORDER_HISTORY_TABLE_REFRESH",
    LICENCE_TABLE_REFRESH = "LICENCE_TABLE_REFRESH",
    INCOMPLETE_TABLE_REFRESH = "INCOMPLETE_TABLE_REFRESH",
    CONTENT_TABLE_REFRESH = "CONTENT_TABLE_REFRESH",
}

export interface TablesRefreshState {
    locationOverrideRefresh: boolean;
    waitingListRefresh: boolean;
    usersTableRefresh: boolean;
    suggestThreadRefresh: boolean;
    reportsTableRefresh: boolean;
    postReportsTableRefresh: boolean;
    orderHistoryTableRefresh: boolean;
    licenseTableRefresh: boolean;
    incompleteTableRefresh: boolean;
    contentTableRefresh: boolean;
}