import React, { useState, useEffect } from 'react';
import {DragAndDropProps, UploadedFileResponse, ContentSet, DroppedFile } from './ContentUpload.types';
import Dropzone, { IPreviewProps, ILayoutProps, IInputProps, IFileWithMeta } from 'react-dropzone-uploader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SyncLoader from 'react-spinners/SyncLoader';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box, CircularProgress, CircularProgressProps, Typography, IconButton, Collapse } from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import { TContentUploadActions, IUploadFiles, IDeleteFiles, IUploadSet, IAddFile, IRemoveFile } from '../../redux/content-upload/content-upload.actions';
import { Dispatch } from 'redux';
import { CloseButtonTheme } from './ContentUpload.types';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import { AdminStoreState } from '../../redux/root-reducer';
import { selectUploadedFiles, selectDroppedFiles, selectRadioButtonValue } from '../../redux/content-upload/content-upload.selectors';
import { useMutation } from '@apollo/react-hooks';
import { selectCurrentUserId } from '../../redux/admin/admin.selectors';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import 'react-dropzone-uploader/dist/styles.css';
import './DragAndDropContent.styles.scss';
import Image from 'react-async-image';
import {SnackbarComponent} from 'shared';
import { ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { DELETE_CONTENT } from './queries';

const DragAndDropContentStep: React.FC<DragAndDropProps> = ({...props}) => {
    const {droppedFiles, uploadedFiles, userId, snackbar, setUploadingNewFiles, deleteFilesAction, uploadDroppedFile,
        removeDroppedFile, broadcastSnackbarAction } = props;
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);
    const [chosenFiles, setChosenFiles] = useState<File[]>([]);
    const [lastDroppedFilesLength, setLastDroppedFilesLength] = useState<number>(0);

    const urls = new WeakMap();

    useEffect(() => {
        if(snackbar.severity && snackbar.message) {
            setContentUploadSnackbar(true);
        }
    }, [snackbar])


    useEffect(() => {
        if(droppedFiles.length === lastDroppedFilesLength + chosenFiles.length && typeof(setUploadingNewFiles) === 'function') {
            setUploadingNewFiles!(false);
            setLastDroppedFilesLength(droppedFiles.length);
        }
    }, [droppedFiles]);

    const handleClose = () => {
        setContentUploadSnackbar(false);
    };

    const getBlobUrl = (blob: File) => {
        if (urls.has(blob)) {
            return urls.get(blob)
        } else {
            let url = URL.createObjectURL(blob)
            urls.set(blob, url)
            return url
        }
    }

    const [deleteFileMutation] = useMutation(DELETE_CONTENT);
    const deleteFile = (id: number): void => {
        deleteFileMutation({variables: {id: id}
        }).then((result: any) => {
            deleteFilesAction([result.data.deleteContent]);
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                message:"File deleted successfully"});
        }).catch((error: any) => {
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                message:"Failed to delete file. Something went wrong!"});
        })
    }

    const RemoveUploadedFileButton = (props: { fileId: number, fileWithMeta?: IFileWithMeta}) => {
        return (
            <MuiThemeProvider theme={CloseButtonTheme}>
                <Box position="relative" display="inline-flex" top='-35px' right='-100px'>
                    <IconButton onClick={() => removeUploadedFile(props.fileId, props.fileWithMeta)}>
                        <CancelIcon fontSize='small'  /> 
                    </IconButton>
                </Box>
            </MuiThemeProvider>
        )
    }

    const removeUploadedFile = (fileId: number, fileWithMeta?: IFileWithMeta) => {
        let deletedFile = uploadedFiles.find(file => file.id === fileId);
        if(deletedFile) {
            deleteFile(deletedFile.id);
            deleteFilesAction([fileId]);
            if(fileWithMeta)
                fileWithMeta.remove();
        } else {
            removeDroppedFile(fileId);
            if(fileWithMeta)
                fileWithMeta.remove();
            broadcastSnackbarAction({severity: SeveritySnackbarEnum.success,
                message:"File deleted successfully"});
        }
    }

    const Layout = ({ input, submitButton, dropzoneProps, previews, files }: ILayoutProps) => {
        
        return (
            <div className='dropzone-container'>
                <div {...dropzoneProps} className='dropzone-label'>
                    {input}
                </div>

                <div className='preview-container'>
                    {previews && previews.length > 0
                        ?
                        previews
                        :
                        droppedFiles.map((droppedFile: DroppedFile) => (
                            <div key={droppedFile.fileWithMeta.meta.id} className="preview-box">
                                <RemoveUploadedFileButton fileId={parseInt(droppedFile.fileWithMeta.meta.id)} />
                                <Image src={getBlobUrl(droppedFile.fileWithMeta.file)} className="image"
                                    loading='auto' placeholder={<div className="placeholder">Failed to display...</div>}
                                />
                            </div>
                        ))
                    }

                    {uploadedFiles.map((uploadedFile: UploadedFileResponse) => (
                        <div key={uploadedFile.id} className="preview-box">
                            <RemoveUploadedFileButton fileId={uploadedFile.id} />
                            <Image src={uploadedFile.url} className="image"
                                loading='auto' placeholder={<div className="placeholder">Failed to display...</div>}
                            />
                        </div> 
                    ))}
                </div>

                {userId ? 
                    (files.length > 0 && submitButton)
                :
                    <div className='error-box'>You have to be logged in to submit content for upload</div>
                }   
            </div>
        )
    }

    const Preview = ({fileWithMeta}: IPreviewProps) => {

        return (
            <div key={fileWithMeta.meta.id} className="preview-box">
                <RemoveUploadedFileButton fileId={parseInt(fileWithMeta.meta.id, 10)} fileWithMeta={fileWithMeta} />
                <Image src={getBlobUrl(fileWithMeta.file)} className="image"
                    loading='auto' placeholder={<div className="placeholder">Failed to display...</div>}
                />
            </div>     
        )
    }

    const Input = ({ accept, onFiles }: IInputProps) => {
        return (
            <div>
                {droppedFiles.length === 0 && uploadedFiles.length === 0 ?
                    <label className="dropzone-inputLabel">
                        <input style={{ display: 'none' }} type="file"
                            accept={accept} multiple onChange={(event: any) => {
                                getFilesFromEvent(event).then(chosenFiles => {
                                  onFiles(chosenFiles);
                                })
                              }}
                        />
                        {loadingIndicator ?       
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
                        color={"#36D2B3"} loading={loadingIndicator}/>
                        :
                        <div style={{cursor: "pointer"}}>
                            Click or drag here
                            <CloudUploadIcon className='upload-icon'/>
                        </div>
                        } 
                    </label>
                    :
                    <label className='submit-label'>
                        <input style={{ display: 'none' }} type="file"
                            accept={accept} multiple onChange={(event: any) => {
                                getFilesFromEvent(event).then(chosenFiles => {
                                    onFiles(chosenFiles);
                                })
                            }}
                            />                        
                            {loadingIndicator ?       
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
                        color={"#36D2B3"} loading={loadingIndicator}/>
                        :
                        <Collapse in={true} timeout={500}>
                            <span className='label-text'>Click or drag here</span>
                            <CloudUploadIcon className='upload-icon'/>
                        </Collapse>
                        } 
                    </label>
                }
            </div>
        )
    }

    const getFilesFromEvent = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>) : Promise<any> => {
        setLoadingIndicator(true);
        if(typeof(setUploadingNewFiles) === 'function') {
            setUploadingNewFiles!(true);
        }

        let invalidFiles: string[] = [];

        return new Promise((resolve, reject) => {
            getDroppedOrSelectedFiles(event).then((uploadedFiles: File[]) => {
                const validFiles = uploadedFiles.filter((uploadedFile: any) => {
                    const fileType = uploadedFile.fileObject.type;
                    const type = fileType.substr(fileType.indexOf('/') + 1);
                    if(type == "jpg" || type == "jpeg") {
                        return uploadedFile;
                    }
                    else {
                        invalidFiles.push(uploadedFile.fileObject.name);
                    }
                });
                if(validFiles.length + droppedFiles.length <= 50) {
                    setChosenFiles(validFiles);
                    setLoadingIndicator(false);
                    resolve(validFiles.map((f: any) => {
                        
                        return f.fileObject;
                    }));
                } else {
                    setLoadingIndicator(false);
                    broadcastSnackbarAction({severity: SeveritySnackbarEnum.error,
                        message:"Exceeded maximum file count for upload. The maximum is 50 per upload"});
                    reject();
                }
          }).catch((error: any) => {
              reject(error);
          })
        })
    }

    const onChangeStatus = (file: IFileWithMeta, status: any) => {
        if (status==='preparing') {
            let droppedFile: DroppedFile = {
                fileWithMeta: file, 
                fileObject: chosenFiles.find(f => f.name === file.file.name)
            };

            uploadDroppedFile(droppedFile);            
        }
    }
    
    return (
        <div>
            <Dropzone
                accept="image/jpg,image/jpeg"
                disabled={userId ? false : true}
                LayoutComponent={Layout}
                onChangeStatus={onChangeStatus}
                getFilesFromEvent={getFilesFromEvent}
                PreviewComponent={Preview}
                InputComponent={Input}
                maxFiles={50}
                classNames={{dropzone: 'dropzone-container', 
                inputLabel: 'dropzone-inputLabel'
            }}
            />
            <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                severity={snackbar.severity}
                message={snackbar.message}/>
        </div>
    )
}

const mapStateToProps = (state: AdminStoreState): {uploadedFiles: UploadedFileResponse[], droppedFiles: DroppedFile[]
    , userId: number | undefined, selectedRadioButton: string, snackbar: IBroadcastMessage} => {
    return {
        uploadedFiles: selectUploadedFiles(state),
        droppedFiles: selectDroppedFiles(state),
        userId: selectCurrentUserId(state),
        selectedRadioButton: selectRadioButtonValue(state),
        snackbar: selectSnackbarState(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TSnackbarActions>) => {
    return {
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>({type: ContentUploadActionTypes.UPLOAD_FILES, data: data}),
        deleteFilesAction: (data: number[]) => dispatch<IDeleteFiles>({type: ContentUploadActionTypes.DELETE_FILES, data: data}),
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({type: ContentUploadActionTypes.UPLOAD_SET, data: data}),
        uploadDroppedFile: (data: DroppedFile) => dispatch<IAddFile>({type: ContentUploadActionTypes.ADD_FILE, data: data}),
        removeDroppedFile: (data: number) => dispatch<IRemoveFile>({type: ContentUploadActionTypes.REMOVE_FILE, data: data}),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DragAndDropContentStep);
