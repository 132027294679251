import { TIncompleteSFTPFlowReducerActions } from "./incompleteSFTPUpload.actions";
import { IncompleteSFTPFlowActionTypes, IncompleteSFTPFlowState } from "./incompleteSFTPUpload.types";

const InitialState: IncompleteSFTPFlowState = {
    incompleteSFTPFlowData: [],
    selectedUserId: null,
    incompleteTableCurrentPageNumber:0,
}

export const incompleteSFTPUploadReducer = (state = InitialState, action: TIncompleteSFTPFlowReducerActions): IncompleteSFTPFlowState => {
    switch(action.type) {
        case IncompleteSFTPFlowActionTypes.LOAD_INCOMPLETE_SFTP_DATA: {
            return {
                ...state,
                incompleteSFTPFlowData: action.data
            }
        }
        case IncompleteSFTPFlowActionTypes.SELECT_INCOMPLETE_SFTP_FLOW: {
            return {
                ...state,
                selectedUserId: action.data
            }
        }
        case IncompleteSFTPFlowActionTypes.RESET_INCOMPLETE_STATE: {
            return {
                ...state,
                incompleteSFTPFlowData: [],
                selectedUserId: null
            }
        }
        case IncompleteSFTPFlowActionTypes.SET_INCOMPLETE_TABLE_CURRENT_PAGE:
            return {
                ...state,
                incompleteTableCurrentPageNumber: action.data
            };
        default:
            return state;
    }
}

export default incompleteSFTPUploadReducer;