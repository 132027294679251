export enum SnackbarActionTypes {
    BROADCAST_MESSAGE = "BROADCAST_MESSAGE",
    RESET_MESSAGE = "RESET_MESSAGE"
}

export enum SeveritySnackbarEnum {
    none = '',
    success = "success",
    warning = "warning",
    info = "info",
    error = "error"
}

export interface IBroadcastMessage {
    severity: SeveritySnackbarEnum;
    message: string;
}

export interface SnackbarState {
    snackbar: IBroadcastMessage;
}