import { TLicenseTableReducerActions } from "./licenseTable.actions";
import { LicenseTableActionTypes, LicenseTableState } from "./licenseTable.types";

const InitialState: LicenseTableState = {
    tableData: []
}

export const licenseTableReducer = (state = InitialState, action: TLicenseTableReducerActions) : LicenseTableState => {
    switch(action.type) {
        case LicenseTableActionTypes.LOADED_LICENSE_TABLE_DATA:
            return { 
                tableData: action.data
            };
        case LicenseTableActionTypes.ADD_PLAN:
            return {
                tableData: state.tableData.concat(action.data)
                    .sort((licenseData1, licenseData2) => licenseData2.id - licenseData1.id)
            };
        case LicenseTableActionTypes.UPDATE_PLAN:
            const newData = state.tableData.filter( item => item.id !== action.data.id);
            return {
                tableData: newData.concat(action.data)
                    .sort((licenseData1,licenseData2) => licenseData2.id - licenseData1.id)
            };
        case LicenseTableActionTypes.DELETE_PLAN:
            let newDataDeleted = state.tableData;
            for(let index of action.data) {
                newDataDeleted = newDataDeleted.filter(item => item.id !== index);
            }
            return{
                tableData: newDataDeleted
            };
        default:
            return state;
    }
}

export default licenseTableReducer;

