import { AdminStoreState } from "redux/root-reducer";
import { createSelector } from "reselect";

const contentTableStates = (state: AdminStoreState) => state.contentTable;

export const getCurrentPageNumber = createSelector(
    [contentTableStates],
    (contentTable) => contentTable.currentPageNumber
);

export const getSortDirection = createSelector(
    [contentTableStates],
    (contentTable) => contentTable.sortDirection
);

export const getSortColumn = createSelector(
    [contentTableStates],
    (contentTable) => contentTable.sortColumn
);