import { FlatRate } from "components/license-table/LicenseAdminTable.types";

export enum LicenseTableActionTypes { 
    LOADED_LICENSE_TABLE_DATA = "LOADED_LICENSE_TABLE_DATA",
    ADD_PLAN = "ADD_PLAN",
    UPDATE_PLAN = "UPDATE_PLAN",
    DELETE_PLAN = "DELETE_PLAN"
}

export interface LicenseTableState {
    tableData: FlatRate[];
}