import React, { useEffect, useState } from "react";
import { IconButton, Dialog, Select, Button, Backdrop, Fade, TextField, MuiThemeProvider, InputLabel, Tooltip, withStyles, FormLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CREATE_USER } from './queries';
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { NewUserProps, dialogStyles, RoleTypes, styles } from './usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import { TUserTableReducerActions, IAddUser } from '../../redux/users-table/usersTable.actions';
import { UsersTableActionTypes, UserData } from '../../redux/users-table/usersTable.types';
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PASSWORD_REGEX } from 'shared'
import { ISnackbarMessage, TSnackbarActions } from "../../redux/generic/snackbar/snackbar.actions";
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from "../../redux/generic/snackbar/snackbar.types";
import { AdminStoreState } from "../../redux/root-reducer";
import { selectSnackbarState } from "../../redux/generic/snackbar/snackbar.selectors";
import { SnackbarComponent } from "shared";

const AddNewUserModal: React.FC<NewUserProps> = ({ ...props }) => {
    const { userType, addUserAction, classes, refetch, broadcastSnackbarAction, snackbarMessage } = props;
    const [open, setOpen] = useState(false);
    const [response, setResponseState] = useState("");
    const [userTypeSelected, setTypeSelected] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [createUserMutation] = useMutation(CREATE_USER);

    const isOrganizationSelected = userTypeSelected === RoleTypes.Pro_organization;
    const isAgencySelected = userTypeSelected === RoleTypes.Contributor_agency;
    const isFreelancerSelected = userTypeSelected === RoleTypes.Pro_freelancer;
    const isIndividualSelected = userTypeSelected === RoleTypes.Contributor_individual;

    const contributerTab = (userType === RoleTypes.Contributor_individual || userType === RoleTypes.Contributor_agency);
    const proTab = (userType === RoleTypes.Pro_freelancer || userType === RoleTypes.Pro_organization);
    const adminTab = (userType === RoleTypes.Admin || userType === RoleTypes.SuperAdmin);

    const validationSchema = Yup.object({
        username: Yup.string().required("Username is required."),
        email: Yup.string().email().required("Email is required."),
        password: Yup.string().required("Password is required.").matches(
            PASSWORD_REGEX,
            "Field requires at least 8 Characters, one Uppercase letter, one Number and one special case Character"),
        firstName: Yup.string().when("username", () => {
            if (!isAgencySelected && !isOrganizationSelected) {
                return Yup.string().required("This field is required")
            }
            else {
                return Yup.string().notRequired()
            }
        }),
        lastName: Yup.string().when("username", () => {
            if (!isAgencySelected && !isOrganizationSelected) {
                return Yup.string().required("This field is required")
            }
            else {
                return Yup.string().notRequired()
            }
        }),
        organizationName: Yup.string().when('email', () => {
            if (isAgencySelected || isOrganizationSelected) {
                return Yup.string().required("This field is required")
            }
            else {
                return Yup.string().notRequired()
            }
        })
    });
    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            id: 0,
            username: "",
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            authStrategy: "local",
            role: userTypeSelected,
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values, { resetForm }) {
            try {
                addUserAction(values);
                const { username, email, password, firstName, lastName, organizationName } = values;
                createUserMutation({
                    variables: {
                        username: username,
                        email: email,
                        password: password,
                        role: userTypeSelected,
                        authStrategy: 'local',
                        firstName: firstName,
                        lastName: lastName,
                        organizationName: organizationName
                    }
                }).then((result: any) => {
                    if (result.data.createUser) {
                        handleClose();
                        refetch();
                        resetForm({});
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.success,
                            message: "New user added successfully"
                        });
                    }
                }).catch((error: any) => {
                    setResponseState(error.graphQLErrors[0].message);
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: "Something went wrong when adding user"
                    });
                });
            } catch (error) {
                handleClose();
                resetForm({});
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when adding user"
                });
            }
        },
    });

    const handleChangeAdmin = (event: any) => {
        event.preventDefault();
        setTypeSelected(event.target.value);
    };

    const addNewUser = () => {
        setOpen(true);
    };

    const checkUserRole = () => {
        if (contributerTab) {
            setTypeSelected(RoleTypes.Contributor_agency);
        }
        else if (proTab) {
            setTypeSelected(RoleTypes.Pro_organization);
        }
        else if (adminTab) {
            setTypeSelected(RoleTypes.Admin)
        }
        else setTypeSelected(RoleTypes.Member)
    };

    const handleClose = () => {
        setOpen(false);
        checkUserRole();
    };

    const handleClickType = (event: any) => {
        setTypeSelected(event.target.value)
    };

    useEffect(() => {
        checkUserRole();
    }, [userType]);

    return (
        <React.Fragment>
            <Tooltip title={"Add new " + userType}>
                <IconButton onClick={addNewUser}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Add new user</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit} autoComplete='off'>

                                    <FormLabel htmlFor="username" className='select-label'>Username</FormLabel>
                                    <TextField type='text' autoComplete='off' onChange={handleChange} 
                                        hiddenLabel={true} name='username'
                                        variant='standard' 
                                        error={errors.username === ""}
                                        helperText={errors.username ? errors.username : null}
                                        InputLabelProps={{ shrink: false }} 
                                        value={values.username}
                                        FormHelperTextProps={{ className: classes.error }} />
                                    <FormLabel htmlFor="email" className='select-label'>Email</FormLabel>
                                    <TextField type='text' autoComplete='off' onChange={handleChange} 
                                        hiddenLabel={true} name='email'
                                        variant='standard' 
                                        error={errors.email === ""}
                                        helperText={errors.email ? errors.email : null}
                                        InputLabelProps={{ shrink: false }} 
                                        value={values.email}
                                        FormHelperTextProps={{ className: classes.error }} />
                                    <FormLabel htmlFor="password" className='select-label'>Password</FormLabel>
                                    <TextField type='password' autoComplete='off' onChange={handleChange} 
                                        hiddenLabel={true} name='password'
                                        variant='standard' 
                                        error={errors.password === ""}
                                        helperText={errors.password ? errors.password : null}
                                        InputLabelProps={{ shrink: false }}
                                        value={values.password}
                                        FormHelperTextProps={{ className: classes.error }} />
                                    <div className="mebership-type">
                                        {contributerTab || proTab ?
                                            <div className="button-wrapper">
                                                <RadioGroup name="contributor-type" defaultValue={contributerTab ? RoleTypes.Contributor_agency : RoleTypes.Pro_organization}
                                                    onChange={handleClickType}>
                                                    <FormControlLabel value={contributerTab ? RoleTypes.Contributor_individual : RoleTypes.Pro_freelancer} control={<Radio disableRipple color="default" />} label={contributerTab ? "Individual" : "Freelancer"} />
                                                    <FormControlLabel value={contributerTab ? RoleTypes.Contributor_agency : RoleTypes.Pro_organization} control={<Radio disableRipple color="default" />} label={contributerTab ? "Agency" : "Organization"} />
                                                </RadioGroup>
                                                {isAgencySelected || isOrganizationSelected
                                                    ?
                                                    <TextField autoComplete="off" className='select-label' name="organizationName" 
                                                        onChange={handleChange}
                                                        label={contributerTab ? "Agency name" : "Organization name"}
                                                        variant="standard" 
                                                        value={values.organizationName}
                                                        error={errors.organizationName === ""}
                                                        helperText={errors.organizationName ? errors.organizationName : null}
                                                        FormHelperTextProps={{ className: classes.error }}
                                                    /> : null
                                                }
                                                {isIndividualSelected || isFreelancerSelected
                                                    ?
                                                    <div className="text-field-wrapper">
                                                        <TextField autoComplete="off" className='select-label' name="firstName" 
                                                            onChange={handleChange}
                                                            label="First name" variant="standard" 
                                                            value={values.firstName}
                                                            error={errors.firstName === ""}
                                                            helperText={errors.firstName ? errors.firstName : null}
                                                            FormHelperTextProps={{ className: classes.error }}
                                                        />
                                                        <TextField autoComplete="off" className='select-label' name="lastName" 
                                                            onChange={handleChange}
                                                            label="Last name" variant="standard" 
                                                            value={values.lastName}
                                                            error={errors.lastName === ""}
                                                            helperText={errors.lastName ? errors.lastName : null}
                                                            FormHelperTextProps={{ className: classes.error }}
                                                        />
                                                    </div> : null
                                                }
                                            </div> : null
                                        }
                                        {adminTab ?
                                            <div>
                                                <div className='text-field-wrapper'>
                                                    <FormLabel htmlFor="firstName" className='select-label'>First name</FormLabel>
                                                    <TextField autoComplete='off' name='firstName' onChange={handleChange}
                                                        variant='standard' value={values.firstName}
                                                        error={errors.firstName === ""}
                                                        helperText={errors.firstName ? errors.firstName : null}
                                                        FormHelperTextProps={{ className: classes.error }}
                                                    />
                                                    <FormLabel htmlFor="lastName" className='select-label'>Last name</FormLabel>
                                                    <TextField autoComplete='off' name='lastName' onChange={handleChange}
                                                        variant='standard' value={values.lastName}
                                                        error={errors.lastName === ""}
                                                        helperText={errors.lastName ? errors.lastName : null}
                                                        FormHelperTextProps={{ className: classes.error }}
                                                    />
                                                </div>
                                                <InputLabel className='select-label' htmlFor="age-native-simple">Role</InputLabel>
                                                <Select native defaultValue={RoleTypes.Admin} onChange={handleChangeAdmin}
                                                    inputProps={{ name: 'role', id: 'age-native-simple' }}>
                                                    <option aria-label="None" value="" />
                                                    <option value={RoleTypes.Admin}>Admin</option>
                                                    <option value={RoleTypes.SuperAdmin}>Super Admin</option>
                                                </Select>
                                            </div> : null
                                        }
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Add user</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
            <SnackbarComponent showSnackbar={showSnackbar} handleClose={handleClose}
                    severity={snackbarMessage.severity}
                    message={snackbarMessage.message} />
        </React.Fragment>
    );
}
const mapStateToProps = (state: AdminStoreState): {snackbarMessage: IBroadcastMessage} => {
    return {
        snackbarMessage: selectSnackbarState(state)
    }
}
const mapDispatchToProps = (dispatch: Dispatch<TUserTableReducerActions | TSnackbarActions>) => {
    return {
        addUserAction: (data: UserData) => { dispatch<IAddUser>({ type: UsersTableActionTypes.ADD_USER, data: data }) },
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({ type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddNewUserModal));