import { createSelector } from 'reselect';
import { AdminStoreState } from 'redux/root-reducer';

const selectIncomplete = (state: AdminStoreState) => state.incompleteFlow;

export const selectIncompleteFlowData = createSelector(
    [selectIncomplete],
    (incomplete) => incomplete.incompleteFlowData
);

export const selectCurrentIncompleteFlow = createSelector(
    [selectIncomplete],
    (incomplete) => incomplete.incompleteFlowData.find(
        (flow) => flow.incompleteFlowId === incomplete.selectedIncompleteFlowData)
);

export const getIncompleteTableCurrentPageNumber = createSelector(
    [selectIncomplete],
    (incomplete) => incomplete.incompleteTableCurrentPageNumber
);