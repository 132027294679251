import { IncompleteUserUploadFlow } from "../../components/incomplete-upload-list/incompleteUpload.types";

export enum IncompleteFlowActionTypes{
    LOAD_INCOMPLETE_DATA = "LOAD_INCOMPLETE_DATA",
    SELECT_INCOMPLETE_FLOW = "SELECT_INCOMPLETE_FLOW",
    RESET_INCOMPLETE_STATE = "RESET_INCOMPLETE_STATE",
    SET_INCOMPLETE_TABLE_CURRENT_PAGE = "SET_INCOMPLETE_TABLE_CURRENT_PAGE",
}

export interface IncompleteFlowState {
    incompleteFlowData: IncompleteUserUploadFlow[];
    selectedIncompleteFlowData: string | null;
    incompleteTableCurrentPageNumber: number
}