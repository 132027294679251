import {createTheme, makeStyles} from '@material-ui/core/styles';
import { IFileWithMeta } from 'react-dropzone-uploader';
import {History} from "history";
import { IncompleteUserUploadFlow } from '../incomplete-upload-list/incompleteUpload.types';
import { IBroadcastMessage } from '../../redux/generic/snackbar/snackbar.types';
import { ImageAngle } from '../../redux/EditContent/editContent.types';

export const TIME_BEFORE_REDIRECT = 3000;

export enum RadioButtonGroup {
    INDIVIDUAL = 'individual',
    SET = 'set',
    MOVE_TO_EXISTING_SET = 'move_to_existing_set',
    COPY_TO_EXISTING_SET = 'copy_to_existing_set',
    MOVE_TO_NEW_SET = 'move_to_new_set',
}

export enum UploadSteps {
    SFTP_STEP = "SFTPStep",
    PICK_ITEMS = "Pick items",
    MANAGE_CONTENT = "Manage content",
    ADD_ARTICLE = "Add article (Optional)",
    REVIEW_DATA = "Review data"
}

export enum ContentUploadMethods {
    SFTP_UPLOAD = "SFTP_UPLOAD",
    BROWSER_UPLOAD = "BROWSER_UPLOAD"
}

export interface SlideDialogProps {
    open: boolean;
}

export interface ContentUploadPageProps {
    uploadedFiles: UploadedFileResponse[];
    articles: Article[];
    droppedFiles: DroppedFile[];
    audioFiles: AudioFile[];
    contentSets: ContentSet[];
    individualImageCategories: IndividualImageCategory[];
    snackbar: IBroadcastMessage;
    userId: number | undefined;
    selectedIncompleteSFTPSet: any;
    incompleteSFTPSetContributorId: any;
    imageAngles: ImageAngle[];
    selectedIncompleteFlow: IncompleteUserUploadFlow | undefined;
    uploadFilesAction: (data: UploadedFileResponse[]) => void;
    uploadSetAction: (data: ContentSet) => void;
    updateSetAction: (data: ContentSet) => void;
    updateArticleAction: (data: Article) => void;
    toggleRadioButtonAction: (data: string) => void;
    removeDroppedFile: (data: number) => void;
    resetReduxState: () => void;
    resetSnackbar: () => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
    resetImageAngles: () => void;
    history: History;
}

export interface DroppedFile {
    fileWithMeta: IFileWithMeta;
    fileObject?: any;
}

export interface AudioFile extends DroppedFile {
    articleId: string;
}

export type UploadedFileResponse = {
    id: number;
    filename: string;
    mimetype: string;
    encoding: string;
    key?: string;
    url: string;
    code: string;
    blobObject?: File;
    isApproved?: boolean;
    isDeleted?: boolean;
    movedToSetId?: number;
    moveToNewSetTitle?: string;
    status?: string;
}

export type DeleteObjectResponse = {
    id: number;
}

export type ContentSetUploadResponse = {
    contentSetId: number;
    contentIds: number[];
}

export interface ContentSetUploadResponseInput extends ContentSetUploadResponse {}

export interface IndividualImageCategory {
    selectedThreads: number[];
    contentId: number;
}


export interface DragAndDropProps {
    selectedRadioButton: string;
    droppedFiles: DroppedFile[];
    uploadedFiles: UploadedFileResponse[];
    userId: number | undefined;
    snackbar: IBroadcastMessage;
    setUploadingNewFiles?: (value: boolean) => void;
    deleteFilesAction: (data: number[]) => void;
    uploadDroppedFile: (data: DroppedFile) => void;
    removeDroppedFile: (data: number) => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export interface DragAndDropPropsModal {
    droppedFiles: DroppedFile[];
    uploadedFiles: UploadedFileResponse[];
    userId: number | undefined;
    deleteFilesAction: (data: number[]) => void;
    uploadDroppedFile: (data: DroppedFile) => void;
    removeDroppedFile: (data: number) => void;
}

export interface ManageContentProps {
    selectedRadioButton: string;
    uploadedFiles: UploadedFileResponse[];
    contentSetsState: ContentSet[];
    selectedContentUploadMethod: ContentUploadMethods;
    articles: Article[];
    snackbar: IBroadcastMessage;
    uploadSetAction: (data: ContentSet) => void;
    updateSetAction: (data: ContentSet) => void;
    deleteArticleAction: (data: string) => void;
    deleteSetAction: (data: string) => void;
    uploadFilesAction: (data: UploadedFileResponse[]) => void;
    deleteFilesAction: (data: number[]) => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export interface ArticleTabsProps {
    contentSetsState: ContentSet[];
    changesToBeSaved: boolean;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export interface TabPanelProps {
    contentSetsState: ContentSet[];
    children?: React.ReactNode;
    index: any;
    value: any;
    articles: Article[];
    updateArticleAction: (article: Article) => void;
    uploadArticleAction: (article: Article) => void;
}

export interface ArticleComponentProps {
    contentSetId: string;
    articles: Article[];
    audioId?: string;
    changesToBeSaved: boolean;
    snackbar: IBroadcastMessage;
    uploadArticleAction: (article: Article) => void;
    updateArticleAction: (article: Article) => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
    deleteArticleAction: (data: string) => void;
    toggleChangesToBeSavedAction: (data: boolean) => void;
}

export interface ContentSet {
    id: string;
    title: string;
    files: UploadedFileResponse[];
    coverPhoto?: UploadedFileResponse;
    uploadResponse?: ContentSetUploadResponse;
    selectedThreadIds?: number[];
}

export interface SFTPContentSet {
    id: string;
    title: string;
    files: UploadedFileResponse[];
    coverPhoto?: UploadedFileResponse;
    uploadResponse?: ContentSetUploadResponse;
    selectedThreadIds?: number[];
}

export interface Article {
    contentSetId: string;
    title: string;
    description: string;
    audioId?: number;
    articleId?: number;
}

export interface PopperComponentProps {
    selectedContentSetImages: number[];
    selectedSetId: string;
    //anchorElem: null | HTMLElement;
    contentSets: ContentSet[];
    newSetTitle: string;
    currentSetId: string;
    handleChange: (event: any) => void;
    moveToExistingSet: (selectedSet: ContentSet, selectedUploadedImages?: number[]) => void;
    moveToNewSet: (selectedUploadedImages?: number[]) => void;
}

export const contentUploadTheme = createTheme({
    overrides: {
        MuiFormGroup: {
            root: {
                flexDirection: 'row',
                justifyContent: 'space-evenly'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiStepper: {
            root: {
                margin: '8px',
                padding: '0 !important'
            }
        },
        MuiStepIcon: {
            completed: {
                color: 'rgb(12, 175, 149) !important'
            },
            active: {
                color: 'rgb(12, 175, 149) !important'
            }
        },
        MuiPaper: {
            // elevation3: {
            //     height: '75%'
            // },
            root: {
                borderRadius: '15px !important',
                margin: '0 20px',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: 'rgb(155, 224, 213) !important'
            },
            barColorPrimary: {
                backgroundColor: 'rgb(12, 175, 149) !important'
            }
        }
    }
});

export const ArticleTabsTheme = createTheme({
    overrides: {
        MuiTabs: {
            root: {
                backgroundColor: 'rgb(12, 175, 149)',
                minHeight: '68px'
            }
        },
        MuiTab: {
            root: {
                maxWidth: '200px',
                textOverflow: 'ellipsis',
                minHeight: '68px'
            },
            wrapper: {
                color: 'black',
                borderRadius: '10px',
                paddingLeft: '3%',
                paddingRight: '3%',
                backgroundColor: 'white',
                width: 'auto'
            },
            textColorPrimary: {
                color: 'white !important'
            },
            textColorInherit: {
                opacity: '1'
            }
        }
    }
});

export const AddArticleTheme = createTheme({
    overrides: {
        MuiFormControl: {
            root: {
                marginBottom: '24px',
                marginLeft: '8px',
                marginRight: '8px',
                marginTop: '8px',
                height: '5%'
            }
        },
        MuiFormControlLabel: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiPaper: {
            root: {
                margin: '0 20px'
            }
        },
        MuiInputLabel: {
            formControl: {
                position: 'relative',
                margin: '0 auto',
                marginBottom: '-15px'
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: '2px solid rgb(12, 175, 149)'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                '&:focused': {
                    border: '2px solid rgb(12, 175, 149)'
                }
            }
            
        },
        MuiInputBase: {
            input: {
                textAlign: 'center'
            }
        }
    }
});

export const manageContentTheme = createTheme({
    overrides: {
        MuiListItem: {
            root: {
                justifyContent: 'center',
                height: 'auto',
                width: 'auto',

                "&$hover": {
                    opacity: '0.5'
                },

                "&$selected": {
                    backgroundColor: 'rgba(0, 0, 0, 0.28) !important',
                    // boxShadow: '0 9px 9px 0 rgba(0,0,0,0.24), 0 12px 12px 9px rgba(0,0,0,0.29)',
                    borderRadius: '5px'
                }
            },
            gutters: {
                padding: '8px !important'
            }
        },
        MuiPaper: {
            root: {
                margin: '0'
            }
        },
        MuiAccordion: {
            root: {
                width: '100%',
                marginBottom: '12px'
            }
        },
        MuiAccordionDetails: {
            root: {
                display: 'block'
            }
        },
        MuiAccordionSummary: {
            root: {
                backgroundColor: '#888888'
            },
            content: {
                justifyContent: 'center',
                alignItems: 'center'
            }
        },
        MuiInputBase: {
            input: {
                textAlign: 'center'
            }
        },
        MuiFormControl: {
            root: {
                height: '5%',
                width: '100%'
            }
        },
    }
})

export const LinearProgressTheme = createTheme({
    overrides: {
        MuiLinearProgress: {
            root: {
                width: '100%',
                minWidth: '100px'
            }
        }
    }
})

export const CircularProgressTheme = createTheme({
    overrides: {
        MuiCircularProgress: {
            root: {
                width: '35px !important',
                height: '35px !important'
            },
            colorPrimary: {
                color: 'rgb(12, 175, 149)'
            },
            colorSecondary: {
                color: 'rgb(12, 175, 149)'
            }
        },
        MuiSvgIcon: {
            root: {
                width: '35px',
                height: '35px',
                marginTop: '6px',
                fill: 'white',
                backgroundColor: 'rgb(12, 175, 149)',
                borderRadius: '100px'
            }
        },
        MuiTypography: {
            caption: {
                color: 'white !important',
                fontSize: '0.65rem'
            }
        }
    }
})

export const CloseButtonTheme = createTheme({
    overrides: {
        MuiSvgIcon: {
            root: {
                fill: "gray",
                borderRadius: '100px',
                backgroundColor: 'lightgrey'
            }
        }
    }
})

export const textfieldStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inputBaseRoot: {
        color: "black !important",
        fontWeight: 'bold',
        opacity: '1',
        fontSize: '1.2rem',

        "&:disabled": {
            backgroundColor: 'white !important'
        }
    },
    inputBase: {
        textOverflow: 'ellipsis',
        paddingRight: '10%',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        textAlign: 'center',

        "&:disabled": {
            color: 'black'
        }
    },
    inputUnderline: {
        "&:before": {
            borderBottom: '2px solid black !important'
        },

        "&:after": {
            borderBottom: `2px solid #0CAF95`
        }
    },
    inputAdornmentRoot: {
        position: 'absolute',
        right: '0',
        height: 'auto'
    }
}));
