import { createTheme } from "@material-ui/core/styles";
import { UpdateActionData } from "../../redux/channels-table/channels.types";
import { User } from "../../redux/admin/admin.types";
import { WithStyles } from "@material-ui/core/styles";

export enum TableNames {
	Channels = "CHANNELS",
	SubChannels = "SUBCHANNELS",
	Threads = "THREADS",
}
export interface ChannelsPageProps {
	currentUser: User;
	channels: Channel[];
	subchannels: SubChannel[];
	threads: Thread[];
	loadChannelsAction: (data: Channel[]) => void;
	resetThreadsAction: () => void;
}

export interface ChannelsTableProps {
	tableName: string;
	channelData: Channel[] | SubChannel[] | Thread[];
	selectedChannelId: number;
	selectedSubChannelId: number;
	loadSubchannelsAction: (data: SubChannel[] | number) => void;
	loadThreadsAction: (data: Thread[] | number) => void;
	resetThreadsAction: () => void;
}

export interface Channel {
	id: number;
	title: string;
}

export interface SubChannel extends Channel {
	channelId: number;
}

export interface Thread extends Channel {
	subChannelId: number;
	hasContent: boolean;
}

export interface NewChannelProps extends WithStyles<typeof styles> {
	tableName: string;
	selectedChannelId: number;
	selectedSubChannelId: number;
	selectedThreadId: number;
	selectFormChannels: Channel[];
	selectedItem?: any;
	addChannelAction: (data: any) => void;
}

export interface UpdateChannelProps extends WithStyles<typeof styles> {
	selectFormChannels: Channel[];
	selectedChannel: number;
	tableName: string;
	selectedItem: any;
	updateChannelAction: (data: UpdateActionData) => void;
	loadThreadsAction: (data: Thread[] | number) => void;
}

export interface DeleteChannelProps {
	tableName: string;
	selectedItemId: number;
	deleteChannelAction: (data: any) => void;
}

export const channelsTableTheme = createTheme({
	overrides: {
		MuiContainer: {
			root: {
				width: "auto",
				display: "flex",
				justifyContent: "flex-end",
			},
		},
		MuiDialog: {
			root: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			},
			paper: {
				borderRadius: "25px",
			},
		},
		MuiInputBase: {
			root: {
				marginLeft: "40px",
				width: "80%",
			},
		},
		MuiFormGroup: {
			root: {
				display: "flex",
				flexDirection: "column",
				textAlign: "center",
			},
		},
		MuiFormLabel: {
			root: {
				marginLeft: "40px",
				color: "black",
				fontWeight: "bold",
			},
		},
		MuiFormControl: {
			root: {
				width: "100%",
			},
		},
		MuiListItem: {
			root: {
				"&$selected": {
					border: "2px solid #36D2B3",
				},
			},
		},
		MuiFormControlLabel: {
			root: {
				marginLeft: "30px",
			},
		},
	},
});

export const styles = {
	error: {
	  color: 'red',
	  fontSize: '10px',
	  width: '240px'
	}
  }

export const CHANNEL_TITLE_REGEX = /^((?![\^\~]).)*$/;