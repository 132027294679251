import gql from 'graphql-tag';

export const SINGLE_UPLOAD = gql`
  mutation singleUploadMutation($file: Upload!, $userId: String!) {
    singleUpload(file: $file, userId: $userId) {
      id
      filename
      mimetype
      encoding
      key
      url
      code
    }
  }
`;

export const UPLOAD_SET = gql`
  mutation uploadSet($userId: String!, $contentIds: [Int!]!, $setName: String!, $coverId: Int!) {
    uploadSet(userId: $userId, contentIds: $contentIds, setName: $setName, coverId: $coverId ) {
      contentSetId
      contentIds
    }
  }
`;


export const ADD_ARTICLE = gql`
  mutation addArticle($contentSetId: Int!, $text: String!, $title: String!, $audioId: Int) {
    addArticle(contentSetId: $contentSetId, text: $text, title: $title, audioId: $audioId)
  }
`;


export const ATTACH_ARTICLE_TO_APPROVED_SET = gql`
  mutation attachArticleToApprovedSet($contentSetId: Int!, $text: String!, $title: String!, $audioId: Int) {
    attachArticleToApprovedSet(contentSetId: $contentSetId, text: $text, title: $title, audioId: $audioId)
  }
`;


export const UPDATE_KEYWORDS = gql`
    mutation updateKeywords($contentId: Int!, $keywordsToBeAdded: [String!]!) {
        updateKeywords(contentId: $contentId, keywordsToBeAdded: $keywordsToBeAdded) {
            keywordId
            name
        }
    }
`;

export const DELETE_KEYWORDS = gql`
    mutation deleteKeywords($contentId: Int!, $keywordsToBeDeleted: [String!]!) {
      deleteKeywords(contentId: $contentId, keywordsToBeDeleted: $keywordsToBeDeleted)
    }
`;


export const UPDATE_KEYWORDSTOSET = gql`
    mutation updateKeywordsToSet($contentIds: [Int!]!, $keywordsToBeAdded: [String!]!, $keywordsToBeDeleted: [String!]!) {
        updateKeywordsToSet(contentIds: $contentIds, input: {keywordsToBeAdded: $keywordsToBeAdded, keywordsToBeDeleted: $keywordsToBeDeleted }) 
    }
`;


export const UPDATE_COPYRIGHT = gql`
    mutation updateCopyright($contentId: Int!, $byLine: String!, $credit: String!, $copyrightNotice: String!, $specialInstructions: String!, $sourceUserId: Int) {
        updateCopyright(contentId: $contentId, input: { byLine: $byLine, credit: $credit, copyrightNotice: $copyrightNotice, specialInstructions: $specialInstructions, sourceUserId: $sourceUserId}) {
            copyrightId
            byLine
            credit
            copyrightNotice
            specialInstructions
        }
    }
`;

export const UPDATE_COPYRIGHTS = gql`
    mutation updateCopyrights($contentIds: [Int!]!, $byLine: String!, $credit: String!, $copyrightNotice: String!, $specialInstructions: String!, $sourceUserId: Int) {
      updateCopyrights(contentIds: $contentIds, input: { byLine: $byLine, credit: $credit, copyrightNotice: $copyrightNotice, specialInstructions: $specialInstructions, sourceUserId: $sourceUserId})
    }
`

export const ASSIGN_CONTENT_SET = gql`
  mutation assignContentSet($threadIds: [Int!]!, $contentSetId: Int!, $subchannelId: Int, $suggestedThreadTitle: String, $userId: Int) {
    assignContentSet(threadIds: $threadIds, contentSetId: $contentSetId, subchannelId: $subchannelId, suggestedThreadTitle: $suggestedThreadTitle, userId: $userId)
  }
`

export const UPDATE_CONTENT_SOURCES = gql `
    mutation updateContentsSource($contentIds: [Int!]!, $sourceUserId: Int!) {
      updateContentsSource(contentIds: $contentIds, sourceUserId: $sourceUserId)
    }
`

export const UPDATE_FILE_DETAILS = gql`
    mutation updateFileDetails($contentId: Int!, $fileName: String!, $fileSize: String!, $fileType: String!, $imageWidth: Float!, $imageHeight: Float!, $cameraModel: String!) {
        updateFileDetails(contentId: $contentId, input: { fileName: $fileName, fileSize: $fileSize, fileType: $fileType, imageWidth: $imageWidth, imageHeight: $imageHeight, cameraModel: $cameraModel}) {
            fileDetailId
            fileName
            fileSize
            fileType
            imageWidth
            imageHeight
            cameraModel
        }
    }
`;

export const UPDATE_FILE_SOURCE = gql`
    mutation updateFileSource($contentId: Int!, $source: String!, $createdDate: DateTime!, $createdTime: DateTime!, $uploadedDate: DateTime!, $originalFileName: String!) {
        updateFileSource(contentId: $contentId, input: { source: $source, createdDate: $createdDate, createdTime: $createdTime, uploadedDate: $uploadedDate, originalFileName: $originalFileName}) {
            fileSourceId
            source
            createdDate
            createdTime
            uploadedDate
            originalFileName
        }
    }
`;

export const UPDATE_LOCATION = gql`
    mutation updateLocation($contentId: Int!, $country: String!, $stateProvince: String!, $city: String!, $countryCode: String!, $stateCode: String!, $longitude: Float!, $latitude: Float!) {
        updateLocation(contentId: $contentId, input: { country: $country, stateProvince: $stateProvince, city: $city, countryCode: $countryCode, stateCode: $stateCode, longitude: $longitude, latitude: $latitude}) {
            country{
              country
              countryCode
            }
            province{
              stateProvince
              stateCode
            }
            city{
              city
            }
            locationCoordinate {
              longitude
              latitude
            }
        }
    }
`;

export const UPDATE_CONTENT = gql`
    mutation updateContent($contentId: Int!, $objectName: String!, $headline: String!, $caption: String!, $externalLink: String!, $externalLinkMessage: String!) {
      updateContent(contentId: $contentId, input: { objectName: $objectName, headline: $headline, caption: $caption, externalLink: $externalLink, externalLinkMessage: $externalLinkMessage}) {
        id
        objectName
        headline,
        caption,
        externalLink,
        externalLinkMessage
      }
    }
`;

export const CONTENT_TO_THREAD = gql`
  mutation assignContentToThread($threadIds: [Int!]!, $contentId: Int!) {
    assignContentToThread(threadIds: $threadIds, contentId: $contentId) {
      id
    }
  }
`;

export const CONTENT_SET_TO_THREAD = gql`
  mutation assignContentSetToThread($threadIds: [Int!]!, $contentSetId: Int!) {
    assignContentSetToThread(threadIds: $threadIds, contentSetId: $contentSetId) {
      id
      threadId
      contentSetId
    }
  }
`;


export const UPDATE_LICENSE_TYPE = gql`
    mutation updateLicensingTypeMutation($id: Int!, $licensingType: String!) {
      updateLicensingType(id: $id, licensingType: $licensingType)
    }
`;

export const UPDATE_LICENSE_TYPES = gql`
    mutation updateLicensingTypesMutation($contentIdArray: [Int!]!, $licensingType: String!) {
      updateLicensingTypes(contentIdArray: $contentIdArray, licensingType: $licensingType)
    }
`;

export const SAVE_USER_INCOMPLETE_FLOW = gql`
  mutation saveUserIncompleteUploadFlow($userId: Int!, $flowTitle: String!, $contentIds: [Int!]!, $contentSetIds: [Int!]) {
    saveUserIncompleteUploadFlow(userId: $userId, flowTitle: $flowTitle, contentIds: $contentIds, contentSetIds: $contentSetIds)
  }
`;

export const UPDATE_MAIN_INFORMATION = gql`
  mutation updateMainInformation($contentId: Int!, $objectName: String!, $headline: String!, $caption: String!, $externalLink: String!, $externalLinkMessage: String!) {
    updateMainInformation(contentId: $contentId, input: {objectName: $objectName, headline: $headline, caption: $caption, externalLink: $externalLink, externalLinkMessage: $externalLinkMessage}){
      id,
      objectName,
      headline,
      caption,
      externalLink,
      externalLinkMessage
    }
  }
`

export const UPDATE_MAIN_INFORMATION_SET = gql`
  mutation updateMainInformationToSet($contentIds: [Int!]!, $objectName: String!, $headline: String!, $caption: String!, $externalLink: String!, $externalLinkMessage: String!){
    updateMainInformationToSet(contentIds: $contentIds, input: {objectName: $objectName, headline: $headline, caption: $caption, externalLink: $externalLink, externalLinkMessage: $externalLinkMessage})
  }
`

export const UPDATE_LOCATIONS = gql`
  mutation updateLocations($contentIds: [Int!]!, $country: String!, $stateProvince: String!, $city: String!, $countryCode: String!, $stateCode: String!, $longitude: Float!, $latitude: Float!) {
    updateLocations(contentIds: $contentIds, input: { country: $country, stateProvince: $stateProvince, city: $city, countryCode: $countryCode, stateCode: $stateCode, longitude: $longitude, latitude: $latitude})
  }
`

export const DELETE_CONTENT_FROM_SET = gql`
  mutation deleteContentFromSet($contentId: Int!) {
    deleteContentFromSet(contentId: $contentId)
  }
`;

export const DELETE_CONTENT = gql`
  mutation deleteContentMutation($id: Int!) {
    deleteContent(id: $id)
  }
`;

export const CONTRIBUTOR_AGENCY = gql`
    query getContributorAgenciesQuery {
      getContributorAgencies {
            id
            email
            authStrategy
            role
            company {
                name
            }
        }
    }
`;

export const ROTATE_IMAGE = gql`
  mutation rotateContentNew($contents: [RotateContentDto!]!) {
    rotateContentNew(contents: $contents)
  }
`;