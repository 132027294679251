import { IBroadcastMessage } from "../../redux/generic/snackbar/snackbar.types";
import { LicensePlanTypes } from "../../components/license-table/LicenseAdminTable.types";
import { makeStyles } from "@material-ui/core";
import { RapidApiCountryDto } from "../../globals/locations/location.types";

export interface LocationOverrideTypes {
    licenseType: string;
    snackbarAction: IBroadcastMessage;
    locationOverrideRefreshState: boolean;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
    toggleLocationOverrideRefresh: (data: boolean) => void;
}


export interface LicensingCountries {
    id: number;
    country: RapidApiCountryDto;
    licensingPlanType: LicensePlanTypes;
    percentageFactor: number;
}

export interface AddLocationOverrideTypes {
    tableData: LicensingCountries[];
    refetch: () => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export interface EditLocationOverrideTypes {
    tableMeta: any;
    loadedData: LicensingCountries[];
    refetch: () => void;
    broadcastSnackbarAction: (data: IBroadcastMessage) => void;
}

export const useStyles = makeStyles((theme) => ({
    error: {
        color: 'red',
        fontSize: '10px',
        width: '240px'
    }
}));