import React, { useState } from "react";
import { IconButton, Dialog, Button, Backdrop, Fade, TextField, MuiThemeProvider, Tooltip, withStyles, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ADD_EDITORIAL_RATE } from './queries';
import { NewEditorialRateProps, styles } from './LicenseAdminTable.types';
import { dialogStyles } from '../users-table/usersAdminTable.types';
import { useMutation } from "@apollo/react-hooks";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const AddEditorialRate: React.FC<NewEditorialRateProps> = ({ ...props }) => {
    const { refetch, classes, licenseTable } = props;
    const openRegisterInitial = false;
    const [open, setOpen] = useState(openRegisterInitial);
    const [response, setResponseState] = useState("");
    const [contentType, setContentType] = useState("");
    const [size, setSize] = useState("");

    const [addEditorialRateMutation] = useMutation(ADD_EDITORIAL_RATE);
    const validationSchema = Yup.object({
        rate: Yup.number().required("You must enter content price.")
            .test(
                'Is positive?',
                'The price must be greater than 0!',
                (value: any) => value > 0
            ),
    });

    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            licenseType: "editorial",
            rate: 0,
            contentType: "",
            size: ""
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { rate } = values;
            checkEditorialSize();
        }
    });

    const checkEditorialSize = () => {
        const existRow = licenseTable.filter(editorialRate => editorialRate.contentType === contentType && editorialRate.size === size).length > 0;

        if (existRow) {
            setResponseState("This size already exists for this content type");
        } else {
            addEditorialRateMutation({
                variables: {
                    licenseType: "editorial",
                    contentType: contentType,
                    rate: values.rate,
                    size: size
                }
            }).then((result: any) => {
                if (result.data.addEditorialRate) {
                    refetch()
                    handleClose();
                }
            }).catch((error: any) => {
                //setResponseState(error.graphQLErrors[0].message);
            });
        }
    }

    const addNewUser = () => {
        setOpen(true);
    }
    const handleSelectPeriod = (e: any) => {
        if (e.target.name === "contentType") {
            setContentType(e.target.value)
        }
        if (e.target.name === "size") {
            setSize(e.target.value)
        }

    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <Tooltip title={"Add new editorial rate"}>
                <IconButton onClick={addNewUser}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <MuiThemeProvider theme={dialogStyles}>
                <Dialog closeAfterTransition={true} onClose={handleClose} open={open} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={open}>
                        <div>
                            <IconButton className='close-button' aria-label='google' onClick={handleClose}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes} />
                            </IconButton>

                            <div className='add-user-container'>
                                <h1 className='title'>Add new plan</h1>
                                {response ? <div className='error-box'>{response}</div> : null}
                                <form className='add-user-form' onSubmit={handleSubmit}>
                                    <div className="mebership-type">
                                        <div className="button-wrapper">
                                            <FormControl>
                                                <InputLabel >Content type</InputLabel>
                                                <Select name="contentType" onChange={handleSelectPeriod}>
                                                    <MenuItem value="image">Image</MenuItem>
                                                    <MenuItem disabled value="video">Video</MenuItem>
                                                    <MenuItem disabled value="audio">Audio</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel >Size</InputLabel>
                                                <Select name="size" onChange={handleSelectPeriod} >
                                                    <MenuItem value="small">Small</MenuItem>
                                                    <MenuItem value="medium">Medium</MenuItem>
                                                    <MenuItem value="large">Large</MenuItem>
                                                    <MenuItem value="online only">Online only</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                autoComplete="off" type="number"
                                                className='select-label' name="rate"
                                                onChange={handleChange} label="Price"
                                                variant="standard"
                                                defaultValue={0}
                                                error={errors.rate === ''}
                                                helperText={errors.rate ? errors.rate : null}
                                                FormHelperTextProps={{ className: classes.error }} />

                                        </div>
                                    </div>
                                    <div className='button-group'>
                                        <Button type='submit' className='submit-button' variant='contained'>Add plan</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            </MuiThemeProvider>
        </React.Fragment>
    );
}


export default (withStyles(styles)(AddEditorialRate));