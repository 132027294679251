import React, { useEffect, useState } from 'react';
import { Button, Box, ButtonGroup, Dialog, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import { EditContentProps, TIME_BEFORE_REDIRECT } from './contentList.types';
import EditContentMetadata from '../batch-upload/EditContentMetadata.component';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Dispatch } from 'redux';
import {
    IAddAudioFileResponse,
    IResetContentUpload,
    IToggleRadioButton,
    IUpdateSet,
    IDeleteAudioFile,
    IUploadArticle,
    IUploadFiles,
    IUploadSet,
    TContentUploadActions,
    IResetMovedContents,
    IDeleteSingleContent,
    IResetCopiedContents
} from '../../redux/content-upload/content-upload.actions';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { Article, AudioFile, ContentSet, IndividualImageCategory, RadioButtonGroup, UploadedFileResponse } from '../batch-upload/ContentUpload.types';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import {
    selectArticles,
    selectAudioFileResponse,
    selectAudioFiles,
    selectContentSets,
    selectCopiedContents,
    selectIndividualImagesCategory,
    selectMovedContents,
    selectRadioButtonValue
} from '../../redux/content-upload/content-upload.selectors';
import { AdminStoreState } from '../../redux/root-reducer';
import { GET_CHANNELS } from '../channels-table/queries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_ARTICLE_CONTENT_BY_ID, GET_AUDIO_CONTENT_BY_ID, GET_CONTENT_BY_ID, GET_CONTENT_SET_CONTENT_BY_ID, DELETE_ARTICLE, APPROVE_ARTICLE } from '../waiting-list/queries';
import { connect } from 'react-redux';
import ArticleTabsComponent from '../batch-upload/ArticleTabsComponent';
import {
    UPDATE_ARTICLE,
    EDIT_THREADS_IN_CONTENT_SET,
    EDIT_THREADS_IN_CONTENT,
    UPDATE_CONTENT_SET,
    MOVE_CONTENT_TO_SET,
    MOVE_MULTIPLE_CONTENT_TO_SET,
    COPY_CONTENT_TO_SET, COPY_MULTIPLE_CONTENT_TO_SET
} from './queries';
import { SnackbarComponent } from 'shared';
import { ATTACH_ARTICLE_TO_APPROVED_SET, DELETE_CONTENT, DELETE_CONTENT_FROM_SET, ROTATE_IMAGE } from '../batch-upload/queries';
import { uploadToBucket } from '../batch-upload/uploadToBucketRequest';
import { selectCurrentUser, selectCurrentUserId } from '../../redux/admin/admin.selectors';
import {CopiedContentToSets, MovedContentToSets} from '../batch-upload/EditContentMetadata.types';
import { IBroadcastMessage, SeveritySnackbarEnum, SnackbarActionTypes } from '../../redux/generic/snackbar/snackbar.types';
import { IResetSnackbar, ISnackbarMessage, TSnackbarActions } from '../../redux/generic/snackbar/snackbar.actions';
import '../waiting-list/editWaitingList.styles.scss';
import { selectSnackbarState } from '../../redux/generic/snackbar/snackbar.selectors';
import { RouteParamsContentTypes } from '../../components/waiting-list/waitingList.types';
import { IToggleResetFilter, TTableFiltersReducerActions } from '../../redux/table-filter/table-filter.actions';
import { TableFilterActions } from '../../redux/table-filter/table-filter.types';
import { selectContentUserId } from '../../redux/waiting-list/waitinglist.selectors';
import {config as conf} from "../../config";
import { IEditContentBaseAction, IMultiselectImages, IResetImageAngle, ISelectedImage, ISelectedImages, TEditContentReducerActions } from '../../redux/EditContent/editContent.actions';
import { EditContentActionTypes, ImageAngle } from '../../redux/EditContent/editContent.types';
import { getImageAngles, getSelectedImage, getSelectedImages } from '../../redux/EditContent/editContent.selectors';
import axios from 'axios';
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
import fileDownload from 'js-file-download';
import { Typography } from '@mui/material';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { User } from 'redux/admin/admin.types';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress color="inherit" variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="common.white"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

const EditContent: React.FC<EditContentProps> = ({ ...props }) => {
    const { selectedRadioButton, articles, contentSets, routeParams, audioFiles, contentUserId, currentUser,
        individualImageCategories, audioFilesResponses, userId, movedContents, copiedContents, snackbar, userSelectedImage, userSelectedImages, imageAngles, uploadFilesAction, deleteSingleContentAction,
        uploadSetAction, updateSetAction, uploadArticleAction, addAudioFileResponse, resetReduxState, resetSnackbar, returnToContent, resetImageAngles,
        toggleRadioButtonAction, broadcastSnackbarAction, deleteAudioFile, resetTableFiltersAction, resetMovedContentsAction, resetCopiedContentsAction,setMultiselectImages,setUserSelectedImage,setUserSelectedImages } = props;
    const [open, setOpen] = React.useState(false);
    const [downloadFileName, setDownloadFileName] = useState<string>('WI_Images');
    const [processingForDownload, setProcessingForDownload] = useState<boolean>(false);
    const [downloadUrl, setDownloadUrl] = useState<any>();
    const [dataQuery, setDataQuery] = useState(GET_CHANNELS);
    const [fetchData, { data, loading, error }] = useLazyQuery(GET_CHANNELS, { fetchPolicy: 'no-cache' });
    const [getContentById, { data: contentData, loading: contentLoading, error: contentError }] = useLazyQuery(GET_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [getContentSetById, { data: setData, loading: setLoading, error: setError }] = useLazyQuery(GET_CONTENT_SET_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [getArticleById, { data: articleData, loading: articleLoading, error: articleError }] = useLazyQuery(GET_ARTICLE_CONTENT_BY_ID, { fetchPolicy: 'network-only' });
    const [getAudioById, { data: audioData, loading: audioLoading, error: audioError }] = useLazyQuery(GET_AUDIO_CONTENT_BY_ID, { fetchPolicy: 'network-only' });

    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);

    const [type] = useState(routeParams.type);
    const [articleId, setArticleId] = useState(routeParams.type === RouteParamsContentTypes.ARTICLE ? parseInt(routeParams.id) : 0);
    const [contentSetId, setContentSetId] = useState(routeParams.type === RouteParamsContentTypes.CONTENT_SET ? parseInt(routeParams.id) : 0);
    const [contentId, setContentId] = useState(routeParams.type === RouteParamsContentTypes.IMAGE ? parseInt(routeParams.id) : 0);
    const [channels, setChannels] = useState([]);
    const [audioId, setAudioId] = useState(0);
    const [newArticle, setNewArticle] = useState(false);
    const [audioObject, setAudioObject] = useState<any>({});
    const [redirectToMetadataStep, setRedirectToMetadataStep] = useState(false);
    const [activeMetadataStep, setActiveMetadataStep] = useState(true);
    const [enableMultiselect, setEnableMultiselect] = useState(false);
    const [duplicateFileIds, setDuplicateFileIds] = useState([]);
    const url = `${conf.REACT_APP_ADMIN_SERVER_HOST}/graphql`;
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'content-type': 'multipart/form-data'
        }
    }

    const [rotateImageMutation] = useMutation(ROTATE_IMAGE);
    const rotateImage = (contents: ImageAngle[]): void => {
      rotateImageMutation({
        variables: {
            contents
        },
      });
    };


    const [moveToContentSetMutation] = useMutation(MOVE_CONTENT_TO_SET, {
        onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when updating " + type
            });
        }
    });


    const [copyToContentSetMutation] = useMutation(COPY_CONTENT_TO_SET, {
        onError(error) {
            if(error.graphQLErrors[0].message == "Content already exists in your Selected content set."){
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: error.graphQLErrors[0].message
                });
                return;
            }
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when copying " + type
            });
        }
    });

    const moveToContentSet = async (currentSetId: number, movedContentId: number, newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        return moveToContentSetMutation({ variables: { currentSetId, movedContentId, newSetId, newSetTitle, threadIds } })
            .then((result: any) => {
                if (result.data.moveContentToSet) {
                    return Promise.resolve(result.data.moveContentToSet);
                }
            }).catch((error: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when updating " + type
                });
            })
    }


    const copyToContentSet = async (currentSetId: number, copyContentId: number, newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        return copyToContentSetMutation({ variables: { currentSetId, copyContentId, newSetId } })
            .then((result: any) => {
                if (result?.data?.copyContentToSet) {
                    return Promise.resolve(result.data.copyContentToSet);
                }
            }).catch((error: any) => {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when updating " + type
                });
            })
    }

    const [moveMultipleContentToSetMutation] = useMutation(MOVE_MULTIPLE_CONTENT_TO_SET, {
        onCompleted(data) {
            if (data.moveMultipleContentToSet) {
                resetMovedContentsAction();
                const set = contentSets[0];
                updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
            }
        }, onError(error) {
            resetMovedContentsAction();
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when updating " + type
            });
        }
    });



    const [copyMultipleContentToSetMutation] = useMutation(COPY_MULTIPLE_CONTENT_TO_SET, {
        onCompleted(data) {
            if (data.copyMultipleContentToSet) {
                resetCopiedContentsAction();
                const set = contentSets[0];
                updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
            }
        }, onError(error) {
            const duplicates = error.graphQLErrors[0].extensions.duplicateCopyContentIds;
            // resetCopiedContentsAction();


            if(duplicates.length > 0){


                const duplicateIndexes = duplicates.map((item: {[key: string]: string})=> Number(Object.keys(item)[0])+1);
                const duplicateValues = duplicates.map((item: {[key: string]: string})=> Object.values(item)[0]);

                setDuplicateFileIds(duplicateValues);

                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    // message: `From your selection order ${duplicateIndexes.toString()} content(s) is/are already in the selected content set.`
                    message: "Yellow marked items already exist in destination content set."
                });
            }else{
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when copying " + type
                });
            }




        }
    });

    const moveMultipleContentToSet = async (currentSetId: number, movedContentIds: number[], newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        moveMultipleContentToSetMutation({ variables: { currentSetId, movedContentIds, newSetId, newSetTitle, threadIds } });
    }


    const copyMultipleContentToSet = async (currentSetId: number, copyContentIds: number[], newSetId?: number, newSetTitle?: string, threadIds?: number[]) => {
        copyMultipleContentToSetMutation({ variables: { currentSetId, copyContentIds, newSetId, newSetTitle, threadIds } });
    }

    const getContentToBeMovedIds = (): number[] => {
        const contentIds = movedContents.map((content: MovedContentToSets) => {
            return content.contentId;
        })
        return contentIds;
    }

    const getContentToBeCopiedIds = (): number[] => {
        const contentIds = copiedContents.map((content: CopiedContentToSets) => {
            return content.contentId;
        })
        return contentIds;
    }

    const moveSingleContent = async (threadIds: number[]) => {
        const movedContentsPromises = movedContents.map((content: MovedContentToSets) => {
            if (content.movedSet) {
                return moveToContentSet(contentSetId, content.contentId, content.movedSet.contentSetId);
            } else if (content.newSetTitle) {
                return moveToContentSet(contentSetId, content.contentId, undefined, content.newSetTitle, threadIds);
            }
        })

        await Promise.all(movedContentsPromises)
            .then((result: any[]) => {
                if (result.every((res: any) => res === true)) {
                    resetMovedContentsAction();
                    const set = contentSets[0];
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
            }).catch((error: any) => {
                resetMovedContentsAction();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when moving content to new sets. Try again."
                });
            })
    }

    const moveMultipleContent = (threadIds: number[]) => {
        const contentIds = getContentToBeMovedIds();
        const newSetTitle = movedContents.find((content: MovedContentToSets) => { return content.newSetTitle! !== '' });
        const movedSet = movedContents.find((content: MovedContentToSets) => { return content.movedSet });

        if (newSetTitle?.newSetTitle) {
            moveMultipleContentToSet(contentSetId, contentIds, undefined, newSetTitle?.newSetTitle, threadIds);
        }
        else if (movedSet?.movedSet) {
            moveMultipleContentToSet(contentSetId, contentIds, movedSet.movedSet!.contentSetId, undefined, [movedSet.movedSet!.threadId]);
        }
    }

    const copySingleContent = async (threadIds: number[]) => {
        const copiedContentsPromises = copiedContents.map((content: MovedContentToSets) => {
            if (content.copiedSet) {
                return copyToContentSet(contentSetId, content.contentId, content.copiedSet.contentSetId);
            } else if (content.newSetTitle) {
                return copyToContentSet(contentSetId, content.contentId, undefined, content.newSetTitle, threadIds);
            }
        })

        await Promise.all(copiedContentsPromises)
            .then((result: any[]) => {
                if (result.every((res: any) => res === true)) {
                    resetMovedContentsAction();
                    const set = contentSets[0];
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
            }).catch((error: any) => {
                resetMovedContentsAction();
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Something went wrong when moving content to new sets. Try again."
                });
            })
    }


    const copyMultipleContent = (threadIds: number[]) => {
        const contentIds = getContentToBeCopiedIds();
        const newSetTitle = copiedContents.find((content: CopiedContentToSets) => { return content.newSetTitle! !== '' });
        const copiedSet = copiedContents.find((content: CopiedContentToSets) => { return content.copiedSet });

        if (newSetTitle?.newSetTitle) {
            copyMultipleContentToSet(contentSetId, contentIds, undefined, newSetTitle?.newSetTitle, threadIds);
        }
        else if (copiedSet?.copiedSet) {
            copyMultipleContentToSet(contentSetId, contentIds, copiedSet.copiedSet!.contentSetId, undefined, [copiedSet.copiedSet!.threadId]);
        }
    }

    const [updateContentSetMutation] = useMutation(UPDATE_CONTENT_SET, {
        async onCompleted(data) {
            if (data.updateContentSet) {
                const set = contentSets[0];
                const failedFiles: number[] = [];
                const allPromises = set.files.map(async (file: UploadedFileResponse) => {
                    if (file.isDeleted === true) {
                        return await deleteContentFromSet(file.id)
                            .then(async (singleResponse: UploadedFileResponse) => {
                                return Promise.resolve(singleResponse);
                            }).catch((error: any) => {
                                failedFiles.push(file.id);
                            })
                    }
                });

                await Promise.all(allPromises).then((result: any[]) => {
                    if (result.length !== 0) {
                        if(imageAngles.length > 0)
                        {
                            rotateImage(imageAngles);
                            resetImageAngles();
                        }
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.success,
                            message: "Content update successful. Redirecting to Content table..."
                        });
                        setTimeout(() => {
                            resetReduxState();
                            resetSnackbar();
                            returnToContent();
                        }, TIME_BEFORE_REDIRECT);
                    }
                }).catch((error: any) => {
                    broadcastSnackbarAction({
                        severity: SeveritySnackbarEnum.error,
                        message: "Something went wrong when updating content"
                    });
                }).finally(() => {
                    if (failedFiles.length > 0) {
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.error,
                            message: "Failed to delete files: " + `${failedFiles.join(", ")}`
                        });
                    }
                })
            }
        }, onError(error) {

            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Something went wrong when updating content set"
            });
        }
    });

    const updateContentSet = (setId: number, setTitle: string, coverId: number) => {
        updateContentSetMutation({ variables: { setId, setName: setTitle, coverId } })
    }

    const [deleteFileMutation] = useMutation(DELETE_CONTENT);
    const deleteFile = (id: number) => {
        return deleteFileMutation({
            variables: { id: id }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        })
    }

    const addArticleToContentSet = async () => {
        setNewArticle(true);
        setRedirectToMetadataStep(false);
        setActiveMetadataStep(false);
    }

    const [deleteArticleMutation] = useMutation(DELETE_ARTICLE);
    const deleteArticle = (articleId: number) => {
        return deleteArticleMutation({ variables: { articleId } })
            .then((result: any) => {
                return Promise.resolve(result.data.deleteArticle);
            }).catch((error: any) => {
                return Promise.reject(error);
            })
    }

    const [attachArticleToApprovedSetMutation] = useMutation(ATTACH_ARTICLE_TO_APPROVED_SET, {
        onCompleted(data) {
            if (data.attachArticleToApprovedSet) {
                setArticleId(data.attachArticleToApprovedSet);
            }
        },
        onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to add new article to content set"
            });
        }
    });

    const attachArticleToApprovedSet = (contentSetId: number, title: string, text: string, audioId?: any) => {
        attachArticleToApprovedSetMutation({
            variables: {
                contentSetId, text, title,
                audioId: audioId ? audioId : null
            }
        });
    }

    const [updateArticleMutation] = useMutation(UPDATE_ARTICLE, {
        onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to update article"
            });
        }
    });
    const updateArticle = (contentId: number, title: string, description: string, audioId?: any) => {
        updateArticleMutation({
            variables: {
                articleId: contentId, title, description,
                audioId: audioId ? audioId : null
            }
        })
    }

    const [deleteContentFromSetMutation] = useMutation(DELETE_CONTENT_FROM_SET);
    const deleteContentFromSet = (contentId: number): Promise<any> => {
        return deleteContentFromSetMutation({
            variables: {
                contentId: contentId
            }
        })
    }

    const [editThreadsInContentSetMutation] = useMutation(EDIT_THREADS_IN_CONTENT_SET, {
        async onCompleted(data) {
            const set = contentSets[0];

            if (data.editThreadsInContentSet) {
                if (movedContents.length > 0) {
                    const threadIds = set.selectedThreadIds ? set.selectedThreadIds : [];

                    if (!enableMultiselect) {
                        moveSingleContent(threadIds);
                    }
                    else {
                        moveMultipleContent(threadIds);
                    }
                }
                else if(copiedContents.length > 0){
                    const threadIds = set.selectedThreadIds ? set.selectedThreadIds : [];

                    if (!enableMultiselect) {
                        copySingleContent(threadIds);
                    }
                    else {
                        copyMultipleContent(threadIds);
                    }
                }
                else {
                    //update set
                    updateContentSet(contentSetId, set.title, set.coverPhoto!.id);
                }
            }

        }, onError(error) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: error.graphQLErrors[0].message
            });
        }
    });
    const editThreadsInContentSet = async (threadIds: number[], contentSetId: number) => {
        editThreadsInContentSetMutation({
            variables: { threadIds: threadIds, contentSetId: contentSetId }
        })
    }

    const [editThreadsInContentMutation] = useMutation(EDIT_THREADS_IN_CONTENT);
    const editThreadsInContent = async (threadIds: number[], contentSetId: number) => {
        return editThreadsInContentMutation({
            variables: { threadIds: threadIds, contentId: contentSetId }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        })
    }

    useEffect(()=>{
        return ()=>{
            setUserSelectedImage(undefined)
            setUserSelectedImages([])
            resetImageAngles()
        }
    },[])

    useEffect(()=>{
        if(enableMultiselect && userSelectedImages.length > 0){
            setDownloadUrl(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${userSelectedImages}]`);
        }
        else if(!enableMultiselect){
            if(routeParams.type === RouteParamsContentTypes.CONTENT_SET || routeParams.type === RouteParamsContentTypes.ARTICLE){
                const allContentIds = setData?.getContentSetContentById?.content.map((item: any)=>item?.id);
                setDownloadUrl(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${allContentIds}]`);
            }
            if(routeParams.type === RouteParamsContentTypes.IMAGE){
                setDownloadUrl(`${conf.REACT_APP_IMAGE_DOWNLOAD_PATH}/download?contentIds=[${contentId}]`);
            }
        }
    },[userSelectedImages,userSelectedImage])

    useEffect(() => {
        if (snackbar.severity && snackbar.message) {
            setContentUploadSnackbar(true);
        }
    }, [snackbar])

    useEffect(() => {
        if (error || contentError || setError || articleError || audioError) {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: `Error while fetching: ${error}`
            });
        }
    }, [error, contentError, setError, articleError, audioError])

    useEffect(() => {
        if (data && data.getAllChannels) {
            setChannels(data.getAllChannels);
            if (type === RouteParamsContentTypes.IMAGE) {
                setDataQuery(GET_CONTENT_BY_ID);
            } else if (type === RouteParamsContentTypes.CONTENT_SET) {
                setDataQuery(GET_CONTENT_SET_CONTENT_BY_ID);
            } else if (type === RouteParamsContentTypes.ARTICLE) {
                setDataQuery(GET_ARTICLE_CONTENT_BY_ID);
            }
        }
    }, [data])

    useEffect(() => {
        if (contentData && contentData.getContentById) {
            setContentId(contentData.getContentById.id);
            const file: UploadedFileResponse = {
                id: contentData.getContentById.id,
                filename: '',
                mimetype: '',
                encoding: '',
                key: contentData.getContentById.bucketKey,
                url: contentData.getContentById.pathToFileCompressed,
                code: contentData.getContentById.code,
                blobObject: undefined,
                status: contentData.status
            }
            uploadFilesAction([file]);
        }
    }, [contentData])

    useEffect(() => {
        if (setData && setData.getContentSetContentById) {
            const files: UploadedFileResponse[] = setData.getContentSetContentById.content.map((contentFile: any) => {
                return {
                    id: contentFile.id,
                    key: contentFile.bucketKey,
                    code: contentFile.code,
                    url: contentFile.pathToFileCompressed,
                    status: contentFile.status
                }
            });

            const coverPhotoObj = setData.getContentSetContentById.content.find(
                (content: UploadedFileResponse) => content.id === setData.getContentSetContentById.contentSet.coverId);

            setContentSetId(setData.getContentSetContentById.contentSet.contentSetId);
            const set: ContentSet = {
                id: setData.getContentSetContentById.contentSet.contentSetId.toString(),
                title: setData.getContentSetContentById.contentSet.title,
                files: files,
                coverPhoto: coverPhotoObj ? {
                    id: coverPhotoObj.id, key: coverPhotoObj.bucketKey,
                    code: coverPhotoObj.code, url: coverPhotoObj.pathToFileCompressed,
                    filename: '', mimetype: '', encoding: '', status: coverPhotoObj.status
                } : undefined,
                selectedThreadIds: setData.getContentSetContentById.contentNavigation.map((nav: any) => { return nav.id })
            };
            if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
                toggleRadioButtonAction(RadioButtonGroup.SET);
            }

            uploadSetAction(set);
        }
    }, [setData])

    useEffect(() => {
        if (articleData && articleData.getArticleById) {
            setContentSetId(articleData.getArticleById.contentSetId);
            const article: Article = {
                contentSetId: articleData.getArticleById.contentSetId.toString(),
                title: articleData.getArticleById.title,
                description: articleData.getArticleById.description,
                audioId: articleData.getArticleById.audioId ? articleData.getArticleById.audioId : null
            }
            uploadArticleAction(article);

            if (article.audioId) {
                setAudioId(article.audioId);
                setDataQuery(GET_AUDIO_CONTENT_BY_ID);
            } else {
                setDataQuery(GET_CONTENT_SET_CONTENT_BY_ID);
            }
        }
    }, [articleData])

    useEffect(() => {
        if (audioData && audioData.getAudioContentById) {
            setAudioObject(audioData.getAudioContentById);
            addAudioFileResponse(audioData.getAudioContentById);
            setDataQuery(GET_CONTENT_SET_CONTENT_BY_ID);
        }
    }, [audioData])

    useEffect(() => {
        if (dataQuery === GET_CHANNELS) {
            fetchData();
        } else if (dataQuery === GET_CONTENT_BY_ID && contentId) {
            getContentById({ variables: { contentId: contentId } })
        } else if (dataQuery === GET_CONTENT_SET_CONTENT_BY_ID && contentSetId) {
            getContentSetById({ variables: { contentSetId: contentSetId, approved: true } });
        } else if (dataQuery === GET_ARTICLE_CONTENT_BY_ID && articleId) {
            getArticleById({ variables: { articleId: articleId } });
        } else if (dataQuery === GET_AUDIO_CONTENT_BY_ID) {
            getAudioById({ variables: { audioId: audioId } });
        }
    }, [dataQuery])

    const redirectToStep4 = () => {
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Redirecting to content set..."
        });

        setRedirectToMetadataStep(true);
        setActiveMetadataStep(true);
    }

    const returnToArticle = () => {
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Redirecting to article..."
        });

        setRedirectToMetadataStep(false);
        setActiveMetadataStep(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const startDownload = (fileName: string)=>{
        setProcessingForDownload(true);
        axios({
            url: downloadUrl,
            method: "GET",
            responseType: 'blob',
            params: {
                "token": currentUser?.token
            },
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setProcessingForDownload(false)
        }).catch(e =>{
            setProcessingForDownload(false);
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to download set"
            });
        });
    }

    const handleDownload = (e: any)=>{  
        e.preventDefault();

        if(enableMultiselect && userSelectedImages.length === 0){
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: `Please select an image to download`
            });
        }
        else{
            handleClickOpen();
        }
    }

    const saveChanges = () => {
        let allPromises: Promise<any>[] = [];
        const set = contentSets.length > 0 ? contentSets[0] : null;
        const article = articles.length > 0 ? articles[0] : null;
        const newAudio = audioFiles.length > 0 ? audioFiles[0] : null;
        const existingAudio = audioFilesResponses.length > 0 ? audioFilesResponses[0] : null;

        if (type === RouteParamsContentTypes.ARTICLE && article) {
            saveArticleChanges(set, article, newAudio, existingAudio);
        } else if (type === RouteParamsContentTypes.ARTICLE && !article) {
            if (set && set.selectedThreadIds && set.title && set.coverPhoto) {
                //when article is deleted
                deleteArticle(articleId)
                    .then((result: any) => {
                        if (result) {
                            editThreadsInContentSet(set.selectedThreadIds!, contentSetId);
                        }
                    }).catch((error: any) => {
                        broadcastSnackbarAction({
                            severity: SeveritySnackbarEnum.warning,
                            message: `Error when removing article: ${error.message}`
                        });
                    });
            } else {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.warning,
                    message: "Make sure you have set cover photo and at least 1 navigation."
                });
            }
        } else if (type === RouteParamsContentTypes.CONTENT_SET) {

            if (set && article && newArticle) {

                if (newAudio) {

                    audioFiles.map(async (audioFile: any) => {
                        await uploadToBucket(audioFile.fileWithMeta, contentUserId, url, config)
                            .then((result: any) => {
                                if (result) {
                                    attachArticleToApprovedSet(parseInt(set.id), article.title, article.description, result.id);
                                }
                                else {
                                    attachArticleToApprovedSet(parseInt(set.id), article.title, article.description);
                                }
                            });
                    });
                }
                else {
                    attachArticleToApprovedSet(parseInt(set.id), article.title, article.description);
                }

            }

            if (set && set.selectedThreadIds && set.title && set.coverPhoto) {
                editThreadsInContentSet(set.selectedThreadIds, contentSetId)
            } else {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.warning,
                    message: "Make sure you have set cover photo and at least 1 navigation."
                });
            }
        } else if (type === RouteParamsContentTypes.IMAGE) {
            allPromises = individualImageCategories.map(async (category: IndividualImageCategory) => {
                return await editThreadsInContent(category.selectedThreads, contentId);
            })
        }

        Promise.all(allPromises).then((result: any) => {
            if (result.length !== 0) {
                broadcastSnackbarAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Content updated successfully. Redirecting to Content table..."
                });
                setTimeout(() => {
                    resetReduxState();
                    resetSnackbar();
                    returnToContent();
                }, TIME_BEFORE_REDIRECT);
            }
        }).catch((error: any) => {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: error.graphQLErrors[0].message
            });
        })
    }

    const saveArticleChanges = (set: ContentSet | null, article: Article | null, newAudio: AudioFile | null, existingAudio: any) => {
        let articlePromise: Promise<any>[] = [];

        if (set && article && set.coverPhoto && set.selectedThreadIds) {
            editThreadsInContentSet(set.selectedThreadIds, contentSetId);

            if (newAudio) {
                //if there is a new audioFile for the article
                articlePromise = audioFiles.map(async (audioFile: any) => {
                    return await uploadToBucket(audioFile.fileWithMeta, contentUserId, url, config);
                });
            } else if (existingAudio) {
                //if there is an audio file which already exists in the db
                updateArticle(articleId, article.title, article.description, existingAudio.id);
            } else {
                //if there is no audio file available at all
                if (audioObject.id) {
                    //delete old audio file from db
                    deleteFile(audioObject.id);
                }
                updateArticle(articleId, article.title, article.description);
            }
        } else {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Error when processing article/content set. Please refresh the page and try again"
            });
            deleteAudioFile(article?.articleId);
        }

        Promise.all(articlePromise).then((result: UploadedFileResponse[]) => {
            if (result.length !== 0 && article && set?.selectedThreadIds) {
                updateArticle(articleId, article.title, article.description, result[0].id);
            }
        }).catch((error: any) => {
            broadcastSnackbarAction({
                severity: SeveritySnackbarEnum.error,
                message: "Failed to upload audio file and link it to current article. Try again?"
            });
        })
    }

    const handleClose = () => {
        setContentUploadSnackbar(false);
    }

    const handleMultiSelectButton = () => {
        if (!enableMultiselect) {
            setEnableMultiselect(true);
            setMultiselectImages(true)
        }
        else {
            setEnableMultiselect(false);
            setMultiselectImages(false);
        }
    }

    const handleBackButton = () => {
        returnToContent();
        resetReduxState();
        resetSnackbar();
        // resetTableFiltersAction();
    }
    window.onpopstate = () => {
        returnToContent();
        resetReduxState();
        resetSnackbar();
    }
    const handleDeleteSingleContent = (contentId: number) => {
        broadcastSnackbarAction({
            severity: SeveritySnackbarEnum.success,
            message: "Single content deleted successfully"
        });
        deleteSingleContentAction(contentId);
    }
    if(setData?.getContentSetContentById?.content?.length === 0 || contentData?.getContentById === null)return <ErrorPage/>;

    return (
        <div className="edit-waiting-list-container">
            <Box key={12313}>
                <div className='button-group'>
                    {redirectToMetadataStep || (redirectToMetadataStep && newArticle) ?
                        <Button className='white-button' onClick={() => {
                            returnToArticle();
                        }} variant='contained'><ArrowBackIosIcon />Back to article</Button>
                        :
                        <Button className='white-button' onClick={handleBackButton} variant='contained'><ArrowBackIosIcon />Back</Button>
                    }
                    <ButtonGroup>
                        {
                            (type === RouteParamsContentTypes.CONTENT_SET || type === RouteParamsContentTypes.ARTICLE) && !redirectToMetadataStep ?
                                <Button className='white-button' onClick={() => redirectToStep4()} variant='contained'>Edit content set</Button>
                                :
                                null
                        }

                        {
                            (type === RouteParamsContentTypes.CONTENT_SET || type === RouteParamsContentTypes.ARTICLE) && activeMetadataStep ?
                                !enableMultiselect ?
                                    <Button size='large' className='white-button' variant='contained' onClick={handleMultiSelectButton}>Select multiple photos</Button>
                                    :
                                    <Button size='large' className='red-button' variant='contained' onClick={handleMultiSelectButton}>Discard changes</Button>

                                : null
                        }

                        {
                            type === RouteParamsContentTypes.CONTENT_SET && activeMetadataStep
                                ?
                                <Button size='large' className='white-button' onClick={() => addArticleToContentSet()}
                                    variant='contained'>Add article(optional)</Button>
                                :
                                null
                        }
                        <Button disabled={processingForDownload} size='large' className='green-button' onClick={e=>{
                            handleDownload(e)
                        }}
                        variant="contained">  {processingForDownload ? <CircularProgress color="inherit"/> : "Download"}</Button>
                        <Button className='green-button' onClick={() => saveChanges()} variant='contained'>Save Changes</Button>
                    </ButtonGroup>
                </div>
                {!redirectToMetadataStep ?
                    (type === RouteParamsContentTypes.CONTENT_SET && newArticle) || type === RouteParamsContentTypes.ARTICLE
                        ?
                        <ArticleTabsComponent />
                        :
                        <EditContentMetadata duplicateFileIds={duplicateFileIds} editSetFromAdmin={false} channels={channels} isMultiselectEnabled={enableMultiselect} handleDeleteImage={handleDeleteSingleContent} isInIncomplete={false} />
                    :
                    <EditContentMetadata duplicateFileIds={duplicateFileIds} editSetFromAdmin={false} channels={channels} handleDeleteImage={handleDeleteSingleContent} isInIncomplete={false} />
                }
                <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                    severity={snackbar.severity}
                    message={snackbar.message} />
            </Box>
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogContent>
                <DialogContentText>Please enter file name</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e)=>{
                        setDownloadFileName(e.target.value);
                    }}
                />
                </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={()=>{    
                        startDownload(downloadFileName);
                        handleDialogClose();
                    }}>Download</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state: AdminStoreState): {
    selectedRadioButton: string; contentSets: ContentSet[]; contentUserId: number;
    articles: Article[]; audioFiles: AudioFile[]; individualImageCategories: IndividualImageCategory[];
    userId: number; audioFilesResponses: any; movedContents: MovedContentToSets[];
    copiedContents: CopiedContentToSets[]; snackbar: IBroadcastMessage;userSelectedImage: number | undefined; userSelectedImages: number[]; imageAngles: ImageAngle[]; currentUser: User;
} => {
    return {
        selectedRadioButton: selectRadioButtonValue(state),
        contentSets: selectContentSets(state),
        articles: selectArticles(state),
        audioFiles: selectAudioFiles(state),
        individualImageCategories: selectIndividualImagesCategory(state),
        userId: selectCurrentUserId(state),
        audioFilesResponses: selectAudioFileResponse(state),
        movedContents: selectMovedContents(state),
        copiedContents: selectCopiedContents(state),
        snackbar: selectSnackbarState(state),
        contentUserId: selectContentUserId(state),
        userSelectedImage: getSelectedImage(state),
        userSelectedImages: getSelectedImages(state),
        imageAngles: getImageAngles(state),
        currentUser: selectCurrentUser(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | CallHistoryMethodAction | TSnackbarActions | TTableFiltersReducerActions | TEditContentReducerActions | IEditContentBaseAction>) => {
    return {
        deleteSingleContentAction: (data: number) => dispatch<IDeleteSingleContent>({ type: ContentUploadActionTypes.DELETE_SINGLE_FILE, data: data }),
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>({ type: ContentUploadActionTypes.UPLOAD_FILES, data: data }),
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({ type: ContentUploadActionTypes.UPLOAD_SET, data: data }),
        updateSetAction: (data: ContentSet) => dispatch<IUpdateSet>({ type: ContentUploadActionTypes.UPDATE_SET, data: data }),
        resetReduxState: () => dispatch<IResetContentUpload>({ type: ContentUploadActionTypes.RESET_CONTENT }),
        resetSnackbar: () => dispatch<IResetSnackbar>({ type: SnackbarActionTypes.RESET_MESSAGE }),
        returnToContent: () => dispatch(push(`/content`)),
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>({ type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data }),
        addAudioFileResponse: (data: any) => dispatch<IAddAudioFileResponse>({ type: ContentUploadActionTypes.ADD_AUDIO_FILE_RESPONSE, data: data }),
        deleteAudioFile: (data: any) => dispatch<IDeleteAudioFile>({ type: ContentUploadActionTypes.DELETE_AUDIO_FILE, data: data }),
        toggleRadioButtonAction: (data: string) => dispatch<IToggleRadioButton>({ type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON, data: data }),
        broadcastSnackbarAction: (data: IBroadcastMessage) => dispatch<ISnackbarMessage>({
            type: SnackbarActionTypes.BROADCAST_MESSAGE, data: data
        }),
        resetTableFiltersAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_FILTERS }),
        resetMovedContentsAction: () => dispatch<IResetMovedContents>({ type: ContentUploadActionTypes.RESET_MOVED_CONTENTS }),
        resetCopiedContentsAction: () => dispatch<IResetCopiedContents>({ type: ContentUploadActionTypes.RESET_COPIED_CONTENTS }),
        setMultiselectImages: (data: boolean) =>
        dispatch<IMultiselectImages>({
          type: EditContentActionTypes.SET_MULTISELECT_IMAGES,
          data:data
        }),
        setUserSelectedImage: (data: number | undefined) =>
        dispatch<ISelectedImage>({
        type: EditContentActionTypes.SET_SELECTED_IMAGE,
        data:data
        }),
        setUserSelectedImages: (data: number[]) =>
        dispatch<ISelectedImages>({
            type: EditContentActionTypes.SET_SELECTED_IMAGES,
            data:data
        }),
        resetImageAngles: () =>
        dispatch<IResetImageAngle>({
            type: EditContentActionTypes.RESET_IMAGE_ANGLES
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContent);