export enum UsersTableActionTypes {
    LOADED_DATA = "LOADED_DATA",
    ADD_USER = "ADD_USER",
    UPDATE_USER = "UPDATE_USER",
    DELETE_USER = "DELETE_USER",
    CHANGE_PASSWORD = "CHANGE_PASSWORD"
}

export interface UserData {
    id: number;
    email: string;
    role: string;
    currentUserEmail?: string;
    authStrategy?: string;
    username?: string;
    person?: Person;
    company?: Company;
    firstName?: string;
    lastName?: string;
    organizationName?: string;
    incomePercentage?: number;
    hasSFTPAccess?: boolean;
    hasPublicSFTPAccess?: boolean;
}

export interface Person {
    id: number;
    firstName: string;
    lastName: string;
}

export interface Company {
    id: number;
    name: string;
}

export interface UpdateUserData extends UserData {
    password?: string;
}

export interface DataTableState {
    tableData: UserData[];
}
export interface UpdateUserPassword {
    email: string;
    oldPassword: string;
    password: string;
}