import { UserUploadedContent } from "../../components/content-list/contentList.types";
import { IBroadcastMessage } from "../../redux/generic/snackbar/snackbar.types";
import { ContentTypes } from "../waiting-list/waitingList.types";

export interface CustomToolbarProps {
    activeRows: any[];
    waitingListData: UserUploadedContent[];
    refetch: () => void;
    toolbarError: IBroadcastMessage;
    broadcastToolbarErrorAction: (data: IBroadcastMessage) => void;
}
export interface SelectedTableDataRow {
    contentId: number;
    type: ContentTypes;
}

export enum ToolbarActions { 
    APPROVE = 'approve',
    REJECT = 'reject',
    ERROR_IN_APPROVE = 'Approve_error',
    ERROR_IN_REJECT = 'Reject_error',
    WARNING = 'warning',
    NO_ACTION = 'No_action',
    FETCH_ERROR = 'Fetch_error'
}