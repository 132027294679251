import { TSingleItemReducerActions } from './singleItem.actions';
import { SingleItemActionTypes, SingleItemState } from './singleItem.types';

const InitialState: SingleItemState = {
    selectedItem: {
        selectedThreads: [],
        contentId: 0,
    }  
}

export const singleItemReducer = (state = InitialState, action: TSingleItemReducerActions): SingleItemState => {
    switch(action.type) {
        case SingleItemActionTypes.SET_SELECTED_ITEM:
            return {
                selectedItem: action.data
            };
        default:
            return state;
    }
}

export default singleItemReducer;