export enum ContentTableActionTypes {
    SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
    SET_SORT_DIRECTION = "SET_SORT_DIRECTION",
    SET_SORT_COLUMN = "SET_SORT_COLUMN",
}

export interface ContentTableState {
    currentPageNumber: number;
    sortDirection: string | null;
    sortColumn: string | null;
}