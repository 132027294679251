import React from 'react'
import './ErrorPage.styles.scss';
export const ErrorPage = ()=> {
  return (
    <div className='errorDiv'>
        <h1 className='text large'>404</h1>
        <p className='text small'>The page you are looking for does not exist</p>
    </div>
  )
}
